export type ResponsiveSizes = "xs" | "sm" | "md" | "lg" | "xl"
export const RESPONSIVE_SIZES = ["xs", "sm", "md", "lg", "xl"];
export enum ResponsiveBreakpoints {
  xs = 0,
  sm = 767,
  md = 1023,
  lg = 1365,
  xl = 1920
}

export const COLORS = {
  RED: "#e82268",
  GREEN: "#00cfac",
  PURPLE: "#5b7ade",
  LINK_BLUE: "#82a0ff",
  GREY: "#8a8f94",
  YELLOW: "#f1c844"
};

export type ThemeProps = {
  breakpoints: {
    keys: any,
    up: (arg0: ResponsiveSizes) => string,
    down: (arg0: ResponsiveSizes) => string,
  },
  spacing: (arg0: number) => number
}

const theme: ThemeProps = {
  breakpoints: {
    keys: RESPONSIVE_SIZES,
    up: key => `@media (min-width:${ResponsiveBreakpoints[key]}px)`,
    down: key => `@media (max-width:${ResponsiveBreakpoints[key]}px)`
  },
  spacing: value => {
    // Each whole number increment is an additional 8px
    // For more fine grained concrol you can increment by quarters of a #.
    // eg: 2 = 16px, 2.5 = 20px, 4 = 32px
    if (value % 0.25 !== 0) {
      throw new Error("0.25 is the minimal increment for spacing values.");
    }
    return value * 8;
  }
};

export default theme;
