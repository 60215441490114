import React from "react";
import { withStyles, makeStyles } from "@material-ui/styles";
import Slider from "@material-ui/core/Slider";
import Box from "components/Box";
import { MainMetrics } from "components/Typography";
import { COLORS } from "styles/theme";

const useStyles = makeStyles({
  sliderColor: {
    color: isLong => (isLong ? COLORS.GREEN : COLORS.RED)
  }
});

const LeverageSlider = ({ isLong, onChange, leverageAmount }) => {
  const minLeverage = 2;
  const maxLeverage = 10;

  const { sliderColor } = useStyles(isLong);
  const renderSlider = () => (
    <ThemedSlider
      className={sliderColor}
      min={minLeverage}
      max={maxLeverage}
      aria-label="slider"
      defaultValue={leverageAmount}
      valueLabelDisplay="on"
      valueLabelFormat={leverage => `${leverage}x`}
      onChange={(e, v) => onChange(v)}
    />
  );

  return (
    <Box>
      {renderSlider()}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <MainMetrics>{minLeverage}x</MainMetrics>
        </Box>
        <Box>
          <MainMetrics>{maxLeverage}x</MainMetrics>
        </Box>
      </Box>
    </Box>
  );
};

export default LeverageSlider;

const ThemedSlider = withStyles({
  root: {
    // color: "#00a7aa",
    height: 6,
    padding: "14px 0 6px"
  },

  valueLabel: {
    top: -16,
    left: -8,
    "& *": {
      background: "transparent",
      color: "#ddd",
      fontSize: 10,
      fontWeight: 500
    }
  },
  track: {
    height: 6
  },
  rail: {
    height: 6,
    backgroundColor: "#bfbfbf",
    overflow: "hidden",
    borderRadius: 2
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 6,
    width: 1,
    marginTop: -0
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor"
  },
  thumb: {
    padding: 8
  }
})(Slider);
