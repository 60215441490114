import { FsRootState } from "redux/store";
import { TokenProperties } from "../wallet/model";

// TODO(dankurka): refactor and remove
export interface SplicedTokenProperties extends TokenProperties {
  indexPrice?: string;
  marketPrice?: string;
  isDepositing: boolean;
  price?: string;
}

// TODO(dankurka): refactor and remove
export interface FsTokenProperties {
  localBalance?: string;
  address: string;
}

// TODO(dankurka): refactor data model and delete
export const getExchangeTokenProperties = (state: FsRootState) => {
  // get the FsToken
  const { fsTokenAddress } = state.registry;
  const fsTokenState = state.wallet.tokens[fsTokenAddress];
  const fsTokenProperties: FsTokenProperties = {
    address: fsTokenAddress,
    localBalance: fsTokenState ? fsTokenState.localBalance : undefined
  };

  // TODO(dankurka): Why are we loading other data here and splicing it in?
  // Should propably not be on the registry (maybe wallet instead?)
  const dynamicExchangeData = state.dynamicExchangeData.exchange;

  // get stable and asset value tokens

  const exchangeProperties = state.registry.exchange;

  const {
    assetToken,
    stableToken,
    assetSymbol,
    stableSymbol
  } = exchangeProperties;

  const assetTokenState = state.wallet.tokens[assetToken];
  const stableTokenState = state.wallet.tokens[stableToken];

  const assetTokenProperties: SplicedTokenProperties = {
    address: assetToken,
    indexPrice: dynamicExchangeData?.assetPrice,
    marketPrice: dynamicExchangeData?.marketAssetPrice,
    symbol: assetSymbol,
    localBalance: assetTokenState ? assetTokenState.localBalance : "0",
    fsBalance: assetTokenState ? assetTokenState.fsBalance : "0",
    isUnlocked: assetTokenState ? assetTokenState.isUnlocked : false,
    // TODO(dankurka): This is very suspecious since it was not on the model before
    isUnlocking: assetTokenState ? assetTokenState.isUnlocking : false,
    decimals: assetTokenState ? assetTokenState.decimals : "18",
    isDepositing: state.wallet.requests.tokensBeingDeposited.includes(
      assetToken
    )
  };
  const stableTokenProperties: SplicedTokenProperties = {
    address: stableToken,
    price: dynamicExchangeData?.stablePrice,
    symbol: stableSymbol,
    localBalance: stableTokenState ? stableTokenState.localBalance : "0",
    fsBalance: stableTokenState ? stableTokenState.fsBalance : "0",
    isUnlocked: stableTokenState ? stableTokenState.isUnlocked : false,
    isUnlocking: stableTokenState ? stableTokenState.isUnlocking : false,
    decimals: stableTokenState ? stableTokenState.decimals : "18",
    isDepositing: state.wallet.requests.tokensBeingDeposited.includes(
      stableToken
    )
  };

  return {
    assetTokenProperties,
    stableTokenProperties,
    fsTokenProperties
  };
};
