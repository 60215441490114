import React, { useState } from "react";

import Modal from "../Modal";
import Box from "../Box";
import Button from "../Button";
import { COLORS } from "../../styles/theme";
import styled from "@emotion/styled";
import { A } from "components/Typography";

const SunsetV2Modal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const renderContinueButton = () => (
    <Button
      color={COLORS.PURPLE}
      size="lg"
      onClick={() => setIsOpen(false)}
      fluidWidth={true}
    >
      Ok, continue
    </Button>
  );

  return (
    <Modal isOpen={isOpen}>
      <Box>
        <Header>Getting ready for V3</Header>
        <Box mb={3} mt={2}>
          <Box mb={2}>
            To prepare for our next version and preserve FST rewards, we're
            turning off trading on the following timeline:
          </Box>
          <Box mb={1}>
            <b>May 29th</b>: Open fees will be increased to disincentivize new
            trades.
          </Box>
          <Box mb={1}>
            <b>May 30th</b>: End rewards and disable opening new trades
          </Box>
          <Box mb={1}>
            <b>May 31st</b>: Turn on the time fee to encourage the remaining
            trades to close.
          </Box>
          <Box mb={2}>
            <b>June 2nd</b>: Sunset trading and end exchange rewards
            distribution. If you're a liquidity provider, there's no need to
            take action - liquidity can be withdrawn at any time.
          </Box>
          <Box>
            <A
              href="https://medium.com/futureswap/getting-ready-for-v3-6d92d596c526"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more about the v3 roadmap
            </A>
            .
          </Box>
        </Box>
      </Box>
      <Box mb={2}>{renderContinueButton()}</Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box>
          <A
            href="https://discord.gg/ucEsZ4v"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </A>{" "}
          ·{" "}
          <A href="https://twitter.com/futureswapx" target="_blank">
            Twitter
          </A>
        </Box>
      </Box>
    </Modal>
  );
};

export default SunsetV2Modal;

const Header = styled.h2`
  font-weight: 500;
  font-size: 26px;
  color: #eee;
  text-align: center;
  line-height: 1.2em;
`;
