import { getVotingContractInstance } from "contracts/VotingContract";
import { getErc20Instance } from "contracts/ERC20";
import { toWei, fromWei } from "utils/numbers";
import { BigNumber } from "ethers";

export const getGovernanceState = async votingContractAddress => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );

  const voteThreshold = 10; //10%
  const proposalFee = toWei("100");
  const [
    isProposalCreationPaused,
    proposalCount,
    proposalFstStake,
    minimumVoteTime
  ] = await Promise.all([
    votingContractInstance.methods.pauseTimestamp().call(),
    votingContractInstance.methods.proposalCount().call(),
    votingContractInstance.methods.proposalFstStake.call().call(),
    votingContractInstance.methods.minimumVoteTime.call().call()
  ]);

  return {
    minTime: minimumVoteTime,
    voteThreshold,
    isProposalCreationPaused,
    proposalCount,
    proposalFee,
    proposalFstStake: fromWei(proposalFstStake)
  };
};

export const getProposal = async (votingContractAddress, id) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  return await votingContractInstance.methods.proposals(id).call();
};

export const getProposalsDataFromContractState = async votingContractAddress => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const proposalCount = await votingContractInstance.methods
    .proposalCount()
    .call();

  const proposalDataPromises = [];
  for (let i = 0; i < proposalCount; i++) {
    proposalDataPromises.push(getProposal(votingContractAddress, i));
  }

  return await Promise.all(proposalDataPromises);
};

export const getFstData = async (
  fstAddress,
  accountAddress,
  votingContractAddress
) => {
  const fstInstance = getErc20Instance(fstAddress);
  const totalFstSupply = await fstInstance.methods.totalSupply().call();

  const amountOfAssetTokensApproved = await fstInstance.methods
    .allowance(accountAddress, votingContractAddress)
    .call();
  const isFstUnlocked = !BigNumber.from(amountOfAssetTokensApproved).isZero();

  return { totalFstSupply, isFstUnlocked };
};

export const handleUnlockFst = async (
  dispatch,
  fstAddress,
  accountAddress,
  votingContractAddress
) => {
  const fstInstance = getErc20Instance(fstAddress);
  const unlockTransaction = await fstInstance.methods
    .approve(votingContractAddress, toWei("1000000000000000000000000000000"))
    .send({ from: accountAddress })
    .on("transactionHash", () => {
      dispatch.governance.setRequestsValues({ isUnlockingFst: true });
    });

  if (unlockTransaction.status === true) {
    dispatch.governance.setGovernanceData({ isFstUnlocked: true });
  }
  dispatch.governance.setRequestsValues({ isUnlockingFst: false });
};

export const castVote = async (
  dispatch,
  accountAddress,
  votingContractAddress,
  id,
  voteYes
) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  await votingContractInstance.methods
    .vote(id, voteYes)
    .send({ from: accountAddress })
    .on("transactionHash", () => {
      dispatch.governance.setRequestsValues({ isVoting: true });
    });
};

//@TODO we should just use the events now?
export const getUserProposalDetails = async (
  accountAddress,
  votingContractAddress,
  id
) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );

  let didAddressVote = await votingContractInstance.methods
    .didAddressVote(id, accountAddress)
    .call();

  if (!didAddressVote) {
    didAddressVote = false;
  }

  const directionVoted = false;

  return { didAddressVote, directionVoted };
};

export const handleResolve = async (
  id,
  isUpgradingVotingContract,
  votingContractAddress,
  accountAddress,
  dispatch
) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );

  if (isUpgradingVotingContract) {
    await votingContractInstance.methods
      .resolveUpgradeVotingContract(id)
      .send({
        from: accountAddress
      })
      .on("transactionHash", () => {
        dispatch.governance.setRequestsValues({ isResolving: true });
      });
  } else {
    await votingContractInstance.methods
      .resolve(id)
      .send({
        from: accountAddress
      })
      .on("transactionHash", () => {
        dispatch.governance.setRequestsValues({ isResolving: true });
      });
  }
};

export const getProposalCreatedEvents = async votingContractAddress => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const proposals = await votingContractInstance.getPastEvents(
    "ProposalCreated",
    {
      fromBlock: 7019690,
      toBlock: "latest"
    }
  );

  return proposals.map(e => e.returnValues);
};

export const getCurrentUserVotedCastedEvents = async (
  votingContractAddress,
  userAddress
) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const proposals = await votingContractInstance.getPastEvents("VotedCasted", {
    filter: { voter: userAddress },
    fromBlock: 7019690,
    toBlock: "latest"
  });

  return proposals.map(e => e.returnValues);
};

export const getProposalCallFailedEvents = async votingContractAddress => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const proposals = await votingContractInstance.getPastEvents(
    "ProposalCallFailed",
    {
      fromBlock: 7019690,
      toBlock: "latest"
    }
  );

  return proposals.map(e => e.returnValues);
};

export const getProposalAcceptedEvents = async votingContractAddress => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const proposals = await votingContractInstance.getPastEvents(
    "ProposalAccepted",
    {
      fromBlock: 7019690,
      toBlock: "latest"
    }
  );

  return proposals.map(e => e.returnValues);
};
