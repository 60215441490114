import React, { useState } from "react";
import { COLORS } from "styles/theme";
import moment from "moment";
import { useSelector } from "react-redux";
import { fromWei } from "utils/numbers";
import {
  selectPendingCloseTradeTransaction,
  selectIsTradePendingClose
} from "redux/messageProcessor/selectors";
import Button from "components/Button";
import Box from "components/Box";
import { Text } from "components/Typography";
import { formatNumber } from "utils/formatter";
import ListItem from "components/ListItem";
import { MESSAGE_PROCESSOR_TRANSACTION_STATUS } from "../../../constants";
import styled from "@emotion/styled";
import AddCollateral from "./OpenPositionActions/AddCollateral";
import ClosePosition from "./OpenPositionActions/ClosePosition";
import { FsRootState } from "redux/store";
import PulsatingWarningDot from "components/PulsatingWarningDot";

const PositionItemDetails = ({ trade }) => {
  const [addCollateralClicked, setAddCollateralClicked] = useState(false);
  const [closeTradeClicked, setCloseTradeClicked] = useState(false);

  const isCloseTradePending = useSelector(selectPendingCloseTradeTransaction);
  const isTradeClosing = useSelector((state: FsRootState) =>
    selectIsTradePendingClose(state, trade.tradeId)
  );

  const renderActionButtons = () => (
    <Box display="flex">
      <Box mr={2}>
        <Button
          color={COLORS.PURPLE}
          onClick={() => {
            setAddCollateralClicked(!addCollateralClicked);
            setCloseTradeClicked(false);
          }}
          disabled={
            trade.state.status !== MESSAGE_PROCESSOR_TRANSACTION_STATUS.MINED
          }
        >
          add collateral
        </Button>
      </Box>
      <Box mr={2}>
        <Button
          color={COLORS.PURPLE}
          onClick={() => {
            setCloseTradeClicked(!closeTradeClicked);
            setAddCollateralClicked(false);
          }}
          isLoading={isTradeClosing || isCloseTradePending}
          disabled={
            trade.state.status !== MESSAGE_PROCESSOR_TRANSACTION_STATUS.MINED
          }
        >
          close trade
        </Button>
      </Box>
    </Box>
  );

  const calculateCollateralPlusPL = () => {
    const value = (
      Number(fromWei(trade.collateral)) +
      Number(fromWei(trade.profitLoss.value))
    ).toFixed(2);

    return value;
  };

  const renderInfo = () => {
    return (
      <Box display={{ xs: "block", md: "flex" }} width="100%">
        <Box display="flex" flex="1">
          <Box mr={4}>
            <ListItem label="OPENED">
              {moment.unix(trade.creationTime).fromNow()}
            </ListItem>
          </Box>

          <Box mr={4}>
            <ListItem label="Trade ID">
              <Truncate>{trade.tradeId}</Truncate>
            </ListItem>
          </Box>
          <Box mr={4}>
            <ListItem label="Trade Status">
              {trade.state.status === "mined" ? "mined" : "mining"}
            </ListItem>
          </Box>
          <Box mr={4}>
            <ListItem label="Transaction Hash">
              {trade.transactionHash ? (
                <Truncate>
                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href={`https://etherscan.io/tx/${trade.transactionHash}`}
                  >
                    {trade.transactionHash}
                  </Link>
                </Truncate>
              ) : (
                "pending"
              )}
            </ListItem>
          </Box>
          <Box mr={4}>
            <ListItem label="Collateral + P/L">
              {`$${calculateCollateralPlusPL()}`}
            </ListItem>
          </Box>
          <Box mr={4}>
            <ListItem label="Funding P&L">
              {`$${formatNumber(fromWei(trade.profitLoss.fundingPL), 2)}`}
            </ListItem>
          </Box>
        </Box>
        <Box mt={{ xs: 3, md: 0 }}>{trade.isOpen && renderActionButtons()}</Box>
      </Box>
    );
  };
  return (
    <>
      <Box mb={1.5}>
        <Text color="#f1c844">
          Please close any remaining trades - rewards have ended and trades will
          incur a 2% 8hr time fee
        </Text>
      </Box>
      {renderInfo()}
      {addCollateralClicked && (
        <AddCollateral
          trade={trade}
          setAddCollateralClicked={setAddCollateralClicked}
        />
      )}
      {closeTradeClicked && (
        <ClosePosition
          trade={trade}
          setCloseTradeClicked={setCloseTradeClicked}
        />
      )}
    </>
  );
};

export default PositionItemDetails;

const Truncate = styled.div`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Link = styled.a`
  color: white;
`;
