import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Location } from "@reach/router";
import ApiErrorModal from "components/ApiErrorModal";
import ExchangeStatusModal from "components/ExchangeStatusModal";
import UnsupportedRegionModal from "components/UnsupportedRegionModal";
import TermsModal from "components/TermsModal";
import NetworkModal from "components/NetworkModal";
import SunsetV2Modal from "components/SunsetV2Modal";
import Positions from "./Positions";
import ContractWithdraw from "./ContractWithdraw";
import Pool from "./Pool";
import PoolDetails from "./Pool/PoolDetails";
import PoolsList from "./Pool/PoolsList";
import Wallet from "./Wallet";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiesPolicy from "./CookiesPolicy";
import Rewards from "./Rewards";
import useLocalStorage from "hooks/useLocalStorage";
import { REFERRAL_QUERY_PARAM } from "../constants";
import { initializeApp } from "redux/actions";
import Vesting from "./Vesting";
import Balancer from "./Balancer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getProvider from "provider";
// import Referrals from "./Referrals";
import Governance from "./Governance";
import Proposals from "./Governance/ProposalsPage";
import Proposal from "./Governance/ProposalPage";
import { toast } from "react-toastify";
import { FunctionId } from "offchainTradingSystem/signing/UserMessageCoder";

const Main = () => {
  const dispatch = useDispatch();
  const { exchange } = useSelector(state => state.registry);
  // assume mainnet until proven otherwise so that modal doesn't flicker in
  const [localStateNetwork, setLocalStateNetwork] = useState("main");
  // eslint-disable-next-line no-unused-vars
  const [_, setReferralAddress] = useLocalStorage(REFERRAL_QUERY_PARAM);

  useEffect(() => {
    const init = async () => {
      await getWalletStatus();
      setReferralAddressIfExists();
      const network = await getProvider().eth.net.getNetworkType();
      if (network === "main" || network === "kovan") {
        // TODO(dankurka): Missing error handling
        await initializeApp(dispatch);
      } else {
        setLocalStateNetwork(network);
      }
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async () => {
          dispatch.wallet.setRequestsValues({ isInitialized: false });
          if (network === "main" || network === "kovan") {
            await Promise.all([
              getWalletStatus(),
              dispatch.wallet.initializeWalletInfo()
            ]);
          }
        });
        window.ethereum.on("networkChanged", () => {
          window.location.reload();
        });
      }
    };

    init();
  }, []);

  const setReferralAddressIfExists = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get(REFERRAL_QUERY_PARAM);
    if (referral) {
      setReferralAddress(referral);
    }
  };

  const getWalletStatus = async () => {
    let isMetaMaskLoggedIn = false;
    let isKovanConnected = false;

    await getProvider().eth.net.getNetworkType((err, network) => {
      isKovanConnected = network === "kovan";
    });

    const accounts = await getProvider().eth.getAccounts();
    if (accounts.length !== 0) {
      const accountAddress = accounts[0];
      // fullstory
      if (window.FS) {
        window.FS.identify(accountAddress);
        window.FS.setUserVars({ accountAddress });
      }
      isMetaMaskLoggedIn = true;
    }
  };

  // TODO(dankurka): This is not a good place for this
  const { transactions } = useSelector(state => state.messageProcessor);
  const [sentTransactions, setSentTransactions] = useState(new Set());
  const [transactionSet, setTransactionSet] = useState(new Set());

  const renderToast = (message, autoClose) => {
    toast.dark(message, {
      position: "top-right",
      autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined
    });
  };

  useEffect(() => {
    const transactionArray = transactions.values();
    for (const transaction of transactionArray) {
      const isInstantWithdraw =
        transaction.functionId === FunctionId.INSTANT_WITHDRAW_ID;
      if (
        !sentTransactions.has(transaction.id) &&
        transaction.status === "sent"
      ) {
        sentTransactions.add(transaction.id);
        setSentTransactions(sentTransactions);
      }

      if (transactionSet.has(transaction.id)) {
        continue;
      }

      if (transaction.status === "error") {
        // ERRORS need to be displayed better
        renderToast(transaction.errorReason, false);
        transactionSet.add(transaction.id);
        setTransactionSet(transactionSet);
        continue;
      }

      if (isInstantWithdraw) {
        if (
          transaction.status === "published" ||
          transaction.status === "mined"
        ) {
          renderToast(transaction.successMessage, true);
          transactionSet.add(transaction.id);
          setTransactionSet(transactionSet);
          continue;
        }
      } else {
        if (
          transaction.status === "signed" ||
          transaction.status === "published" ||
          transaction.status === "mined"
        ) {
          renderToast(transaction.successMessage, true);
          transactionSet.add(transaction.id);
          setTransactionSet(transactionSet);
          continue;
        }
      }
    }
  }, [transactions]);

  return (
    <Location>
      <Router primary={false}>
        <Positions path="/" />
        <Positions path="positions" />
        <Governance path="governance">
          <Proposals path="/" />
          <Proposal path="proposal/:id" />
        </Governance>

        <Balancer path="balancer" />
        <Pool path="pool">
          <PoolsList path="/" />
          <PoolDetails path="/:exchangeAddress" />
        </Pool>
        <ContractWithdraw path="wallet" />
        <Terms path="terms" />
        <PrivacyPolicy path="privacypolicy" />
        <CookiesPolicy path="cookiespolicy" />
        <Rewards path="rewards" />
        <Vesting path="vesting" />
        <ContractWithdraw path="withdraw" />
      </Router>
      <ToastContainer />
      <UnsupportedRegionModal />
      <TermsModal />
      <ApiErrorModal />
      <ExchangeStatusModal />
      <SunsetV2Modal />
      <NetworkModal network={localStateNetwork} />
    </Location>
  );
};

export default Main;
