import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectLiquidityProviderIncentives } from "redux/incentives/selectors";
import { FsRootState } from "redux/store";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import {
  LP_FST_PER_DAY,
  TOTAL_REWARDS_DISTRIBUTED_BY_CONTRACT,
  LP_PERCENT_REDUCTION_IN_DISTRIBUTION
} from "../../constants";
import { COLORS } from "styles/theme";
import Box from "components/Box";
import Button from "components/Button";
import { Text, A } from "components/Typography";
import HorizontalList from "./HorizontalList";

const getLiquidityMultiplier = () => {
  const LpDistribution =
    TOTAL_REWARDS_DISTRIBUTED_BY_CONTRACT *
    LP_PERCENT_REDUCTION_IN_DISTRIBUTION; // 9.760
  return LP_FST_PER_DAY / LpDistribution; // 537.9098360655738
};
const LiquiditySection = ({ epochTimeLeft }) => {
  const dispatch = useDispatch();
  const { accumulatedFst } = useSelector(selectLiquidityProviderIncentives);
  const {
    newLiquidityProviderPayout,
    requests: incentivesRequests
  } = useSelector((state: FsRootState) => state.incentives);

  const renderUpdateText = () => {
    return (
      <Box py={2} px={3} border="2px solid #f1c844">
        <Text fontSize="15px">
          Rewards will be released soon and claimable here
        </Text>
      </Box>
    );
  };

  const renderNewIncentivesButton = () => {
    const isDisabled =
      newLiquidityProviderPayout === "0" || newLiquidityProviderPayout === "1";
    const label = isDisabled
      ? "No unclaimed FST"
      : `Claim ${formatNumber(fromWei(newLiquidityProviderPayout), 2)} FST`;
    return (
      <Button
        isLoading={incentivesRequests.isClaimingNewLiquidityFst}
        onClick={() => dispatch.incentives.handlePayoutLiquidityProviderNew()}
        color={COLORS.GREEN}
        disabled={isDisabled}
        size="lg"
        fluidWidth
      >
        {label}
      </Button>
    );
  };
  const renderAccumulatingIncentivesButton = () => {
    const amount = fromWei(accumulatedFst) * getLiquidityMultiplier();
    const buttonLabel = `Accumulating: ${formatNumber(amount, 2)} FST`;

    return (
      <Button
        disabled={true}
        isLoading={incentivesRequests.isClaimingNewLiquidityFst}
        onClick={() => dispatch.incentives.handlePayoutLiquidityProvider()}
        color={COLORS.GREEN}
        size="lg"
        fluidWidth
      >
        {buttonLabel}
      </Button>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" mb={3}>
        <Text fontSize="20px">LIQUIDITY PROVIDER REWARDS</Text>
      </Box>
      <Box mb={3}>
        <Box mb={0.5}>
          <HorizontalList
            label="Distribution"
            value={
              <Text>
                LP rewards have ended and will resume in{" "}
                <A
                  href="https://medium.com/futureswap/getting-ready-for-v3-6d92d596c526"
                  target="_blank"
                >
                  v3
                </A>
                !
              </Text>
            }
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Text fontSize="15px" color="#ddd">
          Earn FST based on your percentage of liquidity provided to the pool.
          Liquidity must be in for the full rewards period, starting from before
          it starts, to be eligible
        </Text>
      </Box>
      <Box mb={2}>{renderNewIncentivesButton()}</Box>
    </>
  );
};

export default LiquiditySection;
