import React from "react";
import styled from "@emotion/styled";
import CreatePosition from "./CreatePosition";
import Box from "components/Box";
import CollateralWallet from "./CollateralWallet";

const CreatePositionSection = () => {
  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" alignItems="baseline" style={styles}>
          ETH - USD
        </Box>
        <CollateralWalletContainer py={3} px={3}>
          <CollateralWallet />
        </CollateralWalletContainer>
        <CreatePosition />
      </Box>
    </>
  );
};

const styles = {
  minHeight: "54px",
  padding: "15px 24px",
  color: "#fff",
  fontSize: "17px"
};

export default CreatePositionSection;

const CollateralWalletContainer = styled(Box)`
  background: #1d212d;
`;
