import React, { useState, useCallback, useRef } from "react";
import styled from "@emotion/styled";
import Advanced from "./Advanced";
import Button from "components/Button";
import Input from "components/Input";
import ListItem from "components/ListItem";
import Box from "components/Box";
import ButtonToggle from "components/ButtonToggle";
import V2SunsetButton from "components/V2SunsetButton";
import LeverageSlider from "./LeverageSlider";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "utils/formatter";
import { MainMetrics, H4, Text } from "components/Typography";
import { COLORS } from "styles/theme";
import { fromWei, toWei } from "utils/numbers";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import Tooltip from "components/Tooltip";
import RestrictedRegionButton from "components/RestrictedRegionButton";
import axios from "axios";
import {
  API_ENDPOINT_TYPES,
  getMessageProcessorEndpoint,
  TRANSACTIONS_GAS_GWEI
} from "../../../constants";
import { debounce } from "lodash";

import { selectCurrentExchangeDynamicData } from "redux/dynamicExchangeData/selectors";
import getProvider from "provider";
import { selectOpeningTrades } from "redux/messageProcessor/selectors";
import { FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";
import { WalletModel } from "redux/wallet/model";
import { RegistryModel } from "redux/registry/model";

const CreatePosition = () => {
  const dispatchGlobal = useDispatch();
  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const currentExchange = exchange.address;
  const { accountAddress, averageGasGwei } = useSelector<
    FsRootState,
    WalletModel
  >(state => state.wallet);
  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const validateInterval = useRef<any>(null);

  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);
  const isOpeningTrade = useSelector(selectOpeningTrades).length > 0;

  const [isLong, setIsLong] = useState<any>(true);
  const [isUpdatingPositionSize, setIsUpdatingPositionSize] = useState(true);
  const [isValidTradeObj, setIsValidTradeObj] = useState<any>({});

  const [leverageAmount, setLeverageAmount] = useState<any>("6");
  const [collateralAmount, setCollateralAmount] = useState<any>(0);
  const [positionSizeAmount, setPositionSizeAmount] = useState<any>(0);

  const marketAssetPriceFromWei = fromWei(dynamicExchangeData.marketAssetPrice);
  const fstDiscount = "0"; //todo

  const remainingAssetAvalible =
    (1 - Number(fromWei(dynamicExchangeData.assetPoolUtilization))) *
    fromWei(dynamicExchangeData.assetPoolSize);

  const remainingStableAvalible =
    (1 - Number(fromWei(dynamicExchangeData.stablePoolUtilization))) *
    fromWei(dynamicExchangeData.stablePoolSize);

  const getMaxCollateral = () => {
    const collateralBalance = fromWei(stableTokenProperties.fsBalance);

    const gasCost =
      fromWei(averageGasGwei, 9) *
      fromWei(TRANSACTIONS_GAS_GWEI.TRADE_OPEN, 9) *
      fromWei(assetTokenProperties.indexPrice);

    const GAS_BUFFER = 50;
    const maxCollateral = Math.floor(
      Number(collateralBalance) - gasCost - GAS_BUFFER
    );
    return maxCollateral <= 0 ? "0" : String(maxCollateral);
  };

  const setCollateralToMax = () => {
    const maxCollateralAmount = getMaxCollateral();
    setIsUpdatingPositionSize(false);
    onUpdateCollateral(maxCollateralAmount);
  };

  const getValidateParams = ({
    _collateral,
    _leverage,
    _asset,
    _isLong,
    _isUpdatingPositionSize
  }: {
    _collateral?: any;
    _leverage?: any;
    _asset?: any;
    _isLong?: any;
    _isUpdatingPositionSize?: any;
  }) => {
    const calculatedIsUpatingPositionSize =
      _isUpdatingPositionSize === null || _isUpdatingPositionSize === undefined
        ? isUpdatingPositionSize
        : _isUpdatingPositionSize;

    return {
      _collateral: !calculatedIsUpatingPositionSize
        ? _collateral === ""
          ? "0"
          : _collateral || collateralAmount
        : "0",
      _asset: calculatedIsUpatingPositionSize
        ? _asset === ""
          ? "0"
          : _asset || positionSizeAmount
        : "0",
      _leverage: _leverage ? _leverage : leverageAmount,
      _isLong: _isLong === null || _isLong === undefined ? isLong : _isLong,
      _isUpdatingPositionSize: calculatedIsUpatingPositionSize
    };
  };

  const callValidate = async ({
    _collateral,
    _leverage,
    _asset,
    _isLong,
    _isUpdatingPositionSize
  }) => {
    clearInterval(validateInterval.current);
    if (_isUpdatingPositionSize && _asset === "0") {
      setCollateralAmount("");
      return;
    }
    if (!_isUpdatingPositionSize && _collateral === "0") {
      setPositionSizeAmount("");
      return;
    }
    const doCallValidate = async () => {
      try {
        const endpoint = await getMessageProcessorEndpoint();
        const { data } = await axios.post(
          `${endpoint}/exchange/${currentExchange}/validation/openTrade/${accountAddress}`,
          JSON.stringify({
            isLong: _isLong,
            collateralAmount: toWei(String(_collateral || "0")),
            assetAmount: toWei(String(_asset || "0")),
            leverage: String(_leverage),
            exchangeAddress: currentExchange
          })
        );

        setIsValidTradeObj(data);
        if (_isUpdatingPositionSize) {
          const collateralRound = Number.isInteger(
            Number(fromWei(data.collateralAmount))
          )
            ? Number(fromWei(data.collateralAmount))
            : Number(fromWei(data.collateralAmount)).toFixed(6);
          setCollateralAmount(collateralRound);
        } else {
          const positionRound = Number.isInteger(
            Number(fromWei(data.assetAmount))
          )
            ? Number(fromWei(data.assetAmount))
            : Number(fromWei(data.assetAmount)).toFixed(6);
          setPositionSizeAmount(positionRound);
        }
      } catch (e) {
        dispatchGlobal.api.addError({
          type: API_ENDPOINT_TYPES.VALIDATE_OPEN,
          code: e?.response?.status,
          message: e?.response?.data?.message
        });
        console.log(e);
      }
    };
    validateInterval.current = setInterval(() => {
      doCallValidate();
    }, 10000);

    doCallValidate();
  };

  const callValidateDebounced = useCallback(debounce(callValidate, 250), []);

  const onUpdateCollateral = localCollateral => {
    setIsUpdatingPositionSize(false);
    setCollateralAmount(localCollateral);

    callValidateDebounced(
      getValidateParams({
        _collateral: localCollateral,
        _isUpdatingPositionSize: false
      })
    );
  };

  const onUpdatePositionSize = localPositionSizeAsset => {
    setIsUpdatingPositionSize(true);
    setPositionSizeAmount(localPositionSizeAsset);
    callValidateDebounced(
      getValidateParams({
        _asset: localPositionSizeAsset,
        _isUpdatingPositionSize: true
      })
    );
  };

  const onUpdateLeverage = newLeverageAmount => {
    setLeverageAmount(newLeverageAmount);
    callValidateDebounced(getValidateParams({ _leverage: newLeverageAmount }));
  };

  const onToggleLongShort = isLong => {
    setIsLong(isLong);
    callValidate(getValidateParams({ _isLong: isLong }));
  };

  const inputsHaveValue = Boolean(
    Number(positionSizeAmount) && Number(collateralAmount)
  );

  const MIN_COLLATERAL = 200;
  const isPositionSizeTooSmall =
    inputsHaveValue && Number(collateralAmount) < MIN_COLLATERAL;
  const tradeExceedsPoolSize = isLong
    ? Number(positionSizeAmount) > remainingAssetAvalible
    : Number(positionSizeAmount) > remainingStableAvalible;

  const hasTradeSizeError = isPositionSizeTooSmall || tradeExceedsPoolSize;

  const getTradeSizeErrorMessage = () => {
    if (isPositionSizeTooSmall) {
      return `Collateral must be greater than ${MIN_COLLATERAL}`;
    }
    if (tradeExceedsPoolSize) {
      return "Trade size Exceeds pool availability. Reduce size or leverage.";
    }
    return false;
  };

  const openPosition = async () => {
    if (!collateralAmount) {
      return;
    }

    const collateral = getProvider().utils.toWei(collateralAmount.toString());

    await dispatchGlobal.messageProcessor.handleOpenTrade({
      collateral,
      isLong,
      leverageAmount: leverageAmount.toString(),
      exchangeAddress: currentExchange,
      entryPrice: isValidTradeObj.assetMarketPrice
    });
  };

  const renderUnlockButton = () => (
    <Button
      color={COLORS.PURPLE}
      onClick={() =>
        dispatchGlobal.wallet.unlockToken(stableTokenProperties.address)
      }
      isLoading={stableTokenProperties.isUnlocking}
      fluidWidth
      size="lg"
    >
      Unlock {stableTokenProperties.symbol}
    </Button>
  );

  const renderPlaceTradeButton = () => (
    <>
      {!isValidTradeObj.isValid && (
        <Box mb={0.5} textAlign="center">
          <WarningText>{isValidTradeObj.reason}</WarningText>
        </Box>
      )}
      <Button
        color={isLong ? COLORS.GREEN : COLORS.RED}
        onClick={() => openPosition()}
        disabled={
          !stableTokenProperties.isUnlocked ||
          !isValidTradeObj.isValid ||
          isPositionSizeTooSmall
        }
        isLoading={isOpeningTrade}
        fluidWidth
        size="lg"
      >
        Place Your Trade
      </Button>
    </>
  );

  const renderFundingRate = () => {
    const value = Number(fromWei(dynamicExchangeData.fundingRate));
    return renderHorizontalList(
      `Funding Rate`,
      <FundingRateText>{Number(value * 100).toFixed(4)}%</FundingRateText>,
      <Box>
        <Box>
          If positive, longs pay shorts. If negative shorts pay longs. The
          funding rate is measured on an 8 hour period and paid continuously on
          the full leveraged trade size.
        </Box>
      </Box>
    );
  };

  const renderPositionInputs = () => (
    <>
      <Box mt={1.75}>
        <ListItem>
          <ButtonToggle
            isLeftToggled={isLong}
            onToggle={isLong => onToggleLongShort(isLong)}
            leftLabel="LONG"
            rightLabel="SHORT"
          />
        </ListItem>
        <Box mt={1.5}>{renderFundingRate()}</Box>
      </Box>

      <Box mt={3}>
        {renderHorizontalList(
          "Collateral",
          <Text onClick={setCollateralToMax}>
            <MaxButton>MAX</MaxButton>
          </Text>,
          `The amount of collateral(USDC) to enter the trade with`
        )}
        <Input
          value={collateralAmount === 0 ? "" : collateralAmount}
          placeholder={"0"}
          type="number"
          name="open"
          id="collateral"
          width="100px"
          onChange={e => {
            onUpdateCollateral(e.target.value);
          }}
          rightLabel={stableTokenProperties.symbol}
        />
      </Box>

      <Box mt={3}>
        <Box display="flex">
          <ListItem label="Position Size" />
          <Tooltip
            content={`How much of the underlying asset the trade will own`}
          />
        </Box>
        <Box>
          <Input
            width="100%"
            value={positionSizeAmount === 0 ? "" : positionSizeAmount}
            placeholder={"0"}
            type="number"
            onChange={e => {
              onUpdatePositionSize(e.target.value);
            }}
            rightLabel={assetTokenProperties.symbol}
          />
        </Box>
        <Box mt={1}>
          {renderHorizontalList(
            "Notional Value",
            `$${formatNumber(collateralAmount * leverageAmount, 2)}`
          )}
        </Box>
        {hasTradeSizeError && (
          <Box mt={0.5}>
            <WarningText>{getTradeSizeErrorMessage()}</WarningText>
          </Box>
        )}
      </Box>

      <Box mt={2}>
        <ListItem label="Leverage">
          <LeverageSlider
            isLong={isLong}
            leverageAmount={leverageAmount}
            onChange={value => onUpdateLeverage(value)}
          />
        </ListItem>
      </Box>
    </>
  );

  const renderHorizontalList = (
    label: string,
    value: any,
    tooltipInfo?: any
  ) => {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <H4>
          <Box display="flex">
            {label} {tooltipInfo && <Tooltip content={tooltipInfo} />}
          </Box>
        </H4>

        <MainMetrics>{value}</MainMetrics>
      </Box>
    );
  };

  const renderPositionInfo = () => {
    const totalCost =
      collateralAmount === 0
        ? 0
        : Number(collateralAmount) +
          Number(fromWei(isValidTradeObj?.tradeFeeStable));
    return (
      <>
        <Box mb={3}>
          {renderHorizontalList(
            "Entry price",
            `$${formatNumber(fromWei(isValidTradeObj.assetMarketPrice), 2)}`
          )}

          {renderHorizontalList(
            "Price Impact",
            `${formatNumber(
              fromWei(isValidTradeObj.priceDeviation) * 100,
              2
            )}%`,
            `The impact the trade will on the current Mark price.`
          )}

          {renderHorizontalList(
            "Funding Rate After",
            `${formatNumber(
              fromWei(isValidTradeObj.fundingRateAfter) * 100,
              4
            )}%`,
            `The funding rate after the trade is executed.`
          )}

          {renderHorizontalList(
            "Liquidation Price",
            `$${formatNumber(fromWei(isValidTradeObj.liquidationPrice), 2)}`,
            `The price at which this trade would get liquidated at. Add more collateral or reduce leverage to increase the buffer.`
          )}
        </Box>
        {renderHorizontalList(
          "Open fee",
          `${formatNumber(fromWei(isValidTradeObj?.tradeFeeStable), 2)} ${
            stableTokenProperties.symbol
          }`,
          `The open fee for trading that is earned by liquidity providers. Is charged from your Futureswap wallet and not the trade's collateral.`
        )}
        {renderHorizontalList(
          "ESTIMATED GAS",
          `${formatNumber(fromWei(isValidTradeObj?.gasCostValue), 2)} ${
            stableTokenProperties.symbol
          }`
        )}
        {renderHorizontalList(
          "Total",
          `${formatNumber(
            totalCost + fromWei(isValidTradeObj?.gasCostValue),
            2
          )} ${stableTokenProperties.symbol}`
        )}
      </>
    );
  };

  const renderTradeButton = () => {
    if (restrictedRegion) {
      return <RestrictedRegionButton fluidWidth />;
    } else {
      return stableTokenProperties.isUnlocked
        ? renderPlaceTradeButton()
        : renderUnlockButton();
    }
  };

  return (
    <Container width="100%" px={3} pb={3}>
      <Box mb={3}>{renderPositionInputs()}</Box>
      {Number(collateralAmount) > 0 && (
        <PositionInfo mb={3}>{renderPositionInfo()}</PositionInfo>
      )}
      <Box mb={3}>
        <Advanced />
      </Box>
      <Box mb={3}>
        <V2SunsetButton fluidWidth>v2 beta has ended</V2SunsetButton>
      </Box>
    </Container>
  );
};

export default CreatePosition;

const Container = styled(Box)`
  box-sizing: border-box;
  overflow-y: scroll;
`;

const PositionInfo = styled(Box)`
  word-break: break-word;
`;

const FundingRateText = styled(MainMetrics)`
  font-weight: 500;
`;

const MaxButton = styled(Text)`
  color: #7895f1;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
`;

const WarningText = styled(Text)`
  color: #da4f4f;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
`;
