import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { COLORS } from "styles/theme";
import {
  getProposal,
  getcurrentUserVotedCastedForPropsal
} from "redux/governance/selectors";
import { useSelector, useDispatch } from "react-redux";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import Box from "components/Box";
import { Text } from "components/Typography";
import TitleBar from "./TitleBar";
import VoteStatusBar from "./VoteStatusBar";
import TimerBar from "./TimerBar";
import SectionCard from "./SectionCard";
import Button from "components/Button";
import ButtonToggle from "components/ButtonToggle";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import useForceComponentRerender from "hooks/useForceComponentRerender";
import { BigNumber } from "ethers";

const ProposalPage = ({ id }) => {
  const dispatch = useDispatch();
  const {
    voteThreshold,
    totalFstSupply,
    requests: proposalRequests,
    isFstUnlocked,
    didAddressVote,
    votingContractAddress
  } = useSelector(state => state.governance);

  const { accountAddress } = useSelector(state => state.wallet);
  const forceUpdate = useForceComponentRerender();

  const currentUserVotedCasted = useSelector(state =>
    getcurrentUserVotedCastedForPropsal(state, id)
  );
  const { fsTokenProperties } = useSelector(getExchangeTokenProperties);

  const [isApproveToggled, setIsApproveToggled] = useState(true);

  const fetchedProposal = useSelector(state =>
    getProposal(state.governance, id)
  );

  useEffect(() => {
    const getUserData = async () => {
      await dispatch.governance.userProposalDetails(id);
    };
    votingContractAddress && getUserData();
  }, [votingContractAddress]);

  const renderWithdrawPendingContext = () => {
    return (
      <Box p={1.5} border="2px solid #f1c844">
        <Text color="#eee" fontSize="14px" lineHeight="18px">
          Only FSTs claimed before this proposal was created will be counted
          towards your vote. We're updating the UI to reflect this more clearly
        </Text>
      </Box>
    );
  };

  const renderVoteAction = () => {
    const formattedFstokenBalace = Number(
      fromWei(fsTokenProperties.localBalance || "0")
    ).toFixed(2);

    const hasFsTokens = fsTokenProperties.localBalance !== "0";

    const voteButtonLabel = hasFsTokens
      ? `Vote ${formattedFstokenBalace} FST to ${
          isApproveToggled ? "Approve" : "Reject"
        }`
      : "No claimed FST to vote";

    if (
      !fetchedProposal.isVoteResolved &&
      !didAddressVote &&
      fetchedProposal.timeLeft !== 0
    ) {
      return (
        <>
          {hasFsTokens && <Box mb={2}>{renderWithdrawPendingContext()}</Box>}
          <Box mb={2}>
            <ButtonToggle
              isLeftToggled={isApproveToggled}
              onToggle={isApprove => setIsApproveToggled(isApprove)}
              leftLabel="Approve"
              rightLabel="Reject"
            />
          </Box>
          <Button
            onClick={() =>
              dispatch.governance.castVote({
                id: fetchedProposal.id,
                yesVote: isApproveToggled
              })
            }
            color={COLORS.PURPLE}
            size="lg"
            fluidWidth
            isLoading={proposalRequests.isVoting}
            disabled={!hasFsTokens}
          >
            {voteButtonLabel}
          </Button>
        </>
      );
    } else if (didAddressVote) {
      return (
        <>
          {currentUserVotedCasted.amountOfVotes === "0" && (
            <Box mb={2}>{renderWithdrawPendingContext()}</Box>
          )}
          <Box mb={2}>
            <ButtonToggle
              onToggle={() => {}}
              isLeftToggled={currentUserVotedCasted.isYesVote}
              leftLabel="Approve"
              rightLabel="Reject"
            />
          </Box>
          <Box mb={2}>
            <Button
              disabled={true}
              color={COLORS.PURPLE}
              size="lg"
              fluidWidth
              isLoading={proposalRequests.isVoting}
            >
              {`YOU VOTED ${formatNumber(
                fromWei(currentUserVotedCasted.amountOfVotes),
                2
              )} TO ${currentUserVotedCasted.isYesVote ? "Approve" : "Reject"}`}
            </Button>
          </Box>
        </>
      );
    } else {
      return (
        <Box mb={0}>
          <Button disabled={true} color={COLORS.PURPLE} size="lg" fluidWidth>
            {"Vote ended"}
          </Button>
        </Box>
      );
    }
  };

  const renderMainSection = () => (
    <SectionCard title={fetchedProposal.title}>
      <Box mb={4}>
        <Text>{fetchedProposal.description}</Text>
      </Box>
      {renderVoteAction()}
    </SectionCard>
  );

  const renderStatus = () => {
    return (
      <SectionCard title="Current Status">
        <Box>
          <VoteStatusBar
            yesVotes={fetchedProposal.yesVotes}
            noVotes={fetchedProposal.noVotes}
          />
        </Box>
        <Box mt={3}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={1}
          >
            <Text color="#A5A5A5">Total Votes</Text>

            <Text>
              {formatNumber(
                fromWei(
                  BigNumber.from(fetchedProposal.yesVotes).add(
                    BigNumber.from(fetchedProposal.noVotes)
                  )
                ),
                2
              )}{" "}
              FST
            </Text>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            mt={1}
          >
            <Text color="#A5A5A5">Potential Votes</Text>
            {totalFstSupply && (
              <Text>
                {formatNumber(String(fromWei(totalFstSupply)), 2)} FST
              </Text>
            )}
          </Box>
          {fetchedProposal.timeLeft === 0 ? (
            <Box mt={3} display="flex" justifyContent="center">
              {fetchedProposal.isVoteResolved ? (
                <Text>
                  {fetchedProposal.result || fetchedProposal.ownerApproved
                    ? "Approved 👍"
                    : "Rejected 👎"}
                </Text>
              ) : (
                <Button
                  onClick={async () => {
                    const isUpgradingVotingContract = false;

                    await dispatch.governance.handleResolve({
                      id: fetchedProposal.id,
                      isUpgradingVotingContract
                    });
                  }}
                  color={COLORS.PURPLE}
                  size="lg"
                  fluidWidth
                  isLoading={proposalRequests.isResolving}
                >
                  Resolve Proposal
                </Button>
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box mt={1} display="flex" justifyContent="center">
          <Text>{fetchedProposal.ownerApproved && "Admin Approve"}</Text>
        </Box>
      </SectionCard>
    );
  };

  const renderTimeLeft = () => (
    <SectionCard title="Time Left">
      <TimerBar
        timeLeft={fetchedProposal.timeLeft}
        handleRerenderParent={forceUpdate}
      />
    </SectionCard>
  );

  const renderProposalProperties = () => (
    <SectionCard title="Proposal Properties">
      <Box mt={3}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        ></Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        >
          <Text color="#A5A5A5">To</Text>
          <Text>{fetchedProposal.to.substring(0, 10) + "..."}</Text>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        >
          <Text color="#A5A5A5">Action</Text>
          <Text>{fetchedProposal.action}</Text>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        >
          <Text color="#A5A5A5">Proposer</Text>
          <Text>{fetchedProposal.proposer.substring(0, 10) + "..."}</Text>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        >
          <Text color="#A5A5A5">Resolve Threshold Min.</Text>
          <Text>
            {formatNumber(Number(fromWei(totalFstSupply)) / 10, 2)} FST
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={1}
      >
        <Text color="#A5A5A5">Resolved</Text>
        <Text>{fetchedProposal.isVoteResolved.toString()}</Text>
      </Box>
      {fetchedProposal.isVoteResolved && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={1}
        >
          <Text color="#A5A5A5">Result</Text>
          <Text>{fetchedProposal.proposalAccepted.toString()}</Text>
        </Box>
      )}
    </SectionCard>
  );

  return (
    <Box>
      <TitleBar
        title={`Proposal`}
        backLabel="Proposals"
        backPath="/governance"
      />
      <Grid>
        <Primary>{renderMainSection()}</Primary>
        <Secondary>
          <Box mb={2}>{renderStatus()}</Box>
          <Box mb={2}>{renderTimeLeft()}</Box>
          <Box mb={2}>{renderProposalProperties()}</Box>
        </Secondary>
      </Grid>
    </Box>
  );
};

export default ProposalPage;

const Grid = styled(Box)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 8fr 4fr;
  grid-template-areas: "primary secondary";
`;

const Primary = styled(Box)`
  grid-area: primary;
`;

const Secondary = styled(Box)`
  grid-area: secondary;
`;
