import { getVotingContractInstance } from "contracts/VotingContract";
import getProvider from "provider";
import { toWei } from "utils/numbers";

export const upgradeIncentives = ({
  title,
  description,
  fstSnapshotAddress,
  votingContractAddress,
  fsTokenAddress
}) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const data = getProvider().eth.abi.encodeFunctionCall(
    {
      name: "upgrade",
      type: "function",
      inputs: [
        {
          type: "address",
          name: "proxy"
        },
        {
          type: "address",
          name: "implementation"
        }
      ]
    },
    [fsTokenAddress, fstSnapshotAddress]
  );

  const action = `{
        name: "upgrade",
        type: "function",
        inputs: [
          {
            type: "address",
            name: "proxy"
          },
          {
            type: "address",
            name: "implementation"
          }
        ]
      },
      [${fsTokenAddress}, ${fstSnapshotAddress}]
    `;

  return votingContractInstance.methods.createProposal(
    data,
    action,
    title,
    description
  );
};

export const newExchangeProposal = ({
  title,
  description,
  votingContractAddress,
  stableToken,
  assetToken,
  factoryAddress
}) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );

  const payload = {
    name: "createExchange",
    type: "function",
    inputs: [
      {
        type: "address",
        name: "assetToken"
      },
      {
        type: "address",
        name: "stableToken"
      },
      {
        type: "string",
        name: "_name"
      },
      {
        type: "string",
        name: "_symbol"
      },
      {
        type: "uint256",
        name: "_liquidityPayoutMultiplier"
      },
      {
        type: "uint256",
        name: "_traderPayoutMultiplier"
      },
      {
        type: "uint256",
        name: "_referralPayoutMultiplier"
      },
      {
        type: "uint256",
        name: "_defaultExchangeWeight"
      }
    ]
  };

  const valueArray = [
    assetToken, //address _stableToken,
    stableToken, //address _stableToken,
    "FST_LT1",
    "LT1",
    toWei("0.35"), // liquidity provider rewards
    toWei("0.6"), // trading rewards
    toWei("0.05"), // referral rewards
    "100" // exchange weight
  ];

  const createExchangeVote = getProvider().eth.abi.encodeFunctionCall(
    payload,
    valueArray
  );

  const action = `${JSON.stringify(payload)},${[valueArray]}`;

  return votingContractInstance.methods.createProposal(
    factoryAddress,
    createExchangeVote,
    action,
    title,
    description
  );
};

export const upgradeFactoryProposal = ({
  title,
  description,
  votingContractAddress,
  newExchangeFactoryAddress
}) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const data = getProvider().eth.abi.encodeFunctionCall(
    {
      name: "upgradeFactory",
      type: "function",
      inputs: [
        {
          type: "address",
          name: "_newFactory"
        }
      ]
    },
    [newExchangeFactoryAddress]
  );

  const action = `{
      name: "upgradeFactory"
    }`;

  return votingContractInstance.methods.createProposal(
    data,
    action,
    title,
    description
  );
};

export const upgradeVotingContractProposal = ({
  title,
  description,
  votingContractAddress,
  newVotingContractAddress
}) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );
  const data = getProvider().eth.abi.encodeFunctionCall(
    {
      name: "updateVotingContract",
      type: "function",
      inputs: [
        {
          type: "address",
          name: "_newImplementation"
        }
      ]
    },
    [newVotingContractAddress]
  );

  // we may not need action bc this is the only action.
  const action = `{
      name: "updateVotingContract"
    }`;

  return votingContractInstance.methods.createVotingUpgradeProposal(
    newVotingContractAddress,
    data,
    action,
    title,
    description
  );
};

export const upgradeParamProposalv2 = ({
  title,
  description,
  amount,
  action,
  exchangeAddress,
  constants,
  subAction,
  votingContractAddress
}) => {
  const votingContractInstance = getVotingContractInstance(
    votingContractAddress
  );

  const updatedAmount =
    action === "maxLeverage" || action === "minLeverage"
      ? amount
      : toWei(amount);

  const payload = {
    constant: false,
    inputs: [
      {
        internalType: "bool",
        name: "isAdjustingPoolBalancerDiscount",
        type: "bool"
      },
      {
        internalType: "uint256",
        name: "poolBalancerDiscount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "dfrDivider",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "maxAssetSize",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "addLiquidityFeePercent",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "timeFee",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "maxLeverage",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "minLeverage",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "isUpdatingMaxDfr",
        type: "bool"
      },
      {
        internalType: "uint256",
        name: "maxDfr",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "isUpdatingLinearDfrMultiple",
        type: "bool"
      },
      {
        internalType: "uint256",
        name: "linearDfrMultiple",
        type: "uint256"
      }
    ],
    name: "constantsMultiVote",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  };

  const {
    poolBalancerDiscount,
    dfrDivider,
    maxAssetSize,
    addLiquidityFeePercent,
    timeFee,
    maxLeverage,
    minLeverage,
    maxDfr,
    linearDfrMultiple
  } = constants;

  const params = {
    isAdjustingPoolBalancerDiscount:
      subAction === "isAdjustingPoolBalancerDiscount" ? true : false,
    poolBalancerDiscount:
      action === "poolBalancerDiscount" ? updatedAmount : poolBalancerDiscount,
    dfrDivider: action === "dfrDivider" ? updatedAmount : dfrDivider,
    maxAssetSize: action === "maxAssetSize" ? updatedAmount : maxAssetSize,
    addLiquidityFeePercent:
      action === "addLiquidityFeePercent"
        ? updatedAmount
        : addLiquidityFeePercent,
    timeFee: action === "timeFee" ? updatedAmount : timeFee,
    maxLeverage: action === "maxLeverage" ? updatedAmount : maxLeverage,
    minLeverage: action === "minLeverage" ? updatedAmount : minLeverage,
    isUpdatingMaxDfr: subAction === "isUpdatingMaxDfr" ? true : false,
    maxDfr: action === "maxDfr" ? updatedAmount : maxDfr,
    isUpdatingLinearDfrMultiple:
      subAction === "isUpdatingLinearDfrMultiple" ? true : false,
    linearDfrMultiple:
      action === "linearDfrMultiple" ? updatedAmount : linearDfrMultiple
  };

  const valueArray = [
    params.isAdjustingPoolBalancerDiscount,
    params.poolBalancerDiscount,
    params.dfrDivider,
    params.maxAssetSize,
    params.addLiquidityFeePercent,
    params.timeFee,
    params.maxLeverage,
    params.minLeverage,
    params.isUpdatingMaxDfr,
    params.maxDfr,
    params.isUpdatingLinearDfrMultiple,
    params.linearDfrMultiple
  ];

  const createConstantsMultiVote = getProvider().eth.abi.encodeFunctionCall(
    payload,
    valueArray
  );

  const actionDescription = `${JSON.stringify(payload)},${[valueArray]}`;

  return votingContractInstance.methods.createProposal(
    exchangeAddress,
    createConstantsMultiVote,
    actionDescription,
    title,
    description
  );
};
