import axios from "axios";
import { getIncentivesInstance } from "contracts/Incentives";
import { LpPayoutWeek } from "./model";
import { MAIN_UNI_LP_ADDRESSES } from "../../constants";
const FINAL_WEEK = 141;
const weekCountToArray = weekCount =>
  weekCount === "0"
    ? [] // needed because ["0"] throws error on first week. fix in contract?
    : [...Array(Math.min(Number(weekCount), FINAL_WEEK)).keys()].map(int =>
        int.toString()
      );

export const getIncentivesData = async incentivesAddress => {
  const incentivesInstance = getIncentivesInstance(incentivesAddress);
  const timeInterval = 60 * 60 * 24;
  const [lastUpdateTimestamp, weekCount] = await Promise.all([
    incentivesInstance.methods.lastUpdateTimestamp().call(),
    incentivesInstance.methods.epochCount().call()
  ]);

  return {
    lastUpdateTimestamp,
    weekCount,
    timeInterval
  };
};

export const getAmountToSendAll = async (
  incentivesAddress,
  accountAddress,
  weekCount
) => {
  const incentivesInstance = getIncentivesInstance(incentivesAddress);
  const arrayOfWeeks = weekCountToArray(weekCount);

  const [
    traderPayout,
    referralPayout,
    liquidityProviderPayout
  ] = await Promise.all([
    incentivesInstance.methods
      .getTraderPayout(accountAddress, arrayOfWeeks)
      .call(),
    incentivesInstance.methods
      .getReferralPayout(accountAddress, arrayOfWeeks)
      .call(),
    incentivesInstance.methods
      .getLiquidityProviderPayout(accountAddress, arrayOfWeeks)
      .call()
  ]);

  return {
    traderPayout,
    referralPayout,
    liquidityProviderPayout
  };
};

export const getTraderWeeklyPendingPayouts = async (
  incentivesAddress: string,
  accountAddress: string,
  weekCount: number
) => {
  const incentivesInstance = getIncentivesInstance(incentivesAddress);
  const arrayOfWeeks = weekCountToArray(weekCount);

  const weekPayouts = await Promise.all(
    arrayOfWeeks.map(week =>
      incentivesInstance.methods.getTraderPayout(accountAddress, [week]).call()
    )
  );

  return weekPayouts.map((amount, week) => ({
    week: week.toString(),
    amount
  }));
};

export const getReferralWeeklyPendingPayouts = async (
  incentivesAddress,
  accountAddress,
  weekCount
) => {
  const incentivesInstance = getIncentivesInstance(incentivesAddress);
  const arrayOfWeeks = weekCountToArray(weekCount);

  return (
    await Promise.all(
      arrayOfWeeks.map(week =>
        incentivesInstance.methods
          .getReferralPayout(accountAddress, [week])
          .call()
      )
    )
  ).map((amount, week) => ({
    week: week.toString(),
    amount
  }));
};

export const getLiquidityProviderWeeklyPendingOrClaimedPayouts = async (
  incentivesAddress,
  exchangeAddress,
  userAddress,
  weekCount
): Promise<LpPayoutWeek[]> => {
  const incentivesInstance = getIncentivesInstance(incentivesAddress);
  const arrayOfWeeks = weekCountToArray(weekCount);

  const lpPayout = await Promise.all(
    arrayOfWeeks.map(week =>
      incentivesInstance.methods
        .getExchangeEpochByUser(exchangeAddress, week, userAddress)
        .call()
    )
  );

  return lpPayout.map((exchangeWeek, week) => ({
    week,
    hasPaidOut: exchangeWeek[3],
    payout: exchangeWeek[4]
  }));
};

//@todo decide if we should remove this because it doesn't really work
export const getIncentivesEventsHistory = async (
  incentivesAddress,
  userAddress
) => {
  const incentivesInstance = getIncentivesInstance(incentivesAddress);

  const eventProps = {
    filter: { _target: userAddress },
    fromBlock: 0,
    toBlock: "latest"
  };

  const [
    traderPayouts,
    referralPayouts,
    liquidityProviderPayouts
  ] = await Promise.all([
    incentivesInstance.getPastEvents("TraderPayout", eventProps),
    incentivesInstance.getPastEvents("ReferralPayout", eventProps),
    incentivesInstance.getPastEvents("LiquidityProviderPayout", eventProps)
  ]);

  return {
    traderPayouts: traderPayouts.map(e => e.returnValues),
    referralPayouts: referralPayouts.map(e => e.returnValues),
    liquidityProviderPayouts: liquidityProviderPayouts.map(e => e.returnValues)
  };
};

export const getFstLpTokenData = async (): Promise<{
  reserveUSD: string;
  totalSupply: string;
}> => {
  try {
    const response = await axios.post(
      "https://api.thegraph.com/subgraphs/name/ianlapham/uniswapv2",
      {
        query: `query($pairId: String) {
        pair(id: $pairId) {
          reserveUSD
          totalSupply
        }
      }`,
        variables: {
          pairId: MAIN_UNI_LP_ADDRESSES.uniswapLpToken
        }
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    return {
      reserveUSD: response.data.data.pair.reserveUSD,
      totalSupply: response.data.data.pair.totalSupply
    };
  } catch (e) {
    return { reserveUSD: "0", totalSupply: "0" };
  }
};

export const getFstPriceUsd = async (): Promise<number> => {
  try {
    const response = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=futureswap&vs_currencies=usd"
    );
    return response.data.futureswap.usd;
  } catch (e) {
    console.log(e);
    console.log("could not get data");
    return 0;
  }
};
