import React from "react";
import Box from "components/Box";
import { Text } from "components/Typography";

const HorizontalList = ({ label, value }) => {
  return (
    <Box display="flex">
      <Box mr={1}>
        <Text fontSize="16px" color="#aaa">
          {label}:
        </Text>
      </Box>
      <Text fontSize="16px" color="#ddd">
        {value}
      </Text>
    </Box>
  );
};

export default HorizontalList;
