import React from "react";
import Button from "components/Button";
import Tippy from "components/Tippy";
import { A, Text } from "components/Typography";
type Props = {
  size?: "sm" | "md" | "lg";
  fluidWidth?: boolean;
};

const V2SunsetButton: React.FC<Props> = ({
  size = "md",
  fluidWidth = false,
  children
}) => {
  return (
    <Tippy
      interactive={true}
      interactiveBorder={20}
      delay={100}
      content={
        <Text>
          v2 has ended so we can begin rolling out v3 - thanks to everyone who
          participated!{" "}
          <A
            href="https://medium.com/futureswap/getting-ready-for-v3-6d92d596c526"
            target="_blank"
          >
            Read more about our v3 roadmap
          </A>
        </Text>
      }
    >
      <div>
        <Button size={size} disabled fluidWidth={fluidWidth}>
          {children}
        </Button>
      </div>
    </Tippy>
  );
};

export default V2SunsetButton;
