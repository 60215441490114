import getProvider from "../provider";

const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_registryHolder",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalPayout",
        type: "uint256"
      }
    ],
    name: "LiquidityProviderPayout",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalPayout",
        type: "uint256"
      }
    ],
    name: "ReferralPayout",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "totalPayout",
        type: "uint256"
      }
    ],
    name: "TraderPayout",
    type: "event"
  },
  {
    constant: true,
    inputs: [],
    name: "currentSumOfExchangeWeights",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "epochAdvanceTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "epochCount",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "exchangeDataByExchangeAddress",
    outputs: [
      {
        internalType: "address",
        name: "exchangeAddress",
        type: "address"
      },
      {
        internalType: "contract ERC20Snapshot",
        name: "liquidityToken",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "defaultLiquidityPayoutMultiplier",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "defaultTraderPayoutMultiplier",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "defaultReferralPayoutMultiplier",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "defaultExchangeWeight",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "isRemoved",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "fsToken",
    outputs: [
      {
        internalType: "contract ERC20Mintable",
        name: "",
        type: "address"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "a",
        type: "address"
      }
    ],
    name: "isExchangeFactory",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "oracleAddress",
        type: "address"
      }
    ],
    name: "isValidOracleAddress",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "stamperAddress",
        type: "address"
      }
    ],
    name: "isValidStamperAddress",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "verifierAddress",
        type: "address"
      }
    ],
    name: "isValidVerifierAddress",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "a",
        type: "address"
      }
    ],
    name: "isVotingSystem",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "lastUpdateTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "maxEpoch",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "minRequiredSnapshotId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "totalTokensToMintPerEpoch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint16",
        name: "_maxEpoch",
        type: "uint16"
      }
    ],
    name: "setMaxEpoch",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint256",
        name: "_totalTokensToMintPerEpoch",
        type: "uint256"
      }
    ],
    name: "setTotalTokensToMintPerEpoch",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint256",
        name: "_epochAdvanceTime",
        type: "uint256"
      }
    ],
    name: "setEpochAdvanceTime",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "uint256",
        name: "_minRequiredSnapshotId",
        type: "uint256"
      }
    ],
    name: "setMinRequiredSnapshotId",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "advanceEpoch",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_exchangeAddress",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_liquidityPayoutMultiplier",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_traderPayoutMultiplier",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_referralPayoutMultiplier",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "defaultExchangeWeight",
        type: "uint256"
      }
    ],
    name: "updatePayoutDistribution",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_exchange",
        type: "address"
      },
      {
        internalType: "address",
        name: "_liquidityToken",
        type: "address"
      }
    ],
    name: "addExchange",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_exchange",
        type: "address"
      }
    ],
    name: "removeExchange",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "renounceMinter",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_trader",
        type: "address"
      },
      {
        internalType: "address",
        name: "_referral",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      }
    ],
    name: "rewardTrader",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_liquidityProvider",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256"
      }
    ],
    name: "trackLiquidityRemoved",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        internalType: "uint16[]",
        name: "_epochs",
        type: "uint16[]"
      }
    ],
    name: "payoutTrader",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        internalType: "uint16[]",
        name: "_epochs",
        type: "uint16[]"
      }
    ],
    name: "getTraderPayout",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        internalType: "uint16[]",
        name: "_epochs",
        type: "uint16[]"
      }
    ],
    name: "payoutReferral",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        internalType: "uint16[]",
        name: "_epochs",
        type: "uint16[]"
      }
    ],
    name: "getReferralPayout",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [
      {
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        internalType: "uint16[]",
        name: "_epochs",
        type: "uint16[]"
      }
    ],
    name: "payoutLiquidityProvider",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "_target",
        type: "address"
      },
      {
        internalType: "uint16[]",
        name: "_epochs",
        type: "uint16[]"
      }
    ],
    name: "getLiquidityProviderPayout",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "onRegistryRefresh",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    constant: true,
    inputs: [],
    name: "getAllExchangeAddresses",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "exchangeAddress",
        type: "address"
      }
    ],
    name: "getExchangeDataByAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "exchangeAddress",
        type: "address"
      },
      {
        internalType: "uint16",
        name: "epochNumber",
        type: "uint16"
      }
    ],
    name: "getExchangeEpoch",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "",
        type: "bool"
      },
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "exchangeAddress",
        type: "address"
      },
      {
        internalType: "uint16",
        name: "epochNumber",
        type: "uint16"
      },
      {
        internalType: "address",
        name: "userAddress",
        type: "address"
      }
    ],
    name: "getExchangeEpochByUser",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "",
        type: "bool"
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  }
];

export const getIncentivesInstance = address =>
  new (getProvider().eth.Contract)(abi, address);
