import { getLocalWalletAccountAddress } from "../actions";
import { createModel } from "@rematch/core";
import { API_ENDPOINT_TYPES } from "../../constants";
import { DataLoader } from "data/DataLoader";
import { maybeReportError } from "redux/errors";
import { FsDispatch } from "redux/store";

const API_INITIAL_STATE = {
  agreedToTerms: true,
  allowedActions: [] as number[],
  restrictedRegion: false,
  terms: "",
  requests: {
    isAgreeingToTerms: false
  },
  termsLoading: false,
  errorLoadingTerms: false
};

export type TermsModel = typeof API_INITIAL_STATE;

export const termsAndActions = createModel()({
  state: API_INITIAL_STATE,
  reducers: {
    resetState: () => API_INITIAL_STATE,
    setTerms: (
      state: TermsModel,
      terms: string,
      errorLoadingTerms: boolean
    ) => ({
      ...state,
      terms,
      errorLoadingTerms
    }),
    setTermsLoading: (state: TermsModel, termsLoading: boolean) => ({
      ...state,
      termsLoading
    }),
    setAllowedActions: (state: TermsModel, allowedActions) => ({
      ...state,
      allowedActions: allowedActions.allowedActions,
      agreedToTerms: allowedActions.agreedToTerms,
      restrictedRegion: allowedActions.restrictedRegion
    }),
    setRequestsValues: (state: TermsModel, values = {}) => ({
      ...state,
      requests: values
    })
  },
  effects: dispatch => {
    return {
      async loadTerms() {
        dispatch.termsAndActions.setTermsLoading(true);
        try {
          // TODO(dankurka): Use server types
          const { data } = await new DataLoader().get<{ terms: string }>(
            "/terms"
          );
          dispatch.termsAndActions.setTerms(data.terms, false);
        } catch (e) {
          dispatch.termsAndActions.setTerms("", true);
          // TODO(dankurka): fix type here
          maybeReportError(dispatch as FsDispatch, API_ENDPOINT_TYPES.TERMS, e);
        }
        dispatch.termsAndActions.setTermsLoading(false);
      },
      async agreeToTerms(_, { wallet }) {
        // TODO(dankurka): Why is this here?
        dispatch.termsAndActions.setRequestsValues({ isAgreeingToTerms: true });
        const payload = JSON.stringify({
          userAddress: wallet.accountAddress,
          agreesToTerms: true
        });
        try {
          await new DataLoader().put("/terms", payload);
        } catch (e) {
          // TODO(dankurka): fix type here
          maybeReportError(dispatch as FsDispatch, API_ENDPOINT_TYPES.TERMS, e);
        }
        await dispatch.termsAndActions.getAllowedActions();
        // TODO(dankurka): Why is this here?
        dispatch.termsAndActions.setRequestsValues({
          isAgreeingToTerms: false
        });
      },
      async getAllowedActions() {
        try {
          const accountAddress = await getLocalWalletAccountAddress();
          const path = `/allowedactions/${accountAddress}`;
          const { data } = await new DataLoader().get(path);
          dispatch.termsAndActions.setAllowedActions(data);
        } catch (e) {
          // TODO(dankurka): if this fails users are stuck
          // TODO(dankurka): fix type here
          maybeReportError(dispatch as FsDispatch, API_ENDPOINT_TYPES.TERMS, e);
        }
      }
    };
  }
});
