import React from "react";
import Box from "components/Box";
import ListItem from "components/ListItem";
import { MainMetrics } from "components/Typography";
import { fromWei } from "utils/numbers";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled/macro";
import { formatNumber } from "utils/formatter";

const Summary = ({ openTrades }) => {
  const totalValue = () => {
    const totalValue = openTrades.reduce((total, trade) => {
      if (trade.state.status === "mock") {
        return total;
      }
      return total + fromWei(trade.tradeValue);
    }, 0);
    return totalValue;
  };

  const totalCollateral = () => {
    const sumCollateral = openTrades.reduce((total, trade) => {
      if (trade.state.status === "mock") {
        return total;
      }
      return total + fromWei(trade.collateral);
    }, 0);

    return sumCollateral;
  };

  const netProfit = () => {
    const sumCollateral = openTrades.reduce((total, trade) => {
      if (trade.state.status === "mock") {
        return total;
      }
      return total + fromWei(trade.profitLoss.value);
    }, 0);
    return sumCollateral;
  };

  const profitAndLoss = () => {
    return (netProfit() / totalCollateral()) * 100;
  };

  const net = netProfit();
  const profitLossColor = net >= 0 ? COLORS.GREEN : COLORS.RED;

  return (
    <TableRow>
      <Box />
      <ListItem label="Total Collateral">
        <MainMetrics>{formatNumber(totalCollateral(), 2)}</MainMetrics>
      </ListItem>
      <ListItem label="Total Value">
        <MainMetrics>${formatNumber(totalValue(), 2)}</MainMetrics>
      </ListItem>
      <ListItem label="NET P&L">
        <MainMetrics color={profitLossColor}>
          {formatNumber(profitAndLoss(), 2)}%
        </MainMetrics>
      </ListItem>
      <ListItem label="Net Profit">
        <MainMetrics color={profitLossColor}>
          ${formatNumber(netProfit(), 2)}
        </MainMetrics>
      </ListItem>
    </TableRow>
  );
};

const TableRow = styled(Box)`
  display: grid;
  grid-template-columns: 5fr 1fr 1fr 1fr 1.2fr;
`;

export default Summary;
