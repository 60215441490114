import React from "react";
import { Link } from "@reach/router";

import Box from "components/Box";
import VoteStatusBar from "./VoteStatusBar";
import TimerBar from "./TimerBar";
import { Text } from "components/Typography";
import styled from "@emotion/styled";

const ProposalSummaryCard = ({
  path,
  id,
  title,
  description,
  noVotes,
  yesVotes,
  timeLeft,
  isVoteResolved,
  result,
  action,
  ownerApproved
}) => {
  const renderResolutionStatus = () => {
    if (timeLeft === 0 && isVoteResolved) {
      return (
        <Box>
          {result || ownerApproved ? (
            <Text>Approved 👍</Text>
          ) : (
            <Text>Rejected 👎</Text>
          )}
        </Box>
      );
    } else if (timeLeft === 0 && !isVoteResolved) {
      return (
        <Box>
          <Text>Waiting for resolve</Text>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text>In progress...</Text>
        </Box>
      );
    }
  };

  const renderBottomStatusContent = () => (
    <Box display="flex">
      <Box flex="1" mr={2}>
        <VoteStatusBar yesVotes={yesVotes} noVotes={noVotes} />
      </Box>
      <Box flex="1" ml={2}>
        <Text>
          <TimerBar timeLeft={timeLeft} />
        </Text>
      </Box>
    </Box>
  );

  const renderMain = () => {
    return (
      <>
        <Box
          display="flex"
          mb={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="18px" fontWeight="medium">
            {`${title}`}
          </Text>
          {renderResolutionStatus()}
        </Box>
        <Text>{description}</Text>
      </>
    );
  };
  return (
    <UnstyledLink to={path}>
      <ClickableContainer>
        <Box py={4} px={3}>
          {renderMain()}
        </Box>
        <Box py={2} px={3} borderTop={2} borderColor="#1c202f">
          {renderBottomStatusContent()}
        </Box>
      </ClickableContainer>
    </UnstyledLink>
  );
};

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

const ClickableContainer = styled(Box)`
  background: #272b3e;
  cursor: pointer;
  &:hover {
    background: #363c56;
  }
`;

export default ProposalSummaryCard;
