import React, { useState } from "react";
import { css } from "@emotion/core";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled/macro";
import chevron from "media/chevron.svg";
import OpenPositionDetails from "./OpenPositionDetails";
import Box from "components/Box";
import PulsatingWarningDot from "components/PulsatingWarningDot";
import { formatNumber } from "utils/formatter";
import { fromWei, toWei } from "utils/numbers";
import { useSelector } from "react-redux";
import { MainMetrics, Text, A } from "components/Typography";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import LoadingSpinner from "../../../components/LoadingSpinner";

const OpenPositition = ({ trade, isExpanded, toggleIsExpanded, isMock }) => {
  const { assetTokenProperties } = useSelector(getExchangeTokenProperties);

  const getProfit = (
    openAssetPrice,
    closeAssetPrice,
    assetBorrowedAmount,
    isLong
  ) => {
    if (isMock) {
      return { profit: 0, profitPercent: 0 };
    }
    const openAssetPriceFromWei = fromWei(openAssetPrice);
    const borrowed = fromWei(assetBorrowedAmount);
    const closedOrLatestPrice = closeAssetPrice
      ? fromWei(closeAssetPrice)
      : fromWei(assetTokenProperties.price);
    const profit =
      (closedOrLatestPrice - openAssetPriceFromWei) *
      borrowed *
      (isLong ? 1 : -1);

    const profitPercent = (profit / fromWei(trade.stableTokenCollateral)) * 100;

    return { profit, profitPercent };
  };

  const { profit, profitPercent } = getProfit(
    trade.openPrice,
    trade.closePrice,
    trade.assetTokenBorrowed,
    trade.isLong
  );

  const profitLossColor = trade.profitLoss.isProfitable
    ? COLORS.GREEN
    : COLORS.RED;

  const renderExpandIcon = () => (
    <ChevronContainer isExpanded={isExpanded}>
      <img
        style={{ cursor: "pointer", height: "18px" }}
        alt="png"
        src={chevron}
      />
    </ChevronContainer>
  );

  const renderOpenPrice = () => {
    const boolSetToFalseSoWeAlwaysHideForDev = true;
    return trade.isfrontRunStatusPending ||
      (isMock && boolSetToFalseSoWeAlwaysHideForDev) ? (
      <Box display="flex">
        <MainMetrics>$--</MainMetrics>
      </Box>
    ) : (
      <MainMetrics>${formatNumber(fromWei(trade.openPrice), 2)}</MainMetrics>
    );
  };

  return (
    <Box>
      <TableRow
        py={0.5}
        px={2}
        onClick={() => toggleIsExpanded()}
        isExpanded={isExpanded}
      >
        <TableCell id="type">
          <MainMetricsWithMock isMock={isMock}>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <PulsatingWarningDot />
              </Box>{" "}
              {trade.type}
            </Box>
          </MainMetricsWithMock>
        </TableCell>
        <TableCell id="leverage">
          <MainMetricsWithMock isMock={isMock}>
            {formatNumber(isMock ? trade.leverage : fromWei(trade.leverage), 2)}
            x
          </MainMetricsWithMock>
        </TableCell>
        <TableCell id="position eth">
          <MainMetricsWithMock isMock={isMock}>
            {formatNumber(
              isMock
                ? (trade.leverage * fromWei(trade.collateral)) /
                    fromWei(trade.openPrice)
                : fromWei(trade.assetTokenBorrowed),
              2
            )}
          </MainMetricsWithMock>
        </TableCell>
        <TableCell id="open price">
          <MainMetricsWithMock isMock={isMock}>
            {renderOpenPrice()}
          </MainMetricsWithMock>
        </TableCell>
        <TableCell id="liq price">
          <MainMetricsWithMock isMock={isMock}>
            {`$${
              isMock ? "--" : formatNumber(fromWei(trade.liquidationPrice), 2)
            }`}
          </MainMetricsWithMock>
        </TableCell>
        <TableCell id="collateral">
          <MainMetricsWithMock isMock={isMock}>
            {formatNumber(fromWei(trade.collateral), 2)}
          </MainMetricsWithMock>
        </TableCell>
        <TableCell id="tradeValue">
          <MainMetricsWithMock isMock={isMock}>{`$${formatNumber(
            isMock
              ? trade.leverage * fromWei(trade.collateral)
              : fromWei(trade.tradeValue),
            2
          )}`}</MainMetricsWithMock>
        </TableCell>
        <TableCell>
          <MainMetricsWithMock isMock={isMock} color={profitLossColor}>
            {`${formatNumber(fromWei(trade.profitLoss.percent) * 100, 2)}%`}
          </MainMetricsWithMock>
        </TableCell>
        <TableCell>
          <MainMetricsWithMock isMock={isMock} color={profitLossColor}>
            {`$${formatNumber(fromWei(trade.profitLoss.value), 2)}`}
          </MainMetricsWithMock>
        </TableCell>
        {isMock ? (
          <TableCell justifyContent="flex-end">
            <LoadingSpinner color={"dark"} />
          </TableCell>
        ) : (
          <TableCell justifyContent="flex-end">{renderExpandIcon()}</TableCell>
        )}
      </TableRow>
      {isExpanded && !isMock && (
        <DetailsContainer px={2} pb={2} pt={0.25}>
          <Border pt={2} />
          <OpenPositionDetails trade={trade} />
        </DetailsContainer>
      )}
    </Box>
  );
};

export default OpenPositition;

const EXPANDED_BG_COLOR = "rgba(149, 159, 225, 0.075)";

const HOVER_BG_COLOR = "#25293ccc";

const TableRow = styled(Box)<{ isExpanded: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
  grid-template-rows: 25px 0.1fr;
  cursor: pointer;
  ${props =>
    props.isExpanded &&
    css`
      background-color: ${EXPANDED_BG_COLOR};
    `}
  &:hover {
    ${props =>
      !props.isExpanded &&
      css`
        background-color: ${HOVER_BG_COLOR};
      `}
  }
`;

const TableCell = styled(Box)`
  display: flex;
  align-items: center;
`;

const DetailsContainer = styled(Box)`
  background-color: ${EXPANDED_BG_COLOR};
`;
const Border = styled(Box)`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const ChevronContainer = styled(Box)<{ isExpanded: boolean }>`
  display: flex;
  opacity: 1;
  ${props =>
    props.isExpanded &&
    css`
      opacity: 0.8;
    `};
`;

const MainMetricsWithMock = styled(MainMetrics)<{ isMock: boolean }>`
  opacity: ${props => (props.isMock ? 0.8 : 1)};
`;
