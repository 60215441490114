import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const SIZES = {
  Sm: "sm",
  Md: "md"
};

const Input = props => {
  const renderRightLabel = () => (
    <InputRightText>{props.rightLabel}</InputRightText>
  );
  return (
    <InputContainer disabled={props.disabled}>
      <StyledInput
        width={props.width}
        placeholder={props.placeholder}
        type={props.type}
        name={props.name}
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
      {props.rightLabel && renderRightLabel()}
    </InputContainer>
  );
};

Input.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  rightLabel: PropTypes.string,
  width: PropTypes.string
};

Input.defaultProps = {
  size: SIZES.Md,
  type: "text",
  disabled: false
};

export default Input;

const InputContainer = styled.div<{ disabled: boolean }>`
  flex-direction: row;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.4);
  &:focus-within {
    border: 1px solid rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 3px 0px #fff;
  }
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 1px 0px #fff;
        &:focus-within {
          box-shadow: 0px 0px 3px 0px #fff;
        }
      }
    `}
`;

const StyledInput = styled.input`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
  flex: 1;
  outline: none;
  font-size: 15px;
  font-family: Rubik;
  border: none;
  margin-left: 10px;
  background: none;
  height: 36px;
  color: #fff;
`;

const InputRightText = styled.div<{ theme: any }>`
  font-weight: 500;
  font-size: 9px;
  height: 38px;
  justify-content: center;
  display: flex;
  word-break: normal;
  align-items: center;
  color: #c0c0c0;
  margin-right: ${props => props.theme.spacing(1.5)}px;
`;
