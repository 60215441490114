import styled from "@emotion/styled";
import { typography, palette } from "@material-ui/system";
import { COLORS } from "styles/theme";
// https://material-ui.com/system/typography/
export const Text = styled.span`
  color: #eee;
  ${typography};
  ${palette};
`;

export const A = styled.a`
  font-weight: 500;
  color: ${COLORS.LINK_BLUE} !important;
`;

export const H2 = styled.h2`
  color: #eee;
  font-size: 18px;
  font-weight: 500;
  font-family: "Rubik";
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin: 0;
`;

export const H3 = styled.h3`
  color: #eee;
  font-size: 12px;
  font-weight: 500;
  font-family: "Rubik";
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin: 0;
`;

export const H4 = styled.h4`
  color: #757575;
  font-size: ${props => props.fontSize};
  font-weight: 500;
  font-family: "Rubik";
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin: 0;
`;
H4.defaultProps = {
  fontSize: "10px"
};

export const MainMetrics = styled.div`
  ${typography};
  ${palette};
`;

MainMetrics.defaultProps = {
  fontFamily: "Roboto Mono",
  fontSize: "13px",
  fontWeight: 400,
  color: "#ddd"
};
