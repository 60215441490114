import { getExchangeInstance } from "contracts/Exchange";

export const getInternalExchangeHistory = async (
  exchangeAddress,
  userAddress
) => {
  const exchangeInstance = getExchangeInstance(exchangeAddress);
  const internalExchangeEvents = await exchangeInstance.getPastEvents(
    "PoolBalancerEvent",
    {
      filter: { provider: userAddress },
      fromBlock: 0,
      toBlock: "latest"
    }
  );

  return internalExchangeEvents.map(e => e.returnValues);
};

export const getPoolImbalance = async ({
  exchangeAddress,
  assetPrice,
  stablePrice
}) => {
  const exchangeInstance = getExchangeInstance(exchangeAddress);

  try {
    const {
      0: traderSendsDiscountedAmount,
      1: traderWillReceiveAmount,
      2: exchangeNeedsAsset
    } = await exchangeInstance.methods
      .calculateImbalance(assetPrice, stablePrice)
      .call();

    return {
      traderSendsDiscountedAmount,
      traderWillReceiveAmount,
      exchangeNeedsAsset
    };
  } catch (e) {
    return {
      traderSendsDiscountedAmount: 0,
      traderWillReceiveAmount: 0,
      exchangeNeedsAsset: 0
    };
  }
};
