import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/browser";

import Box from "components/Box";
import ListItem from "components/ListItem";
import styled from "@emotion/styled";
import { Text, MainMetrics } from "components/Typography";
import Input from "components/Input";
import { fromWei, toWei } from "utils/numbers";
import Button from "components/Button";
import FsWalletModal from "components/FsWalletModal";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "styles/theme";
import {
  API_ENDPOINT_TYPES,
  NULL_ADDRESS,
  REFERRAL_QUERY_PARAM,
  getMessageProcessorEndpoint
} from "../../../../constants";
import useLocalStorage from "hooks/useLocalStorage";
import axios from "axios";
import getProvider from "../../../../provider";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { selectIsTradePendingClose } from "redux/messageProcessor/selectors";
import { BigNumber } from "ethers";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";
import { RegistryModel } from "redux/registry/model";

const ClosePosition = ({ trade, setCloseTradeClicked }) => {
  const [amountToClose, setAmountToClose] = useState(trade.assetTokenBorrowed);
  const [closeTradeValidation, setCloseTradeValidation] = useState<any>({
    hasFundsForGas: true
  });
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const currentExchange = exchange.address;
  const [referralAddress] = useLocalStorage(REFERRAL_QUERY_PARAM, []);
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const isTradeClosing = useSelector((state: FsRootState) =>
    selectIsTradePendingClose(state, trade.tradeId)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    validateClosePosition(amountToClose);
  }, []);

  const calcClosePosition = async () => {
    const tradeValueFromWei = fromWei(trade.assetTokenBorrowed);
    const amountToCloseFromWei = fromWei(amountToClose);

    const percentToClose = amountToCloseFromWei / tradeValueFromWei;
    closePosition(percentToClose);
  };

  const validateClosePosition = async amountToClose => {
    const tradeValueFromWei = fromWei(trade.assetTokenBorrowed);
    const amountToCloseFromWei = fromWei(amountToClose);

    const percentToClose = amountToCloseFromWei / tradeValueFromWei;
    try {
      const endpoint = await getMessageProcessorEndpoint();
      const { data } = await axios.post(
        `${endpoint}/exchange/${currentExchange}/validation/closeTrade/${accountAddress}`,
        {
          percentToClose: toWei(String(percentToClose)),
          tradeId: trade.tradeId
        }
      );

      setCloseTradeValidation(data);
    } catch (e) {
      if (e.response) {
        Sentry.captureException(e);
        dispatch.api.addError({
          type: API_ENDPOINT_TYPES.VALIDATE_CLOSE,
          code: e.response?.status,
          message: e.response?.data?.message
        });
      } else if (e.request) {
        Sentry.captureException(e);
      }
      console.log(e);
    }
  };

  const closePosition = async percentToClose => {
    let referral = NULL_ADDRESS;

    if (
      getProvider().utils.isAddress(referralAddress) &&
      referralAddress !== accountAddress
    ) {
      referral = referralAddress;
    }
    try {
      await dispatch.messageProcessor.handleCloseTrade({
        tradeId: trade.tradeId,
        isLong: trade.type === "long",
        percentToCloseInWei: toWei(String(percentToClose)),
        referral,
        exchangeAddress: currentExchange
      });
      setCloseTradeClicked(false);
    } catch (e) {
      console.error(e);
    }
  };

  const renderAddMoreGas = () => (
    <>
      <Button
        color={COLORS.PURPLE}
        fluidWidth
        onClick={() => {
          setIsDepositModalOpen(true);
        }}
      >
        Add more USDC for gas
      </Button>
    </>
  );

  const renderCloseInput = () => {
    return (
      <>
        <Box display="flex" justifyContent="space-between" mb={0.25}>
          <Text color="#757575" fontSize="10px" fontWeight={500}>
            AMOUNT TO CLOSE
          </Text>

          <MaxButton
            onClick={() => setAmountToClose(trade.assetTokenBorrowed)}
            color={COLORS.PURPLE}
            fontWeight={500}
            fontSize="10px"
          >
            MAX
          </MaxButton>
        </Box>
        <Input
          value={amountToClose === "0" ? "" : fromWei(amountToClose)}
          type="number"
          placeholder="0.00"
          name="close"
          id="closeTrade"
          onChange={e => {
            let value;
            if (e.target.value > Number(fromWei(trade.assetTokenBorrowed))) {
              value = e.target.value > 0 ? trade.assetTokenBorrowed : "0";
              validateClosePosition(value);
              setAmountToClose(value);
            } else {
              value = e.target.value > 0 ? toWei(e.target.value) : "0";
              validateClosePosition(value);
              setAmountToClose(value);
            }
          }}
          rightLabel={assetTokenProperties.symbol}
        />
      </>
    );
  };

  const {
    assetMarketPrice,
    profitLoss,
    gasCostValue,
    positionDetails,
    tradeFeeStable
  } = closeTradeValidation;

  return (
    <>
      <Box display={{ xs: "block", md: "flex" }} my={4} alignItems="center">
        <Box flex="1" />
        <Box mr={4} mt={2}>
          {positionDetails?.forcedFullClose &&
            closeTradeValidation.hasFundsForGas && (
              <Text color={COLORS.YELLOW}>Closes full trade</Text>
            )}
        </Box>
        <Box mr={4}>{renderCloseInput()}</Box>
        <Box mr={4} mt={{ xs: 2, md: 0 }}>
          <ListItem label="Close Price">
            {`${Number(fromWei(assetMarketPrice)).toFixed(2)} ${
              stableTokenProperties.symbol
            }`}
          </ListItem>
        </Box>
        <Box mr={4} mt={{ xs: 2, md: 0 }}>
          {positionDetails?.remainingAssetAmount && (
            <ListItem label="New Position Size">
              {`${Number(
                fromWei(
                  BigNumber.from(
                    positionDetails.remainingAssetAmount
                  ).toString()
                )
              ).toFixed(4)} ${assetTokenProperties.symbol}`}
            </ListItem>
          )}
        </Box>
        <Box mr={4} mt={{ xs: 2, md: 0 }}>
          {tradeFeeStable && (
            <ListItem label="Trade Fee">
              {`${Number(
                fromWei(BigNumber.from(tradeFeeStable).toString())
              ).toFixed(2)} ${stableTokenProperties.symbol}`}
            </ListItem>
          )}
        </Box>
        <Box mr={4} mt={{ xs: 2, md: 0 }}>
          <ListItem label="Net Profit">
            <MainMetrics
              color={profitLoss?.isProfitable ? COLORS.GREEN : COLORS.RED}
            >
              {`${Number(fromWei(profitLoss?.value)).toFixed(2)} ${
                stableTokenProperties.symbol
              }`}
            </MainMetrics>
          </ListItem>
        </Box>
        <Box mr={4} mt={{ xs: 2, md: 0 }}>
          <ListItem label="Gas fee">
            {`${Number(fromWei(gasCostValue)).toFixed(2)} ${
              stableTokenProperties.symbol
            }`}
          </ListItem>
        </Box>
        <Box mt={{ xs: 2, md: 0 }}>
          <Button
            disabled={!amountToClose || !closeTradeValidation.hasFundsForGas}
            color={COLORS.PURPLE}
            onClick={() => calcClosePosition()}
            isLoading={isTradeClosing}
          >
            {closeTradeValidation.hasFundsForGas
              ? "Confirm"
              : `Not enough ${stableTokenProperties.symbol} for gas`}
          </Button>
        </Box>
        {!closeTradeValidation.hasFundsForGas && (
          <Box ml={1} mt={{ xs: 2, md: 0 }}>
            {renderAddMoreGas()}
          </Box>
        )}
      </Box>
      <FsWalletModal
        token={stableTokenProperties}
        isOpen={isDepositModalOpen}
        isDeposit={true}
        handleClose={() => setIsDepositModalOpen(false)}
      />
    </>
  );
};

export default ClosePosition;

const MaxButton = styled(Text)`
  cursor: pointer;
`;
