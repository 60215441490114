import React from "react";
import styled from "@emotion/styled";
import PriceChart from "./PriceChart";
import ExchangeActivity from "./ExchangeActivity";
import { useSelector } from "react-redux";

import Box from "components/Box";
const ChartSection = () => {
  return (
    <Container width="100%" height="100%">
      <ActivityContainer height="55px">
        {<ExchangeActivity />}
      </ActivityContainer>
      <PriceChart />
    </Container>
  );
};

export default ChartSection;

const Container = styled(Box)`
  position: relative;
  border-left: 1px solid #393e4e;
  border-right: 1px solid #393e4e;
  border-bottom: 1px solid #393e4e;
`;

const ActivityContainer = styled(Box)`
  background: #151721;
  border-bottom: 1px solid #393e4e;
`;
