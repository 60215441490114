import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import { useDispatch, useSelector } from "react-redux";
import { FsRootModel, FsDispatch } from "redux/store";

const TermsPage = () => {
  const dispatch = useDispatch<FsDispatch>();

  // TODO(dankurka): any
  const { terms } = useSelector<FsRootModel, any>(
    state => state.termsAndActions
  );

  return (
    <>
      <Container px={2} py={4}>
        <div>{terms}</div>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  width: 100%;
  color: #fff;
  line-height: 24px;
  font-size: 17px;
`;

export default TermsPage;
