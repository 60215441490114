import { fromWei } from "utils/numbers";
import moment from "moment";
import { BigNumber } from "ethers";

const getProposalTimeLeft = votingEnds => {
  //votingEnds time - current time
  var currentTimeInSeconds = moment().unix();
  const secondsLeft = votingEnds - currentTimeInSeconds;

  if (secondsLeft > 0) {
    return secondsLeft;
  } else {
    return 0;
  }
};

export const handleActionFormat = action => {
  //clean code
  let cleanedAction = action.trim().replace(/\n\s|\t\n\r/g, "");

  //find if ends with array
  const doesEndWithArray = cleanedAction[cleanedAction.length - 1];

  const lastParen = cleanedAction.lastIndexOf("}") + 1;

  //if so remove the array
  if (doesEndWithArray !== "}") {
    cleanedAction = cleanedAction.substring(0, lastParen);
  }

  //now check all keys to make sure they have quotes and if not add them
  let isValidJson;
  try {
    JSON.parse(cleanedAction);
    isValidJson = true;
  } catch {
    isValidJson = false;
  }

  if (!isValidJson) {
    //add quotes to keys without quotes

    const numOfColons = cleanedAction.split(":").length;

    let marker = cleanedAction.length;

    for (var i = 0; i < numOfColons - 1; i++) {
      const findLastColon = cleanedAction.substring(0, marker).lastIndexOf(":");

      const findLastSpaceUpToColon =
        cleanedAction.substring(0, findLastColon).lastIndexOf(" ") + 1;

      const copy = cleanedAction.split("");
      copy.splice(findLastColon, 0, `"`);
      copy.splice(findLastSpaceUpToColon, 0, `"`);

      cleanedAction = copy.join("");
      marker = findLastSpaceUpToColon;
    }
  }
  const actionObj = JSON.parse(cleanedAction);
  const actionName = actionObj.name;

  return actionName;
};

export const getProposal = (governance, id) => {
  const proposal = governance.proposals.find(proposal => proposal.id === id);

  const proposalEvent = governance.events.proposalCreated.find(proposalData => {
    return proposalData.proposalId === id;
  });

  if (!proposal) {
    return false;
  }

  const timeLeft = getProposalTimeLeft(proposal.votingEnds);
  // const actionName = handleActionFormat(proposalEvent.action); //@TODO @derek fix this by redeploying contracts
  const actionName = "";

  const result =
    Number(
      fromWei(
        BigNumber.from(proposal.yesVotes).sub(BigNumber.from(proposal.noVotes))
      )
    ) > 0
      ? true
      : false;

  const proposalCallFailed = !!governance.events.proposalCallFailed.find(
    proposalFail => {
      return proposalFail.proposalId === id;
    }
  );

  const proposalAccepted = !!governance.events.proposalAccepted.find(
    proposalAccepted => proposalAccepted.proposalId === id
  );

  return {
    title: proposalEvent.title || "No title",
    description: proposalEvent.description || "No description",
    yesVotes: proposal.yesVotes,
    noVotes: proposal.noVotes,
    votingEnds: proposal.votingEnds,
    timeLeft,
    isVoteResolved: proposal.isVoteResolved,
    to: proposal.to,
    pausedAt: proposal.pausedAt,
    isUpgradingVotingContract: proposal.isUpgradingVotingContract,
    fstSnapshotId: proposal.fstSnapshotId,
    id: proposal.id,
    action: actionName,
    result,
    isUpgradeProposal: proposal.isUpgradeProposal,
    ownerApproved: proposal.ownerApproved,
    proposalCallFailed,
    proposalAccepted,
    proposer: proposal.proposer
  };
};

export const getProposals = state => {
  if (state.governance.proposals.length > 0) {
    const proposals = state.governance.proposals
      .map(proposal => getProposal(state.governance, proposal.id))
      .reverse();
    return proposals;
  }
  return [];
};

export const getcurrentUserVotedCastedForPropsal = (state, proposalId) => {
  const currentUserVotedCasted = state.governance.events.currentUserVotedCasted.find(
    proposal => proposal.proposalId === proposalId
  );
  return currentUserVotedCasted || {};
};
