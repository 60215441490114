import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import * as models from "./models";

const store = init({
  models,
  redux: {
    // middlewares: [logger] this is jamming up the logs in the console.
  }
});

export type FsRootModel = typeof models;
export type Store = typeof store;
export type FsDispatch = RematchDispatch<FsRootModel>;
export type FsRootState = RematchRootState<typeof models>;

export default store;
