import { RematchRootState } from "@rematch/core";
import { FsRootModel } from "redux/store";
import { GetExchangeDataResponse } from "./model";
import { BigNumber } from "ethers";
import { ONE_ETHER } from "utils/numbers";

export const selectCurrentExchangeDynamicData = (
  state: RematchRootState<FsRootModel>
) => {
  // TODO(dankurka): Fix here
  return state.dynamicExchangeData.exchange || ({} as GetExchangeDataResponse);
};

export const select24HrVolume = (state: RematchRootState<FsRootModel>) => {
  const { network } = state.wallet;
  const {
    openTrades24Hr,
    closeTrades24Hr
  } = state.dynamicExchangeData.theGraph;

  if (network === "main") {
    const openTradeVolume = openTrades24Hr.reduce((pre, cur) => {
      const sum = pre.add(
        BigNumber.from(cur.collateral).mul(BigNumber.from(cur.leverage))
      );
      return sum;
    }, BigNumber.from("0"));
    const closeTradeVolume = closeTrades24Hr.reduce((pre, cur) => {
      const sum = pre.add(
        BigNumber.from(cur.assetMarketPrice)
          .mul(BigNumber.from(cur.assetRedemptionAmount))
          .div(BigNumber.from(ONE_ETHER))
      );

      return sum;
    }, BigNumber.from("0"));
    return closeTradeVolume.add(openTradeVolume).toString();
  } else {
    return state.dynamicExchangeData.exchange?.volume24hr || "0";
  }
};
