import React, { useRef, useEffect } from "react";
import Box from "components/Box";
import styled from "@emotion/styled";
import { Portal } from "react-portal";
import { Text } from "components/Typography";

type ModalProps = {
  children: React.ReactNode;
  handleClose: () => void;
  isOpen: boolean;
  p?: number;
  theme?: { spacing: () => string };
  title?: string;
  width?: string;
}

const Modal = (props: ModalProps)  => {
  // TODO(dankurka): Better type
  const modalRef: React.MutableRefObject<any> = useRef(null);
  useEffect(() => {
    document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  });

  const handleEscape = e => {
    if (e.keyCode === 27) {
      props.handleClose();
    }
  };

  const renderTitleSection = () => {
    return (
      <Title p={1.5}>
        <Text>{props.title}</Text>

        <CloseButtonContainer p={2} onClick={props.handleClose}>
          <img height="14px" alt="png" src="/images/icon-close-x.svg" />
        </CloseButtonContainer>
      </Title>
    );
  };

  return props.isOpen ? (
    <Portal>
      <ModalWrapper>
        <ModalMain ref={modalRef} width={props.width}>
          <ModalMainInner>
            {props.title && renderTitleSection()}
            <Box p={props.p}>{props.children}</Box>
          </ModalMainInner>
        </ModalMain>
      </ModalWrapper>
    </Portal>
  ) : (
    <div />
  );
};

Modal.defaultProps = {
  handleClose: () => {},
  width: "650px",
  p: 4
};

export default Modal;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
`;

const ModalMain = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${(props: {width?: string} )=> props.width};
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// TODO(dankurka): Type here needs fixing
const ModalMainInner = styled.div`
  margin: ${props => (props.theme as any).spacing(2)}px;
  background: #384156;
  font-size: 16px;
  font-weight: 400;
  text-transform: inherit;
  color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const Title = styled(Box)`
  background: #2b313f;
  text-align: center;
  position: relative;
`;

const CloseButtonContainer = styled(Box)`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;
