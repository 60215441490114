import registryModel from "./registry/model";
import chartModel from "./chart/model";
import walletModel from "./wallet/model";
import positionsModel from "./positions/model";
import incentivesModel from "./incentives/model";
import governanceModel from "./governance/model";
import internalExchangeModel from "./internalExchange/model";
import messageProcessorModel from "./messageProcessor/model";
import dynamicExchangeDataModel from "./dynamicExchangeData/model";
import activityStreamModel from "./activityStream/model";
import apiModel from "./api/model";
import liquidityModel from "./liquidity/model";
import { termsAndActions as terms } from "./termsAndActions/model";

// TODO(dankurka): Remove this
export const registry = registryModel;
export const chart = chartModel;
export const wallet = walletModel;
export const positions = positionsModel;
export const incentives = incentivesModel;
export const governance = governanceModel;
export const internalExchange = internalExchangeModel;
export const messageProcessor = messageProcessorModel;
export const dynamicExchangeData = dynamicExchangeDataModel;
export const activityStream = activityStreamModel;
export const api = apiModel;
export const termsAndActions = terms;
export const liquidity = liquidityModel;
