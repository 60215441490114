import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
const YELLOW = "#f2cd59";

type Props = {
  pulseTimeout?: number;
  disablePulse?: boolean;
};

const PulsatingWarningDot: React.FC<Props> = ({
  pulseTimeout,
  disablePulse
}) => {
  const [isPulsating, setIsPulsating] = useState(!disablePulse);
  useEffect(() => {
    if (pulseTimeout) {
      setTimeout(() => {
        setIsPulsating(false);
      }, pulseTimeout);
    }
  });
  return (
    <Container>
      <PulsatingDot isPulsating={isPulsating} />
    </Container>
  );
};

PulsatingWarningDot.defaultProps = {
  disablePulse: false,
  pulseTimeout: 0
};

const Container = styled.div`
  position: relative;
  height: 7px;
  width: 7px;
`;

interface PulsatingDotProps {
  isPulsating: boolean;
}

const PulsatingDot = styled.div<PulsatingDotProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 50%;
    background-color: ${YELLOW};
    ${props =>
      props.isPulsating
        ? "animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;"
        : "transform: scale(0.33)"};
  
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: ${YELLOW};
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
      transform: scale(0.85);
    }
  }
  }
`;

export default PulsatingWarningDot;
