import React, { useState } from "react";
import Box from "components/Box";
import styled from "@emotion/styled";
import { Text, H4 } from "components/Typography";
import useLocalStorage from "hooks/useLocalStorage";
import Tooltip from "components/Tooltip";
import { DEFAULT_MAX_PRICE_DEVIATION } from "../../../constants";

const MAX_PRICE_DEVIATION_QUERY_PARAM = "max_price_deviation";
const maxDeviationOptions = [0.2, DEFAULT_MAX_PRICE_DEVIATION];

const Advanced: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxPriceDeviation, setMaxPriceDeviation] = useLocalStorage(
    MAX_PRICE_DEVIATION_QUERY_PARAM,
    DEFAULT_MAX_PRICE_DEVIATION
  );
  const [isCustom, setIsCustom] = useState(
    !maxDeviationOptions.includes(maxPriceDeviation)
  );

  const renderItemButton = (maxDeviation: number) => {
    return (
      <Item
        isActive={maxPriceDeviation === maxDeviation}
        onClick={() => {
          setMaxPriceDeviation(maxDeviation);
          setIsCustom(false);
        }}
      >
        <Text>{maxDeviation}%</Text>
      </Item>
    );
  };

  const renderExpandedContent = () => {
    return (
      <>
        <Box display="flex" mt={0.5}>
          <H4>Slippage tolerance</H4>{" "}
          <Tooltip content="The transaction will be rejected if the price changes unfavorably by more than this percentage" />
        </Box>
        <Box display="flex" mt={1} mb={2}>
          {renderItemButton(maxDeviationOptions[0])}
          {renderItemButton(maxDeviationOptions[1])}
          <Item
            display="flex"
            alignItems="center"
            isActive={!maxDeviationOptions.includes(maxPriceDeviation)}
          >
            <StyledInput
              aria-label="max deviation"
              type="number"
              min="0"
              max="5"
              step="any"
              placeholder={Number(maxPriceDeviation).toFixed(1)}
              value={isCustom ? maxPriceDeviation : ""}
              onChange={e => {
                if (e.target.value === "") {
                  setMaxPriceDeviation(DEFAULT_MAX_PRICE_DEVIATION);
                  setIsCustom(false);
                } else if (Number(e.target.value) > 5) {
                  setMaxPriceDeviation(10);
                  setIsCustom(true);
                } else {
                  setMaxPriceDeviation(Number(e.target.value));
                  setIsCustom(true);
                }
              }}
            />
            <InputRight>%</InputRight>
          </Item>
        </Box>
      </>
    );
  };

  return (
    <Container>
      <ClickableHeader
        display="flex"
        justifyContent="space-between"
        py={1}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Text>Advanced</Text>
        <ChevronContainer isExpanded={isExpanded}>
          <img
            style={{ cursor: "pointer", height: "18px" }}
            alt="toggled-icon"
            src="/images/chevron.svg"
          />
        </ChevronContainer>
      </ClickableHeader>
      {isExpanded && renderExpandedContent()}
    </Container>
  );
};

export default Advanced;

type ChevronContainerProps = {
  isExpanded: boolean;
};

const Item = styled(Box)<{ isActive?: boolean }>`
  padding: 0 12px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(60 73 121);
  -webkit-flex: 1 1;
  flex: 1 1;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #262d3e;
    border: 1px solid rgb(102 120 185);
  }
  ${props =>
    props.isActive &&
    `
    background: #262d3e;
    border: 1px solid #6b8eff;
    `}
  &:first-of-type {
    margin-right: 8px;
  }
  &:last-of-type {
    margin-left: 8px;
  }
  &:focus-within {
    border: 1px solid #6b8eff;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  color: #fff;
  margin-right: 0;
  height: 35px;
  background: none;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  outline: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 12px 0px;
  font-size: 16px;
  font-family: Avenir Next;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputRight = styled(Box)`
  cursor: auto;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  font-weight: 500;
  right: 0;
  margin-top: 2px;
`;

const ChevronContainer = styled(Box)<ChevronContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transform: ${({ isExpanded }) =>
    isExpanded ? "rotate(0)" : "rotate(-90deg)"};
`;

const Container = styled(Box)`
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
`;

const ClickableHeader = styled(Box)`
  cursor: pointer;
`;
