import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import moment from "moment";
import { COLORS } from "styles/theme";

import Box from "components/Box";
import Button from "components/Button";
import NavBar from "components/NavBar";
import TraderSection from "./TraderSection";
import UniSection from "./UniSection";
import LiquiditySection from "./LiquiditySection";
import { Text, H2 } from "components/Typography";
import { FsRootState } from "redux/store";
import { RegistryModel } from "redux/registry/model";
import { WalletModel } from "redux/wallet/model";

const Rewards = () => {
  const dispatch = useDispatch();
  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const { requests: walletRequest } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );

  const {
    requests: incentivesRequests,
    lastUpdateTimestamp,
    timeInterval
  } = useSelector((state: FsRootState) => state.incentives);
  useEffect(() => {
    if (walletRequest.isInitialized) {
      initializeIncentivesData();
    }
  }, [exchange, walletRequest.isInitialized]);

  const initializeIncentivesData = async () => {
    await dispatch.incentives.initializeIncentivesData();
  };
  if (!walletRequest.isInitialized) {
    return <NavBar />;
  }
  const nextReleaseTimestamp =
    Number(lastUpdateTimestamp) + Number(timeInterval);

  const currentTimestamp = Math.floor(+new Date() / 1000);
  const hasWeekEnded = currentTimestamp > nextReleaseTimestamp;
  const renderAdvanceWeekButton = () => {
    return (
      <Button
        isLoading={incentivesRequests.isAdvancingWeek}
        onClick={() => dispatch.incentives.advanceWeek()}
        color={COLORS.PURPLE}
      >
        Advance the rewards epoch for everyone
      </Button>
    );
  };

  const getNextDateString = () => {
    const string = moment.unix(nextReleaseTimestamp).fromNow(true);
    switch (string) {
      case "a day":
        return "in 1 day";
      default:
        return string;
    }
  };

  const renderHeader = () => {
    return (
      <Box>
        <Box>
          <Box mb={1}>
            <H2>Rewards</H2>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <NavBar />

      <Contianer px={2} my={6}>
        <Box mb={3}>{renderHeader()}</Box>
        <Section mb={3} p={4}>
          <UniSection />
        </Section>
        <Section mb={3} p={4}>
          <TraderSection epochTimeLeft={getNextDateString()} />
        </Section>
        <Section mb={3} p={4}>
          <LiquiditySection epochTimeLeft={getNextDateString()} />
        </Section>
        {/* <Section p={4}>
          <ReferralSection />
        </Section> */}
      </Contianer>
    </>
  );
};

const Contianer = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  width: 100%;
`;

const Section = styled(Box)`
  background: #272b3e;
`;

export default Rewards;
