import { RematchRootState } from "@rematch/core";
import { FsRootModel } from "redux/store";

export const selectUsersOpenTrades = (state: RematchRootState<FsRootModel>) => {
  const { openTrades } = state.positions.user;
  return openTrades.filter(
    trade => trade.isOpen && trade.state.status !== "error"
  );
};

export const selectUsersClosedTrades = (
  state: RematchRootState<FsRootModel>
) => {
  const { closedTrades } = state.positions.user;
  return closedTrades.filter(trade => trade.state.status !== "error");
};
