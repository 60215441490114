import React from "react";
import styled from "@emotion/styled";
import { Text } from "components/Typography";

type Props = {
  fluidWidth?: boolean;
};

const Button: React.FC<Props> = ({ fluidWidth = false }) => {
  return (
    <StyledButton fluidWidth={fluidWidth}>
      <StyledText fontSize="14px">DISABLED IN YOUR REGION</StyledText>
    </StyledButton>
  );
};

export default Button;

type StyledButtonProps = {
  fluidWidth: boolean;
};
const StyledButton = styled.button<StyledButtonProps>`
  min-height: 42px;
  padding: 0 26px;
  border-radius: 5px;
  border: none;
  position: relative;
  cursor: no-drop;
  ${props => props.fluidWidth && "width: 100%;"}
  background: #515569;
`;

const StyledText = styled(Text)`
  opacity: 0.75;
`;
