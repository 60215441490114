import React, { useState } from "react";
import { useSelector } from "react-redux";
import useInterval from "hooks/useInterval";
import ProgressBar from "components/ProgressBar";

const formatSecondsToMinutesAndSeconds = seconds => {
  const paddedValue = value => (value < 10 ? `0${value}` : value);
  const minutesValue = Math.floor(seconds / 60);
  const secondsValue = seconds % 60;

  return seconds <= 0
    ? "Voting ended"
    : `${paddedValue(minutesValue)}m ${paddedValue(secondsValue)}s`;
};

const TimerBar = ({ timeLeft, handleRerenderParent }) => {
  const [countdownTimeLeft, setCountdownTimeLeft] = useState(timeLeft);
  const { minTime } = useSelector(state => state.governance);

  useInterval(() => {
    if (countdownTimeLeft > 0) {
      setCountdownTimeLeft(countdownTimeLeft - 1);
    }

    // This enables the parent to rerender when the timer runs out.
    // I trigger the rerender a few times just to keep the ui snappy.
    // There's a better of doing this. Consider this an MVP.
    if (
      handleRerenderParent &&
      countdownTimeLeft >= 1 &&
      countdownTimeLeft <= 3
    ) {
      setTimeout(() => {
        handleRerenderParent();
      }, 2000);
    }
  }, 1000);
  const getProgressWidth = () => {
    return (countdownTimeLeft / minTime) * 100;
  };

  return (
    <ProgressBar
      height="22px"
      percentage={getProgressWidth()}
      label={formatSecondsToMinutesAndSeconds(countdownTimeLeft)}
    />
  );
};

TimerBar.defaultProps = {
  onFinish: () => {}
};

export default TimerBar;
