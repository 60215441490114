import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import SentryFullStory from "@sentry/fullstory";
import * as FullStory from "@fullstory/browser";

FullStory.init({ orgId: "10830F" });

Sentry.init({
  dsn:
    "https://3e94331a2bdb4c98b072199c79fdfc0d@o470031.ingest.sentry.io/5500195",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
    new SentryFullStory("futureswap")
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
