import React from "react";
import PropTypes from "prop-types";

import Box from "components/Box";
import Button from "components/Button";
import { Text } from "components/Typography";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { Link } from "@reach/router";

const TitleBar = ({
  title,
  buttonLabel,
  buttonOnClick,
  backLabel,
  backPath
}) => {
  const renderBackLink = () => (
    <UnstyledLink to={backPath}>
      <Text fontSize="13px" color="#ccc" fontWeight={500}>
        ← {backLabel}
      </Text>
    </UnstyledLink>
  );
  const renderButton = () => (
    <Button onClick={buttonOnClick} color={COLORS.PURPLE}>
      {buttonLabel}
    </Button>
  );
  return (
    <>
      <Box height="20px">{backLabel && renderBackLink()}</Box>
      <Box
        display="flex"
        mb={4}
        height="38px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Title fontSize="24px">{title}</Title>
        {buttonLabel && renderButton()}
      </Box>
    </>
  );
};

const Title = styled(Text)`
  text-transform: uppercase;
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  // test
  backLabel: PropTypes.string,
  /* Yo */
  backPath: PropTypes.string
};

TitleBar.defaultProps = {
  buttonOnClick: () => {}
};

export default TitleBar;
