import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectTraderIncentives } from "redux/incentives/selectors";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import { COLORS } from "styles/theme";
import Box from "components/Box";
import Button from "components/Button";
import { Text, A } from "components/Typography";
import HorizontalList from "./HorizontalList";
import { FsDispatch, FsRootState } from "redux/store";
import { getStakingContractAddresses } from "../../constants";
import useInterval from "hooks/useInterval";

const UniSection = () => {
  const dispatch = useDispatch<FsDispatch>();
  const {
    requests: {
      isClaimingStakingFst,
      isEnteringStakingPool,
      isExitingStakingPool
    },
    fstPriceUsd,
    uniswapLp: {
      usersStakedLpTokens,
      usersEarnedRewards,
      rewardRate,
      totalSupply,
      lpTokenGlobal: { reserveUSD, totalSupply: globalSupply }
    }
  } = useSelector((state: FsRootState) => state.incentives);
  const { network, tokens } = useSelector((state: FsRootState) => state.wallet);
  const { uniswapLpToken } = getStakingContractAddresses(network);
  const { isUnlocking, isUnlocked, localBalance } = tokens[uniswapLpToken];

  const hasLpTokens = localBalance !== "0";
  const hasUnclaimedRewards = usersEarnedRewards !== "0";
  const isStaking = usersStakedLpTokens !== "0";

  const rewardsPerDay = fromWei(rewardRate) * 60 * 60 * 24;
  const rewardsPerDayString =
    rewardRate === "0"
      ? "not yet initialized"
      : `${formatNumber(rewardsPerDay, 2)} FST daily (earned continuously)`;

  const ltTokenPrice =
    reserveUSD && globalSupply ? Number(reserveUSD) / Number(globalSupply) : 0;
  const yourStakedLtValue = ltTokenPrice * fromWei(usersStakedLpTokens);
  const totalStakedLtValue = ltTokenPrice * fromWei(totalSupply);

  useInterval(() => {
    if (isStaking) {
      dispatch.incentives.handleGetEarnedRewards();
    }
  }, 5000);

  const renderStakingButton = () => {
    const StakingLabel = hasLpTokens
      ? `Stake ${formatNumber(fromWei(localBalance), 2)} LP tokens`
      : "Stake";

    return !isUnlocked ? (
      <Box>
        <Button
          onClick={() => dispatch.wallet.unlockToken(uniswapLpToken)}
          color={COLORS.PURPLE}
          size="lg"
          fluidWidth
          isLoading={isUnlocking}
        >
          Approve Staking
        </Button>
      </Box>
    ) : (
      <Button
        onClick={() => dispatch.incentives.stakeLpTokens()}
        disabled={!hasLpTokens}
        color={COLORS.PURPLE}
        size="lg"
        fluidWidth
        isLoading={isEnteringStakingPool}
      >
        {StakingLabel}
      </Button>
    );
  };

  const renderClaimButton = () => {
    const claimLabel = hasUnclaimedRewards
      ? `Claim ${formatNumber(fromWei(usersEarnedRewards), 4)} FST`
      : `No unclaimed FST`;

    return (
      <Button
        onClick={() => dispatch.incentives.claimStakingFst()}
        color={COLORS.GREEN}
        size="lg"
        fluidWidth
        disabled={!hasUnclaimedRewards}
        isLoading={isClaimingStakingFst}
      >
        {claimLabel}
      </Button>
    );
  };

  const renderExitAndClaimButton = () => {
    return (
      <Button
        onClick={() => dispatch.incentives.exitStakingPool()}
        color={COLORS.PURPLE}
        size="lg"
        fluidWidth
        disabled={!isStaking}
        isLoading={isExitingStakingPool}
      >
        Unstake & Claim
      </Button>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="center" mb={3}>
        <Text fontSize="20px">UNISWAP ETH/FST LP REWARDS</Text>
      </Box>
      <Box mb={3}>
        <Box mb={0.5}>
          <HorizontalList
            label="Uniswap Pool"
            value={
              <>
                <A
                  href="https://app.uniswap.org/#/add/v2/0x0e192d382a36de7011f795acc4391cd302003606/ETH"
                  target="_blank"
                >
                  FST/ETH
                </A>{" "}
                (
                <A
                  href="https://v2.info.uniswap.org/pair/0x88ae9e1625cfcbd128b89e7f037eaaf6a7cc9666"
                  target="_blank"
                >
                  info
                </A>
                )
              </>
            }
          />
        </Box>
        <Box mb={0.5}>
          <HorizontalList
            label="Distribution"
            value="Staking rewards have ended. There will be more staking opportunities in the future!"
          />
        </Box>
        <Box mb={0.5}>
          <HorizontalList
            label="Total staked LT value"
            value={
              totalStakedLtValue
                ? `$${formatNumber(totalStakedLtValue, 2)}`
                : "--"
            }
          />
        </Box>
        <Box mb={0.5}>
          <HorizontalList
            label="Your staked LT value"
            value={
              yourStakedLtValue
                ? `$${formatNumber(yourStakedLtValue, 2)}`
                : "$0"
            }
          />
        </Box>
      </Box>
      <Box mb={2} display="flex">
        <Box flex="4" mr={2}>
          {renderStakingButton()}
        </Box>
        <Box flex="3">{renderExitAndClaimButton()}</Box>
      </Box>
      <Box>{renderClaimButton()}</Box>
    </>
  );
};

export default UniSection;
