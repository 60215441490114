import { NULL_ADDRESS } from "../constants";
import getProvider from "provider";
import { FsDispatch } from "./store";
/**********************
 ** EXCHANGE EFFECTS **
 **********************/

export const getLocalWalletAccountAddress = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const addressOverride = urlParams.get("address");
  if (addressOverride) {
    return addressOverride;
  }
  // TODO(dankurka): Call without error handling
  const accounts = await getProvider().eth.getAccounts();
  return accounts.length > 0 ? accounts[0] : NULL_ADDRESS;
};

export const initializeApp = async dispatch => {
  // TODO(dankurka): Call without error handling
  const network = await getProvider().eth.net.getNetworkType();
  dispatch.termsAndActions.getAllowedActions();
  if (window["ethereum"]) {
    window["ethereum"].request({ method: "eth_requestAccounts" });
  }

  await dispatch.registry.initialize();

  dispatch.api.getApiStatus();

  // TODO(dankurka): No error handling
  await dispatch.wallet.initializeWalletInfo();
  dispatch.messageProcessor.loadTransactions();
  dispatch.activityStream.getRecentTrades();

  triggerRecurringActions(dispatch);
};

// TODO move
const sleep = async (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

const triggerRecurringActions = async (dispatch: FsDispatch) => {
  triggerContinously(
    () => dispatch.dynamicExchangeData.callSetPriceData(),
    1000,
    2
  );
  triggerContinously(
    () => dispatch.dynamicExchangeData.get24HrGraphTrades(),
    30000,
    2
  );
  triggerContinously(() => dispatch.chart.getHistoricalChartData(), 20000, 2);
  triggerContinously(() => dispatch.activityStream.getRecentTrades(), 10000, 2);
  triggerContinously(() => dispatch.wallet.updateAverageGasGwei(), 20000, 2);
  triggerContinously(
    () => dispatch.wallet.checkIfPendingDepositsAreSynced(),
    5000,
    2
  );
  // TODO(dankurka): Maybe we do not need this?
  triggerContinously(() => dispatch.wallet.updateApiTokenBalances(), 10000, 2);
  triggerContinously(() => dispatch.positions.getUsersOpenTrades(), 5000, 2);
};

export const triggerContinously = async (
  func: () => Promise<void>,
  delay: number,
  outstandingMax = 2
) => {
  let outstandingRequests = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (outstandingRequests < outstandingMax) {
      outstandingRequests++;
      func().finally(() => {
        outstandingRequests--;
      });
    }
    await sleep(delay);
  }
};
