import React, { useState } from "react";
import { getErc20Instance } from "contracts/ERC20";
import Button from "components/Button";
import { useSelector, useDispatch } from "react-redux";
import { toWei } from "utils/numbers";
import { FsDispatch, FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";

type FaucetButtonPros = {
  tokenAddress: string;
  amountToMint: string;
  size: "sm" | "md";
  color: string;
};

const Faucet: React.FC<FaucetButtonPros> = props => {
  const dispatch = useDispatch<FsDispatch>();
  const [isMinting, setIsMinting] = useState(false);
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );

  const sendTokensToUser = async () => {
    const erc20instance = getErc20Instance(props.tokenAddress);

    await erc20instance.methods
      .mint(accountAddress, toWei(props.amountToMint || "100000", 6))
      .send({ from: accountAddress })
      .on("transactionHash", () => {
        setIsMinting(true);
      });
    await dispatch.wallet.updateMetamaskBalance(props.tokenAddress);

    setIsMinting(false);
    //disable button
  };

  return (
    <Button
      onClick={() => sendTokensToUser()}
      size={props.size}
      color={props.color}
      isLoading={isMinting}
    >
      {props.children}
    </Button>
  );
};

Faucet.defaultProps = {
  size: "sm"
};

export default Faucet;
