import React, { useState } from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import Button from "components/Button";
import ListItem from "components/ListItem";
import FsWalletModal from "components/FsWalletModal";
import Tooltip from "components/Tooltip";
import Tippy from "components/Tippy";
import PulsatingWarningDot from "components/PulsatingWarningDot";
import { Text } from "components/Typography";
import { formatNumber } from "utils/formatter";
import { COLORS } from "styles/theme";
import { fromWei } from "utils/numbers";
import { useSelector } from "react-redux";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { selectIsMiningCloseTrade } from "redux/messageProcessor/selectors";
import { selectTokenApiSyncState } from "redux/wallet/selectors";
import { FsRootState } from "redux/store";
import V2SunsetButton from "components/V2SunsetButton";

const CollateralWallet = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDepositSelected, setIsDepositSelected] = useState(true);
  const { stableTokenProperties } = useSelector(getExchangeTokenProperties);
  const stableApiSyncState = useSelector((state: FsRootState) =>
    selectTokenApiSyncState(state, stableTokenProperties.address)
  );
  const isMiningCloseTrade = useSelector(selectIsMiningCloseTrade);

  const isStablePendingApiSync = !!stableApiSyncState;

  const collateralBalance = fromWei(stableTokenProperties.fsBalance);

  const renderFsWalletApiSyncPending = () => {
    return (
      <ApiSyncMessageContainer mb={2} display="flex">
        <Text fontSize="14px" color="#ddd">
          Confirming deposit of{" "}
          {fromWei(stableApiSyncState?.amount, stableTokenProperties.decimals)}{" "}
          {stableTokenProperties.symbol}. Allow about 5 minutes for your funds
          to be available to trade.
        </Text>
      </ApiSyncMessageContainer>
    );
  };

  let tooltipContent;
  if (isStablePendingApiSync) {
    tooltipContent =
      "Allow about 5 minutes for your deposit to be updated and available";
  } else if (isMiningCloseTrade) {
    tooltipContent =
      "Closed position is mining. Your full balance will be available to use when it finishes mining (Usually within 5 minutes)";
  }

  const renderBalance = () =>
    isStablePendingApiSync || isMiningCloseTrade ? (
      <Tippy content={tooltipContent}>
        <Box display="flex">
          {formatNumber(collateralBalance, 2)}
          <Box ml={1}>
            <PulsatingWarningDot />
          </Box>
        </Box>
      </Tippy>
    ) : (
      <>{formatNumber(collateralBalance, 2)}</>
    );

  return (
    <>
      <Box>
        <ListItem>
          <Box display="flex">
            Margin balance
            <Tooltip content="Available collateral that can be used to open a position or pay for gas." />
          </Box>
        </ListItem>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box>{stableTokenProperties.symbol}</Box>
          {renderBalance()}
        </Box>
        {isStablePendingApiSync && renderFsWalletApiSyncPending()}
        <Box display="flex">
          <Box mr={0.75} width="100%">
            <V2SunsetButton size="sm" fluidWidth>
              Deposit
            </V2SunsetButton>
          </Box>
          <Box ml={0.75} width="100%">
            <Button
              onClick={() => {
                setIsModalOpen(true);
                setIsDepositSelected(false);
              }}
              color={COLORS.PURPLE}
              size="sm"
              fluidWidth
            >
              Withdraw
            </Button>
          </Box>
        </Box>
      </Box>
      <FsWalletModal
        token={stableTokenProperties}
        isOpen={isModalOpen}
        isDeposit={isDepositSelected}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

const ApiSyncMessageContainer = styled(Box)`
  border: 2px solid #f1c844;
  padding: 6px 10px;
`;

export default CollateralWallet;
