import React, { useEffect } from "react";
import styled from "@emotion/styled/macro";
import { useDispatch, useSelector } from "react-redux";
import Box from "components/Box";
import { H4 } from "components/Typography";
import PositionItem from "./PositionItem";
import { FsRootState } from "redux/store";
import { RegistryModel } from "redux/registry/model";
import { ActivityModel } from "redux/activityStream/model";

const AllPositions = () => {
  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const activityStream = useSelector<FsRootState, ActivityModel>(
    state => state.activityStream
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      dispatch.activityStream.getRecentTrades();
    };
    initialize();
  }, [exchange]);

  const renderHeader = () => (
    <Box mb={1} display="flex" justifyContent="space-between" px={2}>
      <Box>
        <H4>Size (ETH)</H4>
      </Box>
      <Box>
        <H4>Time</H4>
      </Box>
    </Box>
  );

  return (
    <>
      {renderHeader()}
      <PositionItemsContainer flex="1">
        {activityStream.recentPositions.map(trade => {
          return (
            <PositionItem
              positionSize={trade.positionSize}
              isLiquidated={trade.isLiquidation}
              isLong={trade.isLong}
              isOpen={!trade.isClose}
              timestamp={trade.timestamp}
              key={trade.timestamp}
            />
          );
        })}
      </PositionItemsContainer>
    </>
  );
};

export default AllPositions;

const PositionItemsContainer = styled(Box)`
  overflow-y: scroll;
`;
