import { fromWei } from "utils/numbers";
import { DataLoader } from "data/DataLoader";
import { RegistryModel } from "redux/registry/model";
import { FsDispatch, FsRootState } from "redux/store";
import axios from "axios";

type GraphOpenTrade = {
  collateral: string;
  leverage: string;
  timestamp: number;
};
type GraphCloseTrade = {
  assetMarketPrice: string;
  assetRedemptionAmount: string;
  timestamp: number;
};

export interface GetExchangeDataResponse {
  exchangeAddress: string;
  timestamp: number;
  fundingRate: string;
  assetPrice: string;
  stablePrice: string;
  marketAssetPrice: string;
  // pool usage
  assetPoolSize: string;
  stablePoolSize: string;
  longAssetBorrowed: string;
  shortAssetBorrowed: string;
  stablePoolUtilization: string;
  assetPoolUtilization: string;
  volume24hr: string;
}

const INITIAL_ORACLE_STATE = {
  exchange: null as GetExchangeDataResponse | null,
  errorLoadingData: false,
  theGraph: {
    openTrades24Hr: [] as GraphOpenTrade[],
    closeTrades24Hr: [] as GraphCloseTrade[]
  }
};

export type OracleModel = typeof INITIAL_ORACLE_STATE;

const updateTitleFn = (marketAssetPrice: string) => {
  // TODO(dankurka): Layering violation
  // TODO(dankurka): Number formatting
  const formattedAssetMarkPrice = Number(fromWei(marketAssetPrice)).toFixed(2);
  document.title = `${formattedAssetMarkPrice} ETH-USDC : Futureswap`;
};

export const createCallSetPriceData = (
  dispatch: FsDispatch,
  dataLoader: DataLoader,
  updateTitleFn: (marketAssetPrice: string) => void
) => {
  return async (_, { registry, dynamicExchangeData }: FsRootState) => {
    const { exchange } = registry;
    try {
      const reponse = await dataLoader.get<GetExchangeDataResponse>(
        `/exchange/${exchange.address}`
      );

      const exchangeData = dynamicExchangeData.exchange;

      if (exchangeData !== null) {
        if (exchangeData.timestamp > reponse.data.timestamp) {
          // If an old request comes in too late we ignore it
          return;
        }
      }

      dispatch.dynamicExchangeData.setPriceData(reponse.data);
      updateTitleFn(reponse.data.marketAssetPrice);
      dispatch.dynamicExchangeData.setErrorLoadingData(false);
      dispatch.chart.updateLiveCandle();
    } catch (e) {
      // TODO(dankurka): handle error here
      dispatch.dynamicExchangeData.setErrorLoadingData(true);
    }
  };
};

export default {
  state: INITIAL_ORACLE_STATE,
  reducers: {
    setPriceData: (state, exchangeData: GetExchangeDataResponse) => ({
      ...state,
      exchange: exchangeData
    }),
    set24hrGraphTrades: (
      state,
      {
        openTrades24Hr,
        closeTrades24Hr
      }: {
        openTrades24Hr: GraphOpenTrade[];
        closeTrades24Hr: GraphCloseTrade[];
      }
    ) => {
      return {
        ...state,
        theGraph: {
          closeTrades24Hr,
          openTrades24Hr
        }
      };
    },
    setErrorLoadingData: (state: OracleModel, errorLoadingData: boolean) => ({
      ...state,
      errorLoadingData
    })
  },
  effects: dispatch => {
    return {
      callSetPriceData: createCallSetPriceData(
        dispatch,
        new DataLoader(),
        updateTitleFn
      ),
      catchDynamicExchangeData({ message }) {
        dispatch.dynamicExchangeData.setPriceData(message.data);
      },
      async get24HrGraphTrades() {
        const ONE_DAY_IN_SECONDS = 86400;
        const oneDayAgo =
          Math.floor(new Date().getTime() / 1000) - ONE_DAY_IN_SECONDS;
        try {
          const response = await axios.post(
            "https://api.thegraph.com/subgraphs/name/futureswap/futureswap-v2",
            {
              query: `query {
                  openedTrades(first: 1000, orderBy: timestamp, orderDirection: desc, where: {timestamp_gt: ${oneDayAgo}}) {
                    timestamp
                    collateral
                    leverage
                  }
                  closedTrades(first: 1000, orderBy: timestamp, orderDirection: desc, where: {timestamp_gt: ${oneDayAgo}}) {
                    timestamp
                    assetRedemptionAmount
                    assetMarketPrice
                    }
              }`
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
          dispatch.dynamicExchangeData.set24hrGraphTrades({
            openTrades24Hr: response.data.data.openedTrades,
            closeTrades24Hr: response.data.data.closedTrades
          });
        } catch (e) {
          console.log(e);
        }
      }
    };
  }
};
