import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled/macro";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import OpenPosition from "./OpenPosition";
import Summary from "./Summary";
import Box from "components/Box";
import { H4, H3 } from "components/Typography";
import { selectUsersOpenTrades } from "redux/positions/selectors";
import { useSelector } from "react-redux";

const OpenPositions = () => {
  const openTrades = useSelector(selectUsersOpenTrades);
  const [expandedRow, setExpandedRow] = useState<any>(undefined);
  const { assetTokenProperties, stableTokenProperties } = useSelector(
    getExchangeTokenProperties
  );

  const renderTableHeader = () => (
    <TableHeader>
      <H4>Type </H4>
      <H4>Leverage</H4>
      <H4>Position ({assetTokenProperties.symbol})</H4>
      <H4>Open Price</H4>
      <H4>Liq. Price</H4>
      <H4>Collateral ({stableTokenProperties.symbol})</H4>
      <H4>Value</H4>
      <H4>P&L</H4>
      <H4>Total Profit</H4>
    </TableHeader>
  );

  const renderEmptyState = () => (
    <Box display="flex" justifyContent="center" width="100%" pt={4}>
      <H3>No orders to show</H3>
    </Box>
  );

  const renderTableBody = () => (
    <TableBody>
      {openTrades.map((trade, i) => {
        const isExpanded = expandedRow === trade.tradeId;
        return (
          <OpenPosition
            key={i}
            trade={trade}
            isMock={trade.state.status === "mock"}
            isExpanded={isExpanded}
            toggleIsExpanded={() =>
              setExpandedRow(!isExpanded ? trade.tradeId : undefined)
            }
          />
        );
      })}
    </TableBody>
  );

  const renderSummary = () => {
    return (
      <SummaryContainer pb={1.5} pt={1.5} mx={2}>
        <Summary openTrades={openTrades} />
      </SummaryContainer>
    );
  };

  return (
    <Container
      width="100%"
      pt={2}
      px={1}
      display="flex"
      flexDirection="column"
      maxHeight="100%"
      height="100%"
    >
      <Box px={2}>{renderTableHeader()}</Box>
      {!openTrades.length && renderEmptyState()}

      {openTrades.length > 0 && renderTableBody()}
      <Box flex="1" />
      {openTrades.length > 0 && renderSummary()}
    </Container>
  );
};

export default OpenPositions;

const Container = styled(Box)`
  box-sizing: border-box;
  overflow: auto;
`;

const TableHeader = styled(Box)<{ match?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
  grid-gap: 6px;
  align-items: center;

  ${props =>
    props.match &&
    css`
      background-color: rgba(149, 159, 225, 0.15);
    `};
  /* border-bottom: 1px solid #000000; */
`;
const TableBody = styled(Box)`
  overflow-y: scroll;
`;
const SummaryContainer = styled(Box)`
  border-top: 1px solid #2e323f;
`;
