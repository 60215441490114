import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import styled from "@emotion/styled/macro";

const StyledTippy = styled(Tippy)`
  background: #222d40;
  padding: 4px;
  z-index: 100000;

  /* Styling the arrow for different placements */
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: #222d40;
  }
  &[data-placement^="right"] > .tippy-arrow::before {
    border-right-color: #222d40;
  }
  &[data-placement^="bottm"] > .tippy-arrow::before {
    border-bottom-color: #222d40;
  }
  &[data-placement^="left"] > .tippy-arrow::before {
    border-left-color: #222d40;
  }
`;

export default StyledTippy;
