import React from "react";
import styled from "@emotion/styled";
import { formatNumber } from "utils/formatter";
import { fromWei } from "utils/numbers";
import { useSelector } from "react-redux";
import { Text, H4 } from "components/Typography";
import Box from "components/Box";
import {
  selectCurrentExchangeDynamicData,
  select24HrVolume
} from "redux/dynamicExchangeData/selectors";
import Tooltip from "components/Tooltip";

const ExchangeActivity = () => {
  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);
  const volume24Hr = useSelector(select24HrVolume);
  const listItem = (label: string, value: string, toolDescription?: string) => {
    return (
      <ListItem display="flex" flexDirection="row" alignItems="center" mx={2.5}>
        <Box mr={2}>
          <Text fontWeight="500" fontSize="15px">
            {value}
          </Text>
        </Box>
        <H4>{label}</H4>
        {toolDescription ? <Tooltip content={toolDescription} /> : ""}
      </ListItem>
    );
  };

  return (
    <Box height="100%" display="flex" flexDirection="row" alignItems="center">
      {listItem(
        `Mark price`,
        `${formatNumber(fromWei(dynamicExchangeData.marketAssetPrice), 2)} USD`,
        `The current mark price a trade can be entered on Futureswap`
      )}
      {listItem(
        `Index price`,
        `${formatNumber(fromWei(dynamicExchangeData.assetPrice), 2)} USD`,
        `The current index price, not effected by trades on Futureswap, used for liquidations and liquidity only`
      )}
      {listItem("24 hr volume", `${formatNumber(fromWei(volume24Hr), 2)} USD`)}
    </Box>
  );
};

export default ExchangeActivity;

const ListItem = styled(Box)``;
