import React from "react";
import styled from "@emotion/styled";
import Box from "./Box";
import { Text } from "./Typography";

type Props = {
  percentage: number;
  label: string;
  height: string;
};

const ProgressBar: React.FC<Props> = ({ percentage, label, height }) => {
  return (
    <BarContainer height={height} display="flex" position="relative">
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
      >
        <Text fontSize="11px" fontWeight={500}>
          {label}
        </Text>
      </Box>
      <div style={{ width: `${percentage}%` }}>
        <Filler />
      </div>
    </BarContainer>
  );
};

ProgressBar.defaultProps = {
  height: "18px"
};

export default ProgressBar;

const BarContainer = styled(Box)`
  background: #717171;
  border-radius: 3px;
  overflow: hidden;
`;

const Filler = styled.div`
  background: #339cff;
  width: 100%;
  height: 100%;
`;
