import React, { useState, useEffect } from "react";
import { fromWei } from "utils/numbers";
import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "utils/formatter";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import {
  selectAddingLiquidityInteractions,
  selectIsAddLiquidityProcessing
} from "redux/messageProcessor/selectors";
import { selectTokenApiSyncState } from "redux/wallet/selectors";
import { Text } from "components/Typography";
import { COLORS } from "styles/theme";
import usePoolDeposit from "../../hooks/usePoolDeposit";
import Box from "components/Box";
import PricesAndPoolShare from "../../PricesAndPoolShare";
import Input from "components/Input";
import V2SunsetButton from "components/V2SunsetButton";

import PulsatingWarningDot from "components/PulsatingWarningDot";
import Tippy from "components/Tippy";
import Tooltip from "components/Tooltip";
import styled from "@emotion/styled";
import { FsDispatch, FsRootState } from "redux/store";
import { BigNumber } from "ethers";
import { RegistryModel } from "redux/registry/model";
import { TermsModel } from "redux/termsAndActions/model";
import RestrictedRegionButton from "components/RestrictedRegionButton";

const Deposit = () => {
  const dispatch = useDispatch<FsDispatch>();
  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );
  const {
    handleChangeAssetAmount,
    handleChangeStableAmount,
    handleSetMaxAmountPool,
    assetQuantityToDepositToPool,
    stableQuantityToDepositToPool,
    handleResetInputs,
    activeFieldName,
    validationResults
  } = usePoolDeposit(exchange.address);
  const [isDetailed, setIsDetailed] = useState(false);
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const isAddLiquidityProcessing = useSelector(selectIsAddLiquidityProcessing);
  const isAddingLiquidity =
    useSelector(selectAddingLiquidityInteractions).length > 0;

  const stableApiSyncState = useSelector((state: FsRootState) =>
    selectTokenApiSyncState(state, stableTokenProperties.address)
  );
  const assetApiSyncState = useSelector((state: FsRootState) =>
    selectTokenApiSyncState(state, assetTokenProperties.address)
  );
  const isStablePendingApiSync = !!stableApiSyncState;
  const isAssetPendingApiSync = !!assetApiSyncState;

  useEffect(() => {
    handleSetMaxAmountPool();
  }, [stableTokenProperties.fsBalance, assetTokenProperties.fsBalance]);

  const getInputValue = (quantity, fieldName) => {
    const isActive = fieldName === activeFieldName;
    if (quantity === "") {
      return "";
    } else {
      return isActive ? fromWei(quantity) : fromWei(quantity).toFixed(4);
    }
  };

  const renderInputs = () => {
    return (
      <>
        <Box mb={3}>
          {renderBalanceSection({ isAsset: true })}
          <Input
            placeholder="0"
            type="number"
            name="assetAmount"
            onChange={handleChangeAssetAmount}
            value={getInputValue(assetQuantityToDepositToPool, "assetAmount")}
            rightLabel={assetTokenProperties.symbol}
          />
        </Box>
        <Box>
          {renderBalanceSection({ isAsset: false })}
          <Input
            placeholder="0"
            type="number"
            name="stableAmount"
            onChange={handleChangeStableAmount}
            value={getInputValue(stableQuantityToDepositToPool, "stableAmount")}
            rightLabel={stableTokenProperties.symbol}
          />
        </Box>
      </>
    );
  };

  const renderBalance = (amount, symbol, isPendingApiSync) => {
    const styledAmount = (
      <Text fontSize="13px" color="#aaa">
        {formatNumber(fromWei(amount), 4)} {symbol}
      </Text>
    );
    return isPendingApiSync ? (
      <Tippy content="Allow about 5 minutes for your deposit to be updated and available">
        <Box display="flex">
          {styledAmount}
          <Box ml={0.75}>
            <PulsatingWarningDot pulseTimeout={4000} />
          </Box>
        </Box>
      </Tippy>
    ) : (
      styledAmount
    );
  };
  const renderBalanceSection = ({ isAsset }) => {
    const tokenProperties = isAsset
      ? assetTokenProperties
      : stableTokenProperties;

    const isPendingApiSync = isAsset
      ? isAssetPendingApiSync
      : isStablePendingApiSync;

    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={0.5}
      >
        <Box display="flex">
          <Text color="#aaa">Futureswap Balance:</Text>
          <Box ml={0.5}>
            {renderBalance(
              tokenProperties.fsBalance,
              tokenProperties.symbol,
              isPendingApiSync
            )}
          </Box>
        </Box>
        {isAsset && (
          <MaxButton
            onClick={handleSetMaxAmountPool}
            color={COLORS.PURPLE}
            fontWeight={500}
            fontSize="13px"
          >
            MAX
          </MaxButton>
        )}
      </Box>
    );
  };

  const renderTokenIcon = symbol => (
    <TokenIcon src={`/crypto-icons/${symbol.toLowerCase()}.png`} />
  );

  const renderWithdrawalQuantities = () => {
    const renderRow = (quantity, symbol, isPendingApiSync) => {
      const formattedQuanitity =
        quantity === "" ? "-" : fromWei(quantity).toFixed(4);
      return (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignContent="centt"
          >
            <Box>
              <Text fontSize="24px">
                {isPendingApiSync ? (
                  <Tippy content="Allow about 5 minutes for your deposit to be updated and available">
                    <Box display="flex">
                      <Text>{formattedQuanitity}</Text>{" "}
                      <Box ml={0.75}>
                        <PulsatingWarningDot
                          disablePulse={false}
                          pulseTimeout={4000}
                        />
                      </Box>
                    </Box>
                  </Tippy>
                ) : (
                  formattedQuanitity
                )}
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={1}>{renderTokenIcon(symbol)}</Box>
              <Text fontSize="24px">{symbol}</Text>
            </Box>
          </Box>
        </>
      );
    };
    return (
      <>
        <Box mb={1}>
          {renderRow(
            assetQuantityToDepositToPool,
            assetTokenProperties.symbol,
            isAssetPendingApiSync
          )}
        </Box>
        {renderRow(
          stableQuantityToDepositToPool,
          stableTokenProperties.symbol,
          isStablePendingApiSync
        )}
      </>
    );
  };

  const renderFsWalletApiSyncPending = () => {
    return (
      <ApiSyncMessageContainer mb={1.5} display="flex">
        <Text fontSize="15px" color="#fff">
          Confirming deposit of{" "}
          {formatNumber(
            fromWei(stableApiSyncState?.amount, stableTokenProperties.decimals),
            2
          )}{" "}
          {stableTokenProperties.symbol} and{" "}
          {formatNumber(
            fromWei(assetApiSyncState?.amount, assetTokenProperties.decimals),
            4
          )}{" "}
          {assetTokenProperties.symbol} to your Futureswap wallet. Allow about 5
          minutes for your funds to be available to add to pool
        </Text>
      </ApiSyncMessageContainer>
    );
  };

  const renderAddLiquidityButton = () => {
    const hasAvailableFunds =
      Number(assetQuantityToDepositToPool) <=
        Number(assetTokenProperties.fsBalance) &&
      Number(stableQuantityToDepositToPool) <=
        Number(stableTokenProperties.fsBalance);

    return (
      <V2SunsetButton size="lg" fluidWidth>
        Depositing disabled - V2 Beta has ended
      </V2SunsetButton>
    );
  };
  const renderFees = () => {
    const formattedGasCost = formatNumber(
      fromWei(validationResults.gasCostValue),
      2
    );
    return (
      <Box>
        <Box mb={1.5}>
          <Text color="#aaa" fontSize="15px">
            Fees
          </Text>
        </Box>
        <Box mb={1} display="flex">
          <Box mr={1}>
            <img
              height="15px"
              width="15px"
              alt="entrance fee"
              src="/images/icon-gas.svg"
            />
          </Box>
          <Text>{formattedGasCost} USDC Gas</Text>
          <Tooltip
            content={`Gas fees for pool and trade actions are paid in ${stableTokenProperties.symbol} from your Futureswap wallet balance`}
          />
        </Box>
        <Box display="flex">
          <Box mr={1}>
            <img
              height="15px"
              width="15px"
              alt="entrance fee"
              src="/images/icon-door.svg"
            />
          </Box>
          <Text>0.1% Entrance</Text>
          <Tooltip
            content={`A one time entrance fee that gets distributed to liquidity providers in this pool`}
          />
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      {(isAssetPendingApiSync || isStablePendingApiSync) &&
        renderFsWalletApiSyncPending()}

      <Box mb={1.5} display="flex" justifyContent="space-between">
        <Box>
          <Text color="#aaa" fontSize="15px">
            Amount to deposit
          </Text>
        </Box>
        <TextButton
          fontWeight={500}
          fontSize="14px"
          onClick={() => setIsDetailed(!isDetailed)}
        >
          {isDetailed ? "Simple" : "Edit Values"}
        </TextButton>
      </Box>
      <Box mb={2}>
        {isDetailed ? renderInputs() : renderWithdrawalQuantities()}
      </Box>
      {isDetailed && (
        <Box mb={3}>
          <PricesAndPoolShare />
        </Box>
      )}
      {!validationResults.isValid &&
        assetQuantityToDepositToPool !== "" &&
        validationResults.reason !== "" && (
          <Box textAlign="center" mb={0.5}>
            <WarningText>{validationResults.reason}</WarningText>
          </Box>
        )}
      <Box mb={3}>{renderFees()}</Box>

      {restrictedRegion ? (
        <RestrictedRegionButton fluidWidth />
      ) : (
        renderAddLiquidityButton()
      )}
    </Box>
  );
};

export default Deposit;

const MaxButton = styled(Text)`
  cursor: pointer;
`;

const TokenIcon = styled.img`
  height: 24px;
  width: 24px;
`;

const TextButton = styled(Text)`
  cursor: pointer;
  color: ${COLORS.PURPLE};
`;

const WarningText = styled(Text)`
  color: #da4f4f;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;

const ApiSyncMessageContainer = styled(Box)`
  border: 2px solid #f1c844;
  padding: 12px 20px;
`;

const InfoIconContainer = styled(Box)`
  height: 24px;
  width: 24px;
  display: contents;
`;
