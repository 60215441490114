import React from "react";
import Main from "pages/Main";
import { ThemeProvider } from "emotion-theming";
import theme from "styles/theme";
import globalStyles from "styles/globalStyles";
import { Global } from "@emotion/core";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <Main />
    </ThemeProvider>
  );
}

export default App;
