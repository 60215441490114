import React, { useState } from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import ChartSection from "./ChartSection";
import CreatePositionSection from "./CreatePositionSection";
import PositionsSection from "./PositionsSection";
import ActivitySection from "./ActivitySection";
import { useSelector } from "react-redux";

const MainGrid = () => {
  const { isInitialized: isRegistryInitialized } = useSelector(
    state => state.registry
  );
  const { requests: walletRequests } = useSelector(state => state.wallet);
  const isWalletsAndExchangesInitialized =
    isRegistryInitialized && walletRequests.isInitialized;
  // defaulting the active tab to chart because otherwise there's a
  // chart resize bug that needs to be fixed on mobile
  const [activeMobileTab, setActiveMobileTab] = useState("chart");
  return (
    <Grid>
      <Trade isActive={activeMobileTab === "trade"}>
        {isWalletsAndExchangesInitialized && <CreatePositionSection />}
      </Trade>
      <Activity isActive={activeMobileTab === "activity"}>
        {isRegistryInitialized && <ActivitySection />}
      </Activity>
      <Chart isActive={activeMobileTab === "chart"}>
        {isRegistryInitialized && <ChartSection />}
      </Chart>
      <Positions isActive={activeMobileTab === "positions"}>
        {isWalletsAndExchangesInitialized && <PositionsSection />}
      </Positions>
      <MobileNav>
        <MobileNavItem
          isActive={activeMobileTab === "trade"}
          onClick={() => setActiveMobileTab("trade")}
        >
          Trade
        </MobileNavItem>
        <MobileNavItem
          isActive={activeMobileTab === "chart"}
          onClick={() => setActiveMobileTab("chart")}
        >
          Chart
        </MobileNavItem>
        <MobileNavItem
          isActive={activeMobileTab === "activity"}
          onClick={() => setActiveMobileTab("activity")}
        >
          Activity
        </MobileNavItem>
        <MobileNavItem
          isActive={activeMobileTab === "positions"}
          onClick={() => setActiveMobileTab("positions")}
        >
          Positions
        </MobileNavItem>
      </MobileNav>
    </Grid>
  );
};

const Grid = styled(Box)`
  height: 100%;
  display: grid;
  min-width: 0px;
  min-height: 0px;
  grid-gap: 0px;

  grid-template-columns: 290px 1fr 310px;
  grid-template-rows: 1fr 330px;
  grid-template-areas:
    "trade chart activity"
    "trade positions positions";

  ${props => `
    ${props.theme.breakpoints.down("md")} {
      grid-template-columns: 290px 1fr 1fr;
      grid-template-rows: 1fr 1fr 220px;
      grid-template-areas:
      "trade chart chart"
      "trade activity activity"
      "trade positions positions";
    };
    ${props.theme.breakpoints.down("sm")} {
      grid-gap: 0;
      margin: 0;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 50px;
      grid-template-areas: 
        "mobile"
        "mobile-nav";
    };
  `}
  flex: 1;
  overflow: hidden;
`;

const Trade = styled(Box)`
  display: flex;
  min-width: 0px;
  min-height: 0px;
  grid-area: trade / trade / trade / trade;
  ${props => `
    ${props.theme.breakpoints.down("sm")} {
      ${!props.isActive && "display: none"};
      grid-area: mobile / mobile / mobile / mobile;
    }
  `}
  background: #272c39;
`;

const Activity = styled(Box)`
  display: flex;
  min-width: 0px;
  min-height: 0px;
  grid-area: activity / activity / activity / activity;
  ${props => `
    ${props.theme.breakpoints.down("sm")} {
      ${!props.isActive && "display: none"};
      grid-area: mobile / mobile / mobile / mobile;
    }
  `}
  background: #191d27;
`;
const Chart = styled(Box)`
  display: flex;
  min-width: 0px;
  min-height: 0px;
  grid-area: chart / chart / chart / chart;
  ${props => `
    ${props.theme.breakpoints.down("sm")} {
      ${!props.isActive && "display: none"};
      grid-area: mobile / mobile / mobile / mobile;
    }
  `}
  background: #141722;
`;
const Positions = styled(Box)`
  display: flex;
  min-width: 0px;
  min-height: 0px;
  grid-area: positions / positions / positions / positions;
  ${props => `
    ${props.theme.breakpoints.down("sm")} {
      ${!props.isActive && "display: none"};
      grid-area: mobile / mobile / mobile / mobile;
    }
  `}
  background: #1c202f;
`;
const MobileNav = styled(Box)`
  display: flex;
  min-width: 0px;
  min-height: 0px;
  display: none;
  ${props => `
    ${props.theme.breakpoints.down("sm")} {
      ${!props.isActive && "display: none"};
      display: flex;
      flex-direction: row;
      grid-area: mobile-nav / mobile-nav / mobile-nav / mobile-nav;
    }
  `}
  background: #272a32;
`;

const MobileNavItem = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${props => (props.isActive ? "#1a1d27" : "#40444e")};
`;

export default MainGrid;
