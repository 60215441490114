import Web3 from "web3";


declare global {
  interface Window {
      ethereum:any;
  }
}

let web3;
const getProvider = () => {
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    web3.eth.handleRevert = true;
  } else {
    // Non-DApp Browsers
    web3 = new Web3(
      new Web3.providers.HttpProvider(
        `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
      )
    );
    web3.eth.handleRevert = true;
  }

  return web3;
};

export default getProvider;
