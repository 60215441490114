import { getWalletInstance } from "../../contracts/Wallet";
import { NULL_ADDRESS } from "../../constants";
import * as Sentry from "@sentry/browser";
import { getErc20Instance } from "contracts/ERC20";
import getProvider from "../../provider";
import { checkArgument } from "../../offchainTradingSystem/common/preconditions";
import { TokenProperties } from "./model";

export const getTokensAddresses = (exchanges: any[]) => {
  checkArgument(exchanges.length === 1);
  const exchange = exchanges[0];
  return [exchange.assetToken, exchange.stableToken];
};

export const doGetTokenProperties = async (
  tokenAddress: string,
  accountAddress: string,
  spenderContract: string // usually fsWalletAddress
): Promise<TokenProperties> => {
  const tokenInstance = getErc20Instance(tokenAddress);
  // TODO(dankurka): Unhandled error
  // eslint-disable-next-line prefer-const
  let [localBalance, symbol, amountApproved, decimals] = await Promise.all([
    tokenInstance.methods.balanceOf(accountAddress).call(),
    tokenInstance.methods.symbol().call(),
    tokenInstance.methods.allowance(accountAddress, spenderContract).call(),
    tokenInstance.methods.decimals().call()
  ]);

  if (symbol === "WETH") {
    symbol = "ETH";
    // TODO(dankurka): Unhandled error
    try {
      localBalance =
        accountAddress !== NULL_ADDRESS
          ? await getProvider().eth.getBalance(accountAddress)
          : "0";
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const isUnlocked = amountApproved !== "0";

  return {
    address: tokenAddress,
    localBalance,
    fsBalance: "0", // default value to protect site from crashing in some places
    symbol,
    decimals,
    isUnlocked,
    isUnlocking: false
  };
};
type DoDepositToken = {
  fsWalletAddress: string;
  accountAddress: string;
  amount: string;
  isWeth: boolean;
  tokenAddress: string;
};

export const doDepositToken = async (
  {
    fsWalletAddress,
    accountAddress,
    tokenAddress,
    amount,
    isWeth
  }: DoDepositToken,
  callback: (blockNumber: number) => void
) => {
  const walletInstance = getWalletInstance(fsWalletAddress);
  const handleFirstConfirmation = (
    confirmationNumber: number,
    transaction: any
  ) => {
    if (confirmationNumber === 0 && transaction.status === true) {
      callback(transaction.blockNumber);
    }
  };
  if (isWeth) {
    await walletInstance.methods
      .deposit(tokenAddress, "0") // removing .depositEth() bc that doesn't throw events
      .send({ from: accountAddress, value: amount })
      .on("confirmation", handleFirstConfirmation);
  } else {
    await walletInstance.methods
      .deposit(tokenAddress, amount)
      .send({ from: accountAddress })
      .on("confirmation", handleFirstConfirmation);
  }
};
