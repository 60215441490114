import React from "react";
import Slider from "../Slider";
import Box from "components/Box";
import { Text } from "components/Typography";
import styled from "@emotion/styled";

const PercentageInput = ({
  percentageOfLtToWithdraw,
  handleChangePercentage
}) => {
  const renderLtButtons = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <LtButton
          isActive={percentageOfLtToWithdraw === 0.25}
          onClick={() => handleChangePercentage(".25")}
        >
          25%
        </LtButton>
        <LtButton
          isActive={percentageOfLtToWithdraw === 0.5}
          onClick={() => handleChangePercentage(".50")}
        >
          50%
        </LtButton>
        <LtButton
          isActive={percentageOfLtToWithdraw === 0.75}
          onClick={() => handleChangePercentage(".75")}
        >
          75%
        </LtButton>
        <LtButton
          isActive={percentageOfLtToWithdraw === 1}
          onClick={() => handleChangePercentage("1")}
        >
          Max
        </LtButton>
      </Box>
    );
  };

  return (
    <>
      <Box mb={1}>
        <Text fontSize="72px">
          {Math.floor(percentageOfLtToWithdraw * 100)}%
        </Text>
      </Box>
      <Box mb={1}>
        <Slider
          onChange={v => handleChangePercentage((Number(v) / 100).toString())}
          value={percentageOfLtToWithdraw * 100}
        />
      </Box>
      <Box mx={-1} mb={2}>
        {renderLtButtons()}
      </Box>
    </>
  );
};

export default PercentageInput;

const LtButton = styled.button<{ isActive: boolean }>`
  user-select: none;
  width: 100%;
  padding: 8px 16px;
  background-color: rgb(61 66 109);
  border: 1px solid rgb(60 73 121);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin: 12px;
  overflow: hidden;
  color: rgb(178, 185, 210);
  &:hover {
    background: #48519e;
    color: rgb(221, 221, 221);
    border: 1px solid rgb(102 120 185);

  }
  &:focus {
    background: #48519e;
    color: rgb(221, 221, 221);
    border: 1px solid rgb(102 120 185);
    outline: none;
  }
  ${props =>
    props.isActive &&
    `
      background: #48519e;
      color: rgb(221, 221, 221);
      border: 1px solid rgb(102 120 185);
    `}}
`;
