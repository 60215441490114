import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import Box from "components/Box";
import NavBar from "components/NavBar";
import LoadingSpinner from "components/LoadingSpinner";

const Governance = ({ children }) => {
  const dispatch = useDispatch();
  const { requests: walletRequest } = useSelector(state => state.wallet);

  useEffect(() => {
    if (walletRequest.isInitialized) {
      dispatch.governance.initializeGovernance();
    }
  }, [walletRequest.isInitialized]);
  const { requests: proposalRequests } = useSelector(state => state.governance);

  return (
    <>
      <NavBar />
      <Container maxWidth="940px" py={4} px={2}>
        {walletRequest.isInitialized && proposalRequests.isInitialized ? (
          children
        ) : (
          <Box my={12}>
            <LoadingSpinner size="lg" />
          </Box>
        )}
      </Container>
    </>
  );
};

const Container = styled(Box)`
  margin: 0 auto;
`;

export default Governance;
