import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { spacing } from "@material-ui/system";
import {ResponsiveSizes} from "styles/theme";
import Box, { BoxProps } from "components/Box";

import { ResponsiveBreakpoints, ThemeProps } from "styles/theme";

type StyledContainerProps = 
  BoxProps & 
  {maxWidth: ResponsiveSizes} &
  {theme: ThemeProps}

const StyledContainer = styled(Box)<StyledContainerProps>`
  ${spacing}
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${props =>
    props.mb ? `${props.theme.spacing(props.mb)}px` : "0px"};
  box-sizing: border-box;
  ${(props: {theme: ThemeProps, maxWidth: ResponsiveSizes}) => `
    max-width: ${ResponsiveBreakpoints[props.maxWidth]}px;
    ${props.theme.breakpoints.up("xs")} {
      padding: 0 ${props.theme.spacing(2)}px;
    };
    ${props.theme.breakpoints.up("sm")} {
      padding: 0 ${props.theme.spacing(3)}px;
    };
    ${props.theme.breakpoints.up("md")} {
      padding: 0 ${props.theme.spacing(4)}px;
    };
  `}
`;

StyledContainer.propTypes = {
  maxWidth: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  // Margin bottom
  mb: PropTypes.number
};

StyledContainer.defaultProps = {
  maxWidth: "sm",
  mb: 0
};

export default StyledContainer;
