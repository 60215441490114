export interface LogEntry {
  level: string;
  message: any[];
}

const MAX_LOG_ENTRIES = 500;

class Logger {
  private static logStack: LogEntry[] = [];

  static debug(...message: any[]) {
    this.maybePop();

    this.logStack.push({ level: "debug", message });
  }

  static info(...message: any[]) {
    this.maybePop();

    this.logStack.push({ level: "info", message });
  }

  static warn(...message: any[]) {
    this.maybePop();

    this.logStack.push({ level: "warn", message });
  }

  static error(...message: any[]) {
    this.maybePop();

    this.logStack.push({ level: "error", message });
  }

  private static maybePop() {
    if (this.logStack.length > MAX_LOG_ENTRIES) {
      this.logStack.shift();
    }
  }

  static publish() {
    for (const e of this.logStack) {
      switch (e.level) {
        case "info":
        case "debug":
        case "warn":
          console.info(e.message);
          break;
        case "error":
          console.error(e.message);
          break;
      }
    }
  }

  private constructor() {}
}

export default Logger;
