import PropTypes from "prop-types";
import React, { useState } from "react";
import Cell from "./Cell";
import styled from "@emotion/styled";
import Box from "components/Box";
import { css } from "@emotion/core";
import { navigate } from "@reach/router";

const getCellPadding = size => {
  switch (size) {
    case "sm":
      return { x: 2, y: 1 };
    case "md":
      return { x: 2, y: 1.5 };
    case "lg":
      return { x: 3, y: 2 };
    case "xl":
      return { x: 3, y: 3 };
    default:
      return { x: 2, y: 1.5 };
  }
};

// TODO(dankurka): Figure out types
type Props = {
  headings: any;
  rows: any;
  size?: "sm" | "md" | "lg" | "xl";
  isStructured?: boolean;
};

const Table: React.FC<Props> = ({
  headings,
  rows,
  size,
  isStructured // include bg even if not linked
}) => {
  const cellPadding = getCellPadding(size);
  const [expandedRowIndex, setExpandedRowIndex] = useState(undefined);

  const renderHeadingCell = (_cell, cellIndex) => {
    const content =
      typeof headings[cellIndex] === "object"
        ? headings[cellIndex].content
        : headings[cellIndex];

    const tooltip =
      typeof headings[cellIndex] === "object"
        ? headings[cellIndex].tooltip
        : null;

    const width =
      typeof headings[cellIndex] === "object"
        ? headings[cellIndex].width
        : undefined;

    return (
      <Cell
        key={`heading-${cellIndex}`}
        isHeader
        content={content}
        width={width}
        px={cellPadding.x}
        py={1.5}
        tooltip={tooltip}
      />
    );
  };

  const renderExpandIcon = rowIndex => (
    <ChevronContainer isExpanded={rowIndex === expandedRowIndex}>
      <img
        style={{ cursor: "pointer", height: "18px" }}
        alt="png"
        src="/images/chevron.svg"
      />
    </ChevronContainer>
  );
  const renderRow = (_row, rowIndex) => {
    const isExpandable = !!rows[rowIndex].expandedContent;
    const { linkPath } = rows[rowIndex];
    const isExpanded = rowIndex === expandedRowIndex;
    const handleExpandRow = () => {
      setExpandedRowIndex(expandedRowIndex !== rowIndex ? rowIndex : undefined);
    };
    const handleLinkRow = () => {
      navigate(linkPath);
    };
    const isLinked = !!linkPath;
    return (
      <>
        <StyledTr
          isExpandable={isExpandable}
          isExpanded={isExpanded}
          isLinked={isLinked}
          isStructured={isStructured}
          key={`row-${rowIndex}`}
          onClick={target => {
            (isExpandable && handleExpandRow()) ||
              (isLinked && handleLinkRow());
          }}
        >
          {rows[rowIndex].cells.map((cell, cellIndex) => {
            return (
              <Cell
                key={`${rowIndex}-${cellIndex}`}
                content={cell}
                px={cellPadding.x}
                py={cellPadding.y}
              />
            );
          })}
          {isExpandable && <td>{renderExpandIcon(rowIndex)}</td>}
        </StyledTr>
        {isExpanded && (
          <StyledTr isExpanded={isExpanded} key={`row-${rowIndex}-toggle`}>
            <td colSpan={rows[rowIndex].cells.length + 1}>
              <ExpandedBox mx={2} py={2.5}>
                {rows[rowIndex].expandedContent}
              </ExpandedBox>
            </td>
          </StyledTr>
        )}
        <tr>
          <td
            height={isExpandable || isLinked || isStructured ? "8" : "0"}
          ></td>
        </tr>
      </>
    );
  };

  return (
    <StyledTable>
      <thead>
        <StyledTr>{headings.map(renderHeadingCell)}</StyledTr>
      </thead>
      <tbody>{rows.map(renderRow)}</tbody>
    </StyledTable>
  );
};

Table.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"])
};

Table.defaultProps = {
  size: "md"
};

export default Table;

const StyledTable = styled.table`
  width: 100%;
`;

const EXPANDED_BG_COLOR = "rgba(149, 159, 225, 0.15)";
const DEFAULT_BG_COLOR = "rgba(149, 159, 225, 0.092)";
const StyledTr = styled.tr<{
  isExpandable?: boolean;
  isLinked?: boolean;
  isStructured?: boolean;
  isExpanded?: boolean;
}>`
  ${({ isExpandable, isLinked, isStructured }) =>
    (isExpandable || isLinked || isStructured) &&
    `background: ${DEFAULT_BG_COLOR};`}
  ${({ isExpandable, isLinked }) =>
    (isExpandable || isLinked) && `cursor: pointer; `}
  ${props =>
    props.isExpanded &&
    css`
      background-color: ${EXPANDED_BG_COLOR};
    `}
  &:hover > td {
    ${props =>
      ((!props.isExpanded && props.isExpandable) || props.isLinked) &&
      css`
        background-color: ${EXPANDED_BG_COLOR};
      `}
  }
`;

const ChevronContainer = styled(Box)<{ isExpanded: boolean }>`
  display: flex;
  opacity: 1;
  ${props =>
    props.isExpanded &&
    css`
      opacity: 0.8;
    `};
`;

const ExpandedBox = styled(Box)`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;
