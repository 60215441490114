import { BigNumber } from "ethers";
import { fromWei, toWei } from "utils/numbers";

// step 1
export const calculateMaxWalletDeposit = (
  assetAmount,
  stableAmount,
  assetPrice,
  gasReserve
) => {
  if (Number(fromWei(stableAmount)) <= Number(fromWei(gasReserve))) {
    return {
      maxAssetAmount: "0",
      maxStableAmount: "0"
    };
  }
  const assetInStable = BigNumber.from(assetAmount)
    .mul(BigNumber.from(assetPrice))
    .div(BigNumber.from(toWei("1")))
    .toString();

  let maxAssetAmount = "0";
  let maxStableAmount = "0";
  if (Number(fromWei(assetInStable)) >= Number(fromWei(stableAmount))) {
    maxAssetAmount = BigNumber.from(stableAmount)
      .sub(BigNumber.from(gasReserve))
      .mul(BigNumber.from(toWei("1")))
      .div(BigNumber.from(assetPrice))
      .toString();
    maxStableAmount = stableAmount;
  } else {
    const difStable = BigNumber.from(stableAmount)
      .sub(BigNumber.from(assetInStable))
      .toString();
    if (Number(fromWei(gasReserve)) >= Number(fromWei(difStable))) {
      const adjustmentStable = BigNumber.from(gasReserve)
        .sub(BigNumber.from(difStable))
        .toString();
      maxAssetAmount = BigNumber.from(assetInStable)
        .sub(BigNumber.from(adjustmentStable))
        .mul(BigNumber.from(toWei("1")))
        .div(BigNumber.from(assetPrice))
        .toString();
      maxStableAmount = stableAmount;
    } else {
      maxAssetAmount = assetAmount;
      maxStableAmount = BigNumber.from(assetInStable)
        .add(BigNumber.from(gasReserve))
        .toString();
    }
  }

  return {
    maxAssetAmount,
    maxStableAmount
  };
};

// step 2
export const calculateMaxPoolDeposit = (
  assetAmount,
  stableAmount,
  assetPrice,
  gasReserve
) => {
  if (Number(fromWei(stableAmount)) <= Number(fromWei(gasReserve))) {
    return {
      maxAssetAmount: "0",
      maxStableAmount: "0"
    };
  }
  const assetInStable = BigNumber.from(assetAmount)
    .mul(BigNumber.from(assetPrice))
    .div(BigNumber.from(toWei("1")))
    .toString();

  let maxAssetAmount = "0";
  let maxStableAmount = "0";
  if (Number(fromWei(assetInStable)) >= Number(fromWei(stableAmount))) {
    const difStable = BigNumber.from(assetInStable)
      .sub(BigNumber.from(stableAmount))
      .toString();
    if (Number(fromWei(gasReserve)) >= Number(fromWei(difStable))) {
      maxAssetAmount = BigNumber.from(assetInStable)
        .sub(BigNumber.from(gasReserve).add(BigNumber.from(difStable)))
        .mul(BigNumber.from(toWei("1")))
        .div(BigNumber.from(assetPrice))
        .toString();
    } else {
      maxAssetAmount = BigNumber.from(assetInStable)
        .sub(BigNumber.from(gasReserve).add(BigNumber.from(difStable)))
        .mul(BigNumber.from(toWei("1")))
        .div(BigNumber.from(assetPrice))
        .toString();
    }
    maxStableAmount = BigNumber.from(stableAmount)
      .sub(BigNumber.from(gasReserve))
      .toString();
  } else {
    const difStable = BigNumber.from(stableAmount)
      .sub(BigNumber.from(assetInStable))
      .toString();
    if (Number(fromWei(gasReserve)) >= Number(fromWei(difStable))) {
      maxAssetAmount = BigNumber.from(assetInStable)
        .sub(BigNumber.from(difStable))
        .mul(BigNumber.from(toWei("1")))
        .div(BigNumber.from(assetPrice))
        .toString();
      maxStableAmount = BigNumber.from(stableAmount)
        .sub(BigNumber.from(gasReserve))
        .toString();
    } else {
      maxAssetAmount = assetAmount.toString();
      maxStableAmount = assetInStable.toString();
    }
  }

  return {
    maxAssetAmount,
    maxStableAmount
  };
};
