import { useState } from "react";
import { useSelector } from "react-redux";
import { toWei } from "utils/numbers";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { calculateMaxPoolDeposit } from "../../utils/depositUtils";
import axios from "axios";
import { getMessageProcessorEndpoint } from "../../../../constants";
import { BigNumber } from "ethers";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";

const STABLE_GAS_BUFFER = toWei("100");

const usePoolDeposit = exchangeAddress => {
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const [
    stableQuantityToDepositToPool,
    setStableQuantityToDepositToPool
  ] = useState("");
  const [
    assetQuantityToDepositToPool,
    setAssetQuantityToDepositToPool
  ] = useState("");
  const [validationResults, setValidationResults] = useState({
    reason: "",
    gasCostValue: "0",
    isValid: false
  });
  const [activeFieldName, setActiveFieldName] = useState();

  const handleValidate = async stableValue => {
    try {
      const endpoint = await getMessageProcessorEndpoint();

      const { data } = await axios.post(
        `${endpoint}/${accountAddress}/validation/changeliquidity`,
        JSON.stringify({
          isAddingLiquidity: true,
          amount: stableValue, // amount == stable tokens for deposit
          exchangeAddress
        })
      );
      setValidationResults(data);
    } catch (e) {
      setValidationResults({
        reason: "",
        gasCostValue: "0",
        isValid: false
      });
    }
  };

  const handleSetMaxAmountPool2 = () => {
    const { maxAssetAmount, maxStableAmount } = calculateMaxPoolDeposit(
      assetTokenProperties.fsBalance,
      stableTokenProperties.fsBalance,
      assetTokenProperties.indexPrice,
      STABLE_GAS_BUFFER
    );
    setAssetQuantityToDepositToPool(maxAssetAmount);
    setStableQuantityToDepositToPool(maxStableAmount);
    handleValidate(maxAssetAmount);
    setActiveFieldName(undefined);
  };

  const handleChangeAssetAmount = e => {
    const { name, value } = e.target;
    if (Number(value) > 0) {
      const assetQuantityWei = toWei(Number(value).toFixed(18).toString());
      const calculatedStableQuantity = BigNumber.from(assetQuantityWei)
        .mul(BigNumber.from(assetTokenProperties.indexPrice))
        .div(BigNumber.from(stableTokenProperties.price))
        .toString();

      handleValidate(assetQuantityWei);
      setStableQuantityToDepositToPool(calculatedStableQuantity);
      setAssetQuantityToDepositToPool(assetQuantityWei);
      setActiveFieldName(name);
    } else {
      setStableQuantityToDepositToPool("");
      setAssetQuantityToDepositToPool("");
      setActiveFieldName(undefined);
      setValidationResults({
        reason: "",
        gasCostValue: "0",
        isValid: false
      });
    }
  };

  const handleChangeStableAmount = e => {
    const { name, value } = e.target;
    if (Number(value) > 0) {
      const calculatedAssetQuantity = BigNumber.from(toWei(value))
        .mul(BigNumber.from(stableTokenProperties.price))
        .div(BigNumber.from(assetTokenProperties.indexPrice))
        .toString();

      handleValidate(calculatedAssetQuantity);
      setAssetQuantityToDepositToPool(calculatedAssetQuantity);
      setStableQuantityToDepositToPool(toWei(value));
      setActiveFieldName(name);
    } else {
      setStableQuantityToDepositToPool("");
      setAssetQuantityToDepositToPool("");
      setActiveFieldName(undefined);
      setValidationResults({
        reason: "",
        gasCostValue: "0",
        isValid: false
      });
    }
  };

  const handleResetInputs = () => {
    setStableQuantityToDepositToPool("");
    setAssetQuantityToDepositToPool("");
    setActiveFieldName(undefined);
    setValidationResults({
      reason: "",
      gasCostValue: "0",
      isValid: false
    });
  };

  return {
    handleChangeAssetAmount,
    handleChangeStableAmount,
    handleSetMaxAmountPool: handleSetMaxAmountPool2,
    handleResetInputs,
    assetQuantityToDepositToPool,
    stableQuantityToDepositToPool,
    activeFieldName,
    validationResults
  };
};

export default usePoolDeposit;
