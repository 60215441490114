import {
  getProposalsDataFromContractState,
  getProposal,
  getGovernanceState,
  getFstData,
  handleUnlockFst,
  handleResolve,
  castVote,
  getUserProposalDetails,
  getProposalCreatedEvents,
  getProposalCallFailedEvents,
  getProposalAcceptedEvents,
  getCurrentUserVotedCastedEvents
} from "./actions";

import deepMerge from "deepmerge";

import {
  upgradeIncentives,
  upgradeFactoryProposal,
  upgradeVotingContractProposal,
  newExchangeProposal,
  upgradeParamProposalv2
} from "./createProposalActions";

const GOVERNANCE_INITIAL_STATE = {
  isFstUnlocked: undefined,
  proposals: [],
  events: {
    proposalCreated: [],
    currentUserVotedCasted: [],
    proposalCallFailed: [],
    proposalAccepted: []
  },
  requests: {
    isInitialized: false,
    isUnlockingFst: false,
    isVoting: false,
    isResolving: false
  }
};

export default {
  state: GOVERNANCE_INITIAL_STATE,
  reducers: {
    setGovernanceData: (state, object) => {
      return deepMerge(state, object);
    },
    setEventsData: (state, object) => ({
      ...state,
      ...object
    }),
    setRequestsValues: (state, values = {}) => {
      return {
        ...state,
        requests: { ...state.requests, ...values }
      };
    },
    setProposal: (state, data) => {
      const updatedProposals = state.proposals.map(obj =>
        obj.id === data.id ? data : obj
      );
      return {
        ...state,
        proposals: updatedProposals
      };
    },
    setProposals: (state, data) => {
      return {
        ...state,
        proposals: data.proposals
      };
    }
  },
  effects: dispatch => {
    const redirectToProposal = reciept => {
      const { ProposalCreated } = reciept.events;
      if (ProposalCreated) {
        const { proposalId } = ProposalCreated.returnValues;
        window.location.assign(`/governance/proposal/${proposalId}`);
      }
    };

    return {
      async initializeGovernance(_, { wallet, registry }) {
        const governanceState = await getGovernanceState(
          registry.votingAddress
        );
        const proposals = await getProposalsDataFromContractState(
          registry.votingAddress
        );
        const filteredProposals = proposals.filter(proposal => {
          // hide admin approved proposals and any other specific ones
          return proposal.ownerApproved === false && proposal.id !== "40";
        });

        const fstData = await getFstData(
          registry.fsTokenAddress,
          wallet.accountAddress,
          registry.votingAddress
        );

        const proposalCreatedEvents = await getProposalCreatedEvents(
          registry.votingAddress
        );
        const proposalCallFailedEvents = await getProposalCallFailedEvents(
          registry.votingAddress
        );
        const proposalAcceptedEvents = await getProposalAcceptedEvents(
          registry.votingAddress
        );
        dispatch.governance.getUsersCastedVotes();

        dispatch.governance.setGovernanceData({
          votingContractAddress: registry.votingAddress,
          events: {
            proposalCreated: proposalCreatedEvents,
            proposalAccepted: proposalAcceptedEvents,
            proposalCallFailed: proposalCallFailedEvents
          },
          ...governanceState,
          ...fstData
        });

        dispatch.governance.setProposals({
          proposals: filteredProposals
        });

        dispatch.governance.setRequestsValues({
          isInitialized: true
        });
      },
      async getUsersCastedVotes(_, { registry, wallet }) {
        const currentUserVotedCastedEvents = await getCurrentUserVotedCastedEvents(
          registry.votingAddress,
          wallet.accountAddress
        );

        dispatch.governance.setGovernanceData({
          events: {
            currentUserVotedCasted: currentUserVotedCastedEvents
          }
        });
      },
      async updateProposal(id, { registry }) {
        const [proposal] = await Promise.all([
          getProposal(registry.votingAddress, id),
          dispatch.governance.userProposalDetails(id)
        ]);
        dispatch.governance.setProposal(proposal);
      },
      async handleUnlockFst(_, { wallet, registry }) {
        await handleUnlockFst(
          dispatch,
          registry.fsTokenAddress,
          wallet.accountAddress,
          registry.votingAddress
        );
      },
      async castVote({ id, yesVote }, { wallet, registry }) {
        await castVote(
          dispatch,
          wallet.accountAddress,
          registry.votingAddress,
          id,
          yesVote
        );
        await dispatch.governance.updateProposal(id);
        await dispatch.governance.getUsersCastedVotes();
        dispatch.governance.setRequestsValues({ isVoting: false });
      },
      async handleResolve(
        { id, isUpgradingVotingContract },
        { wallet, registry }
      ) {
        await handleResolve(
          id,
          isUpgradingVotingContract,
          registry.votingAddress,
          wallet.accountAddress,
          dispatch
        );
        await dispatch.governance.updateProposal(id);
        dispatch.governance.setRequestsValues({ isResolving: false });
      },
      async userProposalDetails(id, { wallet, registry }) {
        const obj = await getUserProposalDetails(
          wallet.accountAddress,
          registry.votingAddress,
          id
        );
        dispatch.governance.setGovernanceData(obj);
      },
      /******************************
       *** Create Proposal effects ***
       *******************************/
      // async upgradeIncentives(
      //   { title, description, fstSnapshotAddress, setSubmitting },
      //   { wallet, registry }
      // ) {
      //   try {
      //     await upgradeIncentives({
      //       title,
      //       description,
      //       fstSnapshotAddress,
      //       votingContractAddress: registry.votingAddress,
      //       fsTokenAddress: registry.fsTokenAddress,
      //       network: wallet.network
      //     })
      //       .send({ from: wallet.accountAddress })
      //       .on("transactionHash", () => {
      //         setSubmitting(true);
      //       })
      //       .on("receipt", redirectToProposal);
      //   } catch (error) {
      //     setSubmitting(false);
      //   }
      // },
      // async upgradeFactory(
      //   { title, description, newExchangeFactoryAddress, setSubmitting },
      //   { wallet, registry }
      // ) {
      //   try {
      //     await upgradeFactoryProposal({
      //       title,
      //       description,
      //       votingContractAddress: registry.votingAddress,
      //       newExchangeFactoryAddress,
      //       network: wallet.network
      //     })
      //       .send({ from: wallet.accountAddress })
      //       .on("transactionHash", () => {
      //         setSubmitting(true);
      //       })
      //       .on("receipt", redirectToProposal);
      //   } catch (error) {
      //     console.error(error);
      //     setSubmitting(false);
      //   }
      // },
      // async upgradeVoting(
      //   { title, description, newVotingContractAddress, setSubmitting },
      //   { wallet, registry }
      // ) {
      //   try {
      //     await upgradeVotingContractProposal({
      //       title,
      //       description,
      //       votingContractAddress: registry.votingAddress,
      //       newVotingContractAddress
      //     })
      //       .send({ from: wallet.accountAddress })
      //       .on("transactionHash", () => {
      //         setSubmitting(true);
      //       })
      //       .on("receipt", redirectToProposal);
      //   } catch (error) {
      //     console.error(error);
      //     setSubmitting(false);
      //   }
      // },
      // async addExchange(
      //   {
      //     title,
      //     description,
      //     setSubmitting,
      //     stableToken,
      //     assetToken,
      //     name,
      //     symbol
      //   },
      //   { wallet, registry }
      // ) {
      //   try {
      //     newExchangeProposal({
      //       title,
      //       description,
      //       stableToken,
      //       assetToken,
      //       name,
      //       symbol,
      //       votingContractAddress: registry.votingAddress,
      //       network: wallet.network,
      //       factoryAddress: registry.factoryAddress
      //     })
      //       .send({ from: "0x073739F853A234F274980193036aE37B3a234C4C" })
      //       .on("transactionHash", () => {
      //         setSubmitting(true);
      //       })
      //       .on("receipt", redirectToProposal);
      //   } catch (error) {
      //     console.error(error);
      //     setSubmitting(false);
      //   }
      // },
      async upgradeParam(
        {
          title,
          description,
          action,
          subAction,
          amount,
          exchangeAddress,
          constants,
          setSubmitting
        },
        { wallet, registry }
      ) {
        try {
          await upgradeParamProposalv2({
            title,
            description,
            amount,
            action,
            subAction,
            exchangeAddress,
            votingContractAddress: registry.votingAddress,
            factoryAddress: registry.factoryAddress,
            constants
          })
            .send({ from: wallet.accountAddress })
            .on("transactionHash", () => {
              setSubmitting(true);
            })
            .on("receipt", redirectToProposal);
        } catch (error) {
          console.error(error);
          setSubmitting(false);
        }
      }
    };
  }
};
