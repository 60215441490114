import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { fromWei } from "utils/numbers";
import WalletDeposit from "./WalletDeposit";
import PoolDeposit from "./PoolDeposit";
import { selectCurrentExchangeDynamicData } from "redux/dynamicExchangeData/selectors";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { POOL_CAPACITY } from "./constants";
import { Text } from "components/Typography";
import { css } from "@emotion/core";
import Box from "components/Box";
import PoolCapacty from "./PoolCapacity";
import styled from "@emotion/styled";

const Deposit = () => {
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);
  const [isPoolDepositOpen, setIsPoolDepositOpen] = useState(true);
  const [isWalletDepositOpen, setIsWalletDepositOpen] = useState(false);

  // compare previous token depositing state to next one to determine if tokens
  // have been deposited, then forward user to deposit to pool step
  // TODO(dankurka): types
  const prevStableTokenIsDepositing = useRef<any>();
  const prevAssetTokenIsDepositing = useRef<any>();

  useEffect(() => {
    if (
      (prevStableTokenIsDepositing.current &&
        !stableTokenProperties.isDepositing) ||
      (prevAssetTokenIsDepositing.current && !assetTokenProperties.isDepositing)
    ) {
      setIsPoolDepositOpen(false);
      setIsWalletDepositOpen(true);
    }
    prevStableTokenIsDepositing.current = stableTokenProperties.isDepositing;
    prevAssetTokenIsDepositing.current = assetTokenProperties.isDepositing;
  }, [stableTokenProperties, assetTokenProperties]);

  const renderChevron = isToggled => (
    <ChevronContainer isToggled={isToggled}>
      <img height="18px" alt="png" src="/images/chevron.svg" />
    </ChevronContainer>
  );

  const displayPoolCapacity =
    Number(fromWei(dynamicExchangeData.assetPoolSize)) > POOL_CAPACITY - 1000;
  return (
    <Box>
      <ToggleableHeader
        py={2}
        px={4}
        isExpanded={isWalletDepositOpen}
        onClick={() => {
          setIsPoolDepositOpen(!isPoolDepositOpen);
          setIsWalletDepositOpen(!isWalletDepositOpen);
        }}
      >
        <Text fontSize="15px">Step 1: Deposit to Futureswap wallet</Text>
        {renderChevron(isPoolDepositOpen)}
      </ToggleableHeader>
      {isPoolDepositOpen && (
        <Box py={3.5} px={4}>
          {displayPoolCapacity && (
            <Box mb={2}>
              <PoolCapacty />
            </Box>
          )}
          <WalletDeposit />
        </Box>
      )}
      <ToggleableHeader
        py={2}
        px={4}
        isExpanded={isPoolDepositOpen}
        onClick={() => {
          setIsPoolDepositOpen(!isPoolDepositOpen);
          setIsWalletDepositOpen(!isWalletDepositOpen);
        }}
      >
        <Text fontSize="15px">Step 2: Deposit to pool</Text>
        {renderChevron(isWalletDepositOpen)}
      </ToggleableHeader>
      {isWalletDepositOpen && (
        <Box py={3.5} px={4}>
          <PoolDeposit />
        </Box>
      )}
    </Box>
  );
};

export default Deposit;

type HeaderProps = {
  isExpanded: boolean;
};

const ToggleableHeader = styled(Box)<HeaderProps>`
  background: #373d73;
  border-top: 1px solid #444d8c;
  border-bottom: 1px solid #131a31;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  cursor: pointer;
  ${props =>
    props.isExpanded &&
    css`
      background: #30334e;
      opacity: 0.8;
      border-top: 1px solid #1e2129;
      &:hover {
        /* background: #373d73; */
        opacity: 0.6;
      }
    `};
  ${props =>
    !props.isExpanded &&
    css`
      &:hover {
        opacity: 0.8;
      }
    `}
`;

type ChevronContainerProps = {
  isToggled: boolean;
};

const ChevronContainer = styled(Box)<ChevronContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transform: ${({ isToggled }) => (isToggled ? "rotate(0)" : "rotate(-90deg)")};
`;
