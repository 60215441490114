import React, { useEffect } from "react";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { useSelector, useDispatch } from "react-redux";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { FsDispatch, FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";

const TermsModal = () => {
  const dispatch = useDispatch<FsDispatch>();
  const {
    terms,
    agreedToTerms,
    restrictedRegion,
    requests: { isAgreeingToTerms }
  } = useSelector<FsRootState, TermsModel>(state => state.termsAndActions);

  useEffect(() => {
    dispatch.termsAndActions.loadTerms();
  }, []);

  const renderButtons = () => (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="center"
      width="100%"
    >
      <Button
        color={COLORS.PURPLE}
        isLoading={isAgreeingToTerms}
        size="lg"
        onClick={() => dispatch.termsAndActions.agreeToTerms()}
        fluidWidth={true}
      >
        I agree
      </Button>
    </Box>
  );

  return (
    <Modal isOpen={!agreedToTerms && !restrictedRegion} p={2}>
      <Box mb={2}>
        <Header>Terms and Conditions</Header>
      </Box>
      <TermsSection mb={2}>
        <pre>{terms}</pre>
      </TermsSection>
      <Box>{renderButtons()}</Box>
    </Modal>
  );
};

export default TermsModal;

const Header = styled.h2`
  font-weight: 500;
  font-size: 26px;
  color: #eee;
  text-align: center;
`;

const InnerContainer = styled(Box)`
  overflow-y: initial !important;
`;

const TermsSection = styled(Box)`
  background: #23243a;
  padding: 16px;
  height: 60vh;
  overflow-y: auto;
  border-radius: 3px;
  font-size: 10px;
  ::-webkit-scrollbar {
    width: 8px;
    background-color: #5d656e;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    overflow: hidden;
  }
  ::-webkit-scrollbar-thumb {
    background: #2e313a;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;
