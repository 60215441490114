import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { FsRootState } from "redux/store";
import { APIModel } from "redux/api/model";

const ExchangeStatusModal = () => {
  const dispatch = useDispatch();
  const {
    status: { status, message }
  } = useSelector<FsRootState, APIModel>(state => state.api);

  const renderButton = () => (
    <Box>
      <Button
        color={COLORS.PURPLE}
        size="lg"
        onClick={() => dispatch.api.resetStatus()}
        fluidWidth
      >
        Close Modal and Continue (features may be disabled)
      </Button>
    </Box>
  );

  const isOpen = status !== "" && status !== "okay";
  return (
    <Modal isOpen={isOpen}>
      <Box
        mb={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box mb={3}>
          <Header>Maintanance mode</Header>
        </Box>
        Futureswap is currently in Maintanance mode. Check back soon.
      </Box>

      {message && <Box mb={3}>Reason: {message}</Box>}
      <Box>{renderButton()}</Box>
    </Modal>
  );
};

export default ExchangeStatusModal;

const Header = styled.h2`
  font-weight: 500;
  font-size: 26px;
  color: #eee;
  text-align: center;
  line-height: 1.2em;
`;
