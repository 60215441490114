import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import LoadingSpinner from "./LoadingSpinner";
import { COLORS } from "styles/theme";

type Sizes = "sm" | "md" | "lg" | "xl";
type Props = {
  children: React.ReactNode;
  color?: string;
  disabled?: boolean;
  fluidWidth?: boolean;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: Sizes;
};

const Button: React.FC<Props> = ({
  children,
  color = COLORS.PURPLE,
  disabled = false,
  isLoading = false,
  fluidWidth = false,
  onClick = () => {},
  size = "md"
}) => {
  const isOnclickDisabled = disabled || isLoading;

  const renderSpinner = () => {
    return (
      <LoadingSpinnerContainer size={size}>
        <LoadingSpinner size={size === "lg" || size === "xl" ? "md" : size} />
      </LoadingSpinnerContainer>
    );
  };

  return (
    <StyledButton
      color={color}
      onClick={isOnclickDisabled ? undefined : onClick}
      disabled={disabled}
      isLoading={isLoading}
      size={size}
      fluidWidth={fluidWidth}
    >
      {isLoading && renderSpinner()}
      <ButtonValue isLoading={isLoading}>{children}</ButtonValue>
    </StyledButton>
  );
};

export default Button;

const sizeStyles = (size: Sizes) => {
  switch (size) {
    case "sm":
      return css`
        height: 28px;
        font-size: 11px;
        padding: 0 24px;
      `;
    case "md":
      return css`
        min-height: 38px;
        font-size: 13px;
        padding: 0 26px;
      `;
    case "lg":
      return css`
        min-height: 40px;
        font-size: 15px;
        padding: 0 32px;
      `;
    case "xl":
      return css`
        min-height: 48px;
        font-size: 15px;
        padding: 0 32px;
      `;
  }
};

const variantStyles = (disabled: boolean, isLoading: boolean) => {
  if (disabled) {
    return css`
      background: #c5c5c5;
      color: #fff;
    `;
  }
  return css`
    background: #3f5774;
    color: #fff;
    border: 0;
    &:hover {
      opacity: ${isLoading ? 1 : 0.85};
    }
  `;
};

const colorStyles = (color: string, disabled: boolean, isLoading: boolean) => {
  if (disabled) {
    return css`
      background: rgba(197, 197, 197, 0.27);
      color: rgba(255, 255, 255, 0.6);
      cursor: default !important;
    `;
  }

  return css`
    background: ${color};
    color: #fff;
    border: 0;
    &:hover {
      opacity: ${isLoading ? 1 : 0.8};
    }
  `;
};

// const loadingStyles = isLoading => {
//   console.log(isLoading);
// };

type StyledButtonProps = {
  color: string;
  fluidWidth: boolean;
  disabled: boolean;
  isLoading: boolean;
  size: Sizes;
};
const StyledButton = styled.button<StyledButtonProps>`
  ${props => sizeStyles(props.size)};
  ${props => variantStyles(props.disabled, props.isLoading)};
  ${props => colorStyles(props.color, props.disabled, props.isLoading)};
  border-radius: 5px;
  border: none;
  position: relative;
  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
  ${props => props.fluidWidth && "width: 100%;"}
`;

const ButtonValue = styled.div<{ isLoading: boolean }>`
  font-family: "Rubik";
  text-transform: uppercase;
  opacity: ${props => (props.isLoading ? 0 : 1)};
  letter-spacing: 0.5px;
  line-height: 1.4em;
  line-height: 1.3em;
  margin: 8px 0px;
`;

const loadingSpinnerMargin = (size: Sizes) => {
  switch (size) {
    case "sm":
      return "-10px";
    case "md":
      return "-12px";
    case "lg":
      return "-12px";
    case "xl":
      return "-16px";
    default:
      return "-12px";
  }
};

const LoadingSpinnerContainer = styled.div<{ size: Sizes }>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: ${props => loadingSpinnerMargin(props.size)};
  margin-top: ${props => loadingSpinnerMargin(props.size)};
`;
