import React from "react";
import Box from "./Box";
import styled from "@emotion/styled";

type AssetPairProps = {
  stableSymbol: string;
  assetSymbol: string;
};

const AssetPair: React.FC<AssetPairProps> = ({ stableSymbol, assetSymbol }) => {
  const getTokenIcon = (symbol: string) =>
    symbol && <TokenIcon src={`/crypto-icons/${symbol.toLowerCase()}.png`} />;

  const renderTokenPair = () => {
    return (
      <TokenContainer>
        <StableTokenContainer>
          {getTokenIcon(stableSymbol)}
        </StableTokenContainer>
        <AssetTokenContainer>{getTokenIcon(assetSymbol)}</AssetTokenContainer>
      </TokenContainer>
    );
  };
  return (
    <Container display="flex" alignItems="center">
      <Box position="relative">{renderTokenPair()}</Box>
    </Container>
  );
};

export default AssetPair;

const Container = styled(Box)`
  line-height: normal;
`;

const TokenContainer = styled(Box)`
  width: 36px;
  height: 36px;
`;

const StableTokenContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
`;

const AssetTokenContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  height: 24px;
  width: 24px;
`;

const TokenIcon = styled.img`
  height: 24px;
  width: 24px;
`;
