import React from "react";
import { fromWei } from "utils/numbers";
import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "utils/formatter";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { selectIsAddLiquidityProcessing } from "redux/messageProcessor/selectors";
import { FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";
import { Text } from "components/Typography";
import { COLORS } from "styles/theme";
import useWalletDeposit from "../../hooks/useWalletDeposit";
import Box from "components/Box";
import PricesAndPoolShare from "../../PricesAndPoolShare";
import Input from "components/Input";
import Button from "components/Button";
import UnlockButtons from "../../UnlockButtons";
import V2SunsetButton from "components/V2SunsetButton";

import styled from "@emotion/styled";
import Tooltip from "components/Tooltip";
import RestrictedRegionButton from "components/RestrictedRegionButton";

const WalletDeposit = () => {
  const {
    handleChangeAssetAmount,
    handleChangeStableAmount,
    handleSetMaxAmountWallet,
    assetQuantityToDepositToWallet,
    stableQuantityToDepositToWallet,
    handleResetInputs,
    activeFieldName
  } = useWalletDeposit();
  const dispatch = useDispatch();
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );
  const isAddLiquidityProcessing = useSelector(selectIsAddLiquidityProcessing);

  const isTokensUnlocked =
    assetTokenProperties.isUnlocked && stableTokenProperties.isUnlocked;

  const handleAddTokensToWallet = () => {
    // todo deposit tokens individually
    if (
      assetQuantityToDepositToWallet !== "0" &&
      stableQuantityToDepositToWallet !== "0"
    ) {
      dispatch.wallet.depositTokenPair({
        assetAddress: assetTokenProperties.address,
        assetAmount: assetQuantityToDepositToWallet,
        stableAddress: stableTokenProperties.address,
        stableAmount: stableQuantityToDepositToWallet
      });
    } else if (assetQuantityToDepositToWallet !== "0") {
      dispatch.wallet.depositToken({
        tokenAddress: assetTokenProperties.address,
        amount: assetQuantityToDepositToWallet
      });
    } else if (stableQuantityToDepositToWallet !== "0") {
      dispatch.wallet.depositToken({
        tokenAddress: stableTokenProperties.address,
        amount: stableQuantityToDepositToWallet
      });
    }
  };

  const getInputValue = (quantity, decimals, fieldName) => {
    const isActive = fieldName === activeFieldName;
    if (quantity === "") {
      return "";
    } else {
      return isActive
        ? fromWei(quantity, decimals)
        : fromWei(quantity, decimals).toFixed(4);
    }
  };

  const renderInputs = () => {
    return (
      <>
        <Box mb={3}>
          {renderBalanceSection({ isAsset: true })}
          <Input
            placeholder="0"
            type="number"
            name="assetAmount"
            onChange={handleChangeAssetAmount}
            value={getInputValue(
              assetQuantityToDepositToWallet,
              assetTokenProperties.decimals,
              "assetAmount"
            )}
            rightLabel={assetTokenProperties.symbol}
          />
        </Box>
        <Box>
          {renderBalanceSection({ isAsset: false })}
          <Input
            placeholder="0"
            type="number"
            name="stableAmount"
            onChange={handleChangeStableAmount}
            value={getInputValue(
              stableQuantityToDepositToWallet,
              stableTokenProperties.decimals,
              "stableAmount"
            )}
            rightLabel={stableTokenProperties.symbol}
          />
          {stableQuantityToDepositToWallet && (
            <Box mt={1} display="flex">
              <Text color="#ccc">
                * Includes 200 {stableTokenProperties.symbol} for gas for future
                transactions
              </Text>
              <Tooltip
                content={`After depositing to the wallet, Gas fees for pool and trade actions are paid in ${stableTokenProperties.symbol} from your Futureswap wallet balance.`}
              />
            </Box>
          )}
        </Box>
      </>
    );
  };

  const renderBalanceSection = ({ isAsset }) => {
    const tokenProperties = isAsset
      ? assetTokenProperties
      : stableTokenProperties;

    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-end"
        mb={0.5}
      >
        <Text color="#aaa">
          Metamask Balance:{" "}
          {formatNumber(
            fromWei(tokenProperties.localBalance, tokenProperties.decimals),
            2
          )}{" "}
          {tokenProperties.symbol}
        </Text>
        {isAsset && (
          <MaxButton
            onClick={handleSetMaxAmountWallet}
            color={COLORS.PURPLE}
            fontWeight={500}
            fontSize="13px"
          >
            MAX
          </MaxButton>
        )}
      </Box>
    );
  };

  const renderAddToFsWalletButton = () => {
    const assetAmountNumber = fromWei(assetQuantityToDepositToWallet);
    const stableAmountNumber = fromWei(stableQuantityToDepositToWallet);
    const hasEnteredValue = assetAmountNumber > 0 || stableAmountNumber > 0;

    const hasAvailableFunds =
      Number(assetQuantityToDepositToWallet) <=
        Number(assetTokenProperties.localBalance) &&
      Number(stableQuantityToDepositToWallet) <=
        Number(stableTokenProperties.localBalance);

    return (
      <Button
        fluidWidth
        disabled={!isTokensUnlocked || !hasEnteredValue || !hasAvailableFunds}
        onClick={handleAddTokensToWallet}
        color={COLORS.PURPLE}
        isLoading={
          isAddLiquidityProcessing ||
          assetTokenProperties.isDepositing ||
          stableTokenProperties.isDepositing
        }
        size="lg"
      >
        Add to your futureswap wallet
      </Button>
    );
  };

  return (
    <Box>
      <Box mb={3}>{renderInputs()}</Box>
      <Box mb={3}>
        <PricesAndPoolShare />
      </Box>
      <V2SunsetButton size="lg" fluidWidth>
        Depositing disabled - V2 Beta has ended
      </V2SunsetButton>
    </Box>
  );
};

export default WalletDeposit;

const MaxButton = styled(Text)`
  cursor: pointer;
`;
