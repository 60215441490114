import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { Text } from "components/Typography";
import { Link } from "@reach/router";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";

const UnsupportedRegionModal = () => {
  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );
  const [isOpen, setIsOpen] = useState(restrictedRegion);
  useEffect(() => {
    if (restrictedRegion) {
      setIsOpen(restrictedRegion);
    }
  }, [restrictedRegion]);
  const renderButtons = () => (
    <Box
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      justifyContent="center"
      width="100%"
    >
      <Button
        color={COLORS.PURPLE}
        size="lg"
        onClick={() => setIsOpen(false)}
        fluidWidth={true}
      >
        View as read-only
      </Button>
    </Box>
  );

  return (
    <Modal isOpen={isOpen}>
      <Box mb={3}>
        <Header>Unsupported region</Header>
      </Box>
      <Box mb={4}>
        <Text fontSize="16px">
          Futureswap is currently not serving your region. More information can
          be found in our <Link to="/terms">Terms of Service</Link>.
        </Text>
      </Box>
      <Box>{renderButtons()}</Box>
    </Modal>
  );
};

export default UnsupportedRegionModal;

const Header = styled.h2`
  font-weight: 500;
  font-size: 26px;
  color: #eee;
  text-align: center;
`;
