import React, { useState } from "react";
import { fromWei } from "utils/numbers";
import { useSelector, useDispatch } from "react-redux";
import { formatNumber } from "utils/formatter";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import {
  selectIsRemoveLiquidityProcessing,
  selectRemoveLiquidityInteractions
} from "redux/messageProcessor/selectors";
import FsWalletModal from "components/FsWalletModal";
import RestrictedRegionButton from "components/RestrictedRegionButton";
import { FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";
import { Text } from "components/Typography";
import { COLORS } from "styles/theme";
import usePoolWithdraw from "../hooks/useWithdraw";
import Box from "components/Box";
import PricesAndPoolShare from "../PricesAndPoolShare";
import Button from "components/Button";
import PercentageInput from "../PercentageInput";
import Tooltip from "components/Tooltip";
import styled from "@emotion/styled";
import { FsDispatch } from "redux/store";
import { BigNumber } from "ethers";

const Withdraw = () => {
  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const {
    handleChangeAssetAmount,
    handleChangeStableAmount,
    handleSetMaxAmount,
    handleChangePercentage,
    assetQuantityToWithdraw,
    stableQuantityToWithdraw,
    liquidityTokenQuantityToWithdraw,
    handleResetInputs,
    percentageOfLtToWithdraw,
    activeFieldName,
    validationResults
  } = usePoolWithdraw();
  const dispatch = useDispatch<FsDispatch>();
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );

  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );

  const isRemoveLiquidityProcessing = useSelector(
    selectIsRemoveLiquidityProcessing
  );

  const isRemovingLiquidity =
    useSelector(selectRemoveLiquidityInteractions).length > 0;

  const renderTokenIcon = symbol => (
    <TokenIcon src={`/crypto-icons/${symbol.toLowerCase()}.png`} />
  );

  const renderWithdrawalQuantities = () => {
    const renderRow = (quantity, symbol) => (
      <Box display="flex" justifyContent="space-between" alignContent="centt">
        <Box>
          <Text fontSize="24px">
            {quantity === "" ? "-" : fromWei(quantity).toFixed(4)}
          </Text>
        </Box>
        <Box display="flex" alignItems="center">
          <Box mr={1}>{renderTokenIcon(symbol)}</Box>
          <Text fontSize="24px">{symbol}</Text>
        </Box>
      </Box>
    );
    return (
      <>
        <Box mb={1}>
          {renderRow(assetQuantityToWithdraw, assetTokenProperties.symbol)}
        </Box>
        {renderRow(stableQuantityToWithdraw, stableTokenProperties.symbol)}
      </>
    );
  };

  const renderRemoveLiquidityButton = () => {
    return (
      <Button
        fluidWidth
        isLoading={isRemovingLiquidity || isRemoveLiquidityProcessing}
        disabled={
          liquidityTokenQuantityToWithdraw === "" || !validationResults.isValid
        }
        onClick={async () => {
          try {
            await dispatch.messageProcessor.handleChangeLiquidity({
              assetAmount: BigNumber.from(liquidityTokenQuantityToWithdraw),
              addLiquidity: false
            });
            handleResetInputs();
          } catch (e) {
            console.error(e);
          }
        }}
        color={COLORS.PURPLE}
        size="lg"
      >
        Withdraw liquidity from pool
      </Button>
    );
  };

  const renderFees = () => {
    const formattedGasCost = formatNumber(
      fromWei(validationResults.gasCostValue),
      2
    );
    return (
      <Box>
        <Box mb={1.5}>
          <Text color="#aaa" fontSize="15px">
            Fees
          </Text>
        </Box>
        <Box mb={1} display="flex">
          <Box mr={1}>
            <img
              height="15px"
              width="15px"
              alt="entrance fee"
              src="/images/icon-gas.svg"
            />
          </Box>
          <Text>
            {formattedGasCost} {stableTokenProperties.symbol} Gas
          </Text>
          <Tooltip
            content={`Gas fees for pool and trade actions are paid in ${stableTokenProperties.symbol} from your Futureswap wallet balance`}
          />
        </Box>
      </Box>
    );
  };

  const renderAddMoreGas = () => (
    <>
      <Button
        color={COLORS.PURPLE}
        fluidWidth
        onClick={() => {
          setIsDepositModalOpen(true);
        }}
      >
        Add more USDC for gas
      </Button>
    </>
  );

  const notEnoughForGas =
    validationResults.reason === "NOT_ENOUGH_STABLE_FOR_GAS_IN_WALLET";
  const getErrorMessage = reason => {
    switch (reason) {
      case "NOT_ENOUGH_STABLE_FOR_GAS_IN_WALLET":
        return `Not enough ${stableTokenProperties.symbol} deposited for gas`;
      default:
        return reason;
    }
  };

  return (
    <Box py={3} px={4}>
      <Box mb={3}>
        <PercentageInput
          percentageOfLtToWithdraw={percentageOfLtToWithdraw}
          handleChangePercentage={handleChangePercentage}
        />
        <Box>{renderWithdrawalQuantities()}</Box>
      </Box>
      <Box mb={3}>
        <PricesAndPoolShare />
      </Box>
      <Box mb={3}>{validationResults.gasCostValue && renderFees()}</Box>
      {!validationResults.isValid && percentageOfLtToWithdraw > 0 && (
        <Box textAlign="center" mb={0.5}>
          <WarningText>{getErrorMessage(validationResults.reason)}</WarningText>
        </Box>
      )}
      {restrictedRegion ? (
        <RestrictedRegionButton fluidWidth />
      ) : (
        <>
          {notEnoughForGas && <Box mb={1.5}>{renderAddMoreGas()}</Box>}
          {renderRemoveLiquidityButton()}
        </>
      )}

      <FsWalletModal
        token={stableTokenProperties}
        isOpen={isDepositModalOpen}
        isDeposit={true}
        handleClose={() => setIsDepositModalOpen(false)}
      />
    </Box>
  );
};

export default Withdraw;

const TokenIcon = styled.img`
  height: 24px;
  width: 24px;
`;

const WarningText = styled(Text)`
  color: #da4f4f;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;
