import { RematchRootState } from "@rematch/core";
import { FsRootModel } from "redux/store";

export const selectIsTokenDepositing = (
  state: RematchRootState<FsRootModel>,
  tokenAddress: string
) => state.wallet.requests.tokensBeingDeposited.includes(tokenAddress);

export const selectTokenApiSyncState = (
  state: RematchRootState<FsRootModel>,
  tokenAddress: string
) =>
  state.wallet.requests.tokenDepositsPendingApiSync.find(
    token => token.address === tokenAddress
  );
