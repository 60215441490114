import { COLORS } from "styles/theme";

export const chartOptions = {
  layout: {
    backgroundColor: "#141722",
    textColor: "#ddd"
  },
  localization: {
    locale: "en-US",
    dateFormat: "yyyy/MM/dd"
  },
  grid: {
    vertLines: {
      color: "rgba(197, 203, 206, 0.15)"
    },
    horzLines: {
      color: "rgba(197, 203, 206, 0.15)"
    }
  },
  timeScale: {
    fixLeftEdge: true,
    lockVisibleTimeRangeOnResize: true,
    rightBarStaysOnScroll: true,
    borderVisible: false,
    visible: true,
    timeVisible: true,
    secondsVisible: true
  }
};

export const candleStickOptions = {
  upColor: COLORS.GREEN,
  downColor: COLORS.RED,
  borderVisible: false,
  wickVisible: true,
  borderColor: "#000000",
  wickColor: "#ffffff",
  borderUpColor: "#4682B4",
  borderDownColor: "#A52A2A",
  wickUpColor: COLORS.GREEN,
  wickDownColor: COLORS.RED
};
