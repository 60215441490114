import React, { useState, useEffect, useRef } from "react";
import OpenPositions from "./OpenPositions";
import ClosedPositions from "./ClosedPositions";
import Box from "components/Box";
import styled from "@emotion/styled/macro";
import { useDispatch } from "react-redux";

const MENU = {
  ACTIVE_POSITONS: "Active Positions",
  CLOSED_POSITIONS: "Closed Positions"
};

const PositionSection = () => {
  const [selectedTab, setSelectedTab] = useState(MENU.ACTIVE_POSITONS);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedTab === MENU.CLOSED_POSITIONS) {
      dispatch.positions.getUsersClosedTrades();
    }
  }, [selectedTab]);

  const renderMenu = () => (
    <MenuContainer display="flex" mr={3} py={1.5} px={3}>
      <MenuItem
        mr={2.5}
        py={0.25}
        isActive={selectedTab === MENU.ACTIVE_POSITONS}
        onClick={() => setSelectedTab(MENU.ACTIVE_POSITONS)}
      >
        {MENU.ACTIVE_POSITONS}
      </MenuItem>
      <MenuItem
        py={0.25}
        isActive={selectedTab === MENU.CLOSED_POSITIONS}
        onClick={() => setSelectedTab(MENU.CLOSED_POSITIONS)}
      >
        {MENU.CLOSED_POSITIONS}
      </MenuItem>
    </MenuContainer>
  );

  return (
    <Box maxHeight="100%" width="100%" display="flex" flexDirection="column">
      {renderMenu()}
      {selectedTab === MENU.ACTIVE_POSITONS && <OpenPositions />}
      {selectedTab === MENU.CLOSED_POSITIONS && <ClosedPositions />}
    </Box>
  );
};

const MenuContainer = styled(Box)`
  background: #222536;
  width: 100%;
  border-bottom: 1px solid #121317;
  z-index: 1000;
`;

const MenuItem = styled(Box)<{ isActive: boolean }>`
  color: ${props => (props.isActive ? "#ddd" : "#888")};
  cursor: ${props => (props.isActive ? "default" : "pointer")};
  font-weight: 500;
`;

export default PositionSection;
