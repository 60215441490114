import { DataLoader } from "data/DataLoader";
import { FsDispatch } from "redux/store";
import { OracleModel } from "../dynamicExchangeData/model";
import moment from "moment";

export interface Candle {
  min: string;
  max: string;
  open: string;
  close: string;
  timestamp: number;
}

const CHART_INITIAL_STATE = {
  loadingFailed: false,
  historicCandles: [] as Candle[]
};

export type ChartModel = typeof CHART_INITIAL_STATE;

export const createHistoricalChartdataLoader = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  return async (_, { registry }) => {
    const { currentExchange } = registry;

    try {
      const reponse = await dataLoader.get<{ candles: Candle[] }>(
        `/exchange/${currentExchange}/candles/?resolution=1min&count=60`
      );
      dispatch.chart.setHistoricalChartData(reponse.data.candles);
      dispatch.chart.setLoadingFailure(false);
    } catch (e) {
      // TODO(dankurka): Dispatch error to central error handling
      dispatch.chart.setLoadingFailure(true);
    }
  };
};

export const createUpdateLiveCandle = (dispatch: FsDispatch) => {
  return async (
    _,
    { dynamicExchangeData }: { dynamicExchangeData: OracleModel }
  ) => {
    if (dynamicExchangeData.exchange === null) {
      return;
    }

    dispatch.chart.updateComputedCandles(
      dynamicExchangeData.exchange.assetPrice
    );
  };
};

export default {
  state: CHART_INITIAL_STATE,
  reducers: {
    updateComputedCandles: (state: ChartModel, price: string) => {
      if (state.historicCandles.length === 0) {
        return { ...state };
      }

      const lastCandle =
        state.historicCandles[state.historicCandles.length - 1];

      const localPrice = BigInt(price);
      const newCandle = {
        ...lastCandle,
        close: price,
        min: localPrice < BigInt(lastCandle.min) ? price : lastCandle.min,
        max: localPrice > BigInt(lastCandle.max) ? price : lastCandle.max
      };

      return {
        ...state,
        historicCandles: [
          ...state.historicCandles.slice(0, state.historicCandles.length - 1),
          newCandle
        ]
      };
    },
    setHistoricalChartData: (state: ChartModel, chartData: Candle[]) => {
      if (state.historicCandles.length === 0 || chartData.length === 0) {
        return {
          ...state,
          historicCandles: chartData
        };
      }

      const lastRemoteCandle = chartData[chartData.length - 1];
      const lastLocalCandle =
        state.historicCandles[state.historicCandles.length - 1];

      // Ensure we do not overwrite the modified local candle
      if (lastRemoteCandle.timestamp === lastLocalCandle.timestamp) {
        return {
          ...state
        };
      }

      return {
        ...state,
        historicCandles: chartData
      };
    },
    setLoadingFailure: (state: ChartModel, loadingFailed: boolean) => ({
      ...state,
      loadingFailed
    })
  },
  effects: (dispatch: FsDispatch) => {
    return {
      getHistoricalChartData: createHistoricalChartdataLoader(
        dispatch,
        new DataLoader()
      ),
      updateLiveCandle: createUpdateLiveCandle(dispatch)
    };
  }
};
