import { RematchRootState } from "@rematch/core";
import { FunctionId } from "offchainTradingSystem/signing/UserMessageCoder";
import { FsRootModel } from "redux/store";
import { MESSAGE_PROCESSOR_TRANSACTION_STATUS } from "../../constants";
import { MessageProcessorState } from "./model";
export const selectTransactionByFunctionId = (
  state: RematchRootState<FsRootModel>,
  functionId: number
) => {
  const transaction = Array.from(
    state.messageProcessor.transactions.values()
  ).find(t => t.functionId === functionId);

  if (transaction === undefined) {
    return false;
  }

  // TODO(dankurka): seems fishy
  return (
    transaction.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED ||
    transaction.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.MOCK
  );
};

export const selectIsMiningCloseTrade = (state: {
  messageProcessor: MessageProcessorState;
}) => {
  return Array.from(state.messageProcessor.transactions.values()).some(
    t =>
      t.functionId === FunctionId.CLOSE_TRADE_ID &&
      (t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.SIGNED ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.PUBLISHED)
  );
};

export const selectPendingAddLiquidityTransaction = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.ADD_LIQUIDITY_ID);

export const selectPendingRemoveLiquidityTransaction = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.REMOVE_LIQUIDITY_ID);

export const selectPendingOpenShortTransaction = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.OPEN_SHORT_ID);

export const selectPendingCloseTradeTransaction = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.CLOSE_TRADE_ID);

export const selectPendingAddCollateralTransaction = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.ADD_COLLATERAL_ID);

export const selectPendingInstantWithdrawalTransaction = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.INSTANT_WITHDRAW_ID);

export const selectIsAddLiquidityProcessing = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.ADD_LIQUIDITY_ID);

export const selectIsRemoveLiquidityProcessing = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.REMOVE_LIQUIDITY_ID);

export const selectIsOpenTradeProcessing = (
  state: RematchRootState<FsRootModel>
) =>
  selectTransactionByFunctionId(state, FunctionId.OPEN_LONG_ID) ||
  selectTransactionByFunctionId(state, FunctionId.OPEN_SHORT_ID);

export const selectIsBalancePoolsProcessing = (
  state: RematchRootState<FsRootModel>
) => selectTransactionByFunctionId(state, FunctionId.BALANCE_POOLS_ID);

export const selectIsTradePendingClose = (
  state: RematchRootState<FsRootModel>,
  tradeId: string
) =>
  Array.from(state.messageProcessor.transactions.values()).some(
    t => t.parentId === tradeId
  );

export const selectPendingWithdrawals = (
  state: RematchRootState<FsRootModel>
) => {
  return Array.from(state.messageProcessor.transactions.values()).filter(
    t =>
      t.functionId === FunctionId.INSTANT_WITHDRAW_ID &&
      t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.PUBLISHED
  );
};

export const selectIsSendingWithdrawalMessage = (
  state: RematchRootState<FsRootModel>
) => {
  return Array.from(state.messageProcessor.transactions.values()).some(
    t =>
      t.functionId === FunctionId.INSTANT_WITHDRAW_ID &&
      (t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.NEW ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.SIGNED)
  );
};

export const selectTransactionById = (id: string) => {
  return (state: RematchRootState<FsRootModel>) => {
    return state.messageProcessor.transactions.get(id);
  };
};

export const selectOpeningTrades = (state: RematchRootState<FsRootModel>) => {
  return Array.from(state.messageProcessor.transactions.values()).filter(
    t =>
      (t.functionId === FunctionId.OPEN_LONG_ID ||
        t.functionId === FunctionId.OPEN_SHORT_ID) &&
      (t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.NEW ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED)
  );
};

export const selectAddingLiquidityInteractions = (
  state: RematchRootState<FsRootModel>
) => {
  return Array.from(state.messageProcessor.transactions.values()).filter(
    t =>
      t.functionId === FunctionId.ADD_LIQUIDITY_ID &&
      (t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.NEW ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED)
  );
};

export const selectRemoveLiquidityInteractions = (
  state: RematchRootState<FsRootModel>
) => {
  return Array.from(state.messageProcessor.transactions.values()).filter(
    t =>
      t.functionId === FunctionId.REMOVE_LIQUIDITY_ID &&
      (t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.NEW ||
        t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED)
  );
};
