import React from "react";
import { useSelector } from "react-redux";
import { fromWei } from "utils/numbers";
import { POOL_CAPACITY } from "../constants";
import { formatNumber } from "utils/formatter";
import { selectCurrentExchangeDynamicData } from "redux/dynamicExchangeData/selectors";
import { Text, A } from "components/Typography";
import Box from "components/Box";
import HorizontalList from "pages/Rewards/HorizontalList";

const PoolCapacity: React.FC = () => {
  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);
  const formattedCurrentData = dynamicExchangeData.assetPoolSize
    ? formatNumber(String(fromWei(dynamicExchangeData.assetPoolSize)), 0)
    : "--";
  return (
    <Box py={2} px={3} border="2px solid #f1c844">
      <Box mb={1.5}>
        <Text fontSize="18px">Pool is at/near capacity</Text>{" "}
        <Text fontSize="14px">
          (
          <A
            href="https://docs.futureswap.com/protocol/providing-liquidity#pool-size-limits"
            target="_blank"
          >
            Learn more
          </A>
          )
        </Text>
      </Box>
      <Box mb={2}>
        <Box mb={0.5}>
          <HorizontalList
            label="Current Capacity"
            value={`${formatNumber(POOL_CAPACITY, 0)} ETH`}
          />
        </Box>
        <HorizontalList
          label="Current Deposits"
          value={`${formattedCurrentData} ETH`}
        />
      </Box>
      <Text color="#eee" fontSize="14px" lineHeight="21px">
        Your deposit in step 2 will get declined if the pool exceeds this
        capacity. Follow us on{" "}
        <A href="https://discord.gg/DJxuxp2mwu" target="_blank">
          Discord
        </A>{" "}
        or{" "}
        <A href="https://twitter.com/futureswapx" target="_blank">
          Twitter
        </A>{" "}
        for updates
      </Text>
    </Box>
  );
};

export default PoolCapacity;
