import React from "react";

import Box from "components/Box";
import AllPositions from "./AllPositions";
import PoolUtilization from "./PoolUtilization";

const ActivitySection = () => {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      py={2.5}
      overflow="hidden"
    >
      <AllPositions />
      <Box mt={1.5} px={2}>
        <PoolUtilization />
      </Box>
    </Box>
  );
};

export default ActivitySection;
