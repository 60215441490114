import React from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import MainGrid from "./MainGrid";
import NavBar from "components/NavBar";

const Positions = () => {
  return (
    <Container display="flex" flexDirection="column">
      <NavBar />
      <MainGrid />
    </Container>
  );
};

const Container = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #3e4454;
  color: #ddd;
`;

export default Positions;
