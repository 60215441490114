import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectIsMiningCloseTrade,
  selectIsSendingWithdrawalMessage
} from "redux/messageProcessor/selectors";
import PulsatingWarningDot from "components/PulsatingWarningDot";
import { fromWei, toWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import { FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";
import { Link } from "@reach/router";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import RestrictedRegionButton from "components/RestrictedRegionButton";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { Text, H4, MainMetrics } from "components/Typography";
import Input from "components/Input";
import Table from "components/Table";

const Withdraw = ({ isOpen, handleWithdraw, handleClose, token }) => {
  const dispatch = useDispatch();
  const isMiningCloseTrade = useSelector(selectIsMiningCloseTrade);
  const isSendingWithdrawalMessage = useSelector(
    selectIsSendingWithdrawalMessage
  );
  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );

  const [amountToWithdraw, setAmountToWithdraw] = useState("");

  const isStable = token.symbol.toLowerCase() !== "eth";

  const renderUnlockButton = () => (
    <Button
      color={COLORS.PURPLE}
      onClick={() => dispatch.wallet.unlockToken(token.address)}
      isLoading={token.isUnlocking}
      fluidWidth
      size="lg"
    >
      Unlock {token.symbol}
    </Button>
  );

  const renderActionButton = () => (
    <Box mt={2}>
      <Box>
        <Button
          color={COLORS.PURPLE}
          size="lg"
          onClick={() => handleWithdraw(amountToWithdraw)}
          fluidWidth
          isLoading={isSendingWithdrawalMessage}
        >
          Withdraw {token.symbol}
        </Button>
      </Box>
    </Box>
  );

  const renderHorizontalList = (label, value) => {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <H4>{label}</H4>
        <MainMetrics>{value}</MainMetrics>
      </Box>
    );
  };

  const renderTable = () => {
    const newBalance = String(
      Number(fromWei(token.fsBalance)) - Number(fromWei(amountToWithdraw))
    );
    const rows = [
      {
        cells: [
          <MainMetrics key="a">{token.symbol}</MainMetrics>,
          <MainMetrics key="b">
            {formatNumber(Number(fromWei(amountToWithdraw)), 2)}
          </MainMetrics>,
          <MainMetrics key="c">
            {formatNumber(fromWei(token.fsBalance), 2)}
          </MainMetrics>,
          <MainMetrics key="d">{formatNumber(newBalance, 2)}</MainMetrics>
        ]
      }
    ];
    return (
      <Table
        headings={["Asset", "Withdrawing", "Current Balance", "New Balance"]}
        size="sm"
        rows={rows}
      />
    );
  };

  const renderInput = () => {
    return (
      <>
        {renderHorizontalList(
          `BALANCE: ${fromWei(token.fsBalance)}`,
          <Text onClick={() => setAmountToWithdraw(token.fsBalance)}>
            <MaxButton>MAX</MaxButton>
          </Text>
        )}

        <Input
          value={fromWei(amountToWithdraw) || ""}
          placeholder={"0"}
          type="number"
          name="open"
          id="collateral"
          width="100px"
          onChange={e => {
            if (e.target.value === "") {
              setAmountToWithdraw("");
            } else {
              setAmountToWithdraw(toWei(e.target.value));
            }
          }}
          rightLabel={token.symbol}
        />
      </>
    );
  };

  const renderWithdrawPendingContext = () => {
    return (
      <Box p={1.5} border="2px solid #f1c844">
        <Box display="flex" mb={1}>
          <Text color="#eee" fontSize="16px" lineHeight="18px">
            Closed position is mining
          </Text>
          <Box ml={1}>
            <PulsatingWarningDot />
          </Box>
        </Box>
        <Text color="#eee" fontSize="14px" lineHeight="18px">
          Your full balance will be available to withdraw when the close
          position mines (usually within 5 minutes)
        </Text>
      </Box>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title={`Withdraw ${token.symbol}`}
      handleClose={handleClose}
    >
      <Box mb={2}>
        <Text fontSize="18px">
          {`Withdraw ${token.symbol} from your Futureswap wallet`}
        </Text>
      </Box>
      {isStable && isMiningCloseTrade && (
        <Box mb={2}>{renderWithdrawPendingContext()}</Box>
      )}
      <Box mb={3}>{renderInput()}</Box>
      <Box mb={3}>{renderTable()}</Box>
      {restrictedRegion ? (
        <RestrictedRegionButton fluidWidth />
      ) : token.isUnlocked ? (
        renderActionButton()
      ) : (
        renderUnlockButton()
      )}
      {restrictedRegion && token.fsBalance && (
        <Box mt={2} textAlign="center">
          <Text>Alternative: </Text>
          <StyledLink to="/withdraw">
            Withdraw directly through contract
          </StyledLink>
        </Box>
      )}
    </Modal>
  );
};

Withdraw.defaultProps = {
  isDeposit: false
};

export default Withdraw;

const MaxButton = styled(Text)`
  color: #7895f1;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 15px;
  color: #9daeff !important;
`;
