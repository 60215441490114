import React from "react";
import moment from "moment";
import styled from "@emotion/styled/macro";
import Box from "components/Box";
import { MainMetrics } from "components/Typography";
import { COLORS } from "styles/theme";
import Tippy from "components/Tippy";
import { formatNumber } from "utils/formatter";
import { fromWei } from "utils/numbers";

type Props = {
  positionSize: string;
  isLiquidated?: boolean; // only exists on close trade
  isLong: boolean;
  isOpen: boolean;
  timestamp: number;
};

const getTooltipLabel = (
  isLong: boolean,
  isOpen: boolean,
  isLiquidated?: boolean
): string => {
  const type = isLong ? "long" : "short";
  if (isLiquidated) {
    return `liquidiated ${type}`;
  }
  if (isOpen) {
    return `opened ${type}`;
  }
  return `closed ${type}`;
};

const PositionItem: React.FC<Props> = ({
  positionSize,
  isLiquidated,
  isLong,
  isOpen,
  timestamp
}) => {
  const timeString = moment(timestamp).format("HH:mm:ss");
  const color = isLong ? COLORS.GREEN : COLORS.RED;
  const icon = isLong ? "↑" : "↓";
  const tippyLabel = getTooltipLabel(isLong, isOpen, isLiquidated);

  return (
    <Box>
      <Tippy placement="left" content={tippyLabel}>
        <PositionItemContainer
          display="flex"
          justifyContent="space-between"
          py={0.5}
          px={2}
        >
          <MainMetrics color={color}>
            <StrikeOrNot isOpen={isOpen}>
              {icon}
              {` `}
              {formatNumber(fromWei(positionSize), 2)}
            </StrikeOrNot>
          </MainMetrics>
          <MainMetrics title="???">{timeString}</MainMetrics>
        </PositionItemContainer>
      </Tippy>
    </Box>
  );
};

export default PositionItem;

const PositionItemContainer = styled(Box)`
  cursor: default;
  &:hover {
    background: rgba(255, 255, 255, 0.06);
  }
`;

const StrikeOrNot = styled.div<{ isOpen: boolean }>`
  text-decoration: ${props => (props.isOpen ? "none" : "line-through")};
`;
