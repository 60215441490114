import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { fromWei } from "utils/numbers";
import moment from "moment";
import { formatNumber } from "utils/formatter";
import { getVestingContractInstance } from "../../contracts/Vesting";
import { useSelector } from "react-redux";
import { toWei } from "utils/numbers";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";
import Box from "../../components/Box";
import NavBar from "../../components/NavBar";
import { H2, Text } from "components/Typography";
import LoadingSpinner from "components//LoadingSpinner";

import Button from "components/Button";
const VESTING_CONTRACT = "0x1FC289460Fa74A9a0F98a3C79f34E2639432B059";
const vestingInstance = getVestingContractInstance(VESTING_CONTRACT);

const getClaimableTokens = (
  totalVestingAmount: number,
  totalClaimed: number,
  cliffTimeInSec: number,
  endTimeInSec: number
) => {
  const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
  const hasReachedCliff = currentTimeInSeconds > cliffTimeInSec;
  if (!hasReachedCliff || !totalVestingAmount) {
    return 0;
  }
  const cliffAmount = totalVestingAmount / 3;
  const secondsSinceCliff = currentTimeInSeconds - cliffTimeInSec;
  const claimablePerSecond =
    ((totalVestingAmount / 3) * 2) / (endTimeInSec - cliffTimeInSec);

  const claimableByNow =
    cliffAmount +
    claimablePerSecond *
      Math.min(secondsSinceCliff, endTimeInSec - cliffTimeInSec);

  return claimableByNow - totalClaimed;
};

const VestingPage = () => {
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const getVestingInfo = async () => {
    const {
      cliffTimeInSec,
      endTimeInSec,
      isConfirmed,
      startTimeInSec,
      totalAmount,
      totalAmountWithdrawn
    } = await vestingInstance.methods.schedules(accountAddress).call();
    setCliffTimeInSec(cliffTimeInSec.toString());
    setEndTimeInSec(endTimeInSec.toString());
    setIsInVestingContract(isConfirmed);
    setStartTimeInSec(startTimeInSec.toString());
    setTotalAmount(String(fromWei(totalAmount.toString())));
    setTotalAmountWithdrawn(String(fromWei(totalAmountWithdrawn.toString())));
  };

  useEffect(() => {
    if (accountAddress) {
      getVestingInfo();
    }
  }, [accountAddress]);

  const [cliffTimeInSec, setCliffTimeInSec] = useState(0);
  const [endTimeInSec, setEndTimeInSec] = useState(0);
  const [isInVestingContract, setIsInVestingContract] = useState(false);
  const [startTimeInSec, setStartTimeInSec] = useState(0);
  const [totalAmount, setTotalAmount] = useState("");
  const [totalAmountWithdrawn, setTotalAmountWithdrawn] = useState("");
  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const handleWithdraw = async () => {
    try {
      await vestingInstance.methods
        .withdraw()
        .send({ from: accountAddress })
        .on("transactionHash", () => setIsWithdrawing(true))
        .on("confirmation", async id => {
          if (id === 0) {
            await getVestingInfo();
            setIsWithdrawing(false);
          }
        });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const renderIsNotVestingAddress = () => {
    return (
      <Text>
        Your connected address ({accountAddress}) was not found in the vesting
        contract
      </Text>
    );
  };

  const isClaimable = cliffTimeInSec > new Date().getTime() / 1000;

  const renderHelpfulText = () => {
    return (
      <Box py={2} px={3} border="2px solid #f1c844">
        <Box>
          <Text fontSize="15px">
            Vested tokens can be claimed at any time. Claimed tokens are added
            to the circulating supply.
          </Text>
        </Box>
      </Box>
    );
  };

  const renderVestingContext = () => {
    const claimableTokens = getClaimableTokens(
      Number(totalAmount),
      Number(totalAmountWithdrawn),
      Number(cliffTimeInSec),
      Number(endTimeInSec)
    );

    return (
      <>
        <SectionHolder>
          <SectionTitle>TOTAL VESTING AMOUNT</SectionTitle>
          <Box>{formatNumber(totalAmount, 2)} FST</Box>
        </SectionHolder>
        <SectionHolder>
          <SectionTitle>TOTAL CLAIMABLE</SectionTitle>
          <Box>{formatNumber(claimableTokens, 2)} FST</Box>
        </SectionHolder>
        <SectionHolder>
          <SectionTitle>TOTAL CLAIMED</SectionTitle>
          <Box>{formatNumber(totalAmountWithdrawn, 2)} FST</Box>
        </SectionHolder>
        <SectionHolder>
          <SectionTitle>START DATE</SectionTitle>
          <Box>
            {startTimeInSec
              ? startTimeInSec &&
                moment.unix(startTimeInSec).format("MMMM Do YYYY, h:mm:ss a")
              : "-"}
          </Box>
        </SectionHolder>
        <SectionHolder>
          <SectionTitle>CLIFF DATE</SectionTitle>
          <Box>
            {cliffTimeInSec
              ? cliffTimeInSec &&
                moment.unix(cliffTimeInSec).format("MMMM Do YYYY, h:mm:ss a")
              : "-"}
          </Box>
        </SectionHolder>
        <SectionHolder>
          <SectionTitle>VEST ENDS</SectionTitle>
          <Box>
            {endTimeInSec
              ? endTimeInSec &&
                moment.unix(endTimeInSec).format("MMMM Do YYYY, h:mm:ss a")
              : "-"}
          </Box>
        </SectionHolder>
        {claimableTokens && <Box mt={3}>{renderHelpfulText()}</Box>}
        <Box mt={3}>
          <Button
            fluidWidth
            onClick={handleWithdraw}
            isLoading={isWithdrawing}
            disabled={isClaimable}
          >
            Claim {formatNumber(claimableTokens, 2)} tokens
          </Button>
        </Box>
      </>
    );
  };

  const renderIsLoading = () => (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={2}>
          <LoadingSpinner />
        </Box>
        <Text>(Connect your wallet)</Text>
      </Box>
    </>
  );

  return (
    <>
      <NavBar />
      <Container my={6}>
        <Box mb={4}>
          <H2>Vesting</H2>
        </Box>
        {!accountAddress
          ? renderIsLoading()
          : isInVestingContract
          ? renderVestingContext()
          : renderIsNotVestingAddress()}
      </Container>
    </>
  );
};

const Container = styled(Box)`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 480px;
  color: #ddd;
`;

const SectionHolder = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const SectionTitle = styled(Box)`
  font-weight: 600;
`;

export default VestingPage;
