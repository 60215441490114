// import React from "react";
import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Box from "components/Box";

const COLORS = {
  White: "white",
  Dark: "dark",
  Primary: "primary"
};

const SIZES = {
  Xs: "xs",
  Sm: "sm",
  Md: "md",
  Lg: "lg"
};

const LoadingSpinner = props => {
  return (
    <Box display="flex" justifyContent="center">
      <StyledSpinner {...props} viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2"
        />
      </StyledSpinner>
    </Box>
  );
};

LoadingSpinner.propTypes = {
  color: PropTypes.oneOf(Object.values(COLORS)),
  size: PropTypes.oneOf(Object.values(SIZES))
};

LoadingSpinner.defaultProps = {
  color: COLORS.White,
  size: SIZES.Md
};

export default LoadingSpinner;

const sideLength = size => {
  switch (size) {
    case SIZES.Xs:
      return "16px";
    case SIZES.Sm:
      return "20px";
    case SIZES.Md:
      return "24px";
    case SIZES.Lg:
      return "32px";
    default:
      return "24px";
  }
};

const strokeColor = color => {
  switch (color) {
    case COLORS.White:
      return "#fff";
    case COLORS.Dark:
      return "#777";
    default:
      return "#777";
  }
};

const StyledSpinner = styled.svg<{ size: string; color: string }>`
  animation: rotate 1s linear infinite;
  width: ${props => sideLength(props.size)};
  height: ${props => sideLength(props.size)};

  & .path {
    stroke: ${props => strokeColor(props.color)};
    stroke-width: 4;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
