import { BigNumber, providers, Signer, utils } from "ethers";
import Logger from "logger/Logger";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import getProvider from "provider";
import {
  FunctionId,
  UserMessageEncoder,
  ChangeLiquidityMessage,
  OpenTradeMessage,
  CloseTradeMessage,
  AddCollateralMessage,
  BalancePoolMessage,
  InstantWithdrawMessage
} from "../../offchainTradingSystem/signing/UserMessageCoder";
import { toWei } from "utils/numbers";
import {
  MESSAGE_PROCESSOR_TRANSACTION_STATUS,
  MAX_PRICE_DEVIATION_QUERY_PARAM,
  DEFAULT_MAX_PRICE_DEVIATION
} from "../../constants";
import { SignatureNormalizer } from "signing/SignatureNormalizer";
import { DataLoader } from "data/DataLoader";
import {
  checkArgument,
  checkDefined
} from "offchainTradingSystem/common/preconditions";
import { RegistryModel } from "redux/registry/model";
import { FsDispatch } from "redux/store";

export const createDispatchInteractionFn = (
  dispatch: FsDispatch,
  signatureNormalizer: SignatureNormalizer,
  dataLoader: DataLoader,
  handleUpdateTransactionFn: ({
    id,
    functionId
  }: {
    id: string;
    functionId: number;
  }) => Promise<void>
) => {
  return async (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ): Promise<string> => {
    let signature;
    try {
      signature = signatureNormalizer.normalize(rawSignature);
    } catch (e) {
      Sentry.captureException(e);
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason:
          "Unable to normalize signature, are you using an unsupported hardware device?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    const payload = JSON.stringify({
      signature,
      packedMessage: message,
      functionId
    });

    let response;

    try {
      response = await dataLoader.put("/contractcall", payload);
    } catch (e) {
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Server request failed, please retry the action."
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    dispatch.messageProcessor.updateTransactionStatus({
      id,
      status: MESSAGE_PROCESSOR_TRANSACTION_STATUS.SENT
    });

    dispatch.messageProcessor.saveTransactions();

    // TODO(dankurka): Handle failure => not received

    await handleUpdateTransactionFn({
      id: response.data.id,
      functionId
    });

    return id;
  };
};

export const createChangeLiquidity = (
  dispatch: FsDispatch,
  userMessageEncoder: UserMessageEncoder,
  signerProvider: () => (bytes: Uint8Array) => Promise<string>,
  dispatchInteractionFn: (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ) => Promise<string>,
  userInteractionNumberFn: () => BigNumber,
  timeFn: () => number
) => {
  return async (
    {
      assetAmount,
      addLiquidity
    }: {
      assetAmount: BigNumber;
      addLiquidity: boolean;
    },
    { registry }: { registry: RegistryModel }
  ) => {
    const interactionNumber = userInteractionNumberFn();
    const id = utils.hexStripZeros(interactionNumber.toHexString());
    const functionId = addLiquidity
      ? FunctionId.ADD_LIQUIDITY_ID
      : FunctionId.REMOVE_LIQUIDITY_ID;

    const timestamp = timeFn();

    dispatch.messageProcessor.createTransaction({
      id,
      functionId,
      timestamp,
      successMessage: addLiquidity
        ? "Your liquidity has been added"
        : "Your liquidity has been removed"
    });

    const changeLiquidityMessage: ChangeLiquidityMessage = {
      functionId,
      exchangeAddress: registry.exchange.address,
      assetPriceBound: BigNumber.from("0"),
      stablePriceBound: BigNumber.from(toWei("1")),
      userInteractionNumber: interactionNumber,
      gasStableBound: BigNumber.from("0"),
      minTransmitterGas: BigNumber.from("0"),
      amount: assetAmount,
      signatureTime: BigNumber.from((timestamp / 1000) | 0)
    };

    let localSigner: ((bytes: Uint8Array) => Promise<string>) | null = null;
    try {
      localSigner = signerProvider();
    } catch (e) {
      Logger.error("Failed to obtain signer", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Can not obtain signer, is your meta mask connected?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    let encodedMessage, signature;

    try {
      const {
        packedMessage,
        signature: sig
      } = await userMessageEncoder.encodeChangeLiquidity(
        changeLiquidityMessage,
        localSigner
      );
      encodedMessage = packedMessage;
      signature = sig;
    } catch (e) {
      Logger.error("Failed to sign message", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Failed to sign message, please retry!"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    await dispatchInteractionFn(id, encodedMessage, signature, functionId);

    return id;
  };
};

export const createOpenTrade = (
  dispatch: FsDispatch,
  userMessageEncoder: UserMessageEncoder,
  signerProvider: () => (bytes: Uint8Array) => Promise<string>,
  dispatchInteractionFn: (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ) => Promise<string>,
  userInteractionNumberFn: () => BigNumber,
  timeFn: () => number
) => {
  return async ({
    collateral,
    isLong,
    leverageAmount,
    exchangeAddress,
    entryPrice
  }: {
    collateral: BigNumber;
    isLong: boolean;
    leverageAmount: BigNumber;
    entryPrice: BigNumber;
    exchangeAddress: string;
  }) => {
    const interactionNumber = userInteractionNumberFn();
    const id = utils.hexStripZeros(interactionNumber.toHexString());
    const functionId = isLong
      ? FunctionId.OPEN_LONG_ID
      : FunctionId.OPEN_SHORT_ID;

    const timestamp = timeFn();

    dispatch.messageProcessor.createTransaction({
      id,
      functionId,
      timestamp,
      successMessage: isLong
        ? "Your long has been opened"
        : "Your short has been opened"
    });

    const maxDeviationPercent: string =
      localStorage.getItem(MAX_PRICE_DEVIATION_QUERY_PARAM) ||
      String(DEFAULT_MAX_PRICE_DEVIATION);

    const percentInWei = BigInt(toWei(maxDeviationPercent)) / 100n;
    const maxDeviationAmount =
      (BigInt(entryPrice) * percentInWei) / BigInt(toWei("1"));

    const assetPriceBound = isLong
      ? BigInt(entryPrice) + maxDeviationAmount
      : BigInt(entryPrice) - maxDeviationAmount;

    const openTradeParams: OpenTradeMessage = {
      functionId,
      exchangeAddress,
      assetPriceBound: BigNumber.from(assetPriceBound),
      stablePriceBound: BigNumber.from(toWei("1")),
      userInteractionNumber: interactionNumber,
      gasStableBound: BigNumber.from(toWei("1000")),
      minTransmitterGas: BigNumber.from("0"),
      collateral: BigNumber.from(collateral.toString()),
      leverage: BigNumber.from(leverageAmount),
      tradeFeeBound: BigNumber.from("0"),
      signatureTime: BigNumber.from((timestamp / 1000) | 0)
    };

    let localSigner: ((bytes: Uint8Array) => Promise<string>) | null = null;
    try {
      localSigner = signerProvider();
    } catch (e) {
      Logger.error("Failed to obtain signer", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Can not obtain signer, is your meta mask connected?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    let encodedMessage, signature;

    try {
      const {
        packedMessage,
        signature: sig
      } = await userMessageEncoder.encodeOpenTrade(
        openTradeParams,
        localSigner
      );
      encodedMessage = packedMessage;
      signature = sig;
    } catch (e) {
      Logger.error("Failed to sign message", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Failed to sign message, please retry!"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    await dispatchInteractionFn(id, encodedMessage, signature, functionId);

    return id;
  };
};

export const createCloseTrade = (
  dispatch: FsDispatch,
  userMessageEncoder: UserMessageEncoder,
  signerProvider: () => (bytes: Uint8Array) => Promise<string>,
  dispatchInteractionFn: (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ) => Promise<string>,
  userInteractionNumberFn: () => BigNumber,
  timeFn: () => number
) => {
  return async ({
    tradeId,
    isLong,
    percentToCloseInWei,
    referral,
    exchangeAddress
  }: {
    tradeId: string;
    isLong: boolean;
    percentToCloseInWei: BigNumber;
    referral: string;
    exchangeAddress: string;
  }) => {
    const interactionNumber = userInteractionNumberFn();
    const id = utils.hexStripZeros(interactionNumber.toHexString());
    const functionId = FunctionId.CLOSE_TRADE_ID;
    const timestamp = timeFn();

    dispatch.messageProcessor.createTransaction({
      id,
      functionId,
      timestamp,
      successMessage: "Your trade has been closed",
      parentId: tradeId
    });

    const closeTradeParams: CloseTradeMessage = {
      functionId,
      exchangeAddress,
      assetPriceBound: BigNumber.from("0"),
      stablePriceBound: BigNumber.from(toWei("1")),
      userInteractionNumber: interactionNumber,
      gasStableBound: BigNumber.from(toWei("1000")),
      minTransmitterGas: BigNumber.from("0"),
      tradeId: BigNumber.from(tradeId),
      isLong,
      referral,
      tradeFeeBound: BigNumber.from("0"),
      percentToClose: percentToCloseInWei,
      signatureTime: BigNumber.from((timestamp / 1000) | 0)
    };

    let localSigner: ((bytes: Uint8Array) => Promise<string>) | null = null;
    try {
      localSigner = signerProvider();
    } catch (e) {
      Logger.error("Failed to obtain signer", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Can not obtain signer, is your meta mask connected?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    let encodedMessage, signature;

    try {
      const {
        packedMessage,
        signature: sig
      } = await userMessageEncoder.encodeCloseTrade(
        closeTradeParams,
        localSigner
      );
      encodedMessage = packedMessage;
      signature = sig;
    } catch (e) {
      Logger.error("Failed to sign message", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Failed to sign message, please retry!"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    await dispatchInteractionFn(id, encodedMessage, signature, functionId);

    return id;
  };
};

export const createAddCollateral = (
  dispatch: FsDispatch,
  userMessageEncoder: UserMessageEncoder,
  signerProvider: () => (bytes: Uint8Array) => Promise<string>,
  dispatchInteractionFn: (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ) => Promise<string>,
  userInteractionNumberFn: () => BigNumber,
  timeFn: () => number
) => {
  return async ({
    tradeId,
    collateral,
    exchangeAddress
  }: {
    tradeId: BigNumber;
    collateral: BigNumber;
    exchangeAddress: string;
  }) => {
    const interactionNumber = userInteractionNumberFn();
    const id = utils.hexStripZeros(interactionNumber.toHexString());
    const functionId = FunctionId.ADD_COLLATERAL_ID;
    const timestamp = timeFn();

    dispatch.messageProcessor.createTransaction({
      id,
      functionId,
      timestamp,
      successMessage: "Collateral has been added to your trade"
    });

    const addCollateralParams: AddCollateralMessage = {
      tradeId,
      collateral,
      functionId,
      assetPriceBound: BigNumber.from("0"),
      stablePriceBound: BigNumber.from(toWei("1")),
      userInteractionNumber: interactionNumber,
      gasStableBound: BigNumber.from(toWei("1000")),
      minTransmitterGas: BigNumber.from("0"),
      signatureTime: BigNumber.from((timestamp / 1000) | 0),
      exchangeAddress
    };

    let localSigner: ((bytes: Uint8Array) => Promise<string>) | null = null;
    try {
      localSigner = signerProvider();
    } catch (e) {
      Sentry.captureException(e);
      Logger.error("Failed to obtain signer", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Can not obtain signer, is your meta mask connected?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    let encodedMessage, signature;

    try {
      const {
        packedMessage,
        signature: sig
      } = await userMessageEncoder.encodeAddCollateral(
        addCollateralParams,
        localSigner
      );
      encodedMessage = packedMessage;
      signature = sig;
    } catch (e) {
      Logger.error("Failed to sign message", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Failed to sign message, please retry!"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    await dispatchInteractionFn(id, encodedMessage, signature, functionId);

    return id;
  };
};

export const createBalancePools = (
  dispatch: FsDispatch,
  userMessageEncoder: UserMessageEncoder,
  signerProvider: () => (bytes: Uint8Array) => Promise<string>,
  dispatchInteractionFn: (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ) => Promise<string>,
  userInteractionNumberFn: () => BigNumber,
  timeFn: () => number
) => {
  return async ({
    amount,
    isTradingAsset,
    exchangeAddress
  }: {
    amount: BigNumber;
    isTradingAsset: boolean;
    exchangeAddress: string;
  }) => {
    const interactionNumber = userInteractionNumberFn();
    const id = utils.hexStripZeros(interactionNumber.toHexString());
    const functionId = FunctionId.BALANCE_POOLS_ID;
    const timestamp = timeFn();

    dispatch.messageProcessor.createTransaction({
      id,
      functionId,
      timestamp,
      successMessage: "You balanced the pool"
    });

    const balancePoolParams: BalancePoolMessage = {
      functionId,
      amount,
      exchangeAddress,
      isTradingAsset,
      userInteractionNumber: interactionNumber,
      assetPriceBound: BigNumber.from("0"),
      stablePriceBound: BigNumber.from(toWei("1")),
      gasStableBound: BigNumber.from(toWei("1000")),
      minTransmitterGas: BigNumber.from("0"),
      signatureTime: BigNumber.from((timestamp / 1000) | 0)
    };

    let localSigner: ((bytes: Uint8Array) => Promise<string>) | null = null;
    try {
      localSigner = signerProvider();
    } catch (e) {
      Logger.error("Failed to obtain signer", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Can not obtain signer, is your meta mask connected?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    let encodedMessage, signature;

    try {
      const {
        packedMessage,
        signature: sig
      } = await userMessageEncoder.encodeBalancePools(
        balancePoolParams,
        localSigner
      );
      encodedMessage = packedMessage;
      signature = sig;
    } catch (e) {
      Logger.error("Failed to sign message", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Failed to sign message, please retry!"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    await dispatchInteractionFn(id, encodedMessage, signature, functionId);
    return id;
  };
};

export const createInstantWithdrawal = (
  dispatch: FsDispatch,
  userMessageEncoder: UserMessageEncoder,
  signerProvider: () => (bytes: Uint8Array) => Promise<string>,
  dispatchInteractionFn: (
    id: string,
    message: string,
    rawSignature: string,
    functionId: number
  ) => Promise<string>,
  userInteractionNumberFn: () => BigNumber,
  timeFn: () => number
) => {
  return async (
    {
      amount,
      tokenAddress
    }: {
      amount: BigNumber;
      tokenAddress: string;
    },
    { registry }: { registry: RegistryModel }
  ) => {
    const interactionNumber = userInteractionNumberFn();
    const id = utils.hexStripZeros(interactionNumber.toHexString());
    const functionId = FunctionId.INSTANT_WITHDRAW_ID;

    const registryHolder = checkDefined(registry.registryHolderAddress);

    dispatch.messageProcessor.createTransaction({
      id,
      functionId,
      timestamp: timeFn(),
      tokenAddress,
      successMessage:
        "Withdrawal transaction created, please submit the transaction"
    });

    const instantWithdrawalParams: InstantWithdrawMessage = {
      amount,
      tokenAddress,
      registryHolder,
      userInteractionNumber: interactionNumber,
      minTransmitterGas: BigNumber.from("0")
    };

    let localSigner: ((bytes: Uint8Array) => Promise<string>) | null = null;
    try {
      localSigner = signerProvider();
    } catch (e) {
      Logger.error("Failed to obtain signer", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Can not obtain signer, is your meta mask connected?"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    let encodedMessage, signature;

    try {
      const {
        packedMessage,
        signature: sig
      } = await userMessageEncoder.encodeInstantWithdraw(
        instantWithdrawalParams,
        localSigner
      );
      encodedMessage = packedMessage;
      signature = sig;
    } catch (e) {
      Logger.error("Failed to sign message", { e });
      const status: TransactionStatusDTO = {
        id,
        functionId,
        status: "error",
        errorReason: "Failed to sign message, please retry!"
      };
      dispatch.messageProcessor.updateTransaction(status);
      return id;
    }

    await dispatchInteractionFn(id, encodedMessage, signature, functionId);

    return id;
  };
};

export const createTransactionUpdater = (dispatch: FsDispatch) => {
  return async (id: string) => {
    const transaction: TransactionStatusDTO = dispatch.messageProcessor.getTransaction(
      id
    );

    if (transaction.functionId === FunctionId.INSTANT_WITHDRAW_ID) {
      dispatch.messageProcessor.saveTransactions();
      if (
        transaction.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.PUBLISHED
      ) {
        dispatch.wallet.updateApiTokenBalances();
        return true;
      }
    }

    switch (transaction.status) {
      case MESSAGE_PROCESSOR_TRANSACTION_STATUS.SIGNED:
      case MESSAGE_PROCESSOR_TRANSACTION_STATUS.PUBLISHED:
      case MESSAGE_PROCESSOR_TRANSACTION_STATUS.MINED:
        dispatch.wallet.updateApiTokenBalances();

        if (
          transaction.functionId === FunctionId.OPEN_LONG_ID ||
          transaction.functionId === FunctionId.OPEN_SHORT_ID
        ) {
          dispatch.positions.getUsersOpenTrades();
          dispatch.activityStream.getRecentTrades();
        }

        if (transaction.functionId === FunctionId.CLOSE_TRADE_ID) {
          dispatch.positions.getUsersOpenTrades();
          dispatch.positions.getUsersClosedTrades();
          dispatch.activityStream.getRecentTrades();
        }

        if (transaction.functionId === FunctionId.LIQUIDATE_TRADE_ID) {
          dispatch.activityStream.getRecentTrades();
        }

        if (
          transaction.functionId === FunctionId.ADD_LIQUIDITY_ID ||
          transaction.functionId === FunctionId.REMOVE_LIQUIDITY_ID
        ) {
          dispatch.liquidity.getLiquidityHistory();
        }
        break;
    }

    return false;
  };
};

export const createLoadTransactions = (
  dispatch: FsDispatch,
  localStorage: LocalStorage,
  handleUpdateTransactionFn: ({
    id,
    functionId
  }: {
    id: string;
    functionId: number;
  }) => Promise<void>,
  timeFn: () => number
) => {
  return async () => {
    const json = localStorage.getItem("fs_withdrawals");
    if (json === null) {
      return;
    }

    let transactions;
    try {
      transactions = JSON.parse(json);
    } catch (e) {
      Logger.error("Can not parse withdrawal json", json);
      Sentry.captureException(e);
      localStorage.removeItem("fs_withdrawals");
      return;
    }

    transactions = transactions.filter(
      t => moment.utc(t.timestamp).add(1, "hour").valueOf() > timeFn()
    );

    // TODO(dankurka): Ensure data has correct structure
    for (const transaction of transactions) {
      // TODO(dankurka): Mark transaction as coming from storage
      await dispatch.messageProcessor.createTransaction(transaction);
      await dispatch.messageProcessor.updateTransaction(transaction);

      if (
        transaction.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.SENT ||
        transaction.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED ||
        transaction.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.SIGNED
      ) {
        handleUpdateTransactionFn({
          id: transaction.id,
          functionId: transaction.functionId
        });
      }
    }
  };
};

export interface LocalStorage {
  setItem: (id: string, value: string) => void;
  getItem: (id: string) => string | null;
  removeItem: (id: string) => void;
}

export const createSaveTransactions = (localStorage: LocalStorage) => {
  return async (
    _,
    { messageProcessor }: { messageProcessor: MessageProcessorState }
  ) => {
    const transactions = Array.from(
      messageProcessor.transactions.values()
    ).filter(
      t =>
        t.functionId === FunctionId.INSTANT_WITHDRAW_ID &&
        (t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.PUBLISHED ||
          t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.SENT ||
          t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED ||
          t.status === MESSAGE_PROCESSOR_TRANSACTION_STATUS.SIGNED)
    );

    const serializedTransactions = JSON.stringify(transactions);
    localStorage.setItem("fs_withdrawals", serializedTransactions);
  };
};

// TODO(dankurka): Share DTOs with server

export interface TransactionStatusDTO {
  id: string;
  functionId: number;
  status: string;
  // TODO(dankurka): Should always be present
  timestamp?: number;
  successMessage?: string;
  assetPrice?: string;
  stablePrice?: string;
  errorReason?: string;
  callData?: string;
  tokenAddress?: string;

  parentId?: string;
}

const MESSAGE_PROCESSOR_INITIAL_STATE = {
  transactions: new Map<string, TransactionStatusDTO>()
};

// Exported for tests only
export const createHandleUpdateTransactionStatus = (
  dispatch,
  sleepFn,
  dataLoader: DataLoader,
  transactionUpdaterFn: (id: string) => Promise<boolean>
) => {
  return async ({ id, functionId }: { id: string; functionId: number }) => {
    const getStatusFn = async () => {
      try {
        return await dataLoader.get<TransactionStatusDTO>(
          `/contractcall?id=${id}`
        );
      } catch (e) {
        Logger.error("Failed to load status: ", { e });
        // TODO(dankurka): Better constants
        return { data: { status: -1 } };
      }
    };

    let errorCount = 0;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const response = await getStatusFn();

      if (response.data.status === -1) {
        errorCount++;

        if (errorCount >= 10) {
          // TODO(dankurka): Layering violation, this layer of the app should not know about
          // ui actions. Update this to set a state variable that we use in other places.
          // TODO(dankurka): We need to be more specific for the user, what is the action
          // item that they are supposed to take
          const status: TransactionStatusDTO = {
            id,
            functionId,
            status: "error",
            errorReason: "Unable to obtain transaction status from server"
          };
          dispatch.messageProcessor.updateTransaction(status);
          return;
        } else {
          // retry in a few seconds
          await sleepFn(3000);
          continue;
        }
      }

      dispatch.messageProcessor.updateTransaction(
        response.data as TransactionStatusDTO
      );

      switch (response.data.status) {
        case MESSAGE_PROCESSOR_TRANSACTION_STATUS.SIGNED:
        case MESSAGE_PROCESSOR_TRANSACTION_STATUS.PUBLISHED:
          if (await transactionUpdaterFn(id)) {
            return;
          }
          await sleepFn(5000);
          break;
        case MESSAGE_PROCESSOR_TRANSACTION_STATUS.MINED:
          transactionUpdaterFn(id);
          return;
        case MESSAGE_PROCESSOR_TRANSACTION_STATUS.ERROR:
          transactionUpdaterFn(id);
          return;
        case MESSAGE_PROCESSOR_TRANSACTION_STATUS.RECEIVED:
          await sleepFn(2000);
          break;
        default:
          Logger.error("Should not get here", { response });
          throw new Error("Should not get here");
          return;
      }
    }
  };
};

export type MessageProcessorState = typeof MESSAGE_PROCESSOR_INITIAL_STATE;

export default {
  state: MESSAGE_PROCESSOR_INITIAL_STATE,
  reducers: {
    resetState: () => MESSAGE_PROCESSOR_INITIAL_STATE,
    createTransaction: (
      state: MessageProcessorState,
      {
        id,
        functionId,
        timestamp,
        successMessage,
        tokenAddress,
        parentId
      }: {
        id: string;
        functionId: number;
        timestamp: number;
        successMessage: string;
        tokenAddress?: string;
        parentId?: string;
      }
    ) => {
      const transactions = new Map(state.transactions);
      checkArgument(!transactions.has(id), "Id already present: " + id);
      const transaction: TransactionStatusDTO = {
        id,
        functionId,
        status: "new",
        timestamp,
        successMessage,
        tokenAddress,
        parentId
      };
      transactions.set(id, transaction);
      return {
        ...state,
        transactions
      };
    },
    updateTransaction: (
      state: MessageProcessorState,
      transactionData: TransactionStatusDTO
    ) => {
      const transactions = new Map(state.transactions);
      checkArgument(
        transactions.has(transactionData.id),
        "Id not found: " + transactionData.id
      );
      const oldTransaction = transactions.get(transactionData.id);
      transactions.set(transactionData.id, {
        ...oldTransaction,
        ...transactionData
      });

      return {
        ...state,
        transactions
      };
    },
    updateTransactionStatus: (
      state: MessageProcessorState,
      {
        id,
        status
      }: {
        id: string;
        status: string;
      }
    ) => {
      const transactions = new Map(state.transactions);
      checkArgument(transactions.has(id), "Id not found: " + id);
      const oldTransaction = checkDefined(transactions.get(id));
      transactions.set(id, {
        ...oldTransaction,
        status
      });

      return {
        ...state,
        transactions
      };
    }
  },
  effects: dispatch => {
    const dataLoader = new DataLoader();
    const userMessageEncoder = new UserMessageEncoder();
    const transactionUpdaterFn = createTransactionUpdater(dispatch);
    const handleUpdateTransactionStatusFn = createHandleUpdateTransactionStatus(
      dispatch,
      sleep,
      dataLoader,
      transactionUpdaterFn
    );

    const dispatchInteractionFn = createDispatchInteractionFn(
      dispatch,
      new SignatureNormalizer(),
      dataLoader,
      handleUpdateTransactionStatusFn
    );

    const timeFn = () => Date.now();

    const signerProvider = () => {
      return new providers.Web3Provider(
        getProvider().currentProvider as any
      ).getSigner();
    };

    const userInteractionNumberProviderFn = () => {
      return BigNumber.from(
        utils.hexStripZeros(utils.hexlify(utils.randomBytes(31)) + "01")
      );
    };

    const localSigner = () => (bytes: Uint8Array) =>
      signerProvider().signMessage(bytes);

    return {
      handleUpdateTransactionStatus: createHandleUpdateTransactionStatus(
        dispatch,
        sleep,
        dataLoader,
        transactionUpdaterFn
      ),
      handleOpenTrade: createOpenTrade(
        dispatch,
        userMessageEncoder,
        localSigner,
        dispatchInteractionFn,
        userInteractionNumberProviderFn,
        timeFn
      ),
      handleCloseTrade: createCloseTrade(
        dispatch,
        userMessageEncoder,
        localSigner,
        dispatchInteractionFn,
        userInteractionNumberProviderFn,
        timeFn
      ),
      handleAddCollateral: createAddCollateral(
        dispatch,
        userMessageEncoder,
        localSigner,
        dispatchInteractionFn,
        userInteractionNumberProviderFn,
        timeFn
      ),
      handleChangeLiquidity: createChangeLiquidity(
        dispatch,
        userMessageEncoder,
        localSigner,
        dispatchInteractionFn,
        userInteractionNumberProviderFn,
        timeFn
      ),
      handleInstantWithdrawal: createInstantWithdrawal(
        dispatch,
        userMessageEncoder,
        localSigner,
        dispatchInteractionFn,
        userInteractionNumberProviderFn,
        timeFn
      ),
      handleBalancePools: createBalancePools(
        dispatch,
        userMessageEncoder,
        localSigner,
        dispatchInteractionFn,
        userInteractionNumberProviderFn,
        timeFn
      ),
      loadTransactions: createLoadTransactions(
        dispatch,
        window.localStorage,
        handleUpdateTransactionStatusFn,
        timeFn
      ),
      saveTransactions: createSaveTransactions(window.localStorage),
      getTransaction: (id: string, { messageProcessor }) =>
        checkDefined(messageProcessor.transactions.get(id)),
      removeWithdraw: (id: string) => {
        dispatch.messageProcessor.updateTransactionStatus({
          id,
          status: MESSAGE_PROCESSOR_TRANSACTION_STATUS.MINED
        });
        dispatch.messageProcessor.saveTransactions();
      }
    };
  }
};

const sleep = async ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};
