import { BigNumber } from "ethers";
import { toWei } from "utils/numbers";

//TODO redo
const getIncentivesForWeek = (week, payout, multiplier, payoutEvents) => {
  const payoutEvent = payoutEvents.find(payout => payout.week === `${week}`);
  const unclaimedFst = BigNumber.from(payout)
    .mul(BigNumber.from(multiplier))
    .div(BigNumber.from(toWei("1")))
    .toString();
  const claimedFst = (payoutEvent && payoutEvent.fstPayedOut) || "0";
  const fstEarnings = unclaimedFst !== "0" ? unclaimedFst : claimedFst;
  const isClaimed = claimedFst !== "0";

  return { week, fstEarnings, isClaimed };
};

// selectTraderIncentives and selectReferralIncentives share the same logic.
// We should merge logic together and add a test when there's time
export const selectTraderIncentives = state => {
  const { traderPayout, events, traderWeeklyPendingPayouts } = state.incentives;

  const incentivesByWeek = traderWeeklyPendingPayouts.map(pendingPayout => {
    const payoutEventForWeek = events.traderPayouts.find(
      traderPayout => traderPayout.week === pendingPayout.week
    );
    const fstEarnings =
      pendingPayout.amount !== "0"
        ? pendingPayout.amount
        : payoutEventForWeek?.payout || "0";

    return {
      week: pendingPayout.week,
      fstEarnings,
      isClaimed: pendingPayout.amount === "0" && fstEarnings !== "0"
    };
  });

  return {
    incentivesByWeek,
    totalUnclaimedFst: traderPayout
  };
};

// see comment above on selectTraderIncentives
export const selectReferralIncentives = state => {
  const {
    referralPayout,
    referralWeeklyPendingPayouts,
    events
  } = state.incentives;
  let totalClaimedFst = BigNumber.from("0");

  events.referralPayouts.forEach(payoutEvent => {
    if (payoutEvent._target === state.wallet.accountAddress) {
      totalClaimedFst = totalClaimedFst.add(
        BigNumber.from(payoutEvent.totalPayout)
      );
    }
  });

  const incentivesByWeek = referralWeeklyPendingPayouts.map(pendingPayout => {
    const payoutEventForWeek = events.referralPayouts.find(
      traderPayout => traderPayout.week === pendingPayout.week
    );
    const fstEarnings =
      pendingPayout.amount !== "0"
        ? pendingPayout.amount
        : payoutEventForWeek?.payout || "0";

    return {
      week: pendingPayout.week,
      fstEarnings,
      isClaimed: pendingPayout.amount === "0" && fstEarnings !== "0"
    };
  });

  return {
    incentivesByWeek,
    totalUnclaimedFst: referralPayout,
    totalClaimedFst: totalClaimedFst.toString()
  };
};

export const selectLiquidityProviderIncentives = state => {
  const { liquidityProviderWeeklyPendingOrClaimedPayouts } = state.incentives;
  let totalClaimedFst = BigNumber.from("0");
  let totalUnclaimedFst = BigNumber.from("0");
  liquidityProviderWeeklyPendingOrClaimedPayouts.forEach(payoutEvent => {
    if (payoutEvent.hasPaidOut) {
      totalClaimedFst = totalClaimedFst.add(BigNumber.from(payoutEvent.payout));
    } else {
      totalUnclaimedFst = totalUnclaimedFst.add(
        BigNumber.from(payoutEvent.payout)
      );
    }
  });

  return {
    incentivesByWeek: [],
    accumulatedFst: totalUnclaimedFst.toString() // FST that's accumulating, but not paid out in the new distribution contract
  };
};

export const getHasUnclaimedIncentives = state => {
  const {
    traderPayout,
    referralPayout,
    liquidityProviderPayout
  } = state.incentives;
  return (
    !!Number(traderPayout) ||
    !!Number(referralPayout) ||
    !!Number(liquidityProviderPayout)
  );
};
