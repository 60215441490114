import styled from "@emotion/styled";
import {
  display,
  flexbox,
  palette,
  sizing,
  spacing,
  positions,
  borders,
  breakpoints,
  compose,
  typography,
  DisplayProps,
  FlexboxProps,
  PaletteProps,
  SizingProps,
  SpacingProps,
  PositionsProps,
  BordersProps,
  TypographyProps
} from "@material-ui/system";

// Uses styling primatives from material-ui system (different from material ui). This Box component 
// can be thought of as a replacement for div (in most cases). It allows for a constant simple way to
// handle responsiveness, spacing, borders, color, and more.
// More details about the api here: https://material-ui.com/system/basics/

export type BoxProps = DisplayProps &
FlexboxProps &
PaletteProps &
SizingProps &
SpacingProps &
PositionsProps &
BordersProps &
TypographyProps

const Box = styled.div<BoxProps>`
  ${breakpoints(
    compose(
      display,
      flexbox,
      palette,
      sizing,
      spacing,
      positions,
      borders,
      typography
    )
  )}
`;

export default Box;
