import * as React from "react";
import { display, spacing, breakpoints, compose } from "@material-ui/system";
import { Text, H4 } from "components/Typography";
import styled from "@emotion/styled";
import Box from "components/Box";
import Tooltip from "components/Tooltip";

type Props = {
  content?: any;
  isHeader?: any;
  width?: any;
  px?: any;
  py?: any;
  tooltip?: any;
};

const Cell: React.FC<Props> = ({
  content,
  isHeader,
  width,
  px,
  py,
  tooltip
}) => {
  return isHeader ? (
    <Th width={width} px={px} py={py}>
      <Box display="flex">
        <H4>{content}</H4>
        {tooltip && <Tooltip content={tooltip} />}
      </Box>
    </Th>
  ) : (
    <Td px={px} py={py}>
      <Text>{content}</Text>
    </Td>
  );
};

//https://material-ui.com/system/basics/
const Td = styled.td<{ px: any; py: any }>`
  ${breakpoints(compose(display, spacing))}
`;
const Th = styled.td<{ px: any; py: any }>`
  ${breakpoints(compose(display, spacing))}
`;

export default Cell;
