import React from "react";

import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { COLORS } from "styles/theme";
import { Text } from "components/Typography";
import { TokenProperties } from "redux/wallet/model";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  token: TokenProperties;
};

const WithdrawDisabled: React.FC<Props> = ({ token, isOpen, handleClose }) => {
  const renderActionButton = () => (
    <Box mt={2}>
      <Box>
        <Button
          color={COLORS.PURPLE}
          size="lg"
          onClick={handleClose}
          fluidWidth
        >
          Close
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      title={`Withdraw ${token.symbol}`}
      handleClose={handleClose}
    >
      <Box mb={2}>
        <Text fontSize="18px">
          You must first finish your pending withdraw before you can withdraw
          additional tokens
        </Text>
      </Box>
      {renderActionButton()}
    </Modal>
  );
};

export default WithdrawDisabled;
