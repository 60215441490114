import React from "react";
import moment from "moment";
import { fromWei } from "utils/numbers";
import Box from "components/Box";
import ListItem from "components/ListItem";
import styled from "@emotion/styled";

const ClosePositionDetails = ({ trade }) => {
  return (
    <Box display={{ xs: "block", md: "flex" }} width="100%">
      <Box display="flex" flex="1">
        <Box mr={4}>
          <ListItem label="CLOSED">
            {moment.unix(trade.creationTime).fromNow()}
          </ListItem>
        </Box>
        <Box mr={4}>
          <ListItem label="Collateral + P/L">
            {`$${(
              Number(fromWei(trade.collateral)) +
              Number(fromWei(trade.profitLoss.value))
            ).toFixed(2)}`}
          </ListItem>
        </Box>
        <Box mr={4}>
          <ListItem label={<Box display="flex">Trade Status</Box>}>
            {trade.state.status === "mined" ? "mined" : "mining"}
          </ListItem>
        </Box>
        <Box mr={4}>
          <ListItem label="Transaction Hash" id={trade.transactionHash}>
            {trade.transactionHash ? (
              <Truncate>
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={`https://etherscan.io/tx/${trade.transactionHash}`}
                >
                  {trade.transactionHash}
                </Link>
              </Truncate>
            ) : (
              "pending"
            )}
          </ListItem>
        </Box>
      </Box>
    </Box>
  );
};

export default ClosePositionDetails;

const Truncate = styled.div`
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Link = styled.a`
  color: white;
`;
