import React from "react";
import PropTypes from "prop-types";
import { spacing, breakpoints } from "@material-ui/system";

import Box from "./Box";
import { H4, MainMetrics } from "./Typography";
import styled from "@emotion/styled";

const ListItem = props => {
  const { label, children } = props;

  const renderValue = () =>
    typeof children === "string" || typeof children === "number" ? (
      <MainMetrics>{children}</MainMetrics>
    ) : (
      children
    );

  return (
    <Container {...props}>
      <H4>{label}</H4>
      <Box mt={props.childrenMt}>{renderValue()}</Box>
    </Container>
  );
};

ListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  childrenMt: PropTypes.number
};

ListItem.defaultProps = {
  childrenMt: 1
};

//https://material-ui.com/system/basics/
const Container = styled.div`
  ${breakpoints(spacing)}
  ${spacing}
`;

export default ListItem;
