import getProvider from "provider";

const abi = [
  {
    constant: true,
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "schedules",
    outputs: [
      {
        internalType: "uint256",
        name: "startTimeInSec",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "cliffTimeInSec",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "endTimeInSec",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "totalAmount",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "totalAmountWithdrawn",
        type: "uint256"
      },
      {
        internalType: "bool",
        name: "isConfirmed",
        type: "bool"
      }
    ],
    payable: false,
    stateMutability: "view",
    type: "function"
  },
  {
    constant: false,
    inputs: [],
    name: "withdraw",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
  }
];

export const getVestingContractInstance = address =>
  new (getProvider().eth.Contract)(abi, address);
