import React from "react";
import NavBar from "components/NavBar";
import Container from "components/Container";

const Pool = props => {
  return (
    <>
      <NavBar />
      <Container maxWidth="md">{props.children}</Container>
    </>
  );
};
export default Pool;
