import {
  getIncentivesData,
  getIncentivesEventsHistory,
  getAmountToSendAll,
  getTraderWeeklyPendingPayouts,
  getReferralWeeklyPendingPayouts,
  getLiquidityProviderWeeklyPendingOrClaimedPayouts,
  getFstLpTokenData,
  getFstPriceUsd
} from "./actions";
import { getStakingContractAddresses } from "../../constants";
import { getStakingInstance } from "contracts/Staking";
import { getIncentivesInstance } from "contracts/Incentives";
import { getNewIncentivesInstance } from "contracts/LpIncentives";
import { FsRootState } from "redux/store";

const ADDRESS_FOR_NEW_LP_INCENTIVES =
  "0xE852784f8DEa8cB1B07E8CC00939D8312DC2D468";

interface EpochPayoutEntry {
  week: string;
  amount: string;
}

export interface LpPayoutWeek {
  week: number;
  hasPaidOut: boolean;
  payout: string;
}

const INCENTIVES_INITIAL_STATE = {
  lastUpdateTimestamp: undefined,
  timeInterval: undefined,
  weekCount: undefined,
  traderPayout: "0",
  referralPayout: "0",
  liquidityProviderPayout: "0",
  newLiquidityProviderPayout: "0",
  traderWeeklyPendingPayouts: [] as EpochPayoutEntry[],
  referralWeeklyPendingPayouts: [],
  liquidityProviderWeeklyPendingOrClaimedPayouts: [] as LpPayoutWeek[],
  fstPriceUsd: 0,
  uniswapLp: {
    usersStakedLpTokens: "0",
    usersEarnedRewards: "0",
    periodFinish: "0",
    rewardPerTokenStored: "0",
    rewardRate: "0",
    rewardsDuration: "0",
    totalSupply: "0",
    lpTokenGlobal: {
      reserveUSD: "0",
      totalSupply: "0"
    }
  },
  events: {
    traderPayouts: [],
    referralPayouts: [],
    liquidityProviderPayouts: []
  },
  requests: {
    isClaimingTraderFst: false,
    isClaimingReferralFst: false,
    isClaimingLiquidityFst: false,
    isClaimingNewLiquidityFst: false,
    isClaimingStakingFst: false,
    isEnteringStakingPool: false,
    isExitingStakingPool: false,
    isAdvancingWeek: false
  }
};

export type IncentivesModel = typeof INCENTIVES_INITIAL_STATE;

export default {
  state: INCENTIVES_INITIAL_STATE,
  reducers: {
    setIncentivesData: (state, object) => ({
      ...state,
      ...object
    }),
    setUniswapLpData: (state, object) => ({
      ...state,
      uniswapLp: { ...state.uniswapLp, ...object }
    }),
    setEventsData: (state, object) => ({
      ...state,
      ...object
    }),
    setRequestsValues: (state, values = {}) => {
      return {
        ...state,
        requests: { ...state.requests, ...values }
      };
    }
  },
  effects: dispatch => {
    return {
      async initializeIncentivesData(_, { registry, wallet }: FsRootState) {
        const { incentivesAddress } = registry;
        const {
          weekCount,
          lastUpdateTimestamp,
          timeInterval
        } = await getIncentivesData(incentivesAddress);
        dispatch.incentives.handleGetUniswapStakingData();
        const newIncentivesInstance = getNewIncentivesInstance(
          ADDRESS_FOR_NEW_LP_INCENTIVES
        );
        const [
          events,
          { traderPayout, referralPayout, liquidityProviderPayout }
        ] = await Promise.all([
          getIncentivesEventsHistory(incentivesAddress, wallet.accountAddress),
          getAmountToSendAll(
            incentivesAddress,
            wallet.accountAddress,
            weekCount
          )
        ]);

        const [
          traderWeeklyPendingPayouts,
          referralWeeklyPendingPayouts,
          liquidityProviderWeeklyPendingOrClaimedPayouts,
          newLiquidityProviderPayout,
          fstPriceUsd
        ] = await Promise.all([
          getTraderWeeklyPendingPayouts(
            incentivesAddress,
            wallet.accountAddress,
            weekCount
          ),
          getReferralWeeklyPendingPayouts(
            incentivesAddress,
            wallet.accountAddress,
            weekCount
          ),
          getLiquidityProviderWeeklyPendingOrClaimedPayouts(
            incentivesAddress,
            registry.exchange.address,
            wallet.accountAddress,
            weekCount
          ),
          newIncentivesInstance.methods.balanceOf(wallet.accountAddress).call(),
          getFstPriceUsd()
        ]);

        dispatch.incentives.setIncentivesData({
          weekCount,
          lastUpdateTimestamp,
          timeInterval,
          traderPayout,
          traderWeeklyPendingPayouts,
          referralWeeklyPendingPayouts,
          liquidityProviderWeeklyPendingOrClaimedPayouts,
          referralPayout,
          liquidityProviderPayout,
          newLiquidityProviderPayout,
          fstPriceUsd
        });
        dispatch.incentives.setEventsData({
          events
        });
      },
      async setRewardsWeek(_, { registry, wallet }) {
        const { incentivesAddress } = registry;

        // TODO(dankurka): unhandled error
        try {
          const {
            weekCount,
            lastUpdateTimestamp,
            timeInterval
          } = await getIncentivesData(incentivesAddress);
          dispatch.incentives.setIncentivesData({
            weekCount,
            lastUpdateTimestamp,
            timeInterval
          });
        } catch (e) {
          // TODO(dankurka): Handle error
        }
      },
      async handlePayoutTrader(
        _,
        { registry, wallet, incentives }: FsRootState
      ) {
        const { incentivesAddress } = registry;
        const incentivesInstance = getIncentivesInstance(incentivesAddress);

        const arrayOfWeeks = incentives.traderWeeklyPendingPayouts
          .filter(e => e.amount !== "0")
          .map(e => e.week);

        await incentivesInstance.methods
          .payoutTrader(wallet.accountAddress, arrayOfWeeks)
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isClaimingTraderFst: true
            });
          });
        await Promise.all([
          dispatch.incentives.initializeIncentivesData(),
          dispatch.wallet.updateMetamaskBalance(registry.fsTokenAddress)
        ]);
        dispatch.incentives.setRequestsValues({
          isClaimingTraderFst: false
        });
      },
      async handlePayoutReferral(_, { registry, wallet, incentives }) {
        const { incentivesAddress } = registry;
        const incentivesInstance = getIncentivesInstance(incentivesAddress);
        const arrayOfWeeks = [
          ...Array(Number(incentives.weekCount)).keys()
        ].map(int => int.toString());
        await incentivesInstance.methods
          .payoutReferral(wallet.accountAddress, arrayOfWeeks)
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isClaimingReferralFst: true
            });
          });
        await Promise.all([
          dispatch.incentives.initializeIncentivesData(),
          dispatch.wallet.updateMetamaskBalance(registry.fsTokenAddress)
        ]);
        dispatch.incentives.setRequestsValues({
          isClaimingReferralFst: false
        });
      },
      async handlePayoutLiquidityProvider(
        _,
        { registry, wallet, incentives }: FsRootState
      ) {
        const { incentivesAddress } = registry;

        const claimableWeeks = incentives.liquidityProviderWeeklyPendingOrClaimedPayouts
          .filter(w => !w.hasPaidOut && w.payout !== "0")
          .map(w => w.week);

        const incentivesInstance = getIncentivesInstance(incentivesAddress);

        await incentivesInstance.methods
          .payoutLiquidityProvider(wallet.accountAddress, claimableWeeks)
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isClaimingLiquidityFst: true
            });
          });
        await Promise.all([
          dispatch.incentives.initializeIncentivesData(),
          dispatch.wallet.updateMetamaskBalance(registry.fsTokenAddress)
        ]);
        dispatch.incentives.setRequestsValues({
          isClaimingLiquidityFst: false
        });
      },
      async handlePayoutLiquidityProviderNew(
        _,
        { registry, wallet }: FsRootState
      ) {
        const newIncentivesInstance = getNewIncentivesInstance(
          ADDRESS_FOR_NEW_LP_INCENTIVES
        );

        await newIncentivesInstance.methods
          .claim(wallet.accountAddress)
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isClaimingNewLiquidityFst: true
            });
          });
        await Promise.all([
          dispatch.incentives.initializeIncentivesData(),
          dispatch.wallet.updateMetamaskBalance(registry.fsTokenAddress)
        ]);
        dispatch.incentives.setRequestsValues({
          isClaimingLiquidityFst: false
        });
      },
      async advanceWeek(_, { registry, wallet }) {
        const { incentivesAddress } = registry;
        const incentivesInstance = getIncentivesInstance(incentivesAddress);

        await incentivesInstance.methods
          .advanceEpoch()
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isAdvancingWeek: true
            });
          })
          .on("confirmation", async id => {
            if (id === 0) {
              await dispatch.incentives.initializeIncentivesData();
              dispatch.incentives.setRequestsValues({
                isAdvancingWeek: false
              });
            }
          });
      },
      async handleGetUniswapStakingData(_, { wallet }) {
        const {
          stakingRewardsContract,
          uniswapLpToken
        } = getStakingContractAddresses(wallet.network);
        dispatch.wallet.updateMetamaskBalance(uniswapLpToken);
        const stakingInstance = getStakingInstance(stakingRewardsContract);
        const usersStakedLpTokens = await stakingInstance.methods
          .balanceOf(wallet.accountAddress)
          .call();
        const usersEarnedRewards = await stakingInstance.methods
          .earned(wallet.accountAddress)
          .call();
        const rewardRate = await stakingInstance.methods.rewardRate().call();
        const rewardsDuration = await stakingInstance.methods
          .rewardsDuration()
          .call();
        const periodFinish = await stakingInstance.methods
          .periodFinish()
          .call();
        const rewardPerTokenStored = await stakingInstance.methods
          .rewardPerTokenStored()
          .call();
        const totalSupply = await stakingInstance.methods.totalSupply().call();

        const lpTokenGlobal = await getFstLpTokenData(); // total staked or unstaked supply / usd values
        dispatch.incentives.setUniswapLpData({
          usersStakedLpTokens,
          usersEarnedRewards,
          rewardRate,
          rewardsDuration,
          periodFinish,
          rewardPerTokenStored,
          totalSupply,
          lpTokenGlobal
        });
      },
      async handleGetEarnedRewards(_, { wallet }) {
        const { stakingRewardsContract } = getStakingContractAddresses(
          wallet.network
        );
        const stakingInstance = getStakingInstance(stakingRewardsContract);
        const usersEarnedRewards = await stakingInstance.methods
          .earned(wallet.accountAddress)
          .call();
        dispatch.incentives.setUniswapLpData({ usersEarnedRewards });
      },
      async updateFstPriceUsd(_) {
        const fstPriceUsd = await getFstPriceUsd();
        dispatch.incentives.setIncentivesData({
          fstPriceUsd
        });
      },
      async stakeLpTokens(_, { wallet }) {
        const {
          stakingRewardsContract,
          uniswapLpToken
        } = getStakingContractAddresses(wallet.network);
        const stakingInstance = getStakingInstance(stakingRewardsContract);
        const lpTokenWalletBalance =
          wallet.tokens[uniswapLpToken]?.localBalance;

        await stakingInstance.methods
          .stake(lpTokenWalletBalance)
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isEnteringStakingPool: true
            });
          })
          .on("confirmation", async (confirmationNumber: number) => {
            if (confirmationNumber === 0) {
              await dispatch.incentives.handleGetUniswapStakingData();
              dispatch.incentives.setRequestsValues({
                isEnteringStakingPool: false
              });
            }
          });
      },
      async claimStakingFst(_, { wallet }) {
        const { stakingRewardsContract } = getStakingContractAddresses(
          wallet.network
        );
        const stakingInstance = getStakingInstance(stakingRewardsContract);

        await stakingInstance.methods
          .getReward()
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isClaimingStakingFst: true
            });
          })
          .on("confirmation", async (confirmationNumber: number) => {
            if (confirmationNumber === 0) {
              await dispatch.incentives.handleGetUniswapStakingData();
              dispatch.incentives.setRequestsValues({
                isClaimingStakingFst: false
              });
            }
          });
      },
      async exitStakingPool(_, { wallet }) {
        const { stakingRewardsContract } = getStakingContractAddresses(
          wallet.network
        );
        const stakingInstance = getStakingInstance(stakingRewardsContract);
        //exit runs the following
        // withdraw(_balances[msg.sender]);
        // getReward();
        await stakingInstance.methods
          .exit()
          .send({ from: wallet.accountAddress })
          .on("transactionHash", () => {
            dispatch.incentives.setRequestsValues({
              isExitingStakingPool: true
            });
          })
          .on("confirmation", async (confirmationNumber: number) => {
            if (confirmationNumber === 0) {
              await dispatch.incentives.handleGetUniswapStakingData();
              dispatch.incentives.setRequestsValues({
                isExitingStakingPool: false
              });
            }
          });
      }
    };
  }
};
