import React from "react";
import { fromWei } from "utils/numbers";
import { useSelector } from "react-redux";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { formatNumber } from "utils/formatter";
import { Text } from "components/Typography";
import Box from "components/Box";

const PricesAndPoolShare = () => {
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );

  const assetPerStable = 1 / fromWei(assetTokenProperties.indexPrice);
  const stablePerAsset = fromWei(assetTokenProperties.indexPrice);

  const renderSection = (label, value) => (
    <Box flex="1" alignContent="center" textAlign="center">
      <Box mb={0.25}>
        <Text>{value}</Text>
      </Box>
      <Text fontSize="14px" color="#aaa" textAlign="center">
        {label}
      </Text>
    </Box>
  );
  return (
    <>
      <Box mb={0.25}>
        <Text color="#aaa">Token prices</Text>
      </Box>
      <Box display="flex" border="1px solid #343856" borderRadius="8px" p={1.5}>
        {renderSection(
          `${stableTokenProperties.symbol} per ${assetTokenProperties.symbol}`,
          formatNumber(stablePerAsset, 4)
        )}
        {renderSection(
          `${assetTokenProperties.symbol} per ${stableTokenProperties.symbol}`,
          formatNumber(assetPerStable, 4)
        )}
      </Box>
    </>
  );
};

export default PricesAndPoolShare;
