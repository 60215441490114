import React, { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import Box from "components/Box";
import NavBar from "components/NavBar";
import { Text, H2 } from "components/Typography";
import { getWalletInstance } from "contracts/Wallet";
import { FsRootState } from "redux/store";
import Button from "components/Button";
import { RegistryModel } from "redux/registry/model";
import { WalletModel } from "redux/wallet/model";
import * as Sentry from "@sentry/browser";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import getProvider from "../../provider";
import Logger from "logger/Logger";

const ContractWithdraw = () => {
  const [assetBalance, setAssetBalance] = useState("");
  const [stableBalance, setStableBalance] = useState("");
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [waitTime, setWaitTime] = useState(0);
  const [displayRequest, setDisplayRequest] = useState(false);
  const { fsWalletAddress, exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );

  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { assetToken, stableToken } = exchange;
  const walletInstance = getWalletInstance(fsWalletAddress);

  const getTimeByBlock = async (blockNumber: string): Promise<number> => {
    const blockData = await getProvider().eth.getBlock(blockNumber);
    return blockData.timestamp;
  };

  const loadEvents = async () => {
    try {
      const [requests, withdraws] = await Promise.all([
        walletInstance.getPastEvents("WithdrawalRequest", {
          filter: { userAddress: accountAddress },
          fromBlock: 0,
          toBlock: "latest"
        }),
        walletInstance.getPastEvents("Withdrawal", {
          filter: { userAddress: accountAddress },
          fromBlock: 0,
          toBlock: "latest"
        })
      ]);

      let lastRequestTime = -1;
      if (requests.length > 0) {
        lastRequestTime = await getTimeByBlock(
          requests[requests.length - 1].blockNumber
        );
      }

      let lastWithdraw = -1;

      if (withdraws.length > 0) {
        lastWithdraw = await getTimeByBlock(
          withdraws[withdraws.length - 1].blockNumber
        );
      }

      if (lastWithdraw > lastRequestTime) {
        // setHasNoWithdraw
        setDisplayRequest(true);
        return;
      } else if (lastRequestTime !== -1) {
        const blockTime = moment.utc(lastRequestTime * 1000);
        const now = moment();
        if (now.diff(blockTime, "hours") > 2) {
          setWaitTime(0);
        } else {
          setWaitTime(60 * 60 - now.diff(blockTime, "seconds"));
        }
        setDisplayRequest(false);
      } else {
        setDisplayRequest(true);
      }
    } catch (e) {
      Logger.error(e);
      Logger.error("Error sending transaction");
      Sentry.captureException(e);
    }
  };

  const loadBalance = async () => {
    Logger.info("Loading balance");
    try {
      const [assetBalance, stableBalance] = await Promise.all([
        walletInstance.methods.balanceOf(assetToken, accountAddress).call(),
        walletInstance.methods.balanceOf(stableToken, accountAddress).call()
      ]);

      setAssetBalance(assetBalance);
      setStableBalance(stableBalance);
    } catch (e) {
      Logger.error(e);
      Logger.error("Error sending transaction");
      Sentry.captureException(e);
      // TODO(dankurka): Handle
    }
  };

  useEffect(() => {
    if (accountAddress) {
      loadBalance();
      loadEvents();
    }
  }, [accountAddress]);

  const renderLabelValue = (label, value) => {
    return (
      <>
        <Text fontSize="16px" color="#aaa">
          {label}:
        </Text>{" "}
        <Text fontSize="16px">{value}</Text>
      </>
    );
  };

  const beginWithdraw = async event => {
    Logger.info("Requesting withdraw for token pair");
    try {
      setIsWithdrawing(true);
      await walletInstance.methods
        .requestWithdrawForTokenPair(
          assetToken,
          assetBalance,
          stableToken,
          stableBalance
        )
        .send({ from: accountAddress });

      loadEvents();
    } catch (e) {
      Logger.error(e);
      Logger.error("Error sending transaction");
    }
    setIsWithdrawing(false);
  };

  const finishWithdraw = async () => {
    Logger.info("Finishing withdraw");
    try {
      setIsWithdrawing(true);
      await walletInstance.methods
        .withdrawForTokenPair(assetToken, stableToken)
        .send({ from: accountAddress });

      loadEvents();
    } catch (e) {
      Logger.error(e);
      Logger.error("Error sending transaction");
    }
    setIsWithdrawing(false);
  };

  const renderBeginWithdraw = () => (
    <Button
      fluidWidth
      isLoading={isWithdrawing}
      onClick={beginWithdraw}
      color={COLORS.PURPLE}
      size="lg"
    >
      Begin withdraw
    </Button>
  );

  const renderFinishWithdraw = () => (
    <Button
      fluidWidth
      disabled={waitTime > 0}
      isLoading={isWithdrawing}
      onClick={finishWithdraw}
      color={COLORS.PURPLE}
      size="lg"
    >
      Finish withdraw
    </Button>
  );

  const renderWaitForWithdraw = () => {
    const duration = moment.duration(waitTime, "seconds");
    const wait =
      duration.hours() +
      "h:" +
      duration.minutes() +
      "m:" +
      duration.seconds() +
      "s";

    return renderLabelValue("Complete withdraw in", wait);
  };

  return (
    <>
      <NavBar />

      <Contianer px={2} my={6}>
        <Box mb={3}>
          <H2>Withdraw from your Futureswap wallet</H2>
        </Box>
        <Box mb={3}>
          <Text fontSize="15px" color="#ddd">
            This flow allows you to withdraw from the smart contract. Liquidity
            cannot be automatically moved to V4. Withdrawing requires 2
            transactions:
          </Text>
          <ol>
            <li>
              <Text fontSize="15px" color="#ddd">
                Request a withdraw and then wait for one hour(this is due to the
                exchange being closed).
              </Text>
            </li>
            <li>
              <Text fontSize="15px" color="#ddd">
                Send a second transaction, this will transfer the funds to you.
              </Text>
            </li>
          </ol>
        </Box>

        <Box mb={2}>
          {renderLabelValue(
            "Asset to withdraw",
            `${formatNumber(fromWei(assetBalance), 2)} ETH`
          )}
        </Box>
        <Box mb={2}>
          {renderLabelValue(
            "Stable to withdraw",
            `${formatNumber(fromWei(stableBalance, 6), 2)} USDC`
          )}
        </Box>

        {waitTime > 0 && <Box mb={2}>{renderWaitForWithdraw()}</Box>}
        {waitTime <= 0 && !displayRequest ? renderFinishWithdraw() : undefined}
        {displayRequest && renderBeginWithdraw()}
      </Contianer>
    </>
  );
};

const Contianer = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  width: 100%;
`;

export default ContractWithdraw;
