import { API_ENDPOINT_TYPES } from "../../constants";

// only select 500 errors and some 400 errors
// TODO(dankurka): Type
export const selectHasApiError = (state: any) => {
  return (
    state.api.errors.some(error => error.code >= 500 && error.code < 600) ||
    state.api.errors.some(
      error =>
        error.code === 400 && error.type === API_ENDPOINT_TYPES.CONTRACT_CALL
    )
  );
};

// https://stackoverflow.com/a/56757215 find unique objects in array
// TODO(dankurka): Type
export const selectUniqueErrors = (state: any) =>
  state.api.errors.filter(
    (v, i, a) => a.findIndex(t => t.type === v.type) === i
  );
