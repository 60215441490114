import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { COLORS } from "styles/theme";
import { Text } from "components/Typography";
import getProvider from "../../provider";
import { selectTransactionById } from "redux/messageProcessor/selectors";
import { FsDispatch, FsRootState } from "redux/store";
import { TokenProperties, WalletModel } from "redux/wallet/model";
import { RegistryModel } from "redux/registry/model";

const sleep = async ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  token: TokenProperties;
  transactionId: string;
};

const WithdrawStepTwo: React.FC<Props> = ({
  isOpen,
  handleClose,
  token,
  transactionId
}) => {
  const dispatch = useDispatch<FsDispatch>();
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { messageProcessorAddress } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const transaction = useSelector(selectTransactionById(transactionId));

  const [isWithdrawing, setIsWithdrawing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      handleSendTransaction();
    }
  }, []);

  const checkRequestStatus = async txHash => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // TODO(dankurka): Unhandled error here, this will make users stuck in the
      // with draw flow
      const transactionReceipt = await getProvider().eth.getTransactionReceipt(
        txHash
      );

      if (transactionReceipt) {
        setIsWithdrawing(false);
        const hasTransactionSucceeded = transactionReceipt.status === true;
        // Only checking for success here will get the transaction stuck
        if (hasTransactionSucceeded) {
          // TODO(dankurka): This does not remove the TX from the store if it was not successful
          // This makes users stuck if they have a bad tx
          dispatch.messageProcessor.removeWithdraw(transactionId);
          handleClose();
        }
        return;
      } else {
        await sleep(3000);
        await checkRequestStatus(txHash);
      }
    }
  };

  const handleSendTransaction = async () => {
    if (transaction === undefined) {
      // TODO(dankurka): Handle
      return;
    }
    const transactionParameters = {
      to: messageProcessorAddress,
      from: accountAddress, // must match user's active address.
      data: transaction.callData
    };

    try {
      // txHash is a hex string. As with any RPC call, it may throw an error
      // TODO(dankurka): Unhandled error
      const txHash = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters]
      });
      setIsWithdrawing(true);
      checkRequestStatus(txHash);
    } catch (e) {
      console.log(e);
      setIsWithdrawing(false);
    }
  };

  const renderActionButton = () => (
    <Box mt={2}>
      <Box>
        <Button
          color={COLORS.PURPLE}
          size="lg"
          onClick={handleSendTransaction}
          fluidWidth
          isLoading={isWithdrawing}
        >
          Withdraw {token.symbol}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      isOpen={isOpen}
      title={`Withdraw ${token.symbol}`}
      handleClose={handleClose}
    >
      <Box mb={2}>
        <Text fontSize="18px">Send transaction to complete withdraw</Text>
      </Box>
      {renderActionButton()}
    </Modal>
  );
};

export default WithdrawStepTwo;
