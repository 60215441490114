import { DataLoader } from "data/DataLoader";
import Logger from "logger/Logger";
import { RegistryModel } from "redux/registry/model";
import { FsDispatch } from "redux/store";

// TODO(dankurka): Use shared type
export interface TradeEntry {
  volume: string;
  isLong: boolean;
  isClose: boolean;
  isLiquidation: boolean;
  timestamp: number;
  positionSize: string;
}

// TODO(dankurka): Use shared type
export interface GetTradesResponse {
  trades: TradeEntry[];
}

const ACTIVITY_STREAM_INITIAL_STATE = {
  recentPositions: [] as TradeEntry[],
  isInitialized: false,
  errorLoadingData: false
};

export type ActivityModel = typeof ACTIVITY_STREAM_INITIAL_STATE;

export const createLoadTrades = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  return async (_, { registry }: { registry: RegistryModel }) => {
    const { exchange } = registry;
    try {
      const { data } = await dataLoader.get<GetTradesResponse>(
        `/exchange/${exchange.address}/alltrades/`
      );
      dispatch.activityStream.setAllTrades(data.trades);
      dispatch.activityStream.setIsInitialized(true);
      dispatch.activityStream.setErrorLoadingData(false);
    } catch (e) {
      Logger.error("Error loading trade data", { e });
      dispatch.activityStream.setErrorLoadingData(false);
    }
  };
};

export default {
  state: ACTIVITY_STREAM_INITIAL_STATE,
  reducers: {
    setAllTrades: (state: ActivityModel, trades: TradeEntry[]) => ({
      ...state,
      recentPositions: trades
    }),
    setIsInitialized: (state, isInitialized: boolean) => ({
      ...state,
      isInitialized
    }),
    setErrorLoadingData: (state, errorLoadingData: boolean) => ({
      ...state,
      errorLoadingData
    })
  },

  effects: dispatch => {
    return {
      // TODO rename
      getRecentTrades: createLoadTrades(dispatch, new DataLoader())
    };
  }
};
