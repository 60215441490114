import React from "react";
import { fromWei } from "utils/numbers";
import { useSelector } from "react-redux";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { formatNumber } from "utils/formatter";
import { Text } from "components/Typography";
import Box from "components/Box";

import HorizontalList from "pages/Rewards/HorizontalList";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";

const Stats = () => {
  const { liquidity } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const renderYourBalance = () => {
    const assetAmountInUsd =
      fromWei(liquidity.assetOwned) * fromWei(assetTokenProperties.indexPrice);
    const stableAmountInUsd =
      fromWei(liquidity.stableOwned) * fromWei(stableTokenProperties.price);
    const poolBalanceInUsd = assetAmountInUsd + stableAmountInUsd;

    return (
      <>
        <Box mb={1}>
          <Text fontSize="15px">Your Liquidity Pool balance</Text>
        </Box>
        <Box mb={1}>
          <HorizontalList
            label={`Asset (${assetTokenProperties.symbol})`}
            value={formatNumber(fromWei(liquidity.assetOwned), 2)}
          />
        </Box>
        <Box mb={1}>
          <HorizontalList
            label={`Stable (${stableTokenProperties.symbol})`}
            value={formatNumber(fromWei(liquidity.stableOwned), 2)}
          />
        </Box>
        <Box>
          <HorizontalList
            label="Balance (USD)"
            value={`$${formatNumber(poolBalanceInUsd, 2)}`}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Box>{renderYourBalance()}</Box>
    </>
  );
};

export default Stats;
