import Logger from "logger/Logger";
import getProvider from "provider";

export interface SystemAddresses {
  exchangeFactoryAddress: string;
  fsTokenAddress: string;
  fsTokenProxyAdminAddress: string;
  walletAddress: string;
  incentivesAddress: string;
  liquidityTokenFactoryAddress: string;
  messageProcessorAddress: string;
  votingAddress: string;
  // TODO why is this here and usdc and lt is not?
  wethAddress: string;
  usdcAddress: string;

  exchangeAddress: string;
  registryHolderAddress;
}

const MAIN_ADDRESSES: SystemAddresses = {
  exchangeFactoryAddress: "0xb68023fa5ad90201caf09bd6438d7312cd907853",
  fsTokenAddress: "0x0e192d382a36de7011f795acc4391cd302003606",
  fsTokenProxyAdminAddress: "0xd547bd7ebcda779f611192b2aae25c5cb6c7e497",
  walletAddress: "0xd1ed35a3ee043683a1833509de8f2c1a0d8777b7",
  incentivesAddress: "0x4ea5424c393869505cbd7f8d802595328aab89fe",
  liquidityTokenFactoryAddress: "0xbba3089878fc07cdbb113da5012a0c7982aab821",
  messageProcessorAddress: "0xdeeb86214606ccd4ccc908c302466485c6643ffa",
  votingAddress: "0xef9d095cd04e4e8240771b5fdcb8f2f74d0815ff",
  // TODO why is this here and usdc and lt is not?
  wethAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  usdcAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",

  exchangeAddress: "0x8fa7490cedb7207281a5ceabee12773046de664e",
  registryHolderAddress: "0xd9cf4ca71d2ed15040cc702c2b146bfb848b7a1b"
};

const KOVAN_ADDRESSES: SystemAddresses = {
  exchangeFactoryAddress: "0xb34af01b7086997dcef5b1b623120e0b5084bdea",
  fsTokenAddress: "0x244a8c339c20a5a686cef1eab39d11249c909b5f",
  fsTokenProxyAdminAddress: "0xa0338708917d3ccc6ae7f04bb6af186c74010353",
  walletAddress: "0x6aa9b2bf2a846e41954f3afda4800bc623b1bd28",
  incentivesAddress: "0xa29f1c70233acdc289560de4cd51d34da42f6532",
  liquidityTokenFactoryAddress: "0x96d7b5ca161f18c1d794931397f121cae09daf7b",
  messageProcessorAddress: "0x1ddcf86a0dc391df373d4203edd3af002f247a4d",
  votingAddress: "0x6ebe98ed9ed9b9b6a5b7ce29507977c11c21cc77",
  // TODO why is this here and usdc and lt is not?
  wethAddress: "0x208fc7c199f53ac5e6c62c8021593073f84316d4",
  usdcAddress: "0x20a355db06eff6e44841eae9edabcfadb5cb2fd5",
  exchangeAddress: "0x063590c1513f8d911228e3fc18e45e7d9509d77a",
  registryHolderAddress: "0x4962b90e6a945bc9bb5b782bc0c9f3822506d175"
};

// TODO(dankurka): Types should not contain undefined here
const EXCHANGES_INITIAL_STATE = {
  factoryAddress: MAIN_ADDRESSES.exchangeFactoryAddress,
  fsTokenAddress: MAIN_ADDRESSES.fsTokenAddress,
  fsTokenProxyAdminAddress: MAIN_ADDRESSES.fsTokenProxyAdminAddress,
  fsWalletAddress: MAIN_ADDRESSES.walletAddress,
  incentivesAddress: MAIN_ADDRESSES.incentivesAddress,
  liquidityTokenFactoryAddress: MAIN_ADDRESSES.liquidityTokenFactoryAddress,
  messageProcessorAddress: MAIN_ADDRESSES.messageProcessorAddress,
  votingAddress: MAIN_ADDRESSES.votingAddress,
  wethAddress: MAIN_ADDRESSES.wethAddress,
  usdcAddress: MAIN_ADDRESSES.usdcAddress,
  registryHolderAddress: MAIN_ADDRESSES.registryHolderAddress,
  // TODO(dankurka): Remove
  // requests: {
  //   isInitialized: false
  // }

  exchange: {
    address: MAIN_ADDRESSES.exchangeAddress,
    assetSymbol: "ETH",
    stableSymbol: "USDC",
    assetToken: MAIN_ADDRESSES.wethAddress,
    stableToken: MAIN_ADDRESSES.usdcAddress
  },

  isInitialized: false
};

export interface ExchangeDetails {
  address: string;
  assetSymbol: string;
  stableSymbol: string;
  assetToken: string;
  stableToken: string;
}

export type RegistryModel = typeof EXCHANGES_INITIAL_STATE;

export default {
  state: EXCHANGES_INITIAL_STATE,
  reducers: {
    resetState: () => EXCHANGES_INITIAL_STATE,
    setExchange: (state: RegistryModel, exchange: ExchangeDetails) => ({
      ...state,
      exchange
    }),
    setBaseAddresses: (state: RegistryModel, addresses: SystemAddresses) => {
      return {
        ...state,
        // TODO(dankurka): Use address type once components expect it
        factoryAddress: addresses.exchangeFactoryAddress,
        fsTokenAddress: addresses.fsTokenAddress,
        fsTokenProxyAdminAddress: addresses.fsTokenProxyAdminAddress,
        fsWalletAddress: addresses.walletAddress,
        incentivesAddress: addresses.incentivesAddress,
        liquidityTokenFactoryAddress: addresses.liquidityTokenFactoryAddress,
        messageProcessorAddress: addresses.messageProcessorAddress,
        votingAddress: addresses.votingAddress,
        wethAddress: addresses.wethAddress,
        walletAddress: addresses.walletAddress,
        registryHolderAddress: addresses.registryHolderAddress,
        usdcAddress: addresses.usdcAddress
      };
    },
    setInitialized: (state: RegistryModel) => {
      return {
        ...state,
        isInitialized: true
      };
    }
  },
  effects: dispatch => {
    return {
      initialize: async () => {
        let network;
        try {
          network = await getProvider().eth.net.getNetworkType();
        } catch (e) {
          Logger.error("Can not detect network", { e });
          dispatch.registry.setFailedToInitialize(
            true,
            "Can not detect network"
          );
          return;
        }

        // TODO(dankurka): We need an error for networks that are not kovan / main
        const addresses = network === "main" ? MAIN_ADDRESSES : KOVAN_ADDRESSES;

        dispatch.registry.setBaseAddresses({
          factoryAddress: addresses.exchangeFactoryAddress,
          fsTokenAddress: addresses.fsTokenAddress,
          fsTokenProxyAdminAddress: addresses.fsTokenProxyAdminAddress,
          fsWalletAddress: addresses.walletAddress,
          incentivesAddress: addresses.incentivesAddress,
          liquidityTokenFactoryAddress: addresses.liquidityTokenFactoryAddress,
          messageProcessorAddress: addresses.messageProcessorAddress,
          votingAddress: addresses.votingAddress,
          wethAddress: addresses.wethAddress,
          walletAddress: addresses.walletAddress,
          usdcAddress: addresses.usdcAddress,
          registryHolderAddress: addresses.registryHolderAddress
        });

        const exchangeDetails = {
          address: addresses.exchangeAddress,
          assetSymbol: "ETH",
          stableSymbol: "USDC",
          assetToken: addresses.wethAddress,
          stableToken: addresses.usdcAddress
        };
        dispatch.registry.setExchange(exchangeDetails);

        dispatch.registry.setInitialized();
      }
    };
  }
};
