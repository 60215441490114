import React from "react";
import { withStyles, makeStyles } from "@material-ui/styles";
import Slider from "@material-ui/core/Slider";
import Box from "components/Box";

const useStyles = makeStyles({
  sliderColor: {
    color: "#c5c5c5"
  }
});

type Props = {
  onChange: (v: number | number[]) => void;
  value: number;
};

const WithDrawSlider: React.FC<Props> = ({ onChange, value }) => {
  const { sliderColor } = useStyles();
  const renderSlider = () => (
    <ThemedSlider
      className={sliderColor}
      min={0}
      max={100}
      aria-label="slider"
      value={value}
      valueLabelDisplay="off"
      onChange={(e, v) => onChange(v)}
    />
  );

  return <Box px={1}>{renderSlider()}</Box>;
};

export default WithDrawSlider;

const ThemedSlider = withStyles({
  root: {
    // color: "#00a7aa",
    height: 3,
    padding: "14px 0 6px"
  },
  track: {
    height: 3
  },
  rail: {
    height: 3,
    backgroundColor: "#bfbfbf",
    overflow: "hidden",
    borderRadius: 2
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 3,
    width: 1,
    marginTop: -0
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor"
  },
  thumb: {
    padding: 12,
    marginTop: -11
  }
})(Slider);
