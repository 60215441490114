import getProvider from "provider";

export const ETH_NETWORK_MAIN = 1;
export const ETH_NETWORK_RINKBY = 4;
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
export const REFERRAL_QUERY_PARAM = "src";
export const MAX_PRICE_DEVIATION_QUERY_PARAM = "max_price_deviation";
export const DEFAULT_MAX_PRICE_DEVIATION = 0.5;

export const TOTAL_REWARDS_DISTRIBUTED_BY_CONTRACT = 9760;
export const LP_PERCENT_REDUCTION_IN_DISTRIBUTION = 0.001; // because LP payout is done through new contract
export const TRADER_FST_PER_DAY = 9750; // 65% of 15,000
export const LP_FST_PER_DAY = 5250; // 35% of 15,000

let messageProcessorEndpoint: string | null = null;

export const getMessageProcessorEndpoint = async () => {
  if (messageProcessorEndpoint === null) {
    const network = await getProvider().eth.net.getNetworkType();
    switch (network) {
      case "kovan":
        messageProcessorEndpoint = "https://api.dev.futureswap.gg/prod/api/v1";
        break;
      case "main":
        messageProcessorEndpoint = "https://api.futureswap.gg/prod/api/v1";
        break;
      default:
        messageProcessorEndpoint = "https://api.futureswap.gg/prod/api/v1";
        break;
    }
  }
  return messageProcessorEndpoint;
};

// !important - every time we add a new token, we need to make
// sure it exists here. The asset symbol has to exactly match
// the symbol of the token. This is needed because we dont return
// the unpadded token with the contract
export const getUnpaddedTokens = network => {
  switch (network) {
    case "kovan":
      return {
        FSDPT: {
          address: "0x20a355DB06eff6E44841EAe9eDAbCfAdb5Cb2fD5",
          symbol: "USDC"
        }
      };
    case "main":
      return {
        FSDPT: {
          address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
          symbol: "USDC"
        }
      };
    default:
      throw new Error();
  }
};

export const getEtherscanDomain = network => {
  return network === "kovan"
    ? "http://kovan.etherscan.io"
    : "http://etherscan.io";
};

export const MESSAGE_PROCESSOR_TRANSACTION_STATUS = {
  MOCK: "mock", // implemented on frontend
  NEW: "new",
  SENT: "sent",
  RECEIVED: "received",
  ERROR: "error",
  SIGNED: "signed",
  PUBLISHED: "published",
  MINED: "mined"
};

export const API_ENDPOINT_TYPES = {
  OPEN_TRADES: "Open Trades API",
  CLOSED_TRADES: "Closed Trades API",
  USER_WALLET: "User Wallet API",
  DYNAMIC_EXCHANGE_DATA: "Dynamic Exchange Data API",
  CANDLES: "Candles API",
  VALIDATE_OPEN: "Validate Open API",
  VALIDATE_CLOSE: "Validate Close API",
  TERMS: "TERMS API",
  ALLOWED_ACTIONS: "Allowed Actions API",
  API_STATUS: "API Status",
  CONTRACT_CALL: "Contract Call",
  BLOCK_NUMBER: "Block Number",
  VALIDATE_ADD_COLLATERAL: "Validate add collateral"
};

export const TRANSACTIONS_GAS_GWEI = {
  TRADE_OPEN: "463042",
  TRADE_CLOSE: "412915",
  ADD_LIQUIDITY: "400000",
  REMOVE_LIQUIDITY: "400000"
};

interface UniLpAddresses {
  uniswapLpToken: string;
  fsToken: string;
  stakingRewardsContract: string;
}

export const MAIN_UNI_LP_ADDRESSES: UniLpAddresses = {
  uniswapLpToken: "0x88ae9e1625cfcbd128b89e7f037eaaf6a7cc9666", //uniswap pool for eth-fst
  fsToken: "0x0e192d382a36de7011f795acc4391cd302003606",
  stakingRewardsContract: "0x6147C7dd5836B3bA702Ce26B4Dc49Ac86A55fe89"
};

export const KOVAN_UNI_LP_ADDRESSES: UniLpAddresses = {
  uniswapLpToken: "0x8d2218BCaCBC098fe2E0Ce919d99a86a6ac58EdE",
  fsToken: "0x21b0A6BA1EeC577571800ac4D51fDE0B3e6Bd95D",
  stakingRewardsContract: "0x9564023b882f2c331e804b812d9399E4511D845f"
};

export const getStakingContractAddresses = (network: string | undefined) => {
  return network === "kovan" ? KOVAN_UNI_LP_ADDRESSES : MAIN_UNI_LP_ADDRESSES;
};
