import React, { useState, useEffect, useRef } from "react";
import Box from "components/Box";
import { Text } from "components/Typography";
import { useDispatch, useSelector } from "react-redux";
import { NULL_ADDRESS, getEtherscanDomain } from "../../../constants";
import styled from "@emotion/styled";
import { WalletModel } from "redux/wallet/model";
import { FsRootState } from "redux/store";

const Wallet = () => {
  const { accountAddress, network } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const isLoggedIn = accountAddress !== NULL_ADDRESS;
  const [isDropdownToggled, setIsDropdownToggled] = useState(false);
  const dispatch = useDispatch();
  const dropdownRef = useRef<any>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideToClose, false);
    document.addEventListener("keydown", handleEscapeToClose, false);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideToClose,
        false
      );
      document.removeEventListener("keydown", handleEscapeToClose, false);
    };
  });
  const handleClickOutsideToClose = e => {
    if (
      dropdownRef !== null &&
      dropdownRef.current !== null &&
      !dropdownRef.current.contains(e.target)
    ) {
      setIsDropdownToggled(false);
    }
  };
  const handleEscapeToClose = e =>
    e.keyCode === 27 && setIsDropdownToggled(false);

  const condensedAddress = () => {
    const address = accountAddress || "";
    return `${address.slice(0, 4)}...${address.slice(address.length - 4)}`;
  };

  const renderDropdown = () => {
    return (
      <DropdownContainer>
        <StyledLink
          title={accountAddress}
          href={`${getEtherscanDomain(network)}/address/${accountAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          <DropdownOption
            onClick={() => setIsDropdownToggled(false)}
            display="flex"
          >
            <Text>View on Etherscan </Text>
            <Box ml={1}>
              <img height="16px" alt="png" src="/images/external-link.svg" />
            </Box>
          </DropdownOption>
        </StyledLink>
      </DropdownContainer>
    );
  };

  const renderConnectedWalletButton = () => {
    return (
      <Box position="relative" ref={dropdownRef}>
        <ClickableContainer
          display="flex"
          justifyItems="center"
          onClick={() => setIsDropdownToggled(!isDropdownToggled)}
        >
          <img height="16px" alt="png" src="/images/metamask.png" />
          <Box ml={1} alignItems="center">
            <Text>{condensedAddress()}</Text>
          </Box>
        </ClickableContainer>
        {isDropdownToggled && renderDropdown()}
      </Box>
    );
  };

  const renderConnectToWalletButton = () => {
    return (
      <ClickableContainer
        display="flex"
        justifyItems="center"
        onClick={() => dispatch.wallet.connectToWallet()}
      >
        <img height="16px" alt="png" src="/images/metamask.png" />
        <Box ml={1} alignItems="center">
          <Text>Connect to wallet</Text>
        </Box>
      </ClickableContainer>
    );
  };

  return isLoggedIn
    ? renderConnectedWalletButton()
    : renderConnectToWalletButton();
};

const ClickableContainer = styled(Box)`
  position: relative;
  padding: 8px 16px;
  border-radius: 5px;
  background: rgba(255, 255, 225, 0.025);
  border: 1px solid rgba(255, 255, 225, 0.1);
  &:hover {
    background: rgba(255, 255, 225, 0.075);
  }
  cursor: pointer;
`;

const DropdownContainer = styled(Box)`
  margin-top: 4px;
  min-width: 200px;
  position: absolute;
  right: 0;
  z-index: 10000;
  overflow: auto;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1), 0px 0px 8px rgba(0, 0, 0, 0.05);
  background: #292d36;
`;

const DropdownOption = styled(Box)`
  width: 100%;
  padding: 12px 16px;
  &:hover {
    background: #343a46;
    cursor: pointer;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
`;

export default Wallet;
