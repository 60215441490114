import { DataLoader } from "data/DataLoader";
import { RegistryModel } from "redux/registry/model";
import Logger from "logger/Logger";

//todo(jonas) implement common and use common so we can use shared server side types
export interface AddLiquidityItem {
  amount: string;
  assetPrice: string;
  stablePrice: string;
  totalValue: string;
  assetAmount: string;
  stableAmount: string;
  oracleSignature: number;
  isAddition: boolean;
}

const LIQUIDITY_INITIAL_STATE = {
  history: [] as AddLiquidityItem[],
  isHistoryInitialized: false,
  hasError: false
};

export type LiquidityModel = typeof LIQUIDITY_INITIAL_STATE;

export const createGetLiquidityHistory = (
  dispatch: any,
  dataLoader: DataLoader
) => {
  return async (
    _,
    { registry, wallet }: { registry: RegistryModel; wallet: any }
  ) => {
    try {
      const response = await dataLoader.get<{ items: AddLiquidityItem[] }>(
        `/exchange/${registry.exchange.address}/liquidity/${wallet.accountAddress}`
      );
      dispatch.liquidity.setLiquidityHistory(response.data.items);
    } catch (e) {
      dispatch.liquidity.setHasError(true);
      Logger.error(e);
      Logger.error("Error getting liquidity history");
    }
    dispatch.liquidity.setIsLiquidityHistoryInitialized();
  };
};

export default {
  state: LIQUIDITY_INITIAL_STATE,
  reducers: {
    setLiquidityHistory: (
      state: LiquidityModel,
      history: AddLiquidityItem[]
    ) => {
      return {
        ...state,
        history
      };
    },
    setIsLiquidityHistoryInitialized: (state: LiquidityModel) => {
      return {
        ...state,
        isHistoryInitialized: true
      };
    },
    setHasError: (state: LiquidityModel) => {
      return {
        ...state,
        hasError: true
      };
    }
  },
  effects: dispatch => {
    return {
      getLiquidityHistory: createGetLiquidityHistory(dispatch, new DataLoader())
    };
  }
};
