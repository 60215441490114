import { BigNumber, utils } from "ethers";

// utils.parseutils(decimals

//@TODO rename bc it's not necisarily just wei
export const fromWei = (
  // TODO(dankurka): fix callers
  wei: number | string | undefined,
  decimals: string | number = 18
) => {
  return !wei ? 0 : Number(utils.formatUnits(String(wei), Number(decimals)));
};

// TODO(dankurka): fix callers
export const toWei = (
  number: string,
  decimals: string | number | undefined = 18
) => {
  return utils.parseUnits(number, Number(decimals)).toString();
};

export const ONE_ETHER = "1000000000000000000";
