import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectTraderIncentives } from "redux/incentives/selectors";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import { TRADER_FST_PER_DAY } from "../../constants";
import { COLORS } from "styles/theme";
import Box from "components/Box";
import Button from "components/Button";
import HorizontalList from "./HorizontalList";
import { Text, A } from "components/Typography";
import { FsDispatch, FsRootState } from "redux/store";

const TraderSection = ({ epochTimeLeft }) => {
  const dispatch = useDispatch<FsDispatch>();
  const { totalUnclaimedFst } = useSelector(selectTraderIncentives);
  const { requests: incentivesRequests } = useSelector(
    (state: FsRootState) => state.incentives
  );

  const hasClaimableFST = totalUnclaimedFst !== "0";
  const isButtonDisabled = !hasClaimableFST;
  const buttonLabel = hasClaimableFST
    ? `Claim ${formatNumber(fromWei(totalUnclaimedFst), 2)} FST`
    : "No Unclaimed FST";

  return (
    <>
      <Box display="flex" justifyContent="center" mb={3}>
        <Text fontSize="20px">TRADER REWARDS</Text>
      </Box>
      <Box mb={3}>
        <Box mb={0.5}>
          <HorizontalList
            label="Distribution"
            value={
              <Text>
                Trader rewards have ended and will resume in{" "}
                <A
                  href="https://medium.com/futureswap/getting-ready-for-v3-6d92d596c526"
                  target="_blank"
                >
                  v3
                </A>
                !
              </Text>
            }
          />
        </Box>
      </Box>
      <Box mb={3}>
        <Text fontSize="15px" color="#ddd">
          Earn FST based on your percentage of close trade volume for the
          distribution period
        </Text>
      </Box>
      <Button
        disabled={isButtonDisabled}
        isLoading={incentivesRequests.isClaimingTraderFst}
        onClick={() => dispatch.incentives.handlePayoutTrader()}
        color={COLORS.GREEN}
        size="lg"
        fluidWidth
      >
        {buttonLabel}
      </Button>
    </>
  );
};

export default TraderSection;
