import React, { useEffect } from "react";
import { FsDispatch, FsRootState } from "redux/store";
import { IncentivesModel } from "redux/incentives/model";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import Box from "components/Box";
import Button from "components/Button";
import { MainMetrics, H2, H4, Text, A } from "components/Typography";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "styles/theme";
import Table from "components/Table";
import AssetPairStacked from "components/AssetPairStacked";
import {
  selectCurrentExchangeDynamicData,
  select24HrVolume
} from "redux/dynamicExchangeData/selectors";
import { calculatePoolFeesAPY } from "utils/formulas";
import calculateFstApr from "../utils/calculateFstApr";
import styled from "@emotion/styled";
import Tooltip from "components/Tooltip";

const PoolList = () => {
  const dispatch = useDispatch<FsDispatch>();
  useEffect(() => {
    dispatch.incentives.updateFstPriceUsd();
  }, []);

  const { exchange } = useSelector((state: any) => state.registry);

  const { assetOwned, stableOwned } = useSelector(
    (state: any) => state.wallet.liquidity
  );
  const { fstPriceUsd } = useSelector<FsRootState, IncentivesModel>(
    state => state.incentives
  );

  const { assetTokenProperties, stableTokenProperties } = useSelector(
    getExchangeTokenProperties
  );

  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);
  const volume24Hr = useSelector(select24HrVolume);

  const poolSize =
    fromWei(dynamicExchangeData.assetPoolSize) *
      fromWei(dynamicExchangeData.assetPrice) +
    fromWei(dynamicExchangeData.stablePoolSize);

  const userAssetAmountInUsd =
    fromWei(assetOwned) * fromWei(assetTokenProperties.indexPrice);
  const userStableAmountInUsd =
    fromWei(stableOwned) * fromWei(stableTokenProperties.price);
  const userLiquidityInUsd = userAssetAmountInUsd + userStableAmountInUsd;

  const renderExchange = () => {
    return (
      <Box display="flex" alignItems="center">
        <AssetPairStacked assetSymbol="ETH" stableSymbol="USDC" />
        <Box ml={1.5}>
          <Text fontSize="16px">ETH / USDC</Text>
        </Box>
      </Box>
    );
  };

  const renderApy = () => {
    const apy = calculatePoolFeesAPY(
      volume24Hr,
      dynamicExchangeData.stablePoolSize
    );
    const fstApr = calculateFstApr(
      Number(fstPriceUsd),
      Number(fromWei(dynamicExchangeData.stablePoolSize))
    );

    return (
      <Box>
        <Box display="table" marginBottom={-0.5}>
          <Box display="flex" mb={0.25}>
            <MainMetrics fontSize="16px" color={COLORS.GREEN} key="apy">
              {`${formatNumber(apy + fstApr, 2)}%`}
            </MainMetrics>
            <Tooltip
              content={`${formatNumber(apy, 0)}% trade fees, ${formatNumber(
                fstApr,
                0
              )}% FST
              rewards. v2 has ended so we can begin rolling out v3 - thanks to everyone who
              participated!`}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const rows = [
    {
      cells: [
        <Box key="exchange">{renderExchange()}</Box>,
        <Box key="poolsize" display="inline-block">
          <MainMetrics fontSize="16px">
            ${formatNumber(poolSize, 2)}
          </MainMetrics>
        </Box>,
        <Box key="balance">
          <MainMetrics fontSize="16px">
            ${formatNumber(userLiquidityInUsd, 2)}
          </MainMetrics>
        </Box>,
        renderApy(),
        <Box display="flex" key="actionButton">
          <Box flex="1" />
          <Button key="actionButton" color={COLORS.PURPLE}>
            Add Liquidity
          </Button>
        </Box>
      ],
      linkPath: `pool/${exchange.address}`
    }
  ];

  return (
    <Box my={6}>
      <Box mb={1}>
        <H2>Liquidity pools</H2>
      </Box>
      <Box>
        <ApiSyncMessageContainer mb={1.5} display="flex">
          <Text fontSize="14px" color="#ddd">
            Futureswap is currently in Beta and has risks. Futureswap has
            undergone audits but we encourage people to only deposit funds they
            can afford to lose. For more information about risks associated with
            being a liquidity provider please see:{" "}
            <A
              target="_blank"
              rel="noopener noreferrer"
              href={"https://docs.futureswap.com/protocol/providingliquidity"}
            >
              Liquidity Provider Risks
            </A>
          </Text>
        </ApiSyncMessageContainer>
      </Box>
      <Table
        size="lg"
        headings={[
          { content: "Liquidity Pool", width: "280px", size: "lg" },
          { content: "Liquidity", size: "lg" },
          {
            content: "Your Liquidity",
            size: "lg"
          },
          {
            content: "APR",
            size: "lg"
          }
        ]}
        rows={rows}
      />
    </Box>
  );
};

export default PoolList;

const ApiSyncMessageContainer = styled(Box)`
  border: 2px solid #f1c844;
  padding: 12px 20px;
`;
