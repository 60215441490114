import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled/macro";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import ClosedPosition from "./ClosedPosition";
import Box from "components/Box";
import { H4, H3 } from "components/Typography";
import { selectUsersClosedTrades } from "redux/positions/selectors";
import { useSelector } from "react-redux";

const ClosedPositions = () => {
  const closedTrades = useSelector(selectUsersClosedTrades);
  const [expandedRow, setExpandedRow] = useState(undefined);
  const { assetTokenProperties, stableTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const renderTableHeader = () => (
    <TableHeader>
      <H4>Type </H4>
      <H4>Leverage</H4>
      <H4>Position ({assetTokenProperties.symbol})</H4>
      <H4>Open Price</H4>
      <H4>Close Price</H4>
      <H4>Collateral ({stableTokenProperties.symbol})</H4>
      <H4>Value</H4>
      <H4>P&L</H4>
      <H4>Total Profit</H4>
    </TableHeader>
  );

  const renderEmptyState = () => (
    <Box display="flex" justifyContent="center" width="100%" pt={4}>
      <H3>No orders to show</H3>
    </Box>
  );

  const renderTableBody = () => (
    <TableBody>
      {closedTrades.map((trade, i) => {
        const isExpanded = expandedRow === trade.tradeId;
        return (
          <ClosedPosition
            key={i}
            trade={trade}
            details
            isExpanded={isExpanded}
            toggleIsExpanded={() =>
              setExpandedRow(!isExpanded ? trade.tradeId : undefined)
            }
          />
        );
      })}
    </TableBody>
  );

  return (
    <Container
      width="100%"
      pt={2}
      px={1}
      display="flex"
      flexDirection="column"
      maxHeight="100%"
    >
      <Box px={2}>{renderTableHeader()}</Box>
      {!closedTrades.length ? renderEmptyState() : <div />}
      {closedTrades.length ? renderTableBody() : <div />}
    </Container>
  );
};

export default ClosedPositions;

const Container = styled(Box)`
  box-sizing: border-box;
  overflow: auto;
`;

const TableHeader = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.2fr;
  grid-gap: 6px;
  align-items: center;

  ${props =>
    props.match &&
    css`
      background-color: rgba(149, 159, 225, 0.15);
    `};
  /* border-bottom: 1px solid #000000; */
`;
const TableBody = styled(Box)`
  overflow-y: scroll;
`;
