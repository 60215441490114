import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import Box from "components/Box";
import AssetPairStacked from "components/AssetPairStacked";

import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import Stats from "./Stats";
import DebugBar from "components/DebugBar";
import { Text } from "components/Typography";
import { COLORS } from "styles/theme";
import { Link } from "@reach/router";
import {
  selectCurrentExchangeDynamicData,
  select24HrVolume
} from "redux/dynamicExchangeData/selectors";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { calculatePoolFeesAPY } from "utils/formulas";
import calculateFstApr from "../utils/calculateFstApr";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import Tooltip from "components/Tooltip";
import { FsDispatch, FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";
import { IncentivesModel } from "redux/incentives/model";

const MENU = {
  DEPOSIT: "Deposit",
  WITHDRAW: "Withdraw",
  DEV_FAUCET: "Dev Faucet" as string | undefined
};

const PoolDetails = () => {
  const dispatch = useDispatch<FsDispatch>();
  const [selectedTab, setSelectedTab] = useState(MENU.DEPOSIT);
  const { network } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { fstPriceUsd } = useSelector<FsRootState, IncentivesModel>(
    state => state.incentives
  );
  const { assetTokenProperties, stableTokenProperties } = useSelector(
    getExchangeTokenProperties
  );

  useEffect(() => {
    dispatch.incentives.updateFstPriceUsd();
  }, []);

  useEffect(() => {
    // TODO(jonas): Upgrade contracts and remove
    if (network === "main") {
      dispatch.incentives.setRewardsWeek();
    }
  }, [network]);

  const { lastUpdateTimestamp, timeInterval } = useSelector(
    (state: FsRootState) => state.incentives
  );

  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);
  const volume24Hr = useSelector(select24HrVolume);

  const nextReleaseTimestamp =
    Number(lastUpdateTimestamp) + Number(timeInterval);

  if (network !== "kovan") {
    delete MENU.DEV_FAUCET;
  }

  const renderMenu = () => (
    <Box display="flex">
      {Object.keys(MENU).map(key => {
        const tabName = MENU[key];
        return (
          <MenuItem
            py={2}
            flex="1"
            key={key}
            isActive={selectedTab === tabName}
            onClick={() => setSelectedTab(tabName)}
          >
            {tabName}
          </MenuItem>
        );
      })}
    </Box>
  );

  const renderTabContent = () => {
    switch (selectedTab) {
      case MENU.DEPOSIT:
        return <Deposit />;
      case MENU.WITHDRAW:
        return <Withdraw />;
      case MENU.DEV_FAUCET:
        return <DebugBar />;
    }
  };
  const renderBackLink = () => (
    <UnstyledLink to="/pool">
      <Text fontSize="14px" color="#ccc" fontWeight={500}>
        ← Pools
      </Text>
    </UnstyledLink>
  );

  const renderApy = () => {
    const apy = calculatePoolFeesAPY(
      volume24Hr,
      dynamicExchangeData.stablePoolSize
    );
    const fstApr = calculateFstApr(
      Number(fstPriceUsd),
      Number(fromWei(dynamicExchangeData.stablePoolSize))
    );

    return apy ? (
      <>
        <Box mr={1}>
          <Text color={COLORS.GREEN} key="apy" fontSize="14px">
            {`${formatNumber(apy + fstApr, 2)}% APR`}
          </Text>
        </Box>
        <Text color="#aaa" fontSize="12px">
          ({formatNumber(apy, 0)}% trade fees, {formatNumber(fstApr, 0)}% FST
          rewards)
        </Text>
        <Tooltip
          content="v2 has ended so we can begin rolling out v3 - thanks to everyone who
          participated!"
        />
      </>
    ) : (
      <Text color={COLORS.GREEN} key="apy" fontSize="14px">
        {`__.__% APR`}
      </Text>
    );
  };

  const renderExchange = () => {
    return (
      <Box display="flex" alignItems="center">
        <AssetPairStacked
          assetSymbol={assetTokenProperties.symbol}
          stableSymbol={stableTokenProperties.symbol}
        />
        <Box ml={1.5} mb={0.5}>
          <Text fontSize="18px" fontWeight={500}>
            {assetTokenProperties.symbol} / {stableTokenProperties.symbol}
          </Text>
          <Box display="flex" alignItems="center" flexWrap="nowrap">
            {renderApy()}
          </Box>
        </Box>
      </Box>
    );
  };

  const getNextDateString = () => {
    const string = moment.unix(nextReleaseTimestamp).fromNow(true);
    switch (string) {
      case "a day":
        return "in 1 day";
      default:
        return string;
    }
  };

  return (
    <Container my={3}>
      <Box mb={1.5}>{renderBackLink()}</Box>
      <Box display="flex" alignItems="center" mb={2}>
        {renderExchange()}
      </Box>
      <Box mb={2}>
        <Text fontSize="15px">
          Liquidity providers earn fees from trade activity and FST tokens as a
          reward.
        </Text>
      </Box>
      <Box mb={3}>
        <Text fontSize="15px">
          To earn FST, liquidity must be provided for the full duration of a
          period, starting from before the period begins. Next rewards period
          begins: {lastUpdateTimestamp && getNextDateString()}
        </Text>
      </Box>
      <Main>
        {renderMenu()}
        <Box>{renderTabContent()}</Box>
      </Main>
      <Secondary p={4} mt={4}>
        <Stats />
      </Secondary>
    </Container>
  );
};

const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
`;
const Main = styled(Box)`
  background: #272b3e;
  border-radius: 5px;
  overflow: hidden;
`;

const Secondary = styled(Box)`
  background: #3e4357;
  border-radius: 5px;
`;
const MenuItem = styled(Box)<{ isActive: boolean }>`
  background: ${props => (props.isActive ? "#373D73" : "#30334B")};
  text-align: center;
  color: ${props => (props.isActive ? "#ddd" : "#888")};
  cursor: ${props => (props.isActive ? "default" : "pointer")};
  font-weight: 500;
`;

const Grid = styled(Box)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 7fr 5fr;
  grid-template-areas: "primary secondary";
`;

const Primary = styled(Box)`
  grid-area: primary;
  background: #272b3e;
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

export default PoolDetails;
