import { checkDefined } from "offchainTradingSystem/common/preconditions";

const CURVE_N = BigInt(
  "0x7FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF5D576E7357A4501DDFE92F46681B20A0"
);
const BASE_N = BigInt(
  "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEBAAEDCE6AF48A03BBFD25E8CD0364141"
);

export class NormalizerError extends Error {}

export class SignatureNormalizer {
  normalize(signature: string) {
    // EIP-2 still allows signature malleability for ecrecover(). Remove this
    // possibility and make the signature
    // unique. Appendix F in the Ethereum Yellow paper
    // (https://ethereum.github.io/yellowpaper/paper.pdf), defines
    // the valid range for s in (281): 0 < s < secp256k1n ÷ 2 + 1, and for v in
    // (282): v ∈ {27, 28}. Most
    // signatures from current libraries generate a unique signature with an s-value
    // in the lower half order.
    //
    // If your library generates malleable signatures, such as s-values in the upper
    // range, calculate a new s-value
    // with 0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFEBAAEDCE6AF48A03BBFD25E8CD0364141 - s1
    // and flip v from 27 to 28 or
    // vice versa. If your library also generates signatures with 0/1 for v instead
    // 27/28, add 27 to v to accept
    // these malleable signatures as well.
    checkDefined(signature);

    if (!/^0x[0-9a-fA-F]{130}$/.test(signature)) {
      throw new NormalizerError("Invalid signature: " + signature);
    }

    signature = signature.substring(2);

    let sValue = BigInt("0x" + signature.substring(64, 128));
    let vValue = BigInt("0x" + signature.substring(128, 130));
    let flipV = false;

    if (sValue > CURVE_N) {
      sValue = BASE_N - sValue;
      flipV = true;
    }

    if (vValue === BigInt(0) || vValue === BigInt(1)) {
      vValue = vValue + BigInt(27);
    }

    if (vValue !== BigInt(27) && vValue !== BigInt(28)) {
      throw new NormalizerError("Invalid v value: " + vValue);
    }

    vValue = flipV ? (vValue === BigInt(27) ? BigInt(28) : BigInt(27)) : vValue;

    const rString = signature.substring(0, 64);
    let sString = sValue.toString(16);
    sString = "0".repeat(64 - sString.length) + sString;
    return `0x${rString}${sString}${vValue.toString(16)}`;
  }
}
