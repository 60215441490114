import React, { useState } from "react";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { useSelector } from "react-redux";
import { Text, A } from "components/Typography";
import { NULL_ADDRESS } from "../../constants";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";

type Props = {
  network: string;
};

const NetworkModal: React.FC<Props> = ({ network }) => {
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const isOpen = network !== "main" || accountAddress === NULL_ADDRESS;
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const renderMockAccountView = () => {
    return (
      <>
        <Box mb={3}>
          <Header>Metamask not detected</Header>
        </Box>
        <Box mb={4} display="flex" justifyContent="center">
          <img height="96px" alt="png" src="/images/metamask.png" />
        </Box>
        <Box mb={3}>
          <Box mb={0.5} display="flex" justifyContent="center">
            <Text>Login to Metamask</Text>
          </Box>
          <Box mb={0.5} display="flex" justifyContent="center">
            <Text>-or-</Text>
          </Box>
          <Box display="flex" justifyContent="center">
            <MetamaskLink href="https://metamask.io/" target="_blank">
              Install Metamask
            </MetamaskLink>
          </Box>
        </Box>
        <Button
          onClick={() => setIsPreviewMode(true)}
          color={COLORS.PURPLE}
          size="lg"
          fluidWidth
        >
          Enter preview mode (actions are disabled)
        </Button>
      </>
    );
  };

  const renderWrongNetwork = () => {
    return (
      <>
        <Box mb={3}>
          <Header>Unsupported Network</Header>
        </Box>
        <Box mb={4} display="flex" justifyContent="center">
          <img height="96px" alt="png" src="/images/metamask.png" />
        </Box>
        <Box display="flex" justifyContent="center">
          <Text fontSize="20px" textAlign="center">
            Switch to mainnet to continue
          </Text>
        </Box>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen && !isPreviewMode}>
      {accountAddress === NULL_ADDRESS
        ? renderMockAccountView()
        : renderWrongNetwork()}
    </Modal>
  );
};

export default NetworkModal;

const Header = styled.h2`
  font-weight: 500;
  font-size: 26px;
  color: #eee;
  text-align: center;
`;

const MetamaskLink = styled(A)`
  font-weight: normal;
`;
