import React from "react";
import Box from "components/Box";
import Tippy from "components/Tippy";
import info from "media/info.svg";

type TooltipProps = {
  content: string;
};

const Tooltip: React.FC<TooltipProps> = ({ content }) => {
  return (
    <Tippy content={content}>
      <Box ml={0.5} width="11px">
        <img alt="alt" src={info} />
      </Box>
    </Tippy>
  );
};

export default Tooltip;
