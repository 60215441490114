import { RematchRootState } from "@rematch/core";
import { FsRootModel } from "redux/store";
import { fromWei } from "utils/numbers";

export const selectCandlestickChartData = (
  state: RematchRootState<FsRootModel>
) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60;

  return state.chart.historicCandles.map(d => ({
    low: fromWei(d.min),
    high: fromWei(d.max),
    open: fromWei(d.open),
    close: fromWei(d.close),
    time: ((d.timestamp / 1000) | 0) - timezoneOffset
  }));
};
