import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsTokenDepositing } from "redux/wallet/selectors";

import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import RestrictedRegionButton from "components/RestrictedRegionButton";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";
import { Text, MainMetrics } from "components/Typography";
import Input from "components/Input";
import Table from "components/Table";
import { TokenProperties } from "redux/wallet/model";
import { FsRootState } from "redux/store";
import { TermsModel } from "redux/termsAndActions/model";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  handleDeposit: (value: string) => void;
  token: TokenProperties;
};

const Deposit: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleDeposit,
  token
}) => {
  const dispatch = useDispatch();
  const isDepositing = useSelector((state: FsRootState) =>
    selectIsTokenDepositing(state, token.address)
  );
  const { restrictedRegion } = useSelector<FsRootState, TermsModel>(
    state => state.termsAndActions
  );

  const [amountToDeposit, setAmountToDeposit] = useState("");

  const renderUnlockButton = () => (
    <Button
      color={COLORS.PURPLE}
      onClick={() => dispatch.wallet.unlockToken(token.address)}
      isLoading={token.isUnlocking}
      fluidWidth
      size="lg"
    >
      Unlock {token.symbol}
    </Button>
  );

  const renderActionButton = () => (
    <Box mt={2}>
      <Box>
        <Button
          color={COLORS.PURPLE}
          size="lg"
          onClick={() => handleDeposit(amountToDeposit)}
          fluidWidth
          isLoading={isDepositing}
        >
          Deposit {token.symbol}
        </Button>
      </Box>
    </Box>
  );

  const renderHorizontalList = (label, value) => {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text fontSize="11px" color="#bbb" fontWeight="500">
          {label}
        </Text>
        <Text fontSize="11px">{value}</Text>
      </Box>
    );
  };

  const renderTable = () => {
    const newBalance = Number(
      fromWei(token.fsBalance) + Number(amountToDeposit)
    );
    const rows = [
      {
        cells: [
          <MainMetrics key="a">{token.symbol}</MainMetrics>,
          <MainMetrics key="b">
            {formatNumber(Number(amountToDeposit), 2)}
          </MainMetrics>,
          <MainMetrics key="c">
            {formatNumber(fromWei(token.fsBalance), 2)}
          </MainMetrics>,
          <MainMetrics key="d">{formatNumber(newBalance, 2)}</MainMetrics>
        ]
      }
    ];
    return (
      <Table
        headings={["Asset", "Depositing", "Current Balance", "New Balance"]}
        size="sm"
        rows={rows}
      />
    );
  };

  const renderInput = () => {
    return (
      <>
        <Box mb={0.25}>
          {renderHorizontalList(
            `METAMASK BALANCE: ${fromWei(token.localBalance, token.decimals)} ${
              token.symbol
            }`,
            <Text
              onClick={() =>
                setAmountToDeposit(
                  fromWei(token.localBalance, token.decimals).toString()
                )
              }
            >
              <MaxButton>MAX</MaxButton>
            </Text>
          )}
        </Box>
        <Input
          value={amountToDeposit}
          placeholder={"0"}
          type="number"
          name="open"
          id="collateral"
          width="100px"
          onChange={e => {
            setAmountToDeposit(e.target.value);
          }}
          rightLabel={token.symbol}
        />
      </>
    );
  };
  const renderStableGasContext = () => (
    <Box p={1.5} border="2px solid #f1c844">
      <Box mb={1.5}>
        <Text color="#eee" fontSize="14px" lineHeight="18px">
          The current minimum trade size is <b>200 USDC</b>.
        </Text>
      </Box>
      <Text color="#eee" fontSize="14px" lineHeight="18px">
        We recommend depositing an additional <b>200 USDC</b> for future gas
        fees. Gas for transactions are paid in USDC from your Futureswap balance
        instead of ETH from your Metamask.
      </Text>
    </Box>
  );

  const isStable = token.symbol.toLowerCase() !== "eth";

  return (
    <Modal
      isOpen={isOpen}
      title={`Deposit ${token.symbol}`}
      handleClose={handleClose}
    >
      <Box mb={2}>
        <Box mb={1}>
          <Text fontSize="18px">
            {`Deposit ${token.symbol} to your Futureswap wallet`}
          </Text>
        </Box>
        {isStable && <Box mb={3}> {renderStableGasContext()}</Box>}
      </Box>
      <Box mb={3}>{renderInput()}</Box>
      <Box mb={3}>{renderTable()}</Box>
      {restrictedRegion ? (
        <RestrictedRegionButton fluidWidth />
      ) : token.isUnlocked ? (
        renderActionButton()
      ) : (
        renderUnlockButton()
      )}
    </Modal>
  );
};

export default Deposit;

const MaxButton = styled(Text)`
  color: #7895f1;
  cursor: pointer;
  font-weight: 500;
`;
