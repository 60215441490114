import { DataLoader } from "data/DataLoader";
import { RegistryModel } from "redux/registry/model";
import { FsDispatch } from "redux/store";
import { toWei } from "utils/numbers";

export interface Trade {
  type: "long" | "short";
  // TODO(dankurka): we should not have two IDs
  onChainId: string;
  tradeId: string;
  // TODO(dankurka): Figure out type
  collateral: any;
  // TODO(dankurka): Figure out type
  openPrice: any;
  isOpen: boolean;
  userAddress: string;
  tradeValue: string;
  exchange: string;
  tradeOwner: string;
  leverage: string;
  creationTime: number;
  liquidationPrice: string;
  profitLoss: {
    isProfitable: boolean;
    value: string;
    percent: string;
  };
  // TODO(dankurka): Not sure why this is an object
  state: {
    status: string;
  };
}

const POSITION_INITIAL_STATE = {
  user: {
    openTrades: [] as Trade[],
    closedTrades: [] as Trade[]
  },
  failedToLoadOpenTrades: false,
  failedToLoadClosedTrades: false
};

export type PositionModel = typeof POSITION_INITIAL_STATE;

export const createUsersGetOpenTrades = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  return async (
    _,
    { registry, wallet }: { registry: RegistryModel; wallet: any }
  ) => {
    try {
      // TODO(dankurka): use server side type
      const response = await dataLoader.get<{ trades: Trade[] }>(
        `/exchange/${registry.exchange.address}/trades/${wallet.accountAddress}`
      );
      dispatch.positions.setUsersOpentrades(response.data.trades);
      dispatch.positions.setFailedToLoadOpenTrades(false);
    } catch (e) {
      // TODO(dankurka): Handle error
      dispatch.positions.setFailedToLoadOpenTrades(true);
    }
  };
};

export const createUsersGetClosedTrades = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  return async (
    _,
    { registry, wallet }: { registry: RegistryModel; wallet: any }
  ) => {
    try {
      // TODO(dankurka): use server side type
      const response = await dataLoader.get<{ trades: Trade[] }>(
        `/exchange/${registry.exchange.address}/closedtrades/${wallet.accountAddress}`
      );
      dispatch.positions.setUsersClosedTrades(response.data.trades);
      dispatch.positions.setFailedToLoadClosedTrades(false);
    } catch (e) {
      // TODO(dankurka): Handle error
      dispatch.positions.setFailedToLoadClosedTrades(true);
    }
  };
};

export default {
  state: POSITION_INITIAL_STATE,
  reducers: {
    resetState: () => POSITION_INITIAL_STATE,
    setUsersOpentrades: (state: PositionModel, openTrades: Trade[]) => {
      return {
        ...state,
        user: {
          ...state.user,
          openTrades
        }
      };
    },
    setUsersClosedTrades: (state: PositionModel, closedTrades: Trade[]) => {
      return {
        ...state,
        user: {
          ...state.user,
          closedTrades
        }
      };
    },
    setFailedToLoadOpenTrades: (
      state: PositionModel,
      failedToLoadOpenTrades: boolean
    ) => ({ ...state, failedToLoadOpenTrades }),
    setFailedToLoadClosedTrades: (
      state: PositionModel,
      failedToLoadClosedTrades: boolean
    ) => ({ ...state, failedToLoadClosedTrades }),
    addMockTrade: (state: PositionModel, trade) => {
      return {
        ...state,
        user: {
          ...state.user,
          openTrades: [trade, ...state.user.openTrades]
        }
      };
    }
  },
  effects: dispatch => {
    return {
      getUsersOpenTrades: createUsersGetOpenTrades(dispatch, new DataLoader()),
      getUsersClosedTrades: createUsersGetClosedTrades(
        dispatch,
        new DataLoader()
      )
    };
  }
};
