import React from "react";
import { useSelector } from "react-redux";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { fromWei } from "utils/numbers";
import { COLORS } from "styles/theme";
import FaucetButton from "./FaucetButton";
import Box from "./Box";
import { Text } from "components/Typography";

const DebugBar: React.FC = () => {
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const STABLE_TO_MINT = "1000";
  return (
    <Box m={4}>
      <Box mb={3}>
        <Box mb={1} display="flex">
          <Box mr={2}>
            <Text color="#aaa">ETH FS Wallet Balance: </Text>
          </Box>
          <Text>
            {fromWei(assetTokenProperties.localBalance).toFixed(2)} ETH
          </Text>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Text color="#aaa">USDC FS Wallet Balance: </Text>
          </Box>
          <Text>
            {fromWei(
              stableTokenProperties.localBalance,
              stableTokenProperties.decimals
            ).toFixed(2)}{" "}
            USDC
          </Text>
        </Box>
      </Box>
      <Box>
        <Box mb={2} display="flex">
          <FaucetButton
            tokenAddress={stableTokenProperties.address}
            size="md"
            color={COLORS.PURPLE}
            amountToMint={STABLE_TO_MINT}
          >
            Mint {STABLE_TO_MINT} dev USDC
          </FaucetButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DebugBar;
