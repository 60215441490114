import React from "react";
import styled from "@emotion/styled";
import Box from "./Box";
import { COLORS } from "styles/theme";


type LeftRightToggleProps = {
  isLeftToggled: boolean,
  onToggle: (arg0: boolean) => void,
  leftLabel: string,
  rightLabel: string
};


const LeftRightToggle: React.FC<LeftRightToggleProps> = ({
  isLeftToggled,
  onToggle,
  leftLabel,
  rightLabel
}) => {
  return (
    <Container display="flex">
      <LeftToggle isLeftToggled={isLeftToggled} onClick={() => onToggle(true)}>
        {leftLabel}
      </LeftToggle>
      <RightToggle
        isLeftToggled={!isLeftToggled}
        onClick={() => onToggle(false)}
      >
        {rightLabel}
      </RightToggle>
    </Container>
  );
};

export default LeftRightToggle;

const Container = styled(Box)`
  height: 38px;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  font-size: 15px;
  letter-spacing: 0.5px;
`;


const LeftToggle = styled.div<{isLeftToggled: boolean}>`
  color: ${props => (props.isLeftToggled ? "#fff" : "#d5d5d5")};
  background-color: ${props =>
    props.isLeftToggled ? COLORS.GREEN : "#454958"};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  &:hover {
    ${props => !props.isLeftToggled && "background-color: #50566a"};
  }
`;
const RightToggle = styled(LeftToggle)`
  background-color: ${props => (props.isLeftToggled ? COLORS.RED : "#454958")};
  flex: 1;
`;
