import React from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import { Text } from "components/Typography";
import { fromWei } from "utils/numbers";

const VoteStatusBar = ({ yesVotes, noVotes }) => {
  const yesVotesFromWei = parseInt(fromWei(yesVotes));
  const noVotesFromWei = parseInt(fromWei(noVotes));
  const percentageOfYesVotes =
    (yesVotesFromWei / (yesVotesFromWei + noVotesFromWei)) * 100;

  const percentageOfNoVotes = 100 - percentageOfYesVotes;
  const renderYesNoLabel = (percent, isYes) => (
    <Box pl={isYes && 2} pr={!isYes && 2} zIndex={1}>
      <Text fontSize="12px" fontWeight={500}>
        {percent.toFixed(2)}%
      </Text>
    </Box>
  );

  const renderBarInnerWithActivity = () => (
    <>
      <Filler isYes width={`${percentageOfYesVotes}%`}>
        {percentageOfYesVotes > 5
          ? renderYesNoLabel(percentageOfYesVotes, true)
          : undefined}
      </Filler>
      <Filler flex="1">
        <Box flex="1" />
        {percentageOfNoVotes > 5
          ? renderYesNoLabel(percentageOfNoVotes, false)
          : undefined}
      </Filler>
    </>
  );
  const renderBarInnerNoActivity = () => (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="12px" fontWeight={500} g>
        No votes
      </Text>
    </Box>
  );

  const hasVotes = Number(yesVotes) || Number(noVotes);
  return (
    <BarContainer display="flex" position="relative" hasVotes={!!hasVotes}>
      {hasVotes ? renderBarInnerWithActivity() : renderBarInnerNoActivity()}
    </BarContainer>
  );
};

export default VoteStatusBar;

const BarContainer = styled(Box)`
  height: 22px;
  background: ${props => (props.hasVotes ? "#e82268" : "#777")};
  border-radius: 3px;
  overflow: hidden;
`;

const Filler = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  background: ${props => (props.isYes ? "#00cfac" : "#e82268")};
`;
