import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import { DataLoader } from "data/DataLoader";

const CookiePolicy = () => {
  const [cookiesPolicyText, setPolicyText] = useState("");

  useEffect(() => {
    const runEffect = async () => {
      try {
        const { data } = await new DataLoader().get<{ cookiePolicy: string }>(
          "/cookiespolicy"
        );
        setPolicyText(data.cookiePolicy);
      } catch (e) {
        // TODO(dankurka): Better error handling
        setPolicyText("Failed to load data, please reload the page.");
      }
    };
    runEffect();
  }, []);

  return (
    <>
      <Container px={2} py={4}>
        <div>{cookiesPolicyText}</div>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  width: 100%;
  color: #fff;
  line-height: 24px;
  font-size: 17px;
`;

export default CookiePolicy;
