import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { selectCurrentExchangeDynamicData } from "redux/dynamicExchangeData/selectors";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { selectIsBalancePoolsProcessing } from "redux/messageProcessor/selectors";
import Box from "components/Box";
import NavBar from "components/NavBar";
import Input from "components/Input";
import Button from "components/Button";
import { COLORS } from "styles/theme";
import { Text, H2 } from "components/Typography";
import { fromWei, toWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";

const Balancer = () => {
  const dispatch = useDispatch();
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const { poolImbalanceData } = useSelector(state => state.internalExchange);

  const isBalancePoolsProcessing = useSelector(selectIsBalancePoolsProcessing);
  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);

  const [amountToSend, setAmountToSend] = useState("");

  useEffect(() => {
    if (Object.keys(dynamicExchangeData).length) {
      dispatch.internalExchange.updateInternalExchangeData();
    }
  }, [dynamicExchangeData]);
  const isTradingAsset = poolImbalanceData.exchangeNeedsAsset;
  const handleBalancePools = () => {
    dispatch.messageProcessor.handleBalancePools({
      amount: toWei(amountToSend),
      isTradingAsset,
      exchangeAddress: dynamicExchangeData.exchangeAddress
    });
  };

  const renderHorizontalList = (label, value) => {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="#bbb">
          <Box display="flex">{label}</Box>
        </Text>

        <Text>{value}</Text>
      </Box>
    );
  };

  const neededValue =
    amountToSend /
    Number(fromWei(poolImbalanceData.traderSendsDiscountedAmount));
  const amountToReceive =
    fromWei(poolImbalanceData.traderWillReceiveAmount) * neededValue || 0;

  const renderSendAsset = () => {
    return (
      <Box>
        <Box mb={2}>
          <Text fontSize="16px">Send {assetTokenProperties.symbol}</Text>
        </Box>
        <Box mb={2}>
          <Box mb={0.25}>{renderNeeds()}</Box>
          <Input
            placeholder="0"
            type="number"
            name="addStableAmount"
            id="addStableAmount"
            onChange={e => {
              setAmountToSend(e.target.value);
            }}
            value={amountToSend}
            rightLabel={assetTokenProperties.symbol}
          />
        </Box>
        <Box mb={2}>
          {renderHorizontalList(
            "RECEIVE",
            `${formatNumber(amountToReceive, 2)} ${
              stableTokenProperties.symbol
            }`
          )}
        </Box>
        <Button
          color={COLORS.PURPLE}
          fluidWidth
          onClick={handleBalancePools}
          isLoading={isBalancePoolsProcessing}
          disabled={amountToSend === ""}
        >
          Exchange
        </Button>
      </Box>
    );
  };

  const renderSendStable = () => {
    return (
      <Box>
        <Box mb={2}>
          <Text fontSize="16px">Send {stableTokenProperties.symbol}</Text>
        </Box>
        <Box mb={2}>
          <Box mb={0.25}>{renderNeeds()}</Box>
          <Input
            type="number"
            name="addStableAmount"
            id="addStableAmount"
            onChange={e => {
              setAmountToSend(e.target.value);
            }}
            value={amountToSend}
            rightLabel={stableTokenProperties.symbol}
          />
        </Box>
        <Box mb={2}>
          {renderHorizontalList(
            "RECEIVE",
            `${formatNumber(amountToReceive, 4)} ${assetTokenProperties.symbol}`
          )}
        </Box>
        <Button
          color={COLORS.PURPLE}
          fluidWidth
          onClick={handleBalancePools}
          isLoading={isBalancePoolsProcessing}
          disabled={amountToSend === ""}
        >
          Exchange
        </Button>
      </Box>
    );
  };

  const renderNeeds = () => {
    return (
      <Box>
        <Text fontSize="12px" color="#bbb">
          POOL NEEDS:{" "}
          {`${formatNumber(
            fromWei(poolImbalanceData.traderSendsDiscountedAmount),
            2
          )} ${isTradingAsset ? "ETH" : "USDC"}`}
        </Text>
      </Box>
    );
  };

  const renderWalletBalance = () => {
    return (
      <>
        <Box mb={1}>
          <Text fontSize="16px">FS Wallet Balance</Text>
        </Box>
        <Box mb={1}>
          {renderHorizontalList(
            "Asset amount",
            `${formatNumber(fromWei(assetTokenProperties.fsBalance), 2)} ${
              assetTokenProperties.symbol
            } `
          )}
        </Box>
        <Box mb={1}>
          {renderHorizontalList(
            "Stable amount",
            `${formatNumber(fromWei(stableTokenProperties.fsBalance), 2)} ${
              stableTokenProperties.symbol
            } `
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      <NavBar />
      <Container my={6}>
        <Box mb={3}>
          <H2>Balance pools</H2>
        </Box>
        <>
          <Box mb={5}>
            {isTradingAsset ? renderSendAsset() : renderSendStable()}
          </Box>
          {renderWalletBalance()}
        </>
      </Container>
    </>
  );
};

const Container = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
`;

export default Balancer;
