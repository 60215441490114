import React from "react";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { formatNumber } from "utils/formatter";
import { fromWei } from "utils/numbers";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link, Location } from "@reach/router";
import { useSelector } from "react-redux";
import Walet from "./Wallet";
import PulsatingWarningDot from "components/PulsatingWarningDot";
import logo from "media/logo.svg";
import Box from "components/Box";
import { Text } from "components/Typography";

import { getHasUnclaimedIncentives } from "redux/incentives/selectors";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";

const NAVBAR_HEIGHT_PX = "56px";

// This is a temporary component until I have time to fix the
// getExchangeTokenProperties selector to not return an error
// if the wallet store hasn't initialized
const NavBarFst = () => {
  const { fsTokenProperties } = useSelector(getExchangeTokenProperties);
  return (
    <Box display={{ xs: "none", md: "flex" }} alignItems="center" mr={2}>
      <Text>
        {formatNumber(fromWei(fsTokenProperties.localBalance || "0"), 2)} FST
      </Text>
    </Box>
  );
};

const NavBar = () => {
  const { requests: walletRequests } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { network } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const isKovan = network === "kovan";
  const renderLogo = () => (
    <Box>
      <Link to="/">
        <LogoContainer>
          <Box display="flex" px={3} alignItems="center">
            <Logo src={logo} alt="logo" />
            {isKovan && (
              <Box ml={1.5}>
                <NetworkText>{network}</NetworkText>
              </Box>
            )}
          </Box>
        </LogoContainer>
      </Link>
    </Box>
  );

  const renderNavItem = ({
    label,
    path,
    isPulsating,
    isLast
  }: {
    label: string;
    path: string;
    isPulsating?: boolean;
    isLast?: boolean;
  }) => {
    return (
      <Location>
        {({ location: { pathname } }) => {
          const isActive = path === `/${pathname.split("/")[1]}`;
          return (
            <Box>
              <StyledLink to={path}>
                <NavItem
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  selected={isActive}
                  isLast={isLast}
                >
                  <Box display="flex">
                    {label}
                    <Box ml={0.5}>
                      {isPulsating && <PulsatingWarningDot disablePulse />}
                    </Box>
                  </Box>
                </NavItem>
              </StyledLink>
            </Box>
          );
        }}
      </Location>
    );
  };

  return (
    <NavbarWrapper>
      <Box display="flex" alignItems="center">
        {renderLogo()}
        {renderNavItem({
          label: "Trade",
          path: "/"
        })}
        {renderNavItem({
          label: "Wallet",
          path: "/wallet"
        })}
        {renderNavItem({
          label: "Rewards",
          path: "/rewards",
          // isPulsating: HasUnclaimedIncentives, @todo come back to this if we want it
          isLast: true
        })}
        {renderNavItem({
          label: "Governance",
          path: "/governance",
          isLast: true
        })}

        {/* {renderMore()} */}

        {walletRequests.isInitialized && (
          <>
            <Box flex="1" />
            <NavBarFst />
            <Box mr={3}>
              <Walet />
            </Box>
          </>
        )}
      </Box>
    </NavbarWrapper>
  );
};

export default NavBar;

const NavbarWrapper = styled(Box)`
  background: #16191f;
  border-bottom: 1px solid #393f4e;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

const NavItem = styled(Box)<{ isLast?: boolean; selected: boolean }>`
  cursor: pointer;
  padding: 0 32px;
  height: ${NAVBAR_HEIGHT_PX};
  color: #eee;
  box-sizing: border-box;
  border-left: 1px solid #393f4e;
  ${props => props.isLast && "border-right: 1px solid #393f4e"};
  margin-bottom: -1px;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  ${props =>
    props.selected &&
    css`
      background: rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid #617fc5;
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    `}
`;

const LogoContainer = styled.div<{ theme: any }>`
  width: 290px;
  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
  ${props => `
    ${props.theme.breakpoints.down("sm")} {
      width: auto;
      display: none;
    }
  `}
  height: ${NAVBAR_HEIGHT_PX};
  display: flex;
`;

const Logo = styled.img`
  width: 145px;
  display: block;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-size: 15px;
`;

const MoreBox = styled(Box)`
  text-decoration: none;
  font-size: 15px;
  border-right: 1px solid #393f4e;
`;

const NetworkText = styled(Text)`
  text-decoration: underline;
`;
