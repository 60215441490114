import { DataLoaderError } from "data/DataLoader";
import { FsDispatch } from "./store";

export const maybeReportError = (
  dispatch: FsDispatch,
  errorType: string,
  e: Error
) => {
  if (e instanceof DataLoaderError && e.hasReponse()) {
    dispatch.api.addError({
      type: errorType,
      code: e.httpCode,
      message: e.serverMessage || ""
    });
  }
};
