import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toWei, fromWei } from "utils/numbers";

import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import WithdrawStepTwo from "./WithdrawStepTwo";
import WithdrawDisabled from "./WithdrawDisabled";
import { selectPendingWithdrawals } from "redux/messageProcessor/selectors";
import { FsDispatch } from "redux/store";
import { checkDefined } from "offchainTradingSystem/common/preconditions";
import { TokenProperties } from "redux/wallet/model";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  token: TokenProperties;
  isDeposit: boolean;
};

const FsWalletModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  token,
  isDeposit
}) => {
  const dispatch = useDispatch<FsDispatch>();
  const pendingInstantWithdrawals = useSelector(selectPendingWithdrawals);

  const withDrawTransaction = pendingInstantWithdrawals.filter(
    t => t.tokenAddress === token.address
  );

  const isTokenPendingWithdraw = withDrawTransaction.length > 0;

  const transactionId = isTokenPendingWithdraw
    ? withDrawTransaction[0].id
    : null;

  const handleDeposit = async amountToDeposit => {
    if (amountToDeposit > 0) {
      await dispatch.wallet.depositToken({
        tokenAddress: token.address,
        amount: toWei(amountToDeposit, token.decimals)
      });
      handleClose();
    }
  };

  const handleWithdraw = async amount => {
    if (Number(fromWei(amount)) > 0) {
      await dispatch.messageProcessor.handleInstantWithdrawal({
        amount,
        tokenAddress: token.address
      });
    }
  };

  const renderPendingWithdraw = () => {
    return isTokenPendingWithdraw ? (
      <WithdrawStepTwo
        transactionId={checkDefined(transactionId)}
        isOpen={isOpen}
        handleClose={handleClose}
        token={token}
      />
    ) : (
      <WithdrawDisabled
        isOpen={isOpen}
        handleClose={handleClose}
        token={token}
      />
    );
  };

  return isDeposit ? (
    <Deposit
      isOpen={isOpen}
      handleDeposit={handleDeposit}
      handleClose={handleClose}
      token={token}
    />
  ) : isTokenPendingWithdraw ? (
    renderPendingWithdraw()
  ) : (
    <Withdraw
      isOpen={isOpen}
      handleWithdraw={handleWithdraw}
      handleClose={handleClose}
      token={token}
    />
  );
};

FsWalletModal.defaultProps = {
  isDeposit: false
};

export default FsWalletModal;
