import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectHasApiError, selectUniqueErrors } from "redux/api/selectors";
import Modal from "components/Modal";
import Box from "components/Box";
import Button from "components/Button";
import { Text, H4 } from "components/Typography";
import { COLORS } from "styles/theme";
import styled from "@emotion/styled";

const PreviewModal = () => {
  // TODO(dankurka): Remove any
  const dispatch = useDispatch<any>();
  const hasApiError = useSelector(selectHasApiError);
  const uniqueErrors = useSelector(selectUniqueErrors);

  const renderButtons = () => (
    <>
      <Box mb={1.5}>
        <Button
          color={COLORS.PURPLE}
          size="lg"
          onClick={() => window.location.reload()}
          fluidWidth
        >
          Refresh Futureswap
        </Button>
      </Box>
      <Button
        color={COLORS.GREY}
        size="lg"
        onClick={() => dispatch.api.clearErrors()}
        fluidWidth
      >
        Close Modal and Continue
      </Button>
    </>
  );

  const getError = message => {
    switch (message) {
      case "Invalid signature time":
        return "Messages must be signed within 100 seconds. Is your system clock off?";
      default:
        return message;
    }
  };

  const renderError = error => {
    return (
      <TableRow mb={0.5}>
        <Text>{error.type}</Text>
        <Text>{error.code}</Text>
        <Text>{getError(error.message)}</Text>
      </TableRow>
    );
  };

  const renderErrors = () => {
    return (
      <>
        <TableHeader mb={0.5}>
          <Text color="#bbb" fontWeight={500}>
            Error Type
          </Text>
          <Text color="#bbb" fontWeight={500}>
            Code
          </Text>
          <Text color="#bbb" fontWeight={500}>
            Message
          </Text>
        </TableHeader>
        {uniqueErrors.map(renderError)}
      </>
    );
  };

  return (
    <Modal isOpen={hasApiError}>
      <Box
        mb={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Header>Error fetching data</Header>
      </Box>
      <Box mb={3}>
        A request has failed. Our team has been notified and we're looking into
        it
      </Box>

      <Box mb={3}>{renderErrors()}</Box>

      {/* Responsive buttons should be handled in css, but needing to move fast*/}
      <Box>{renderButtons()}</Box>
    </Modal>
  );
};

export default PreviewModal;

const Header = styled.h2`
  font-weight: 500;
  font-size: 26px;
  color: #eee;
  text-align: center;
  line-height: 1.2em;
`;

const TableHeader = styled(Box)`
  display: grid;
  grid-template-columns: 4fr 1fr 5fr;
  grid-gap: 6px;
  align-items: center;
`;
const TableRow = styled(Box)`
  display: grid;
  grid-template-columns: 4fr 1fr 5fr;
  grid-gap: 6px;
  align-items: center;
`;
