import { useState } from "react";
import { fromWei, toWei } from "utils/numbers";
import { useSelector } from "react-redux";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { calculateMaxWalletDeposit } from "../../utils/depositUtils";
import { BigNumber } from "ethers";

// @TODO We'll need to be smarter about this to handle variable gas prices
export const STABLE_GAS_BUFFER = toWei("200");

const useWalletDeposit = () => {
  const { stableTokenProperties, assetTokenProperties } = useSelector(
    getExchangeTokenProperties
  );
  const [
    stableQuantityToDepositToWallet,
    setStableQuantityToDepositToWallet
  ] = useState("");
  const [
    assetQuantityToDepositToWallet,
    setAssetQuantityToDepositToWallet
  ] = useState("");
  const [activeFieldName, setActiveFieldName] = useState(undefined);

  const handleSetMaxAmountWallet = () => {
    const { maxAssetAmount, maxStableAmount } = calculateMaxWalletDeposit(
      assetTokenProperties.localBalance,
      toWei(
        fromWei(
          stableTokenProperties.localBalance,
          stableTokenProperties.decimals
        ).toString()
      ),
      assetTokenProperties.indexPrice,
      STABLE_GAS_BUFFER
    );
    setAssetQuantityToDepositToWallet(maxAssetAmount);
    setStableQuantityToDepositToWallet(maxStableAmount);
    setActiveFieldName(undefined);
  };

  const handleChangeAssetAmount = e => {
    const { name, value } = e.target;
    if (Number(value) > 0) {
      const assetQuantityWei = toWei(Number(value).toFixed(18).toString());
      const calculatedStableQuantity = BigNumber.from(assetQuantityWei)
        .mul(BigNumber.from(assetTokenProperties.indexPrice))
        .div(BigNumber.from(stableTokenProperties.price))
        .add(BigNumber.from(STABLE_GAS_BUFFER))
        .toString();

      setStableQuantityToDepositToWallet(calculatedStableQuantity);
      setAssetQuantityToDepositToWallet(assetQuantityWei);
      setActiveFieldName(name);
    } else {
      setStableQuantityToDepositToWallet("");
      setAssetQuantityToDepositToWallet("");
      setActiveFieldName(undefined);
    }
  };

  const handleChangeStableAmount = e => {
    const { name, value } = e.target;
    if (Number(value) > 0) {
      const gasReserveInAsset = BigNumber.from(STABLE_GAS_BUFFER)
        .mul(BigNumber.from(toWei("1")))
        .div(BigNumber.from(assetTokenProperties.indexPrice));

      const calculatedAssetQuantity = BigNumber.from(toWei(value))
        .mul(BigNumber.from(stableTokenProperties.price))
        .div(BigNumber.from(assetTokenProperties.indexPrice))
        .sub(gasReserveInAsset)
        .toString();
      setAssetQuantityToDepositToWallet(calculatedAssetQuantity);
      setStableQuantityToDepositToWallet(toWei(value));
      setActiveFieldName(name);
    } else {
      setStableQuantityToDepositToWallet("");
      setAssetQuantityToDepositToWallet("");
      setActiveFieldName(undefined);
    }
  };

  const handleResetInputs = () => {
    setStableQuantityToDepositToWallet("");
    setAssetQuantityToDepositToWallet("");
  };

  return {
    handleChangeAssetAmount,
    handleChangeStableAmount,
    handleSetMaxAmountWallet,
    handleResetInputs,
    stableQuantityToDepositToWallet:
      stableQuantityToDepositToWallet === ""
        ? ""
        : toWei(
            fromWei(stableQuantityToDepositToWallet)
              .toFixed(Number(stableTokenProperties.decimals))
              .toString(),
            stableTokenProperties.decimals
          ),
    assetQuantityToDepositToWallet,
    activeFieldName
  };
};

export default useWalletDeposit;
