import React from "react";
import Box from "components/Box";
import { useSelector } from "react-redux";
import { getProposals } from "redux/governance/selectors";

import ProposalSummaryCard from "./ProposalSummaryCard";
import { navigate } from "@reach/router";
import TitleBar from "./TitleBar";

const Proposals = () => {
  const proposals = useSelector(getProposals);
  const renderProposalSummaryCard = data => {
    const {
      id,
      title,
      description,
      noVotes,
      yesVotes,
      timeLeft,
      finished,
      isVoteResolved,
      result,
      action,
      ownerApproved
    } = data;
    return (
      <Box mb={2} key={id}>
        <ProposalSummaryCard
          path={`proposal/${id}`}
          title={title}
          description={description}
          noVotes={noVotes}
          yesVotes={yesVotes}
          timeLeft={timeLeft}
          finished={finished}
          isVoteResolved={isVoteResolved}
          id={id}
          result={result}
          action={action}
          ownerApproved={ownerApproved}
        />
      </Box>
    );
  };

  return (
    <Box>
      <TitleBar title="Governance" />
      <Box>
        {proposals.length > 0
          ? proposals.map(data => renderProposalSummaryCard(data))
          : "No proposals found."}
      </Box>
    </Box>
  );
};

export default Proposals;
