import { getPoolImbalance } from "./actions";
import deepMerge from "deepmerge";

const INTERNAL_EXCHANGE_STATE = {
  events: {},
  poolImbalanceData: {} // { traderSendsDiscountedAmount, traderWillReceiveAmount, exchangeNeedsAsset } ,
};

export default {
  state: INTERNAL_EXCHANGE_STATE,
  reducers: {
    setEventsData: (state, events) =>
      deepMerge(state, {
        ...state.events,
        events
      }),
    setPoolImbalanceData: (state, poolImbalanceData) => {
      return {
        ...state,
        poolImbalanceData
      };
    }
  },
  effects: dispatch => {
    return {
      async updateInternalExchangeData(_, { dynamicExchangeData }) {
        const poolImbalanceData = await getPoolImbalance({
          exchangeAddress: dynamicExchangeData?.exchange?.exchangeAddress,
          assetPrice: dynamicExchangeData?.exchange?.assetPrice,
          stablePrice: dynamicExchangeData?.exchange?.stablePrice
        });
        dispatch.internalExchange.setPoolImbalanceData(poolImbalanceData);
      }
    };
  }
};
