import deepMerge from "deepmerge";
import getProvider from "provider";
import { getWalletInstance } from "../../contracts/Wallet";
import { toWei, fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import { toast } from "react-toastify";
import { getErc20Instance } from "contracts/ERC20";
import { NULL_ADDRESS } from "../../constants";
import { DataLoader } from "data/DataLoader";
import { getLocalWalletAccountAddress } from "../actions";
import { doGetTokenProperties, doDepositToken } from "./actions";
import { getStakingContractAddresses } from "../../constants";
import { FsDispatch } from "redux/store";

const renderToast = (message: string) => {
  toast.dark(message, {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined
  });
};

export const createInitializeWalletInfo = (
  dispatch: FsDispatch,
  getLocalWalletAccountAddressFn: () => Promise<string>,
  getNetworkFn: () => Promise<string>,
  getTokenPropertiesFn: (
    tokenAddress: string,
    accountAddress: string,
    spenderContract: string
  ) => Promise<TokenProperties>
) => {
  return async (_, { registry }: { registry: any }) => {
    dispatch.wallet.setRequestsValues({ isInitialized: false });
    dispatch.wallet.resetState();
    dispatch.positions.resetState();
    dispatch.termsAndActions.getAllowedActions();

    const [accountAddress, network] = await Promise.all([
      getLocalWalletAccountAddressFn(),
      getNetworkFn()
    ]);

    dispatch.wallet.setAccountAddress(accountAddress); // dispatch this first bc other actions need it
    const {
      uniswapLpToken,
      stakingRewardsContract
    } = getStakingContractAddresses(network);

    const tokenValues = await Promise.all([
      getTokenPropertiesFn(
        registry.wethAddress,
        accountAddress,
        registry.walletAddress
      ),
      getTokenPropertiesFn(
        registry.usdcAddress,
        accountAddress,
        registry.walletAddress
      ),
      getTokenPropertiesFn(
        registry.fsTokenAddress,
        accountAddress,
        registry.walletAddress
      ),
      getTokenPropertiesFn(
        uniswapLpToken,
        accountAddress,
        stakingRewardsContract
      )
    ]);

    dispatch.wallet.updateApiTokenBalances();
    dispatch.wallet.getTokenDepositsPendingApiSync();
    dispatch.wallet.checkIfPendingDepositsAreSynced();
    dispatch.wallet.setNetwork(network);
    dispatch.wallet.addTokens(tokenValues);
    dispatch.wallet.setRequestsValues({ isInitialized: true });
  };
};

export const createGetUserWalletBalanceFromApi = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  let userWalletData;
  return async (_, { wallet }) => {
    try {
      // TODO(dankurka): use server side type
      const response = await dataLoader.get(
        `/userwallet/${wallet.accountAddress}`
      );
      userWalletData = response.data;
    } catch (e) {
      userWalletData = {
        userAddress: NULL_ADDRESS,
        assetTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
        stableTokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        assetTokenAmount: "0",
        stableTokenAmount: "0",
        liquidity: {
          liquidityTokenAmount: "0",
          assetOwned: "0",
          stableOwned: "0"
        },
        hasEntry: false
      };
    }

    dispatch.wallet.setFsTokenBalance(
      userWalletData.assetTokenAddress,
      userWalletData.assetTokenAmount
    );
    dispatch.wallet.setFsTokenBalance(
      userWalletData.stableTokenAddress,
      userWalletData.stableTokenAmount
    );
    dispatch.wallet.setPoolLiquidity(userWalletData.liquidity);
  };
};
export const createCheckIfPendingDepositsAreSynced = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  return async (_, { wallet }) => {
    try {
      // TODO(dankurka): use server side type
      if (wallet.requests.tokenDepositsPendingApiSync.length > 0) {
        const response = await dataLoader.get<GetBlockNumberResponse>(
          "/getblocknumber"
        );
        wallet.requests.tokenDepositsPendingApiSync.forEach(token => {
          const tokenProperties = wallet.tokens[token.address];
          const formattedDepositAmount = formatNumber(
            fromWei(token.amount, tokenProperties.decimals),
            2
          );
          if (token.blockNumberAtDeposit <= response.data.blockNumber) {
            renderToast(
              `${formattedDepositAmount} ${tokenProperties.symbol} deposit to your Futureswap wallet confirmed`
            );
            dispatch.wallet.removeTokenDepositsPendingApiSync(token.address);
          }
        });
      }
    } catch (e) {
      // TODO(JONAS): unhandled error
    }
  };
};

export const createUpdateMetamaskBalance = (
  dispatch: FsDispatch,
  getLocalEthBalanceFn: (accountAddress: string) => Promise<string>,
  getLocalErc20BalanceFn: (
    tokenAddress: any,
    accountAddress: string
  ) => Promise<string>
) => {
  return async (tokenAddress: string, { wallet }) => {
    // const tokenInstance = getErc20Instance(tokenAddress);
    const tokenProperties = wallet.tokens[tokenAddress];
    const isEth = tokenProperties.symbol === "ETH";

    const getLocalBalance = isEth
      ? getLocalEthBalanceFn(wallet.accountAddress)
      : getLocalErc20BalanceFn(tokenAddress, wallet.accountAddress);
    try {
      const localBalance = await getLocalBalance;
      dispatch.wallet.setMetamaskTokenBalance({
        tokenAddress,
        localBalance
      });
    } catch {
      // TODO (JONAS): unhandled error
    }
  };
};

//this one is hairy -- ok doing it later
export const createUnlockToken = (dispatch: FsDispatch) => {
  return async (tokenAddress: string, { registry, wallet }) => {
    const { fsWalletAddress } = registry;
    const { network } = wallet;
    const {
      stakingRewardsContract,
      uniswapLpToken
    } = getStakingContractAddresses(network);

    const tokenInstance = getErc20Instance(tokenAddress);
    const contractToApprove =
      tokenAddress === uniswapLpToken
        ? stakingRewardsContract
        : fsWalletAddress;

    const unlockTransaction = await tokenInstance.methods
      .approve(contractToApprove, toWei("1000000000000000000000000000000"))
      .send({ from: wallet.accountAddress })
      .on("transactionHash", () => {
        dispatch.wallet.setTokenIsUnlocking(tokenAddress, true);
      });
    if (unlockTransaction.status === true) {
      dispatch.wallet.setTokenIsUnlocked(tokenAddress, true);
    }
    dispatch.wallet.setTokenIsUnlocking(tokenAddress, false);
  };
};

// Inject doDepositToken - don't worry about testing doDepositToken directly right now
export const createDepositToken = (dispatch: FsDispatch) => {
  return async (
    { tokenAddress, amount }: { tokenAddress: string; amount: string },
    { registry, wallet }
  ) => {
    const tokenProperties = wallet.tokens[tokenAddress];
    const isWeth = tokenProperties.symbol === "ETH";
    dispatch.wallet.setOrRemovetokensBeingDeposited([tokenAddress], true);
    try {
      await doDepositToken(
        {
          fsWalletAddress: registry.walletAddress,
          accountAddress: wallet.accountAddress,
          tokenAddress,
          isWeth,
          amount
        },
        blockNumber => {
          dispatch.wallet.setTokenDepositsPendingApiSync({
            address: tokenAddress,
            blockNumberAtDeposit: blockNumber,
            amount
          });
        }
      );
    } catch {
      dispatch.wallet.setOrRemovetokensBeingDeposited([tokenAddress], false);
    }
    dispatch.wallet.setOrRemovetokensBeingDeposited([tokenAddress], false);
    await dispatch.wallet.updateMetamaskBalance(tokenAddress);
  };
};

export const createDepositTokenPair = (dispatch: FsDispatch) => {
  return async (
    {
      assetAddress,
      assetAmount,
      stableAddress,
      stableAmount
    }: {
      assetAddress: string;
      assetAmount: string;
      stableAddress: string;
      stableAmount: string;
    },
    { registry, wallet }
  ) => {
    const assetTokenProperties = wallet.tokens[assetAddress];
    const isWeth = assetTokenProperties.symbol === "ETH";
    const walletInstance = getWalletInstance(registry.walletAddress);
    const onTransactionHash = () =>
      dispatch.wallet.setOrRemovetokensBeingDeposited(
        [assetAddress, stableAddress],
        true
      );
    const onFirstConfirmation = (
      confirmationNumber: number,
      transaction: any
    ) => {
      if (confirmationNumber === 0 && transaction.status === true) {
        dispatch.wallet.setTokenDepositsPendingApiSync({
          address: assetAddress,
          blockNumberAtDeposit: transaction.blockNumber,
          amount: assetAmount
        });
        dispatch.wallet.setTokenDepositsPendingApiSync({
          address: stableAddress,
          blockNumberAtDeposit: transaction.blockNumber,
          amount: stableAmount
        });
      }
    };

    isWeth
      ? await walletInstance.methods
          .deposit(stableAddress, stableAmount)
          .send({ from: wallet.accountAddress, value: assetAmount })
          .on("transactionHash", onTransactionHash)
          .on("confirmation", onFirstConfirmation)
      : await walletInstance.methods
          .depositTokenPair(
            assetAddress,
            assetAmount,
            stableAddress,
            stableAmount
          )
          .send({ from: wallet.accountAddress })
          .on("transactionHash", onTransactionHash)
          .on("confirmation", onFirstConfirmation);

    dispatch.wallet.updateMetamaskBalance(assetAddress);
    dispatch.wallet.updateMetamaskBalance(stableAddress);
    dispatch.wallet.setOrRemovetokensBeingDeposited(
      [assetAddress, stableAddress],
      false
    );
  };
};

type Liquidity = {
  liquidityTokenAmount: string;
  assetOwned: string;
  stableOwned: string;
};

export interface GetBlockNumberResponse {
  blockNumber: string;
}

export interface TokenDeposit {
  address: string;
  amount: string;
  blockNumberAtDeposit: number;
}

export interface TokenProperties {
  address: string;
  localBalance: string;
  fsBalance: string;
  symbol: string;
  decimals: string;
  isUnlocked: boolean;
  isUnlocking: boolean;
}

export const WALLET_INITIAL_STATE = {
  network: undefined,
  accountAddress: "",
  averageGasGwei: "0",
  tokens: {} as { [key: string]: TokenProperties },
  liquidity: {
    liquidityTokenAmount: "0",
    assetOwned: "0",
    stableOwned: "0"
  },
  requests: {
    isInitialized: false,
    tokensBeingDeposited: [] as string[], // Arr of tokenAdddresses
    tokenDepositsPendingApiSync: [] as TokenDeposit[] // { address, blockNumberAtDeposit, amount }
  }
};

export type WalletModel = typeof WALLET_INITIAL_STATE;

export default {
  state: WALLET_INITIAL_STATE,
  reducers: {
    resetState: () => WALLET_INITIAL_STATE,
    setAccountAddress: (state: WalletModel, accountAddress: string) => ({
      ...state,
      accountAddress
    }),
    setNetwork: (state: WalletModel, network: string) =>
      deepMerge(state, { network }),
    addTokens: (state: WalletModel, tokens: TokenProperties[] = []) => {
      // map tokens into an object
      const mappedTokens = tokens.reduce(
        (pre, token) => ({ ...pre, [token.address]: token }),
        {}
      );
      return deepMerge(state, {
        tokens: {
          ...mappedTokens
        }
      });
    },
    setMetamaskTokenBalance: (
      state: WalletModel,
      {
        tokenAddress,
        localBalance
      }: { tokenAddress: string; localBalance: string }
    ) =>
      deepMerge(state, {
        tokens: {
          [tokenAddress]: { localBalance }
        }
      }),
    setTokenIsUnlocked: (
      state: WalletModel,
      tokenAddress: string,
      isUnlocked: boolean
    ) =>
      deepMerge(state, {
        tokens: {
          [tokenAddress]: { isUnlocked }
        }
      }),
    setFsTokenBalance: (
      state: WalletModel,
      tokenAddress: string,
      fsBalance: string
    ) =>
      deepMerge(state, {
        tokens: {
          [tokenAddress]: { fsBalance }
        }
      }),
    setPoolLiquidity: (state: WalletModel, liquidity: Liquidity) => ({
      ...state,
      liquidity
    }),
    setTokenIsUnlocking: (
      state: WalletModel,
      tokenAddress: string,
      isUnlocking: boolean
    ) =>
      deepMerge(state, {
        tokens: {
          [tokenAddress]: { isUnlocking }
        }
      }),
    setOrRemovetokensBeingDeposited: (
      state: WalletModel,
      tokensArr: string[],
      isDepositing: boolean
    ) => {
      // if a token is currently being deposited add it to an array
      // when the action is finished, remove it from the array
      const tokensBeingDeposited = isDepositing
        ? deepMerge(state.requests.tokensBeingDeposited, tokensArr)
        : state.requests.tokensBeingDeposited.filter(
            token => !tokensArr.includes(token)
          );
      return {
        ...state,
        requests: { ...state.requests, tokensBeingDeposited }
      };
    },
    setRequestsValues: (state: WalletModel, values = {}) =>
      deepMerge(state, {
        requests: {
          ...values
        }
      }),
    getTokenDepositsPendingApiSync: (state: WalletModel) => {
      const tokenDepositsPendingApiSync = window.localStorage.getItem(
        "tokenDepositsPendingApiSync"
      );
      if (tokenDepositsPendingApiSync) {
        return {
          ...state,
          requests: {
            ...state.requests,
            tokenDepositsPendingApiSync: JSON.parse(tokenDepositsPendingApiSync)
          }
        };
      }
      return state;
    },
    setTokenDepositsPendingApiSync: (
      state: WalletModel,
      pendingTokenDepositObj: {
        address: string;
        blockNumberAtDeposit: number;
        amount: string;
      }
    ) => {
      const newPendingTokenDeposit = [
        ...state.requests.tokenDepositsPendingApiSync,
        pendingTokenDepositObj
      ];
      window.localStorage.setItem(
        "tokenDepositsPendingApiSync",
        JSON.stringify(newPendingTokenDeposit)
      );
      return {
        ...state,
        requests: {
          ...state.requests,
          tokenDepositsPendingApiSync: newPendingTokenDeposit
        }
      };
    },
    removeTokenDepositsPendingApiSync: (
      state: WalletModel,
      address: string
    ) => {
      const newPendingTokenDeposit = state.requests.tokenDepositsPendingApiSync.filter(
        token => (token.address === address ? false : true)
      );
      window.localStorage.setItem(
        "tokenDepositsPendingApiSync",
        JSON.stringify(newPendingTokenDeposit)
      );
      return {
        ...state,
        requests: {
          ...state.requests,
          tokenDepositsPendingApiSync: newPendingTokenDeposit
        }
      };
    },
    setAverageGasWei: (state: any, averageGasGwei: string) => {
      return { ...state, averageGasGwei };
    }
  },
  effects: (dispatch: FsDispatch) => {
    return {
      async connectToWallet(_, { wallet }) {
        window.ethereum.enable().then(function () {
          // User has allowed account access to DApp...
        });
      },
      initializeWalletInfo: createInitializeWalletInfo(
        dispatch,
        getLocalWalletAccountAddress,
        () => getProvider().eth.net.getNetworkType(),
        doGetTokenProperties
      ),
      updateApiTokenBalances: createGetUserWalletBalanceFromApi(
        dispatch,
        new DataLoader()
      ),
      updateMetamaskBalance: createUpdateMetamaskBalance(
        dispatch,
        accountAddress => getProvider().eth.getBalance(accountAddress),
        (tokenAddress, accountAddress) => {
          const tokenInstance = getErc20Instance(tokenAddress);
          return tokenInstance.methods.balanceOf(accountAddress).call();
        }
      ),
      unlockToken: createUnlockToken(dispatch),
      depositToken: createDepositToken(dispatch),
      depositTokenPair: createDepositTokenPair(dispatch),
      //Checks if pending deposits have synced with the API
      checkIfPendingDepositsAreSynced: createCheckIfPendingDepositsAreSynced(
        dispatch,
        new DataLoader()
      ),
      async updateAverageGasGwei() {
        const averageGasGwei = await getProvider().eth.getGasPrice();
        dispatch.wallet.setAverageGasWei(averageGasGwei);
      }
    };
  }
};
