import { DataLoader } from "data/DataLoader";
import { RegistryModel } from "redux/registry/model";
import { FsDispatch } from "redux/store";

export interface Error {
  type: string;
  message: string;
  code: number;
}

const API_INITIAL_STATE = {
  errors: [] as Error[],
  // TODO(dankurka): type is broken here
  status: {
    status: "",
    message: ""
  } as ApiResponse,
  loadingFailure: false
};

export type APIModel = typeof API_INITIAL_STATE;

export interface ApiResponse {
  status: string;
  message: string;
}

export const createGetApiStatus = (
  dispatch: FsDispatch,
  dataLoader: DataLoader
) => {
  return async (_, { registry }: { registry: RegistryModel }) => {
    const { exchange } = registry;
    try {
      // TODO(dankurka): use server type here
      const { data } = await dataLoader.get<ApiResponse>(
        `/exchange/${exchange.address}/status`
      );
      dispatch.api.setStatus(data);
      dispatch.api.setLoadingFailure(false);
    } catch (e) {
      // TODO(dankurka): central error handling
      dispatch.api.setLoadingFailure(true);
    }
  };
};

export default {
  state: API_INITIAL_STATE,
  reducers: {
    resetState: () => API_INITIAL_STATE,
    addError: (state: APIModel, error: Error) => ({
      ...state,
      errors: [...state.errors, error]
    }),
    setStatus: (state: APIModel, status: ApiResponse) => ({
      ...state,
      status
    }),
    resetStatus: state => ({
      ...state,
      status: API_INITIAL_STATE.status
    }),
    clearErrors: (state: APIModel) => ({
      ...state,
      errors: []
    }),
    setLoadingFailure: (state: APIModel, loadingFailure: boolean) => ({
      ...state,
      loadingFailure
    })
  },
  effects: dispatch => {
    return { getApiStatus: createGetApiStatus(dispatch, new DataLoader()) };
  }
};
