import React from "react";
import Box from "components/Box";
import { MainMetrics, H2 } from "components/Typography";
import { useSelector } from "react-redux";
import Table from "components/Table";
import ActionButton from "./ActionButton";
import PulsatingWarningDot from "components/PulsatingWarningDot";
import Tippy from "components/Tippy";
import { fromWei } from "utils/numbers";
import { formatNumber } from "utils/formatter";
import styled from "@emotion/styled";
import { selectCurrentExchangeDynamicData } from "redux/dynamicExchangeData/selectors";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";
import { RegistryModel } from "redux/registry/model";
import V2SunsetButton from "components/V2SunsetButton";

const WalletList = () => {
  const {
    tokens,
    requests: { tokenDepositsPendingApiSync }
  } = useSelector<FsRootState, WalletModel>(state => state.wallet);
  const { usdcAddress, wethAddress } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );

  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);

  const renderButtons = token => (
    <Box display="flex" alignItems="flex-end" flexDirection="column">
      <Box display="flex" alignItems="flex-end">
        <Box mr={2}>
          <V2SunsetButton>Desposit</V2SunsetButton>
        </Box>
        <ActionButton token={token} isDeposit={false} />
      </Box>
    </Box>
  );

  const renderFsBalance = (balance, isPendingSync) =>
    isPendingSync ? (
      <Tippy content="Your balance will be updated and available about 5 minutes from deposit">
        <Box display="flex">
          {formatNumber(fromWei(balance), 2)}
          <Box ml={1}>
            <PulsatingWarningDot />
          </Box>
        </Box>
      </Tippy>
    ) : (
      <>{formatNumber(fromWei(balance), 2)}</>
    );

  const tokenAddresses = Object.keys(tokens);

  const renderRows = () => {
    return (
      tokenAddresses
        // TODO(danurka): Can be improved
        .filter(
          tokenAddress =>
            tokenAddress === usdcAddress || tokenAddress === wethAddress
        )
        .map(tokenAddress => {
          const token = tokens[tokenAddress];
          const isPendingSync = tokenDepositsPendingApiSync.some(
            token => token.address === tokenAddress
          );
          return {
            cells: [
              <Box display="flex" alignItems="center" key="symbol">
                <TokenIcon
                  src={`/crypto-icons/${
                    token.symbol && token.symbol.toLowerCase()
                  }.png`}
                />
                <MainMetrics fontSize="16px">{token.symbol}</MainMetrics>
              </Box>,
              <MainMetrics fontSize="16px" key="price">
                {token.symbol === "USDC"
                  ? fromWei(dynamicExchangeData.stablePrice).toFixed(2)
                  : fromWei(dynamicExchangeData.assetPrice).toFixed(2)}
              </MainMetrics>,
              <MainMetrics fontSize="16px" key="localWalletBalance">
                {formatNumber(fromWei(token.localBalance, token.decimals), 2)}
              </MainMetrics>,
              <MainMetrics fontSize="16px" key="futureswapBalance">
                {renderFsBalance(token.fsBalance, isPendingSync)}
              </MainMetrics>,
              renderButtons({
                address: tokenAddress,
                symbol: token.symbol,
                isUnlocked: token.isUnlocked,
                isUnlocking: false,
                localBalance:
                  token.localBalance /* localWallet.localBalance, @TODO come back to */,
                fsBalance: token.fsBalance
                  ? token.fsBalance
                  : "0" /* localWallet.fsBalance @TODO come back to*/,
                decimals: token.decimals
              })
            ]
          };
        })
    );
  };

  return (
    <Box my={6}>
      <Box mb={1}>
        <H2>Wallet</H2>
        <Table
          size="lg"
          isStructured
          headings={[
            { content: "ASSET", width: "18%" },
            { content: "PRICE", width: "18%" },
            {
              content: (
                <>
                  <div>Metamask</div>
                  <div>Balance</div>
                </>
              ),
              width: "18%"
            },
            {
              content: (
                <>
                  <div>Futureswap</div>
                  <div>Balance</div>
                </>
              ),
              width: "18%"
            },
            { content: "", width: "28%" }
          ]}
          rows={renderRows()}
        />
      </Box>
    </Box>
  );
};

const TokenIcon = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 8px;
`;

export default WalletList;
