import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import Box from "components/Box";
import { fromWei } from "utils/numbers";
import { MainMetrics, H4 } from "components/Typography";
import { COLORS } from "styles/theme";

import Tooltip from "components/Tooltip";
import { selectCurrentExchangeDynamicData } from "redux/dynamicExchangeData/selectors";
import { formatNumber } from "utils/formatter";

const PoolUtilization = () => {
  const dynamicExchangeData = useSelector(selectCurrentExchangeDynamicData);

  const displayShortUsage = () => {
    if (Number(fromWei(dynamicExchangeData.stablePoolSize)) > 0) {
      return (
        <div>
          {formatNumber(fromWei(dynamicExchangeData.shortAssetBorrowed), 2)}/
          {formatNumber(
            fromWei(dynamicExchangeData.stablePoolSize) /
              Number(fromWei(dynamicExchangeData.assetPrice)),
            2
          )}
        </div>
      );
    } else {
      return (
        <div>
          0/
          {formatNumber(fromWei(dynamicExchangeData.stablePoolSize), 2)}
        </div>
      );
    }
  };

  const renderMetrics = () => (
    <>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <H4>Longs</H4>
        <H4>Shorts</H4>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <MainMetrics color={COLORS.GREEN} fontSize="12px">
          {formatNumber(fromWei(dynamicExchangeData.longAssetBorrowed), 2)}/
          {formatNumber(fromWei(dynamicExchangeData.assetPoolSize), 2)}
        </MainMetrics>
        <MainMetrics color={COLORS.RED} fontSize="12px">
          {displayShortUsage()}
        </MainMetrics>
      </Box>
    </>
  );

  const renderBars = () => (
    <BarContainer
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <OuterBar flex="1" mr={0.25}>
        <InnerBar
          flexDirection="row-reverse"
          amount={Number(
            fromWei(Number(dynamicExchangeData.assetPoolUtilization) * 100)
          )}
          background={COLORS.GREEN}
        >
          <Box flex="1" />
          {Number(
            fromWei(Number(dynamicExchangeData.assetPoolUtilization) * 100)
          ).toFixed(0)}
          %
        </InnerBar>
      </OuterBar>
      <OuterBar display="flex" flex="1" ml={0.25}>
        <Box flex="1" />
        <InnerBar
          amount={Number(
            fromWei(Number(dynamicExchangeData.stablePoolUtilization) * 100)
          )}
          background={COLORS.RED}
        >
          {Number(
            fromWei(Number(dynamicExchangeData.stablePoolUtilization) * 100)
          ).toFixed(0)}
          %
          <Box flex="1" />
        </InnerBar>
      </OuterBar>
    </BarContainer>
  );

  return (
    <Box>
      <Box mb={1} display="flex" flexDirection="row" alignItems="center">
        <Box>Pool Usage</Box>
        <Tooltip
          content={`How much of the long and short pool are being utilized by open interest`}
        />
      </Box>
      <Box mb={0.75}>{renderMetrics()}</Box>
      {renderBars()}
    </Box>
  );
};

export default PoolUtilization;

const BarContainer = styled(Box)`
  font-size: 10px;
  font-weight: 500;
  color: white;
`;

const OuterBar = styled(Box)`
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  background: #717171;
`;

const InnerBar = styled(Box)<{ amount: number; background: string }>`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  height: 21px;
  padding: 8px;
  min-width: 7%;
  width: ${props => `${props.amount}%`};
  background: ${props => props.background};
`;
