export const calculatePoolFeesAPY = (
  exchangeVolume: string,
  stablePoolSize: string
) => {
  const feesEarned = Number(exchangeVolume) * 0.00134375;
  if (stablePoolSize) {
    // TODO(dankurka):  stablepoolsize is in wei
    const fullPoolValue = Number(stablePoolSize) * 2;
    const dailyProfitPercent = feesEarned / fullPoolValue;

    return (Math.pow(1 + dailyProfitPercent, 365) - 1) * 100;
  }
  return 0;
};
