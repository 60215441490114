import { useState, useCallback } from "react";
import { fromWei, toWei } from "utils/numbers";
import { useSelector } from "react-redux";
import axios from "axios";
import { debounce } from "lodash";
import { getMessageProcessorEndpoint } from "../../../../constants";
import { BigNumber } from "ethers";
import { FsRootState } from "redux/store";
import { WalletModel } from "redux/wallet/model";
import { RegistryModel } from "redux/registry/model";

const usePoolWithdraw = () => {
  const { liquidity } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { accountAddress } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );

  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );

  const [stableQuantityToWithdraw, setStableQuantityToWithdraw] = useState("");
  const [assetQuantityToWithdraw, setAssetQuantityToWithdraw] = useState("");
  const [
    liquidityTokenQuantityToWithdraw,
    setLiquidityTokenQuantityToWithdraw
  ] = useState("");
  const [validationResults, setValidationResults] = useState({
    reason: "",
    gasCostValue: "0",
    isValid: false
  });
  const [activeFieldName, setActiveFieldName] = useState(undefined);

  const handleValidate = async liquidityTokens => {
    try {
      const endpoint = await getMessageProcessorEndpoint();

      const { data } = await axios.post(
        `${endpoint}/${accountAddress}/validation/changeliquidity`,
        JSON.stringify({
          isAddingLiquidity: false,
          amount: liquidityTokens, // amount == liquidity tokens for withdraw
          exchangeAddress: exchange.address
        })
      );
      setValidationResults(data);
    } catch (e) {
      // TODO(dankurka): handle error
      console.error(e);
      setValidationResults({ reason: "", gasCostValue: "0", isValid: false });
    }
  };

  const handleValidateDebounced = useCallback(
    debounce(handleValidate, 200),
    []
  );

  const handleSetMaxAmount = () => {
    setAssetQuantityToWithdraw(liquidity.assetOwned);
    setStableQuantityToWithdraw(liquidity.stableOwned);
    setLiquidityTokenQuantityToWithdraw(liquidity.liquidityTokenAmount);
    handleValidateDebounced(liquidity.liquidityTokenAmount);
    setActiveFieldName(undefined);
  };

  // TODO(dankurka): Types
  let percentageOfLtToWithdraw =
    Number(assetQuantityToWithdraw) / Number(liquidity.assetOwned);
  percentageOfLtToWithdraw =
    percentageOfLtToWithdraw > 1 || isNaN(percentageOfLtToWithdraw)
      ? 0
      : percentageOfLtToWithdraw;

  const handleChangePercentage = percentage => {
    if (
      liquidity.assetOwned !== undefined ||
      liquidity.stableOwned !== undefined ||
      liquidity.liquidityTokenAmount !== undefined
    ) {
      const assetAmount = BigNumber.from(liquidity.assetOwned)
        .mul(BigNumber.from(toWei(percentage)))
        .div(BigNumber.from(toWei("1")))
        .toString();

      const stableAmount = BigNumber.from(liquidity.stableOwned)
        .mul(BigNumber.from(toWei(percentage)))
        .div(BigNumber.from(toWei("1")))
        .toString();

      const ltAmount = BigNumber.from(liquidity.liquidityTokenAmount)
        .mul(BigNumber.from(toWei(percentage)))
        .div(BigNumber.from(toWei("1")))
        .toString();

      handleValidateDebounced(ltAmount);
      setAssetQuantityToWithdraw(assetAmount === "0" ? "" : assetAmount);
      setStableQuantityToWithdraw(stableAmount === "0" ? "" : stableAmount);
      setLiquidityTokenQuantityToWithdraw(ltAmount === "0" ? "" : ltAmount);
    }
  };

  const handleChangeAssetAmount = e => {
    const { name, value } = e.target;

    if (Number(value) > 0) {
      const percentageOfLt = (value / fromWei(liquidity.assetOwned)).toFixed(
        18
      );
      const stableToWithdraw = BigNumber.from(toWei(percentageOfLt.toString()))
        .mul(BigNumber.from(liquidity.stableOwned))
        .div(BigNumber.from(toWei("1")));
      const liquidityTokenToWithdraw = BigNumber.from(
        toWei(percentageOfLt.toString())
      )
        .mul(BigNumber.from(liquidity.liquidityTokenAmount))
        .div(BigNumber.from(toWei("1")))
        .toString();

      setAssetQuantityToWithdraw(toWei(value));
      setActiveFieldName(name);

      const isValid =
        Number(fromWei(liquidityTokenToWithdraw)) >
        Number(fromWei(liquidity.liquidityTokenAmount));

      handleValidateDebounced(liquidityTokenToWithdraw);
      setStableQuantityToWithdraw(isValid ? "" : stableToWithdraw.toString());
      setLiquidityTokenQuantityToWithdraw(
        isValid ? "" : liquidityTokenToWithdraw
      );
    } else {
      setStableQuantityToWithdraw("");
      setAssetQuantityToWithdraw("");
      setActiveFieldName(undefined);
      setValidationResults({ reason: "", gasCostValue: "0", isValid: false });
    }
  };

  const handleChangeStableAmount = e => {
    const { name, value } = e.target;
    if (Number(value) > 0) {
      const percentageOfLt = (value / fromWei(liquidity.stableOwned)).toFixed(
        18
      );
      const assetToWithdraw = BigNumber.from(toWei(percentageOfLt.toString()))
        .mul(BigNumber.from(liquidity.assetOwned))
        .div(BigNumber.from(toWei("1")));
      const liquidityTokenToWithdraw = BigNumber.from(
        toWei(percentageOfLt.toString())
      )
        .mul(BigNumber.from(liquidity.liquidityTokenAmount))
        .div(BigNumber.from(toWei("1")))
        .toString();

      setStableQuantityToWithdraw(toWei(value));
      setActiveFieldName(name);

      const isValid =
        Number(fromWei(liquidityTokenToWithdraw)) >
        Number(fromWei(liquidity.liquidityTokenAmount));

      handleValidateDebounced(liquidityTokenToWithdraw);
      setAssetQuantityToWithdraw(isValid ? "" : assetToWithdraw.toString());
      setLiquidityTokenQuantityToWithdraw(
        isValid ? "" : liquidityTokenToWithdraw
      );
    } else {
      setStableQuantityToWithdraw("");
      setAssetQuantityToWithdraw("");
      setActiveFieldName(undefined);
      setValidationResults({ reason: "", gasCostValue: "0", isValid: false });
    }
  };

  const handleResetInputs = () => {
    setStableQuantityToWithdraw("");
    setAssetQuantityToWithdraw("");
    setActiveFieldName(undefined);
    setValidationResults({ reason: "", gasCostValue: "0", isValid: false });
  };

  return {
    handleChangeAssetAmount,
    handleChangeStableAmount,
    handleChangePercentage,
    handleSetMaxAmount,
    handleResetInputs,
    assetQuantityToWithdraw,
    stableQuantityToWithdraw,
    liquidityTokenQuantityToWithdraw,
    percentageOfLtToWithdraw,
    activeFieldName,
    validationResults
  };
};

export default usePoolWithdraw;
