import React from "react";
import styled from "@emotion/styled";
import Box from "components/Box";
import { Text } from "components/Typography";

const SectionCard = ({ title, children }) => (
  <SectionCardContainer p={3}>
    <Box mb={2}>
      <Text fontSize="18px" fontWeight={500}>
        {title}
      </Text>
    </Box>
    {children}
  </SectionCardContainer>
);

const SectionCardContainer = styled(Box)`
  background: #272b3e;
`;

export default SectionCard;
