import React, { useState, useEffect } from "react";
import Box from "components/Box";
import ListItem from "components/ListItem";
import * as Sentry from "@sentry/browser";

import { Text } from "components/Typography";
import Input from "components/Input";
import { fromWei, toWei } from "utils/numbers";
import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "styles/theme";
import {
  getMessageProcessorEndpoint,
  API_ENDPOINT_TYPES
} from "../../../../constants";
import axios from "axios";
import { getUnpaddedTokens } from "../../../../constants";
import { BigNumber } from "ethers";
import { getExchangeTokenProperties } from "redux/registry/selectors";
import { FsRootState } from "redux/store";
import { RegistryModel } from "redux/registry/model";
import { WalletModel } from "redux/wallet/model";

const ClosePositionDetails = ({ trade, setAddCollateralClicked }) => {
  const [collateral, setCollateral] = useState<any>(undefined);
  const [isAddingCollateral, setIsAddingCollateral] = useState(false);
  const [addCollateralValidation, setAddCollateralValidation] = useState<any>(
    {}
  );
  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const { accountAddress, network } = useSelector<FsRootState, WalletModel>(
    state => state.wallet
  );
  const { stableTokenProperties } = useSelector(getExchangeTokenProperties);
  const dispatch = useDispatch();

  useEffect(() => {
    validateAddCollateral("1"); // this is to get a gas price initially
  }, []);

  const validateAddCollateral = async collateral => {
    const { FSDPT } = getUnpaddedTokens(network);

    if (collateral && Number(collateral) > 1) {
      const collateralToAdd = String(toWei(String(collateral)));
      try {
        const endpoint = await getMessageProcessorEndpoint();

        const { data } = await axios.post(
          `${endpoint}/${accountAddress}/validation/addcollateral`,
          {
            amount: collateralToAdd,
            tokenAddress: FSDPT.address, //USDC
            tradeId: trade.tradeId
          }
        );
        setAddCollateralValidation(data);
      } catch (e) {
        if (e.response) {
          Sentry.captureException(e);
          if (e.response.status === 500) {
            dispatch.api.addError({
              type: API_ENDPOINT_TYPES.VALIDATE_ADD_COLLATERAL,
              code: e.response.status,
              message: e.response.data?.message
            });
          }
        } else if (e.request) {
          Sentry.captureException(e);
        }
        console.log(e);
      }
    }
  };

  const addCollateral = async trade => {
    try {
      await dispatch.messageProcessor.handleAddCollateral({
        tradeId: trade.tradeId,
        collateral,
        exchangeAddress: exchange.address
      });
      setIsAddingCollateral(false);
    } catch (e) {
      console.error(e);
      setIsAddingCollateral(false);
    }
    setAddCollateralClicked(false);
  };

  return (
    <Box display={{ xs: "block", md: "flex" }} my={4} alignItems="center">
      <Box flex="1" />
      <Box mr={4} mt={{ xs: 2, md: 2 }}>
        {addCollateralValidation?.isValid === false && (
          <Text color={COLORS.RED}>Not enought collateral</Text>
        )}
      </Box>
      <Box mr={4}>
        <Box display="flex" justifyContent="space-between" mb={0.25}>
          <Text color="#757575" fontSize="10px" fontWeight={500}>
            ADD
          </Text>
        </Box>
        <Input
          value={collateral ? fromWei(collateral) : ""}
          type="number"
          placeholder="0.00"
          name="open"
          id="collateral"
          onChange={e => {
            setCollateral(
              e.target.value !== "" ? toWei(e.target.value) : undefined
            );
            validateAddCollateral(e.target.value);
          }}
          rightLabel={stableTokenProperties.symbol}
        />
      </Box>
      <Box mr={4} mt={{ xs: 2, md: 0 }}>
        <ListItem label="Current">
          {`${fromWei(trade.collateral)} ${stableTokenProperties.symbol}`}
        </ListItem>
      </Box>

      <Box mr={4} mt={{ xs: 2, md: 0 }}>
        <ListItem label="New Collateral">
          {`${fromWei(
            BigNumber.from(trade.collateral)
              .add(BigNumber.from(collateral || "0"))
              .toString()
          )} USDC`}
        </ListItem>
      </Box>
      <Box mr={4} mt={{ xs: 2, md: 0 }}>
        <ListItem label="Gas fee">
          {addCollateralValidation?.gasValue &&
            `${Number(fromWei(addCollateralValidation.gasValue)).toFixed(2)} ${
              stableTokenProperties.symbol
            }`}
        </ListItem>
      </Box>

      <Box mt={{ xs: 2, md: 0 }}>
        <Button
          disabled={!collateral}
          onClick={() => addCollateral(trade)}
          isLoading={isAddingCollateral}
          color={COLORS.PURPLE}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default ClosePositionDetails;
