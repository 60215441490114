import React, { useState, useEffect, useRef } from "react";
import { createChart } from "lightweight-charts";
import { useSelector } from "react-redux";
import { selectCandlestickChartData } from "redux/chart/selectors";
import { chartOptions, candleStickOptions } from "./utils";
import Box from "components/Box";
import styled from "@emotion/styled";
import { FsRootState } from "redux/store";
import { RegistryModel } from "redux/registry/model";

const ONE_DAY_IN_SECONDS = 86400; // for setting the charts timespan
let chart; // holds a reference to the chart
let series; // holds a reference to the candlestick

const Chart = () => {
  const chartRef = useRef<any>(null);

  const { exchange } = useSelector<FsRootState, RegistryModel>(
    state => state.registry
  );
  const candlestickChartData = useSelector(selectCandlestickChartData);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const handleResizeChart = () => {
    if (chartRef.current) {
      const { clientWidth, clientHeight } = chartRef.current.parentNode;
      chart.applyOptions({
        ...chartOptions,
        width: clientWidth,
        height: clientHeight - 55
      });
    }
  };

  const handleUpdateChartData = () => {
    if (chart && candlestickChartData.length) {
      if (series) {
        chart.removeSeries(series);
        series = null;
      } else {
        // inital load
        chart.timeScale().setVisibleRange({
          from: new Date().getTime() / 1000 - ONE_DAY_IN_SECONDS * 3,
          to: new Date().getTime() / 1000
        });
      }
      series = chart.addCandlestickSeries(candleStickOptions);
      series.setData(candlestickChartData);
      setIsFetchingData(false);
    }
  };

  // initialize the chart when component mounts
  useEffect(() => {
    chart = createChart(chartRef.current);
    handleResizeChart();
    window.addEventListener("resize", handleResizeChart);
    return () => {
      // clean up when the component unmounts
      chart = null;
      series = null;
      window.removeEventListener("resize", handleResizeChart);
    };
  }, []);

  // fetch new chart data when the exchange changes
  useEffect(() => {
    setIsFetchingData(true);
  }, [exchange]);

  // update chart when new data comes in
  useEffect(() => {
    candlestickChartData && handleUpdateChartData();
  }, [candlestickChartData]);

  return (
    <Container
      isFetchingData={isFetchingData}
      ref={chartRef}
      width="100%"
      height="100%"
    />
  );
};

const Container = styled(Box)<{ isFetchingData: boolean }>`
  opacity: ${props => (props.isFetchingData ? 0.6 : 1)};
`;

export default Chart;
