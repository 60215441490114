import axios, { AxiosResponse } from "axios";
import Logger from "logger/Logger";
import * as Sentry from "@sentry/browser";
import { getMessageProcessorEndpoint } from "../constants";

export interface Error {
  type: string;
  code: number;
  message: string;
}

export class DataLoaderError extends Error {
  public readonly httpCode;
  public readonly serverMessage: string | undefined;
  constructor(
    message: string,
    serverMessage: string | undefined,
    httpCode: number
  ) {
    super(message);
    this.httpCode = httpCode;
    this.serverMessage = serverMessage;
  }

  hasReponse() {
    return this.httpCode > 0;
  }
}

export class DataLoader {
  async get<T>(path: string) {
    const endpoint = await getMessageProcessorEndpoint();
    const url = `${endpoint}${path}`;
    Logger.info("Get: ", url);

    try {
      const response = await axios.get<T>(url);
      Logger.info("Response: ", response);
      return response;
    } catch (e) {
      Logger.error("Failed to load data", { e });
      if (e.response) {
        Sentry.captureException(e);
        throw new DataLoaderError(
          "Failed to load data",
          e.response.data?.message,
          e.response.status
        );
      } else if (e.request) {
        Sentry.captureException(e);
        throw new DataLoaderError("Failed to send request", "", -1);
      }
      throw new DataLoaderError("Error", "", -2);
    }
  }

  async put(path: string, payload: string) {
    const endpoint = await getMessageProcessorEndpoint();
    const url = `${endpoint}${path}`;
    Logger.info("Put: ", { url, payload });

    try {
      const response = await axios.put(url, payload);
      Logger.info("Response: ", response);
      return response;
    } catch (e) {
      Logger.error("Failed to load data", { e });
      if (e.response) {
        Sentry.captureException(e);
        throw new DataLoaderError(
          "Failed to load data",
          e.response.data?.message,
          e.response.status
        );
      } else if (e.request) {
        Sentry.captureException(e);
        throw new DataLoaderError("Failed to send request", "", -1);
      }
      throw new DataLoaderError("Error", "", -2);
    }
  }
}
